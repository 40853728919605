import React from 'react'
import ToolBar from '../Elements/Management/ToolBar'

function ThreeColumnBlock(props) {
    return (
        <div>
            <ToolBar />
            <div className="section shadow p-1">
                <div className="row">
                    <div className="col-3 col-md-3">
                        <img src="assets/img/sample/photo/1.jpg" alt="Content Img" className="imaged w-100" />
                    </div>
                    <div className="col-3 col-md-3">
                        <img src="assets/img/sample/photo/1.jpg" alt="Content Img" className="imaged w-100" />
                    </div>
                    <div className="col-6 col-md-6">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                            Previous
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThreeColumnBlock
