import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import SampleImage from '../../images/avatar3.jpg'
import { cssConverter } from '../../../features/websiteData/websiteDataSlice'
import CardImgSlider from '../../bitComponents/CardImgSlider'

function CARD_DFL3({ item, handleNewEditItem, keys }) {
    return (
        <div className="card position-relative h-100" style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
            <div className='row'>
                <div className={`${item.image.length > 0 ? 'col-md-7' : 'col-md-12'}`}>
                    <div className="card-body">
                        <h5 className="card-title cursor-pointer" onClick={() => { handleNewEditItem(keys) }} dangerouslySetInnerHTML={{ __html: item.title }} style={cssConverter(item.titleCss)} />
                        <span onClick={() => { handleNewEditItem(keys) }}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="card-text cursor-pointer bg-transparent" style={cssConverter(item.subtitleCss)} /></span>
                        <span onClick={() => { handleNewEditItem(keys) }}><MDEditor.Markdown source={item.description} linkTarget="_blank" className="card-text cursor-pointer bg-transparent" onClick={() => { handleNewEditItem(keys) }} style={cssConverter(item.descriptionCss)} /></span>
                        <div className="mt-2">
                            {item.button.map((value, key) => {
                                return <button className={`btn btn-sm me-1 ${value.style && value.style !== '' ? value.style : 'btn-primary'}`} key={key} onClick={() => handleNewEditItem(keys)} style={cssConverter(item.buttonCss)}>{value.title}</button>
                            })}
                        </div>
                    </div>
                </div>
                    {
                        item.image.length > 0 &&
                        <div className='col-md-5'>
                                <>
                                    {
                                        item.image.length === 1 ?
                                            <img src={item.image[0].src.length > 0 ? item.image[0].src : SampleImage} className="card-img-top" alt="image" />
                                            :
                                            <CardImgSlider item={item} />
                                    }
                                </>
                        </div>
                    }
            </div>
            {/* <img src={item.image[0].src.length > 0 ? item.image[0].src : SampleImage} className="card-img-top" alt="image" /> */}
        </div>
    )
}

export default CARD_DFL3