import React from 'react'
// import axios from 'axios'
// import store from '../../app/store'


// const myStore = store()

// console.log(myStore.getState())

// myStore.dispatch({
//     type:'INCREMENT'
// })

// FALE API CALL
// https://reqres.in/api/users/
function Test(props) {
    const getData = () => {
        // axios.get('http://localhost:3000/TestJson.json').then(res=>{
        //     // myStore.dispatch({
        //     //     type:'USER_DATA',
        //     //     value:res.data.data
        //     // })
        //
        //     const data = res.data.lastModifiedWebsite.DevJsonBlock
        //     console.log(data)
        // })
    }
    return (
        <div>
            <button className="btn btn-primary btn-block" onClick={getData}>GET DATA FAKE API</button>
        </div>
    )
}

export default Test
