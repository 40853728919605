import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  config: {},
  isVisible: false
}

export const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    editBlock: (state, action) => {
      console.log(state, action)
      const {
        blockId
      } = action.payload

      const config = localStorage.getItem(blockId)
      state.isVisible = true
      state.config = config
    }
  }
})

export const { editBlock } = toolbarSlice.actions;


export default toolbarSlice.reducer
