import React from 'react'

function Chat(props) {
    return (
        <div id="appCapsule">
            <div className="tab-content mt-1">
                <div className="tab-pane fade show active" id="content" role="tabpanel">
                    <ul className="listview image-listview media mb-2">
                        <li>
                            <a href="thread" className="item">
                                <div className="imageWrapper">
                                    <img src="assets/img/flags/4x3/in.svg" alt="Country Flag" className="imaged w64" />
                                </div>
                                <div className="in">
                                    <div>
                                        Hii, How are you ?
                                        <div className="text-muted mt-1">
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-info">
                                                    <ion-icon name="logo-chrome"></ion-icon>
                                                </i>
                                                <span className="chip-label">Chrome</span>
                                            </div>
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-info">
                                                    <ion-icon name="logo-firefox"></ion-icon>
                                                </i>
                                                <span className="chip-label">Firefox</span>
                                            </div>
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-secondary">
                                                    <ion-icon name="logo-android"></ion-icon>
                                                </i>
                                                <span className="chip-label">Android</span>
                                            </div>
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-secondary">
                                                    <ion-icon name="logo-apple"></ion-icon>
                                                </i>
                                                <span className="chip-label">iOS</span>
                                            </div>
                                            <div className="chip chip-media">
                                                <i className="chip-icon bg-secondary">
                                                    <ion-icon name="logo-windows"></ion-icon>
                                                </i>
                                                <span className="chip-label">Windows</span>
                                            </div>
                                            <span>
                                                <ion-icon name="location"></ion-icon> Panvel
                                            </span>
                                        </div>
                                    </div>
                                    <span className="badge badge-primary">15</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="thread" className="item">
                                <div className="imageWrapper">
                                    <img src="assets/img/flags/4x3/ca.svg" alt="Country Flag" className="imaged w64" />
                                </div>
                                <div className="in">
                                    <div>
                                        Contact
                                        <div className="text-muted">Toronto</div>
                                    </div>
                                    <span className="badge badge-primary">80</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="thread" className="item">
                                <div className="imageWrapper">
                                    <img src="assets/img/flags/4x3/lk.svg" alt="Country Flag" className="imaged w64" />
                                </div>
                                <div className="in">
                                    <div>
                                        Gallery
                                        <div className="text-muted">Colombo</div>
                                    </div>
                                    <span className="badge badge-primary">12</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Chat
