import React from 'react'

function EB_TABS_DFL1(props) {
    return (
        <div>
            Embeded Tabs
        </div>
    )
}

export default EB_TABS_DFL1
