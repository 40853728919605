import React from 'react';

function ItemToolbar({ handleNewEditItem, handleDeleteContent, keys, blockID }) {
  return (
    <div className='position-relative w-100 z-index-10'>
        <div className='d-flex position-absolute w-100 justify-content-between p-1'>
            <div>
                <span className="badge bg-white p-1 editBtnCss me-1 fs-10 text-dark" style={{'cursor': 'not-allowed'}}>Copy</span>
            </div>
            <div>
                <span className="badge bg-success p-1 editBtnCss me-1 fs-10" onClick={() => { handleNewEditItem(keys) }}>Edit</span>
                <span className="badge bg-danger p-1 editBtnCss fs-10" onClick={() => { handleDeleteContent(blockID, keys) }}>Delete</span>
            </div>

        </div>
    </div>
  )
}

export default ItemToolbar