import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideAddOffcanvas, showImageModal, showButtonModal } from '../../../features/modal/offcanvasSlice'
import { setLoading, setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
// import SimpleMDE from 'react-simplemde-editor'
import "easymde/dist/easymde.min.css"
import { useNavigate, useLocation } from 'react-router-dom'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title } from '@uiw/react-md-editor'

const AddOffcanvas = ({
    show,
    message,
    hideAddOffcanvas,
    blockid = '',
    contentPosition,
    websiteDataBlocks = {},
    setWebsiteData,
    showAppToast,
    showImageModal,
    showButtonModal,
    imageDataObj,
    buttonDataObj,
    setLoading
}) => {
    // console.log(contentPosition, blockid, websiteDataBlocks, imageDataObj, buttonDataObj)

    const [disUpdateButton, disableUpdateButton] = useState(false)
    const [value, setDescriptionValue] = useState('')
    const [blockStyleCode, setBlockStyleCode] = useState('')

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const [innerPageValue, setInnerPageValue] = useState('false');
    const [tempUrl, setTempUrl] = useState('');

    const [urlAccepted, setUrlAccepted] = useState(null);

    // const setInputValues = () => {
    //     document.getElementById('titleCheckbox').disabled = true
    //     document.getElementById('subtitleCheckbox').disabled = true
    //     document.getElementById('descriptionCheckbox').disabled = true
    //     document.getElementById('imageCheckbox').disabled = true
    // }

    const onChange = (value) => {
        setDescriptionValue(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        disableUpdateButton(true)
        const tagsArr = document.getElementsByName("tags")[0].value.split(',')
        console.log(tagsArr)
        const inputs = {
            "title": document.getElementsByName("title")[0].value,
            "subtitle": document.getElementsByName("subtitle")[0].value,
            "description": value,
            "image": (imageDataObj !== undefined) ? imageDataObj : [],
            "button": (buttonDataObj !== undefined) ? buttonDataObj : [],
            "category": "",
            "url": "",
            "form": "",
            "content": "",
            "tags": tagsArr,
            "detailPage": JSON.parse(innerPageValue),
            "detailPageUrl": document.getElementById('innerPageUrl') ? document.getElementById('innerPageUrl').value : ''
        }
        console.log(inputs)
        setLoading(true)
        if (blockid !== '') {
            const blockID = websiteDataBlocks[blockid].items.contents.split('.')[1]
            if (tokenData.userInfo.RoleID !== 'Moderator') {
                console.log(inputs)
                axios.post('/content/' + selectedDomain + '/' + blockID + '/' + contentPosition, inputs).then(res => {
                    console.log('RESPONSE', res)
                    if (res.data.message === 'Success') {
                        disableUpdateButton(false)
                        setWebsiteData(res.data.data)
                        showAppToast({ toastMessage: 'ADDED SUCCESSFULLY ', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                        hideAddOffcanvas()
                    } else {
                        disableUpdateButton(false)
                        showAppToast({ toastMessage: 'FAIL ', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                        hideAddOffcanvas()
                    }
                    setLoading(false)
                })
            } else {
                const selectedCategoryID = tokenData.SelectedDomain.ID
                const categoryPageID = localStorage.getItem('selectedPageID')
                axios.post('/content/' + selectedCategoryID + '/' + categoryPageID + '/' + blockID + '/' + contentPosition, inputs).then(res => {
                    console.log('RESPONSE', res)
                    if (res.data.message === 'Success') {
                        disableUpdateButton(false)
                        setWebsiteData(res.data.data)
                        showAppToast({ toastMessage: 'ADDED SUCCESSFULLY ', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                        hideAddOffcanvas()
                    } else {
                        disableUpdateButton(false)
                        showAppToast({ toastMessage: 'FAIL ', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                        hideAddOffcanvas()
                    }
                    setLoading(false)
                })
            }
        }
    }

    const handleCloseEvent = () => {
        hideAddOffcanvas()
    }

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    let titleToUrl = ''
    const handleInnerPageSwitch = (e) => {
        const title = document.getElementById('title')
        setInnerPageValue(e.target.value)
        if(title !== undefined) {
            if (e.target.value === 'true') {
                titleToUrl = title.value.replace(/[^\w\s]|_/g, '').replace(/\s+/g, '-').toLowerCase()
                setTimeout(function () {
                    document.getElementById('innerPageUrl').value = url + '/' + titleToUrl
                }, 2000);
                setTempUrl(url + '/' + titleToUrl)
                // console.log(url, titleToUrl)
            }
        }
    }

    const validateUrl = (value) => {
        axios.post('https://api.webweb.ai/appearance/checkUniqueUrl/' + selectedDomain, { url: value }).then(res => {
            if (res.data.message === 'Success') {
                console.log(res.data.data)
                if (res.data.data === 'Url Accepted') {
                    setUrlAccepted(true)
                } else if (res.data.data === 'Url Not Accepted') {
                    setUrlAccepted(false)
                }
                console.log(urlAccepted)
            } else {
                console.log('URL API ISSUE')
            }
        })
    }

    // const toolbar = [
    //     {
    //       name: "bold",
    //       action: function customFunction(editor) {
    //         editor.toggleBold();
    //       },
    //       className: "fa fa-bold",
    //       title: "Bold"
    //     },
    //     {
    //         name: "italic",
    //         action: function customFunction(editor) {
    //           editor.toggleItalic();
    //         },
    //         className: "fa fa-italic",
    //         title: "Italic"
    //     },
    //     // {
    //     //     name: "quote",
    //     //     action: function customFunction(editor){
    //     //         editor.toggleBlockquote()
    //     //     },
    //     //     className: "fa fa-quote-left",
    //     //     title: "Quote",
    //     // },
    //     "|",
    //     {
    //         name: "unorderedList",
    //         action: function customFunction(editor) {
    //             editor.toggleUnorderedList();
    //         },
    //         className: "fa fa-list-ul",
    //         title: "Unordered List"
    //     },
    //     {
    //         name: "orderedList",
    //         action: function customFunction(editor) {
    //             editor.toggleOrderedList();
    //         },
    //         className: "fa fa-list-ol",
    //         title: "Ordered List"
    //     },
    //     "|",
    //     {
    //         name: "image",
    //         action: function customFunction(editor) {
    //             editor.drawImage();
    //         },
    //         className: "fa fa-image",
    //         title: "Image"
    //     },
    //     {
    //         name: "link",
    //         action: function customFunction(editor) {
    //             editor.drawLink();
    //         },
    //         className: "fa fa-link",
    //         title: "Link"
    //     }
    //     // "|",
    //     // {
    //     //     name: "preview",
    //     //     action: function customFunction(editor) {
    //     //         editor.togglePreview();
    //     //     },
    //     //     className: "fa fa-eye no-disable",
    //     //     title: "Preview"
    //     // }
    // ];

    const handleInputLength = (data, trigger) => {
        // console.log(data.length)
        if (data.length > 0) {
            // document.getElementById(trigger + 'Checkbox').checked = true
            // document.getElementById(trigger + 'Checkbox').disabled = false
        } else {
            // document.getElementById(trigger + 'Checkbox').checked = false
            // document.getElementById(trigger + 'Checkbox').disabled = true
        }
    }

    const collectData = () => {
        // console.log('ENTERED OFFCANVAS')
        // console.log(show,message)
        // setInputValues()
    }

    useEffect(() => {
        if (blockid !== '' && blockid !== undefined) {
            setBlockStyleCode(websiteDataBlocks[blockid].properties.style)
        }
    }, [websiteDataBlocks, blockid])

    const handleShowImageModal = (imageData, id) => {
        let imageType = 'other'
        if (blockStyleCode === 'SM_HEAD' || blockStyleCode === 'SM_FOOT') {
            imageType = 'logo'
        } else if (blockStyleCode === 'SM_BRDC') {
            imageType = 'pageImage'
        } else if (blockStyleCode === 'CN_CRSL') {
            imageType = 'slider'
        } else if (blockStyleCode === 'CN_GLRY') {
            imageType = 'gallery'
        } else {
            imageType = 'other'
        }
        showImageModal({ isImageModalVisible: true, imageData: imageData, imageID: id, imageType: imageType })
    }

    const handleShowButtonModal = (buttonData, id) => {
        showButtonModal({ isButtonModalVisible: true, buttonData: buttonData, buttonID: id })
    }

    // const setInputValues = () => {
    //     document.getElementById('titleCheckbox').checked = false
    //     document.getElementById('subtitleCheckbox').checked = false
    //     document.getElementById('descriptionCheckbox').checked = false
    //     document.getElementById('imageCheckbox').checked = false

    //     document.getElementById('titleCheckbox').disabled = true
    //     document.getElementById('subtitleCheckbox').disabled = true
    //     document.getElementById('descriptionCheckbox').disabled = true
    //     document.getElementById('imageCheckbox').disabled = true
    // }

    return (
        <Offcanvas show={show} placement={'end'} backdrop={true} scroll={true} id="appCapsule" onEntered={collectData}>
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Add {message}</strong>
                    </div>
                    <span className="close-sidebar-button" style={{ cursor: 'pointer' }} onClick={handleCloseEvent}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section mb-2">
                    {/* <div className="listview-title mb-0">
                        <span>Change Background Image</span>
                    </div> */}
                    {message === 'Content' &&
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 col-md-12 mb-2" style={{ cursor: 'pointer' }}>
                                    {/* <div className="d-flex justify-content-center align-items-center">
                                        <img src="assets/img/sample/photo/1.jpg" alt="thumbnail" className="imaged w-100 border border-primary border-2" />
                                    </div> */}

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="image">
                                                <div className="row">
                                                    <div className="col-6">
                                                        Upload Image
                                                    </div>
                                                    {/* <div className="col-6 text-end">
                                                        <div className="webweb-checkbox form-check">
                                                            <input type="checkbox" className="form-check-input" id="imageCheckbox" name="inputField" />
                                                            <label className="form-check-label" htmlFor="imageCheckbox"></label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="custom-file-upload" id="fileUpload1">
                                        {/* <input type="button" id="fileuploadInput" /> */}
                                        <label onClick={() => handleShowImageModal([], 0)}>
                                            <span>
                                                <strong>
                                                    <ion-icon name="cloud-upload-outline"></ion-icon>
                                                    <i>Tap to Upload</i>
                                                </strong>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="title">
                                                <div className="row">
                                                    <div className="col-6">
                                                        Title
                                                    </div>
                                                    {/* <div className="col-6 text-end">
                                                        <div className="webweb-checkbox form-check">
                                                            <input type="checkbox" className="form-check-input" id="titleCheckbox" name="inputField" />
                                                            <label className="form-check-label" htmlFor="titleCheckbox"></label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </label>
                                            <input type="text" className="form-control form-control-sm" id="title" placeholder="Enter Title" name="title" onChange={(e) => { handleInputLength(e.target.value, 'title') }} autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="name5">
                                                <div className="row">
                                                    <div className="col-6">
                                                        Subtitle
                                                    </div>
                                                    {/* <div className="col-6 text-end">
                                                        <div className="webweb-checkbox form-check">
                                                            <input type="checkbox" className="form-check-input" id="subtitleCheckbox" name="inputField" />
                                                            <label className="form-check-label" htmlFor="subtitleCheckbox"></label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </label>
                                            <textarea id="address5" rows="2" className="form-control" placeholder="Enter Subtitle" name="subtitle" onChange={(e) => { handleInputLength(e.target.value, 'subtitle') }}></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="name5">
                                                <div className="row">
                                                    <div className="col-6">
                                                        Description
                                                    </div>
                                                    {/* <div className="col-6 text-end">
                                                        <div className="webweb-checkbox form-check">
                                                            <input type="checkbox" className="form-check-input" id="descriptionCheckbox" name="inputField" />
                                                            <label className="form-check-label" htmlFor="descriptionCheckbox"></label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </label>
                                            {/* <SimpleMDE options={{autofocus: true, spellChecker: false,nativeSpellcheck: false,toolbar: toolbar, status: false, minHeight: "150px", padding: '100px'}} onChange={onChange} value={value} /> */}
                                            <MDEditor height={200} value={value} onChange={onChange} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                            {/* <input type="hidden" name="descriptions" onChange={(e) => {handleInputLength(e.target.value, 'description')}} /> */}
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="tags">
                                                <div className="row">
                                                    <div className="col-6">
                                                        Tags
                                                    </div>
                                                </div>
                                            </label>
                                            <textarea type="tag" className="form-control form-control-sm" id="tags" placeholder="Enter Tags" name="tags" autoComplete="off"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    {/* Create New Page */}
                        
                                    <div className="mt-1">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label
                                                    className="form-label mb-0"
                                                    htmlFor="ineerPage"
                                                >
                                                    <div className="row">
                                                        <div className="col-12 col-md-12">
                                                            Create Inner Page?
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ marginBottom: '3px' }}>
                                            <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq1" value="true" checked={innerPageValue === 'true'} onChange={handleInnerPageSwitch} />
                                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq1">
                                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                                Yes
                                            </label>

                                            <input type="radio" className="btn-check" name="innerPageRadio" id="btnradioq2" value="false" checked={innerPageValue === 'false'} onChange={handleInnerPageSwitch} />
                                            <label className="btn btn-outline-primary btn-sm" htmlFor="btnradioq2">
                                                <ion-icon name="close-circle-outline"></ion-icon>
                                                No
                                            </label>
                                        </div><br />
                                        {innerPageValue === 'true' &&
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <h4 className="mb-0 mt-2 text-secondary">https://{selectedDomain}/</h4>
                                                    <input type="text" className="form-control" id="innerPageUrl" name={'innerPageUrl'} onChange={(e) => { validateUrl(e.target.value) }} placeholder='Enter URL' />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                                {innerPageValue === 'true' &&
                                                    <div classNames="row">
                                                        <div className="col-12 col-md-12 text-end">
                                                            {(urlAccepted) &&
                                                                <span className="text-success fs-14">Url Accepted</span>
                                                            }
                                                            {(!urlAccepted) &&
                                                                <span className="text-danger fs-14">Url Not Accepted</span>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <ul className="listview image-listview flush">
                                    <li>
                                        <div className="item">
                                            {/* <div className="in">
                                                <a href="#!" className="btn btn-primary btn-block w-75"><ion-icon name="book-outline" className="fs-6"></ion-icon><span className='fa fa-link'></span>&nbsp;Static</a>
                                                <div></div>
                                                <span className="text-muted"><ion-icon name="create-outline" className="fs-5" style={{cursor:'pointer'}}></ion-icon></span>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-12 mb-1">
                                                    <button type="button" className="btn btn-secondary btn-sm rounded-start rounded-0" onClick={() => handleShowButtonModal([], 0)}>
                                                        <ion-icon name="add-outline"></ion-icon>
                                                        Add Button
                                                    </button>
                                                    <button type="button" className="btn btn-icon btn-outline-secondary btn-sm rounded-end rounded-0" onClick={() => handleShowButtonModal([], 0)}>
                                                        <ion-icon name="create-outline"></ion-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebar-buttons webweb-sidebar-buttons">
                                <button className="btn btn-block btn-success" type="submit" style={{ borderRadius: "initial" }} disabled={disUpdateButton}>
                                    <ion-icon name="add-outline"></ion-icon> Add {message}
                                </button>
                            </div>
                        </form>
                    }
                    {message === 'Email' &&
                        <div className="row">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="form-label mb-0" htmlFor="title">
                                        <div className="row">
                                            <div className="col-6">
                                                Title
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="webweb-checkbox form-check">
                                                    <input type="checkbox" className="form-check-input" id="title" />
                                                    <label className="form-check-label" htmlFor="title"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <input type="text" value="Static Title" className="form-control form-control-sm" id="title" placeholder="Enter Title" autoComplete="off" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="form-label mb-0" htmlFor="name5">
                                        <div className="row">
                                            <div className="col-6">
                                                Subtitle
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="webweb-checkbox form-check">
                                                    <input type="checkbox" className="form-check-input" id="subtitle" />
                                                    <label className="form-check-label" htmlFor="subtitle"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <textarea id="address5" value="Static Subtitle" rows="2" className="form-control" placeholder="Enter Subtitle"></textarea>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="form-label mb-0" htmlFor="name5">
                                        <div className="row">
                                            <div className="col-6">
                                                Description
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="webweb-checkbox form-check">
                                                    <input type="checkbox" className="form-check-input" id="descriptionMarkdown" />
                                                    <label className="form-check-label" htmlFor="descriptionMarkdown"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    {/* <SimpleMDE options={{autofocus: true,spellChecker: false,nativeSpellcheck: false,toolbar: toolbar, status: false, minHeight: "150px",}} value="Static Description" /> */}
                                    <MDEditor height={200} value="Static Description" commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{ backgroundColor: 'white', color: '#333333' }} />
                                </div>
                            </div>
                            <ul className="listview image-listview flush">
                                <li>
                                    <div className="item">
                                        <div className="in">
                                            <a href="#!" className="btn btn-primary btn-block w-75"><ion-icon name="book-outline" className="fs-6"></ion-icon><span className='fa fa-link'></span>&nbsp;Static</a>
                                            <div></div>
                                            <span className="text-muted"><ion-icon name="create-outline" className="fs-5" style={{ cursor: 'pointer' }}></ion-icon></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    }
                </div>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        blockid,
        contentPosition,
        imageDataObj,
        buttonDataObj
    } = offcanvas
    const {
        blocks: websiteDataBlocks
    } = websiteData
    return {
        blockid,
        contentPosition,
        websiteDataBlocks,
        imageDataObj,
        buttonDataObj
    }
}

const mapDispatchToProps = {
    hideAddOffcanvas,
    setWebsiteData,
    showAppToast,
    showImageModal,
    showButtonModal,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOffcanvas)
