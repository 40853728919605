import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { hideImageModal, newImageData } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import SearchPhotos from '../Management/SearchPhotos'
import Offcanvas from 'react-bootstrap/Offcanvas'
import SampleImage from '../../images/addImage.png'
import { updateImageData } from '../../../features/websiteData/websiteDataSlice'
import { formatBytes } from '../../../features/websiteData/websiteDataSlice';
import Cropper from 'react-cropper'
import "cropperjs/dist/cropper.css"
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { it } from 'faker/lib/locales'
import SelectComponent from '../Comps/SelectComponent'
import { useRef } from 'react'

const ImageModal = ({
    show,
    imageData,
    itemID,
    imageID,
    hideImageModal,
    imageDataObj,
    newImageData,
    contentBlockID,
    updateImageData,
    activeImageData,
    showAppToast,
    imageType,
    CropperSizes
}) => {
    // console.log(imageData, itemID, imageID, imageDataObj, newImageData, updateImageData, activeImageData, contentBlockID)
    const [active, setActive] = useState(0);
    const [AlternateText, setAlternateText] = useState('');
    const [selectImage, selectedImage] = useState([])
    const [cropperAspectRatio, setCropperAspectRatio] = useState('');
    const [cropper, setCropper] = useState();
    const [preloader, setPreloader] = useState(false)
    const [cropperActive, setCropperActive] = useState(false)
    const [cropperConfigList, setCropperConfigList] = useState(null)
    const [cropperConfig, setCropperConfig] = useState(null)
    const [imageExtention, setImageExtention] = useState('')
    const [imageStyle, setImageStyle] = useState('none');

    const [activeTab, setActiveTab] = useState('Upload');

    const [FilesData, setFileData] = useState();

    const [videoExtension, setVideoExtension] = useState(null);
    const [localVideo, setLocalVideo] = useState(null);
    const [videoPreview, setVideoPreview] = useState(false);
    const [thumbnailImage, setThumbnailImageImage] = useState(true);

    const [embedLink, setEmbedLink] = useState('');
    const [disableBtn, setDisableBtn] = useState(true);
    const croperef = useRef();

    const [cropperSizeList, setCropperSizeList] = useState([
        {
            'label': 'Free',
            'value': "NaN"
        },
        {
            'label': '1:1',
            'value': '1'
        },
        {
            'label': '2:3',
            'value': '0.666667'
        },
        {
            'label': '3:2',
            'value': '1.5'
        },
        {
            'label': '3:4',
            'value': '0.75'
        },
        {
            'label': '4:3',
            'value': '1.333333'
        },
        {
            'label': '8:3',
            'value': '2.66666666667'
        },
        {
            'label': '9:16',
            'value': '0.5625'
        },
        {
            'label': '16:9',
            'value': '1.777777'
        },
        {
            'label': '18:3',
            'value': '6'
        }
    ])

    useEffect(() => {
        setActive(imageID);
        if (imageData.length !== 0 && imageData[0].style !== undefined && imageData[0].style !== null) {
            setImageStyle(imageData[0].style);
            if (document.getElementById("imageStyle")) {
                document.getElementById("imageStyle").value = imageData[0].style;
            }
        }
        if (imageData.length !== 0 && imageData[0].videoUrl !== undefined && imageData[0].videoUrl !== null) {
            setEmbedLink(imageData[0].videoUrl);
        }
        if(imageData.length !== 0 && imageData[0].alt) {
            setAlternateText(imageData[0].alt)
        }
    }, [imageID, imageData, imageDataObj])

    if (imageDataObj !== '' && imageDataObj !== undefined) {
        imageData = imageDataObj
    } else {
        imageData = imageData
    }

    useEffect(() => {
        // console.log('USEEFFECt')
        if (contentBlockID && imageID >= 0 && itemID >= 0 && imageDataObj.length > 0) {
            // console.log(imageDataObj)
            updateImageData({
                newImageData: imageDataObj,
                blockID: contentBlockID,
                blockItemIndex: imageID,
                itemID
            })
            newImageData({ imageDataObj, imageID, itemID })
        }
        else {
            // console.log('ELSE')
            // console.log(imageDataObj)
            if (imageDataObj !== undefined) {
                if (imageDataObj.length > 0) {
                    // console.log('ELSE')
                    updateImageData({
                        newImageData: imageDataObj,
                        blockID: contentBlockID,
                        blockItemIndex: imageID,
                        itemID: (itemID === undefined) ? 0 : itemID
                    })
                    newImageData({ imageDataObj, imageID, itemID: (itemID === undefined) ? 0 : itemID })
                }
            }
        }
    }, [imageDataObj])

    const activeImage = (id) => {
        if (id === '' || id === null || id === undefined) {
            setActive(imageID)
        } else {
            setActive(id)
        }
    }

    const handleBack = () => {
        setVideoExtension(null);
        setLocalVideo(null);
        setVideoPreview(false);
        setCropperActive(false)
    }
    const handleRemoveImage = () => {
        newImageData({ imageDataObj: [] })
    }

    const handleFileUpload = () => {
        const fileData = document.getElementById('uploadFile');
        const file = URL.createObjectURL(fileData.files[0])
        console.log(file, fileData.files[0].type, activeImageData);

        let cropperKey = ''
        if (contentBlockID.includes('CRSL')) {
            cropperKey = 'slider'
        } else if (contentBlockID.includes('BRDC')) {
            cropperKey = 'pageImage'
        } else if (contentBlockID.includes('GLRY')) {
            cropperKey = 'gallery'
        } else if (contentBlockID.includes('HEAD') || contentBlockID.includes('FOOT')) {
            cropperKey = 'logo'
        } else {
            cropperKey = 'other'
        }
        if (fileData.files[0].type === 'image' || fileData.files[0].type === 'image/png' || fileData.files[0].type === 'image/jpeg' || fileData.files[0].type === 'image/jpg') {
            if(cropperConfigList[cropperKey].imageLimit && Number(cropperConfigList[cropperKey].imageLimit) < fileData.files[0].size) {
                showAppToast({ toastMessage: `Max image file size should be ${formatBytes(Number(cropperConfigList[cropperKey].imageLimit))}, selected file size is ${formatBytes(fileData.files[0].size)}`, background: 'danger', timeout: '4000', icon: 'close-circle-outline', position: 'toast-center' });
                document.getElementById('uploadFile').value = '';
                return
            }
            setImageExtention(fileData.files[0].type)
            selectedImage(file)
            setCropperActive(true)
        } else if (fileData.files[0].type === 'video/mp4' && imageType !== 'Profile') {
            if(cropperConfigList[cropperKey].videoLimit && Number(cropperConfigList[cropperKey].videoLimit) < fileData.files[0].size) {
                showAppToast({ toastMessage: `Max video file size should be ${formatBytes(Number(cropperConfigList[cropperKey].videoLimit))}, , selected file size is ${formatBytes(fileData.files[0].size)}`, background: 'danger', timeout: '4000', icon: 'close-circle-outline', position: 'toast-center' });
                document.getElementById('uploadFile').value = '';
                return
            }
            setVideoExtension(fileData.files[0].type);
            setLocalVideo(file);
            setFileData(fileData);
            setVideoPreview(true);
        } else {
            if(imageType === 'Profile') {
                showAppToast({ toastMessage: 'Please select an image file', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' });
            } else {
                showAppToast({ toastMessage: 'Please select an image or mp4 file', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' });
            }
            document.getElementById('uploadFile').value = '';
        }
    }

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    let selectedDomain = ""

    if (tokenData.userInfo.RoleID != 'Moderator') {
        selectedDomain = tokenData.SelectedDomain.Domain
    } else {
        selectedDomain = tokenData.SelectedDomain.CategoryName
    }

    if (imageDataObj !== '' && imageDataObj !== undefined) {
        activeImageData = imageDataObj
    } else {
        activeImageData = activeImageData
    }
    const handleCroppedImage = async () => {
        if (typeof cropper !== "undefined") {
            let imgHieght = 720;
            let imageWidth = 720;
            if(cropperConfig?.crop?.height) imgHieght = cropperConfig.crop.height;
            if(cropperConfig?.crop?.width) imageWidth = cropperConfig.crop.width;
            cropper.getCroppedCanvas({
                maxHeight: imgHieght,
                maxWidth: imageWidth,
                fillColor: 'transparent'
            }).toBlob((blob) => {
                const formData = new FormData()
                formData.append('inputFile', blob)

                setPreloader(true)
                let cropperKey = ''
                if (contentBlockID.includes('CRSL')) {
                    cropperKey = 'slider'
                } else if (contentBlockID.includes('BRDC')) {
                    cropperKey = 'pageImage'
                } else if (contentBlockID.includes('GLRY')) {
                    cropperKey = 'gallery'
                } else if (contentBlockID.includes('HEAD') || contentBlockID.includes('FOOT')) {
                    cropperKey = 'logo'
                } else {
                    cropperKey = 'other'
                }
                // console.log(formData, blob);
                axios.post('/website/upload/' + selectedDomain + '/' + cropperKey, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res => {
                    if (res.data.message === 'Success') {
                        // console.log(imageID)
                        if (imageID === -1) {
                            console.log('IF')
                            obj = { 'src': res.data.data, 'alt': AlternateText, 'style': imageStyle, 'videoUrl': (imageData[0] && imageData[0].videoUrl) ? imageData[0].videoUrl : '' }
                            var imageDataArr = []
                            imageDataArr.push(obj)
                            newImageData({ imageDataObj: imageDataArr })
                            showAppToast({ toastMessage: 'IMAGE UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                            setPreloader(false)
                            setCropperActive(false)
                            hideImageModal()
                        } else {
                            console.log('ELSE')
                            var obj = { 'src': res.data.data, 'alt': AlternateText, 'style': imageStyle, 'videoUrl': (imageData[0] && imageData[0].videoUrl) ? imageData[0].videoUrl : '' }
                            var imageDataArr = []
                            // console.log(activeImageData)
                            if (activeImageData !== undefined) {
                                activeImageData.map((value, key) => {
                                    if (key === imageID) {
                                        imageDataArr[imageID] = obj
                                    } else {
                                        imageDataArr[key] = value
                                    }
                                })
                            }

                            if (typeof imageDataArr[imageID] === 'undefined') {
                                imageDataArr.push(obj)
                            }
                            newImageData({ imageDataObj: imageDataArr })

                            showAppToast({ toastMessage: 'IMAGE UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                            setPreloader(false)
                            setCropperActive(false)
                            hideImageModal()
                        }
                    } else {
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                        setCropperActive(false)
                        hideImageModal()
                    }
                })
            }, imageExtention, 100);
        } else {
            console.log('ELSE')
        }
    };

    const handleVideoUpload = async () => {

        const fileData = document.getElementById('uploadFile');
        const formData2 = new FormData();
        formData2.append('inputFile', fileData.files[0]);
        console.log(FilesData, formData2, imageID, imageData, thumbnailImage);
        let cropperKey = ''
        if (contentBlockID.includes('CRSL')) {
            cropperKey = 'slider'
        } else if (contentBlockID.includes('BRDC')) {
            cropperKey = 'pageImage'
        } else if (contentBlockID.includes('GLRY')) {
            cropperKey = 'gallery'
        } else if (contentBlockID.includes('HEAD') || contentBlockID.includes('FOOT')) {
            cropperKey = 'logo'
        } else {
            cropperKey = 'other'
        }

        if (!thumbnailImage) {
            axios.post('/website/uploadVideo/' + selectedDomain + '/' + cropperKey, formData2, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                console.log('IF');

                if (res.data.message === 'Success') {
                    // if (imageID === -1) {
                    //     obj = { 'src': (imageData[0] !== undefined && imageData[0].src) ? imageData[0].src : '' , 'alt': (imageData[0] !== undefined && imageData[0].alt) ? imageData[0].alt : '', 'style': imageStyle, 'videoUrl': res.data.data }
                    //     var imageDataArr = []
                    //     imageDataArr.push(obj)
                    //     newImageData({ imageDataObj: imageDataArr })
                    //     showAppToast({ toastMessage: 'VIDEO UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    //     setPreloader(false)
                    //     setCropperActive(false);
                    //     setVideoExtension(null)
                    //     setLocalVideo(null);
                    //     setVideoPreview(false);
                    //     hideImageModal();
                    // } else {
                    let obj = JSON.parse(JSON.stringify(imageData[0]));
                    // { 'src': imageData[0].src ? imageData[0].src : '', 'alt': imageData[0].alt ? imageData[0].alt : '', 'style': imageStyle, 'videoUrl': res.data.data }
                    obj.videoUrl = res.data.data;
                    let imageDataArr = [];
                    // if (activeImageData !== undefined) {
                    //     activeImageData.map((value, key) => {
                    //         if (key === imageID) {
                    //             imageDataArr[imageID] = obj
                    //         } else {
                    //             imageDataArr[key] = value
                    //         }
                    //     })
                    // }

                    // if (typeof imageDataArr[imageID] === 'undefined') {
                        imageDataArr.push(obj)
                    // }
                    newImageData({ imageDataObj: imageDataArr })

                    showAppToast({ toastMessage: 'VIDEO UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    setPreloader(false)
                    setCropperActive(false);
                    setVideoExtension(null)
                    setLocalVideo(null);
                    setVideoPreview(false);
                    hideImageModal()
                    // }
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    setPreloader(false)
                    setCropperActive(false)
                    hideImageModal()
                }
            })
        } else {
            let canva = document.createElement("CANVAS");
            let videoUp = document.getElementById('videoUp');

            canva.width = videoUp.videoWidth;
            canva.height = videoUp.videoHeight;
            canva.getContext('2d').drawImage(videoUp, 0, 0, videoUp.videoWidth, videoUp.videoHeight);
            canva.toBlob((blob) => {
                const formData = new FormData()
                formData.append('inputFile', blob)

                setPreloader(true)

                axios.post('/website/upload/' + selectedDomain + '/' + cropperKey, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(res => {
                    if (res.data.message === 'Success') {
                        console.log('ELSE');

                        axios.post('/website/uploadVideo/' + selectedDomain + '/' + cropperKey, formData2, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).then(response => {
                            if (response.data.message === 'Success') {
                                let obj = JSON.parse(JSON.stringify(imageData[0]));
                                obj.src = res.data.data;
                                obj.videoUrl = response.data.data;
                                // { 'src': res.data.data, 'alt': imageData[0].alt ? imageData[0].alt : '', 'style': imageStyle, 'videoUrl': response.data.data }
                                let imageDataArr = [];
                                // if (activeImageData !== undefined) {
                                //     activeImageData.map((value, key) => {
                                //         if (key === imageID) {
                                //             imageDataArr[imageID] = obj
                                //         } else {
                                //             imageDataArr[key] = value
                                //         }
                                //     })
                                // }
                                // if (typeof imageDataArr[imageID] === 'undefined') {
                                    imageDataArr.push(obj)
                                // }
                                newImageData({ imageDataObj: imageDataArr })

                                showAppToast({ toastMessage: 'VIDEO UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                                setPreloader(false)
                                setCropperActive(false)
                                setVideoExtension(null)
                                setLocalVideo(null);
                                setVideoPreview(false);
                                hideImageModal()
                            } else {
                                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                                setPreloader(false)
                                setCropperActive(false)
                                setVideoExtension(null)
                                setLocalVideo(null);
                                setVideoPreview(false);
                                hideImageModal()
                            }
                        })

                    } else {
                        showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                        setPreloader(false)
                        setCropperActive(false)
                        hideImageModal()
                    }
                })
            }, imageExtention, 100);
        }

    }

    const handleLinkUpload = async () => {
        let obj = { 'src': '', 'alt': AlternateText, 'style': imageStyle, 'videoUrl': embedLink }
        if (imageData.length > 0) {
            obj = JSON.parse(JSON.stringify(imageData[0]));
            obj.alt = AlternateText;
            obj.style = imageStyle;
            obj.videoUrl = embedLink;
        }
        var imageDataArr = [];
        // if (activeImageData !== undefined) {
        //     activeImageData.map((value, key) => {
        //         if (key === imageID) {
        // imageDataArr[imageID] = obj
        //         } else {
        //             imageDataArr[key] = value
        //         }
        //     })
        // }

        // if (typeof imageDataArr[imageID] === 'undefined') {
        imageDataArr.push(obj)
        // }
        console.log(activeImageData, imageID, imageDataArr);
        newImageData({ imageDataObj: imageDataArr })

        showAppToast({ toastMessage: 'EMBED LINK UPLOADED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
        setPreloader(false)
        setCropperActive(false)
        hideImageModal()
    }

    const changeLink = (link) => {
        setEmbedLink(link);
        if(link.trim().length === 0) {
            setDisableBtn(false);
        } else {
            const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
            if (urlPattern.test(link)) {
                setDisableBtn(false);
            } else {
                setDisableBtn(true);
            }
        }
    }

    const closeImageModal = () => {
        if (imageData[0] !== undefined) {
            const obj = JSON.parse(JSON.stringify(imageData[0]));
            obj.style = imageStyle;
            obj.alt = AlternateText;
            // { 'src': imageData[0].src, 'alt': '', 'style': imageStyle };
            var imageDataArr = []
            imageDataArr.push(obj)
            newImageData({ imageDataObj: imageDataArr })
            // console.log(imageData, imageDataArr);
        }
        setImageStyle('none');
        hideImageModal();
        setDisableBtn(false);
        setVideoExtension(null);
        setLocalVideo(null);
        setVideoPreview(false);
        setEmbedLink('');
        setAlternateText('');
    }
    useEffect(() => {
        if (localStorage.getItem('cropperConfig') === undefined || localStorage.getItem('cropperConfig') === null) {
            axios.get('/info/style/cropperConfig').then(res => {
                if (res.data.message === 'Success') {
                    setCropperConfigList(res.data.data)
                    localStorage.setItem('cropperConfig', JSON.stringify(res.data.data))
                } else {
                    console.log('SOMETHING WENT WRONG')
                }
            })
            // console.log(cropperConfigList)
        } else {
            setCropperConfigList(JSON.parse(localStorage.getItem('cropperConfig')));
            // console.log(cropperConfigList)
        }
    }, [imageType])

    useEffect(() => {
        if(imageType === 'Profile') {
            setCropperAspectRatio({ 'label': '1:1', 'value': '1' })
        } else if (imageType !== null && cropperConfigList !== null && cropperConfigList !== undefined && imageType !== undefined) {
            setCropperConfig(cropperConfigList[imageType]);
            const item = cropperSizeList.filter(item => item.value === cropperConfigList[imageType].crop.aspectRatio)
            setCropperAspectRatio(item[0]);
        }
    }, [cropperConfigList])

    const changeImageStyle = (e) => {
        // console.log(e.target.value);
        setImageStyle(e.target.value)
    }

    useEffect(() => {
        if ((cropperAspectRatio !== null && typeof (cropperAspectRatio) !== 'string' && cropperAspectRatio.value !== undefined)) {
            // console.log(cropperAspectRatio, typeof (cropperAspectRatio), cropper, croperef, selectImage);
            if (selectImage.length !== 0) {
                setCropperActive(false);
                setTimeout(() => {
                    selectedImage(selectImage);
                    setCropperActive(true);
                }, 10)
            }
        }
    }, [cropperAspectRatio])

    useEffect(() => {
       if(!show) {
        handleBack();
       }
    }, [show])
    

    return (
        <Offcanvas show={show} placement={'end'} backdrop={true} class="offcanvas offcanvas-end" id="imageModal" tabindex="-1" role="dialog">
            {/* <Offcanvas.Header class="offcanvas-header ps-1 pe-0" style={{display:'inline'}}>
                <div class="row">
                    <div class="col-4 col-md-4 d-flex align-items-center">
                        <h5 class="modal-title text-start">Edit Image</h5>
                    </div>
                    <div class="col-8 col-md-8 text-end">
                        <button class="btn btn-text-danger btn-sm" onClick={handleRemoveImage}><ion-icon name="close-circle-outline"></ion-icon> Remove Image</button>
                        <button class="btn btn-text-secondary btn-sm" onClick={hideImageModal}><ion-icon name="close-outline" class="m-0 fs-4"></ion-icon></button>
                    </div>
                </div>
            </Offcanvas.Header> */}
            <Offcanvas.Body class="offcanvas-body p-0">
                <div class="profileBox webweb-profileBox">
                    <div class="in">
                        <strong>Manage Media</strong>
                    </div>
                    <a href="#!" class="close-sidebar-button" onClick={closeImageModal}>
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="row w-100 mx-0" style={{ marginTop: '50px' }}>
                    <div class="col-12 col-md-12">
                        <div class="row p-1 d-flex justify-content-center">
                            {imageData.map((img, key) => {
                                return <div class="col-6 col-md-6 mb-1">
                                    <img src={img.src} alt={img.alt} className={(key === active) ? `imaged w-100 border-primary ${imageStyle}` : `imaged w-100 ${imageStyle}`} onClick={() => { activeImage((key === -1 || key === undefined) ? 0 : key) }} />
                                </div>
                            })}
                            <br />
                            {
                            imageType !== 'Profile' &&
                            <div class="col-12 col-md-12 mb-0">
                                <div class="form-group boxed pb-0">
                                    <div class="input-wrapper">
                                        <select class="form-control form-select" id="imageStyle" onChange={(e) => changeImageStyle(e)}>
                                            <option value="none" selected disabled>Select image styles</option>
                                            <option value="img-thumbnail">Thumbnail</option>
                                            <option value="img-thumbnail rounded">Thumbnail with Circle</option>
                                            <option value="rounded">Circle</option>
                                            <option value="border-radius">Edges Rounded</option>
                                            <option value="shadow">Shadow</option>
                                            <option value="img-thumbnail shadow">Thumbnail & Shadow</option>
                                            <option value="rounded shadow">Circle & Shadow</option>
                                            <option value="border-radius shadow">Edges Rounded & Shadow</option>
                                            <option value="none">None</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            }
                            <div class="col-12 col-md-12 mb-1">
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <label class="form-label" htmlFor="alttext">Image Alternate Title</label>
                                        <input type="text" class="form-control" id="alttext" name="alttext" value={AlternateText} onChange={(e) => setAlternateText(e.target.value)} placeholder="Enter altenate text for image" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <ul class="nav nav-tabs lined border-top rounded-0" role="tablist">
                        {
                            imageType !== 'Profile' &&
                            <li class="nav-item">
                                <button class={`nav-link ${activeTab === 'Explore' && 'active'}`} onClick={() => setActiveTab('Explore')} style={{ height: '42px' }}>
                                    Explore
                                </button>
                            </li>
                        }
                            <li class="nav-item">
                                <button class={`nav-link ${activeTab === 'Upload' && 'active'}`} onClick={() => setActiveTab('Upload')} style={{ height: '42px' }}>
                                    Upload
                                </button>
                            </li>
                        {
                            imageType !== 'Profile' &&
                            <li class="nav-item">
                                <button class={`nav-link ${activeTab === 'Youtube' && 'active'}`} onClick={() => setActiveTab('Youtube')} style={{ height: '42px' }}>
                                    Youtube
                                </button>
                            </li>
                        }
                            {/* <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" href="#recentTab" role="tab" style={{height: '42px'}}>
                                    Recent
                                </a>
                            </li> */}
                        </ul>
                    </div>
                    <div class="col-12 col-md-12 px-0">
                        <div class="tab-content extra-header-active mt-2">
                            <div class={`tab-pane fade ${activeTab === 'Explore' && 'show active'}`} id="exploreTab" role="tabpanel">
                                <SearchPhotos activeImageData={(imageData !== '' && imageData !== undefined) ? imageData : ''} id={active} imageType={imageType} contentBlockID={contentBlockID} />
                            </div>
                            <div class={`tab-pane fade ${activeTab === 'Upload' && 'show active'}`} id="uploadTab" role="tabpanel">
                                {/* <div class="section full mt-2">
                                    <div class="section-title text-danger">Note: Ratio for this image is fixed 6:9</div>
                                </div> */}
                                <div class="section full">
                                    <div class="wide-block pb-2 border-0 pt-0">
                                        {/* {!cropperActive && !videoPreview && */}
                                        <div style={{ 'display': (!cropperActive && !videoPreview) ? 'block' : 'none' }}>
                                            <button class="btn btn-secondary btn-block mb-2" disabled><ion-icon name="camera-outline"></ion-icon> Capture</button>

                                            {/* <div class="section-title">Upload Image</div> */}
                                            <form>
                                                <div class="custom-file-upload">
                                                    <input type="file" id="uploadFile" onChange={handleFileUpload} />
                                                    <label htmlFor="uploadFile">
                                                        <span>
                                                            <strong>
                                                                <ion-icon name="cloud-upload-outline"></ion-icon>
                                                                <i>Tap to Upload Image {imageType !== 'Profile' ? ' / Video' : null}</i>
                                                            </strong>
                                                        </span>
                                                    </label>
                                                </div>
                                            </form>
                                        </div>
                                        {/* } */}
                                        {cropperActive &&
                                            <>
                                                <div class="text-start mb-1 mt-1">
                                                    <div class="row">
                                                        <div class="col-6 col-md-6">
                                                            <button class="btn btn-text-secondary btn-sm px-0" onClick={handleBack}> <ion-icon name="arrow-back-outline"></ion-icon> Back</button>
                                                        </div>
                                                        <div class="col-6 col-md-6 text-end">
                                                            {!preloader &&
                                                                <button class="btn btn-success btn-sm" onClick={handleCroppedImage}><ion-icon name="checkmark-outline" class="me-0"></ion-icon></button>
                                                            }
                                                            {preloader &&
                                                                <button class="btn btn-success btn-sm" type="button" disabled>
                                                                    <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                                    Uploading...
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    imageType !== 'Profile' &&
                                                    <div class="col-12 col-md-12 mb-1 px-0">
                                                        <div class="form-group boxed">
                                                            <div class="input-wrapper">
                                                                <SelectComponent value={cropperAspectRatio} setValue={setCropperAspectRatio} options={cropperSizeList} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div>
                                                    <Cropper
                                                        aspectRatio={(cropperAspectRatio !== null && typeof (cropperAspectRatio) !== 'string' && cropperAspectRatio.value !== undefined) ? cropperAspectRatio.value : NaN} // 
                                                        style={{ height: "100%", width: "100%" }}
                                                        zoomable={false}
                                                        preview=".img-preview"
                                                        src={selectImage}
                                                        viewMode={1}
                                                        minCropBoxHeight={50}
                                                        minCropBoxWidth={50}
                                                        background={false}
                                                        responsive={true}
                                                        autoCropArea={1}
                                                        checkOrientation={false}
                                                        id="cropperId"
                                                        onInitialized={(instance) => {
                                                            // console.log(instance);
                                                            setCropper(instance);
                                                        }}
                                                        // setCropperAspectRatio={(cropperAspectRatio !== null && typeof (cropperAspectRatio) !== 'string' && cropperAspectRatio.value !== undefined) ? cropperAspectRatio.value : NaN}
                                                        ref={croperef}
                                                        guides={true}

                                                    /></div>
                                                {/* <div>
                                                {(cropperAspectRatio !== null && typeof (cropperAspectRatio) !== 'string' && cropperAspectRatio.value !== undefined) ? cropperAspectRatio.value : NaN}
                                                </div> */}
                                            </>
                                        }
                                        {
                                            videoPreview &&
                                            <>
                                                <div class="text-start mb-1 mt-1">
                                                    <div class="row">
                                                        <div class="col-6 col-md-6">
                                                            <button class="btn btn-text-secondary btn-sm px-0" onClick={handleBack}> <ion-icon name="arrow-back-outline"></ion-icon> Back</button>
                                                        </div>
                                                        <div class="col-6 col-md-6 text-end">
                                                            {!preloader &&
                                                                <button class="btn btn-success btn-sm" onClick={handleVideoUpload}><ion-icon name="checkmark-outline" class="me-0"></ion-icon></button>
                                                            }
                                                            {preloader &&
                                                                <button class="btn btn-success btn-sm" type="button" disabled>
                                                                    <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                                    Uploading...
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <video id="videoUp" src={localVideo} controls muted style={{ 'width': '100%', 'aspectRatio': '3/2', 'backgroundColor': '#353535' }} />
                                                    <div className='mt-2'>
                                                        <div class="form-check d-flex align-items-center border rounded p-2 bg-gray">
                                                            <input type="checkbox" class="form-check-input" id="customCheckc1" checked={thumbnailImage} onClick={(e) => setThumbnailImageImage(e.target.checked)} />
                                                            <label class="form-check-label" htmlFor="customCheckc1" >
                                                                <h5 className='mb-0' style={{'marginTop': '2px'}}>Extract thumbnail image from video</h5>
                                                            </label>
                                                        </div>
                                                        {
                                                            thumbnailImage &&
                                                            <div class="alert alert-outline-light mt-1 d-flex align-items-center p-1" role="alert">
                                                                <ion-icon name="bulb-outline" style={{'fontSize': '34px'}}></ion-icon>
                                                                <span className='ms-2 fs-12' style={{'lineHeight': '15px'}}>
                                                                    Pause video at specific timestamp to extract that frame as a thumbnail
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class={`tab-pane fade ${activeTab === 'Youtube' && 'show active'}`} id="embedTab" role="tabpanel">
                                <div class="section full">
                                    <div class="wide-block pb-2 border-0 pt-0">
                                        <div class="text-start mb-1 mt-1">
                                            <div class="row">
                                                <div class="col-6 col-md-6" />
                                                <div class="col-6 col-md-6 text-end">
                                                    {!preloader &&
                                                        <button class="btn btn-success btn-sm" disabled={disableBtn} onClick={handleLinkUpload}><ion-icon name="checkmark-outline" class="me-0"></ion-icon></button>
                                                    }
                                                    {preloader &&
                                                        <button class="btn btn-success btn-sm" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                            Uploading...
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group boxed">
                                            <div class="input-wrapper">
                                                <label class="form-label" htmlFor="phone5">Youtube Video Link</label>
                                                <input type="text" class="form-control" id="embedVideoLink" name="embedVideoLink" value={embedLink} onChange={(e) => changeLink(e.target.value)} placeholder="Enter viedo link" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="tab-pane fade" id="recentTab" role="tabpanel">
                                <div class="section full mt-1">
                                    <div class="wide-block border-0 pt-2 pb-2">
                                        <div class="row overflow-auto" style={{height:'250px'}}>
                                            <div class="col-6 col-md-6 mb-1">
                                                <img src="assets/img/sample/photo/1.jpg" alt="Avatar" class="imaged w-100" />
                                            </div>
                                            <div class="col-6 col-md-6 mb-1">
                                                <img src="assets/img/sample/photo/2.jpg" alt="Avatar" class="imaged w-100" />
                                            </div>
                                            <div class="col-12 col-md-12 text-center">
                                                <button class="btn btn-secondary">Load More</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        itemID,
        imageDataObj,
        imageType,
        CropperSizes
    } = offcanvas
    return {
        itemID,
        imageDataObj,
        imageType,
        CropperSizes
    }
}

const mapDispatchToProps = {
    hideImageModal,
    newImageData,
    updateImageData,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal)
