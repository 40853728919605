import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { showEditOffcanvas, showAddOffcanvas, showButtonModal, newButtonData, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import CardSelector from './Cards/CardSelector'

const CN_LIST_TIME1 = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    showAddOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    websiteDataBlocks, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible:true, itemID:itemID, items:items, blockID: blockID, properties:properties,seq:seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID:itemID, items:items, blockID: blockID, properties:properties, seq:seq })
        navigate('/detailPage')
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID, 'Style': properties.style , 'StyleID': properties.styleID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
          '.'
        )[1]
        showDeleteModal({
          isDeleteVisible: true,
          deleteMessage: 'Are your sure? You want to delete this content?',
          triggerMessage: 'content',
          deleteID: contentName,
          deleteItemID: deleteItemID
        })
    }
    const cardStyle = properties !== undefined ? properties.styleID.split('_') : 'CN_LIST_CARD_DFL1';
    let cardWidth = 3;
    if (properties.countInRow === 2) {
        cardWidth = 6
    } else if (properties.countInRow === 3) {
        cardWidth = 4
    } else if (properties.countInRow === 4) {
        cardWidth = 3
    } else if (properties.countInRow === 6) {
        cardWidth = 2
    } else if (properties.countInRow === 1) {
        cardWidth = 12
    }
    return (
        <div style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                <div className="row pt-4">
                    {properties.blockHeading !== '' &&
                        <div className="col-12">
                            <h3 className="text-center" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                        </div>
                    }
                    {properties.blockDescription !== '' &&
                        <div className="col-12">
                        <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                        </div>
                    }
                </div>
            }
            <div className="section full">
                <div className='container'>    
                    <div class="wide-block">
                        <div class="timeline">
                        {tempItems && tempItems.map((item, key) => {
                            return (
                            <div class="item">
                                <div class="dot bg-primary"></div>
                                <div class="content row">
                                    <div className={`col-md-${cardWidth} col-12 pb-2 ${cardStyle[3] === 'DFL8' && 'mt-5'}`}>
                                        <CardSelector cardType={`${cardStyle[2]}_${cardStyle[3]}`} item={item} handleNewEditItem={handleNewEditItem} handleDeleteContent={handleDeleteContent} key={key} keys={key} blockID={blockID} />
                                    </div>
                                </div>
                            </div>
                        )
                        })}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
      isVisible
    } = offcanvas
    const { 
        blocks: websiteDataBlocks 
    } = websiteData
    return {
      offcanvasVisible: isVisible,
      websiteDataBlocks
    }
}
  
const mapDispatchToProps = {
    showEditOffcanvas,
    showAddOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_TIME1)
