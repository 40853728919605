import React from 'react'

function SM_SOON_DFL1() {
    return (
        <div id="appCapsule">

            <div className="error-page">
                <div className="mb-2">
                    <img src="assets/img/sample/photo/vector3.png" alt="alt" className="imaged square w200" />
                </div>
                <h1 className="title">Coming Soon!</h1>
                <div className="text mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
                <div id="countDown" className="mb-5">

                </div>

                <div className="fixed-footer">
                    <div className="row">
                        <div className="col-12">
                            <a href="app-pages.html" className="btn btn-primary btn-lg btn-block">Go Back</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SM_SOON_DFL1
