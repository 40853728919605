import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { AddressAutofill, SearchBox } from '@mapbox/search-js-react';
import Logo from '../images/icon/webweb-logo.png'
// import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { Marker, Layer, Feature } from "react-mapbox-gl";
import axios from 'axios';
// import 'mapbox-gl/dist/mapbox-gl.css';

// mapboxgl.accessToken = 'pk.eyJ1Ijoic2t5cXRlY2giLCJhIjoiY2xoOHFkeXl2MDAxdzNkbGd0cHc3YWxpYiJ9.ty5aLADWsiwGLYn1GZ9qTA'

const accessesToken = 'pk.eyJ1Ijoic2t5cXRlY2giLCJhIjoiY2xoOHFkeXl2MDAxdzNkbGd0cHc3YWxpYiJ9.ty5aLADWsiwGLYn1GZ9qTA';

export const GetMapbox = ({ lng, lat}) => {
  // console.log({ lng, lat});
  const Map = ReactMapboxGl({
    accessToken: accessesToken
  });
  return (
    <Map
      scrollZoom={false}
      maxZoom={19}
      minZoom={19}
      doubleClickZoom={false}
      touchZoomRotate={false}
      trackResize={false}
      dragRotate={false}
      dragPan={false}
      onZoom={() => { return false; }}
      onZoomStart={() => { return false; }}
      onBoxZoomStart={() => { return false; }}
      center={[lng, lat]}
      className='w-100 h-100'
      style="mapbox://styles/mapbox/streets-v12">
      <Marker
        coordinates={[lng, lat]}
        anchor="bottom">
        <img src='assets/img/pin.png' style={{ 'height': '50px' }} />
      </Marker>
    </Map>
  )
}

const GetStartedLogin = ({ websitePostData, setWebsitePostData, onBuisnessNameEnter, onPhoneNumberEnter, onConnectDomain, submitBuildForm, disableBtn, handleCountryChange }) => {
  const inputRef = useRef(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapRef = useRef();
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [zoom, setZoom] = useState(19);

  // console.log(token1);
  const [suggestionText, setSuggetionText] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setLng(position.coords.longitude);
        setLat(position.coords.latitude);
        // console.log(position.coords.longitude);
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED) {
          axios.get('https://api.webweb.ai/website/address/noIp')
            .then(response => {
              setLng(response.data.data[0].lon);
              setLat(response.data.data[0].lat);
              console.log(response)
            })
            .catch(error => {
              console.log('error');
            })
        } else {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      }
    );
  }, [])

  useEffect(() => {
    if (suggestionText.trim().length > 0) {
      const tempDataList = suggestionList.filter(suggestion => suggestion.text === suggestionText);
      if (tempDataList.length > 0) {
        setLng(tempDataList[0].center[0]);
        setLat(tempDataList[0].center[1]);
      }
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${suggestionText}.json?access_token=${accessesToken}`).then(res => {
        if (res.data) {
          // console.log(res.data);
          setSuggestionList(res.data.features);
        } else {
          console.log('SOMETHING WENT WRONG')
        }
      })
    } else {
      setSuggestionList([]);
    }
  }, [suggestionText])


  useEffect(() => {
    if (lat !== null && lng !== null) {
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessesToken}`).then(res => {
        if (res.data) {
          const placeText = res.data.features.filter(feature => feature.id.includes('place'));
          console.log(res.data);
          if (suggestionText.length === 0) {
            setSuggetionText(placeText[0].text)
          }
          const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
          console.log(res.data.features)
          tempwebsitePostData.Location = res.data.features[0];
          setWebsitePostData(tempwebsitePostData);
          // setSuggestionList(res.data.features);
        } else {
          console.log('SOMETHING WENT WRONG')
        }
      })
    }
  }, [lng, lat])

  // const onPlaceTextChanged = (placeText) => {
  //   setSuggetionText(placeText);
  //   if(placeText.trim().length > 0) {
  //     const tempDataList = suggestionList.map((suggestion) => {
  //       return suggestion.text
  //     })
  //     if (tempDataList.includes(suggestionText)) {
  //       console.log(suggestionText)
  //     }
  //     axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${suggestionText}.json?access_token=${accessesToken}`).then(res=>{
  //       if (res.data) {
  //           // console.log(res.data);
  //           setSuggestionList(res.data.features);
  //       } else {
  //           console.log('SOMETHING WENT WRONG')
  //       }
  //   })
  //   } else {
  //     setSuggestionList([]);
  //   }
  // }

  const mapGet = useMemo(() => <GetMapbox lng={lng} lat={lat} />, [lng, lat]);

  return (
    <div id="appCapsule" className="pt-0 pb-0">
      <div className="container-fluid buildForm">
        <div className="row">
          <div className="col-md-5 mt-4">
            <div className="image-logo justify-content-start d-flex mb-2 ps-5">
              <img class="logo-img" src={Logo} />
            </div>
            <form className="mb-6 ps-5 pe-5">
              <div className="form-group boxed mb-1">
                <h1 class="heading-main mb-2">Build your website</h1>
                <div className="input-wrapper">
                  <label class="form-label input-text" for="name5">Business Name</label>
                  <input type="text" className="form-control form-input buisnessName" value={websitePostData.BusinessName} onChange={(e) => onBuisnessNameEnter(e.target.value)} id="name5" autoFocus placeholder="Enter your business name"
                    autocomplete="off" />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              <div className="form-group boxed mb-1">
                <div className="input-wrapper">
                  <label class="form-label input-text" for="phone">Phone Number</label>
                  <PhoneInput
                    className="form-control phoneInput mt-1"
                    international
                    defaultCountry="IN"
                    value={websitePostData.WhatsappNumber}
                    onChange={(e) => onPhoneNumberEnter(e)}
                    onCountryChange={handleCountryChange}
                    ref={inputRef}
                    maxLength={15}
                    id="phone5"
                    placeholder="Enter your phone number"
                    countryCallingCodeEditable={false}
                  />
                  {/* <input type="text" className="form-control form-input" value={websitePostData.WhatsappNumber} onChange={(e) => onPhoneNumberEnter(e.target.value)} id="phone5" placeholder="Enter your phone number" /> */}
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              <div class="section full mt-1 mb-2">
                <div class="location-text ">Connect your domain</div>
                <div class=" pt-2 ">
                  <div class="form-check mb-1">
                    <input class="form-check-input" type="radio" onClick={() => onConnectDomain('true')} checked={websitePostData.isDomainFree === 'true'} name="flexRadioDefault" id="flexRadioDefault2" />
                    <label class="form-check-label redio-button ps-3" for="flexRadioDefault2">
                      Claim free domain name
                    </label>
                  </div>
                  <div class="form-check mb-1 me-2 ">
                    <input class="form-check-input" type="radio" onClick={() => onConnectDomain('false')} checked={websitePostData.isDomainFree === 'false'} name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label redio-button ps-3" for="flexRadioDefault1">
                      Already registered domain
                    </label>
                  </div>

                </div>
              </div>
              <p className="mb-3 fs-sm text-muted">
                <div className="location-text text-grey"> It looks like you're in "{suggestionText}". </div>
              </p>
              <button className="btn w-50 btn-primary submit-btn-build-website" type="submit" onClick={() => submitBuildForm()} disabled={disableBtn}>
                Get Started
              </button>
            </form>
          </div>
          <div className="col-md-7 position-relative only-map">
            <div class="section full search-bar mt-2 me-3">

              <div class="search-form">
                {/* <AddressAutofill accessToken={accessesToken}>
                  <div class="form-group searchbox">
                    <input type="text" class="form-control w-100" name='city' autoComplete="city" placeholder="City" />
                    <i class="input-icon">
                      <ion-icon name="search-outline"></ion-icon>
                    </i>
                  </div>
                </AddressAutofill> */}
                {/* <mapbox-address-autofill name="city" placeholder="City" autocomplete="city" /> */}
                {/* <SearchBox accessToken={accessesToken} ref={mapRef} /> */}

                <div class="form-group searchbox">
                  <input type="text" list='placesList' class="form-control w-100" name='city' autoComplete="city" placeholder="City" value={suggestionText} onChange={(e) => setSuggetionText(e.target.value)} />
                  <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                  </i>
                  {
                    suggestionList.length > 0 &&
                    <datalist id='placesList'>
                      {
                        suggestionList.map((item, i) => {
                          return <option value={item.text} key={i}>{item.text}</option>
                        })
                      }
                    </datalist>
                  }
                </div>
              </div>

            </div>
            {/* <div ref={mapContainer} className="map-container" /> */}
            {/* <Map ref={mapRef} onLoad={onMapLoad} /> */}
            {
              lng !== null && lat !== null && zoom !== null &&
              <>{mapGet}</>
            }
            <div className="shape shape-start shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"></path></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStartedLogin
