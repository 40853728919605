import React from 'react'
import { useLocation } from 'react-router-dom'

function ReqPayment(props) {
    const search = useLocation().search
    const ID = new URLSearchParams(search).get('id')
    console.log(ID)
    return (
        <div id="appCapsule" className="mt-2">
            <div className="section mt-2">
                <div className="card">
                    <div className="card-header fs-5">
                        <div className="row">
                            <div className="col-8 col-md-8">
                                Payment Details
                            </div>
                            <div className="col-4 col-md-4 text-end">
                                <span className="badge badge-secondary">
                                    <ion-icon name="create-outline"></ion-icon>&nbsp;Edit
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <img src="assets/img/sample/photo/1.jpg" alt="QR" className="w-50 mb-1" />
                                <p className="fs-small"><span className="text-light">ID</span> 123456fgh56hdfd</p>
                            </div>
                            <div className="col-md-8 mb-2">
                                <p className="fs-small mb-1"><span className="text-light">Account Holder Name</span> Hussain Kothari</p>
                                <p className="fs-small mb-1"><span className="text-light">Account Number</span> 1234567890</p>
                                <p className="fs-small mb-1"><span className="text-light">IFSC Code</span> IND1234567890</p>
                                <p className="fs-small mb-1"><span className="text-light">Bank Name</span> State Bank of India</p>
                                <p className="fs-small mb-1"><span className="text-light">Branch Name</span> Panvel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section full mt-2 mb-2">
                <div className="wide-block">
                    <ul className="nav nav-tabs capsuled" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#!" role="tab">
                                Requested
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="recPayment" role="tab">
                                Received
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <ul className="listview image-listview">
                <li>
                    <a href="#!" className="item" data-bs-toggle="modal" data-bs-target="#actionSheetIconed">
                        <div className="icon-box bg-success">
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <div className="in">
                            <div>
                                <header>Hussain Kothari</header>
                                support@aapnisite.com
                                <footer>aapnisite.com</footer>
                            </div>
                            <ion-icon name="settings-outline" className="fs-3"></ion-icon>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#!" className="item">
                        <div className="icon-box bg-warning">
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <div className="in">
                            <div>
                                <header>Saurabh Karve</header>
                                info@webweb.in
                                <footer>webweb.in</footer>
                            </div>
                            <ion-icon name="settings-outline" data-bs-toggle="modal" data-bs-target="#actionSheetIconed" className="fs-3"></ion-icon>
                        </div>
                    </a>
                </li>
            </ul>
            <div className="fab-button text bottom-center" style={{bottom:"70px"}}>
                <a href="#!" className="fab">
                    <ion-icon name="add-outline"></ion-icon>
                    Payment Links
                </a>
            </div>
        </div>
    )
}

export default ReqPayment
