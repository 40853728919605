import React from 'react'
// import ToolBar from '../Elements/Management/ToolBar'

function SM_POPP_DFL1() {
    return (
        <div className="text-center">
            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#popupBlock">
                Show
            </button>
           <div className="modal fade dialogbox" id="popupBlock" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content webweb-modal-content">
                        <div className="modal-header position-sticky top-0 right-0 left-0 p-0 pb-1 bg-light" style={{zIndex: '1000'}}>
                            <h5 className="modal-title">
                                {/* <ToolBar show={true} /> */}
                            </h5>
                        </div>
                        <div className="modal-body" style={{display:'flex', flexFlow:'column'}}>
                            <div className="modal-header">
                                <h5 className="modal-title">Dialog title</h5>
                            </div>
                            <img src="assets/img/sample/photo/1.jpg" alt="avatar" className="img-fluid mb-2" />
                            This is a dialog message This is a dialog message This is a dialog message This is a dialog message This is a dialog message This is a dialog message This is a dialog message This is a dialog message
                        </div>
                        <div className="modal-footer position-sticky right-0 left-0 bottom-0">
                            <div className="btn-inline">
                                <a href="#!" className="btn btn-text-secondary" data-bs-dismiss="modal">CLOSE</a>
                                <a href="#!" className="btn btn-text-primary" data-bs-dismiss="modal">OK</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SM_POPP_DFL1
