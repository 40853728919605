import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'
import FormCode from '../bitComponents/FormCode';

function SM_CNTC_DFL1({
    properties,
    items = [],
    seq,
    blockID,
    blockType,
    setNewBlockType,
    showUpdateDataOffcanvas,
    ContentBlockItem,
    forms
}) {


    const handleNewEditItem = (itemID, msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log(itemID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    return (
        <div>
            <div className="section wide-block position-relative py-5" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                <div className="row p-1">
                    <div className="col-12 col-md-5">
                        {
                            items[0] !== null && items[0].form !== '' &&
                            <FormCode formtTitle={items[0].title} forms={forms} items={items}/>
                        }
                    </div>

                    <div className="col-12 col-md-7 mt-1">
                        <div className="row">
                            {items.map((value, key) => {
                                return <>
                                    {value.form === '' &&
                                        <div className="col-12 col-md-6 mt-1 position-relative">
                                                <div className='d-flex pos-absglr z-index-10'>
                                                    <div className="col-12 col-md-12 text-center">
                                                        <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                                    </div>
                                                </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title" style={cssConverter(properties.subtitleCss)} dangerouslySetInnerHTML={{ __html: value.subtitle }} />
                                                    <p className="card-text" style={cssConverter(properties.titleCss)} dangerouslySetInnerHTML={{ __html: value.title }} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            })}
                            
                        </div>
                        
                    </div>
                </div>
                {/* <div style={{position: 'absolute',top: '10px',left: '50%',transform: 'translateX(-50%)',display: 'flex'}}>
                    <div className="col-12 col-md-12 text-center">
                        <span className="badge bg-success p-1" style={{width: '55px', boxShadow: '0px 0px 5px grey', cursor: 'pointer'}} onClick={() => {handleNewEditItem()}}>New Edit</span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const { ContentBlockItem } = offcanvas
    const { forms } = websiteData
    return {
        ContentBlockItem,
        forms
    }
}

const mapDispatchToProps = {
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_CNTC_DFL1)
