import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import axios from '../../AxiosConfig'
import { ProgressBar, Spinner } from 'react-bootstrap'
import { showEmailSettingOffcanvas } from '../../features/modal/offcanvasSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'

const Email = ({
    showEmailSettingOffcanvas,
    showAppToast,
    isEmailSettingVisible
}) => {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.AliasDomain !== '' ? tokenData.SelectedDomain.AliasDomain : tokenData.SelectedDomain.Domain

    const [loader, setLoader] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [activeEmail, setActiveEmail] = useState(0);
    const handleEmailSetting = () => {
        showEmailSettingOffcanvas({ isEmailSettingVisible: true })
    }

    const getEmailList = () => {
        setLoader(true);
        axios.get(`/email/listDomainUsers/${selectedDomain}`)
            .then((response) => {
                if (response.data.data) {
                    console.log(response.data.data);
                    setEmailList(response.data.data);
                } else {
                    showAppToast({
                        toastMessage: 'ERROR',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                }
                setLoader(false)
            }).catch((error) => {
                showAppToast({
                    toastMessage: 'ERROR',
                    background: 'danger',
                    timeout: '1000',
                    icon: 'close-circle-outline',
                    position: 'toast-center'
                })
                setLoader(false)
            })
    }

    useEffect(() => {
        if (tokenData.SelectedDomain.AliasDomain !== '' && !isEmailSettingVisible) {
            // console.log(selectedDomain);
            getEmailList();
        }
    }, [isEmailSettingVisible])


    // console.log(tokenData.SelectedDomain);
    return (
        <div id="appCapsule">
            <div className="section-title justify-content-center border-bottom">{selectedDomain}</div>
            <div className="section mt-2">
                <div className="row">
                    <div className="col-12 col-md-5 mb-2">
                        <div className="card">
                            <div className="card-body mb-0 pb-1 pt-1 d-flex align-items-center justify-content-between">
                                <h5 className="card-subtitle mb-0 pt-1">Email List</h5>
                                <span className="badge badge-primary" onClick={handleEmailSetting} style={{ float: 'right', cursor: 'pointer' }}><ion-icon name="add-circle-outline"></ion-icon>&nbsp;Create Email</span>
                            </div>
                            {
                                loader ?
                                <div className='d-flex justify-content-center align-items-center py-5'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                :
                                <>
                                {
                                    emailList.length > 0 ?
                                    <ul className="listview link-listview">
                                        {
                                            emailList.map((item, i) => {
                                                return (
                                                    <li className={activeEmail === i ? "webweb-bg-secondary" : ""} key={i}>
                                                        <a className='cursor-pointer' onClick={() => setActiveEmail(i)}>
                                                            {item.user_name}@skyq.tech
                                                            <span className="text-muted">Manage</span>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    :
                                    <div className='d-flex justify-content-center align-items-center py-5'>
                                        <h3 className='text-center'>{tokenData.SelectedDomain.AliasDomain === '' ? 'Please add your domain to create emails.' : 'Email list is empty!'}</h3>
                                    </div>
                                }
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="card">
                            {
                                loader ?
                                <div className='d-flex justify-content-center align-items-center py-5'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                :
                            <div className="card-body pt-2">

                                {
                                    emailList.map((item, i) => {
                                        return (
                                            <div key={i} className={activeEmail === i ? '' : "d-none"}>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-4 col-md-4">
                                                        <h5 className="card-subtitle mb-0">Email Detail</h5>
                                                    </div>
                                                    <div className="col-8 col-md-8 text-end">
                                                        <span className="badge badge-secondary me-1" style={{ cursor: 'pointer' }}><ion-icon name="refresh-outline"></ion-icon>&nbsp;Reset Password</span>
                                                        <span className="badge badge-danger" style={{ cursor: 'pointer' }}><ion-icon name="trash-outline"></ion-icon>&nbsp;Delete</span>
                                                    </div>
                                                </div>
                                                <div className="row d-flex align-items-center mt-2">
                                                    <div className="col-8 col-md-8">
                                                        <h3>{item.user_name}@skyq.tech</h3>
                                                    </div>
                                                    <div className="col-4 col-md-4 position-relative">
                                                        <span className="position-absolute fw-bold" style={{ fontSize: '9px', bottom: '-30%', right: '30%', transform: 'translate(0%, 0%)' }}>700MB/{item.disc_quota}{item.disc_quota === "500" ? "MB" : "GB"}</span>
                                                        <ProgressBar now={50} max={100} min={0} striped animated />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="section full">
                                    <div className="accordion border-0" id="accordionExample1">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed p-0 custom-line-accordian" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#accordion1">
                                                    How to configure MAIL in <span className="fw-bold">&nbsp; Microsoft Office Outlook </span> ?
                                                </button>
                                            </h2>
                                            <div id="accordion1" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                                <div className="accordion-body border-bottom ps-0 pe-0">
                                                    <ol style={{ lineHeight: '1.6' }}>
                                                        <li>
                                                            Open Outlook, Go to <span className="fw-bold">File</span>  <span className="fw-bold">Click “Add Account”</span>
                                                        </li>
                                                        <li>
                                                            Select <span className="fw-bold">“Manually configure serversettings or additionalservertypes”</span> and click <span className="fw-bold">“Next”.</span>
                                                        </li>
                                                        <li>
                                                            Select <span className="fw-bold">“Internet E-mail”</span> and click <span className="fw-bold">“Next”</span>
                                                        </li>
                                                        <li>
                                                            Enter as per below <span className="fw-bold">“Screenshots”</span> and click <span className="fw-bold">“More Settings…”</span> (here we areusing account type as IMAP you can choose either POP3 or IMAP for POP3 you can use pop.entpmail.com).
                                                        </li>
                                                        <li>
                                                            Select <span className="fw-bold">“Outgoing Server”</span> tab and tick <span className="fw-bold">“My outgoing server(SMTP)required authentication”</span>
                                                        </li>
                                                        <li>
                                                            Select <span className="fw-bold">“Advanced”</span> tab and enter as per below configuration and click <span className="fw-bold">“Ok”</span>
                                                            <ul>
                                                                <li>POP3 SSL- 995</li>
                                                                <li>IMAP SSL- 993</li>
                                                                <li>SMTP SSL- 465</li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            Verify the settings click <span className="fw-bold">“Next”</span> and <span className="fw-bold">“Finish”.</span>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed p-0 custom-line-accordian" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#accordion2">
                                                    How to configure MAIL in <span className="fw-bold"> &nbsp;Mozilla Thunderbird </span> ?
                                                </button>
                                            </h2>
                                            <div id="accordion2" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                                <div className="accordion-body border-bottom ps-0 pe-0">
                                                    <ol style={{ lineHeight: '1.6' }}>
                                                        <li>
                                                            Open Thunderbird Mail, Under <span className="fw-bold">“Create a new account”</span> click <span className="fw-bold">“Email”.</span>
                                                        </li>
                                                        <li>
                                                            Click, <span className="fw-bold">“Skip this and use my existing email”.</span>
                                                        </li>
                                                        <li>
                                                            Enter name, email & password and click <span className="fw-bold">“Continue”</span>
                                                        </li>
                                                        <li>
                                                            Sometimes it will not auto detect email server so select <span className="fw-bold">“Manual Config”.</span>
                                                        </li>
                                                        <li>
                                                            Enter as per the below screenshot and click <span className="fw-bold">“Done”</span>
                                                            <p className="m-0"><span className="fw-bold">Incoming server:</span>&nbsp;imap.entpmail.com/pop.entpmail.com </p>
                                                            <p className="m-0"><span className="fw-bold">Outgoing server:</span>&nbsp;smtp.entpmail.com </p>
                                                            <p className="m-0"><span className="fw-bold">Ports:</span></p>
                                                            <ul>
                                                                <li>POP3 SSL – 995 </li>
                                                                <li>IMAP SSL – 993 </li>
                                                                <li>SMTP SSL – 465</li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed p-0 custom-line-accordian" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#accordion3">
                                                    How to configure MAIL in <span className="fw-bold"> &nbsp;Android Phone </span> ?
                                                </button>
                                            </h2>
                                            <div id="accordion3" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                                <div className="accordion-body border-bottom ps-0 pe-0">
                                                    <ol style={{ lineHeight: '1.6' }}>
                                                        <li>
                                                            Launch <span className="fw-bold"> &nbsp;“Settings”</span> on your Android phone > tap on <span className="fw-bold"> &nbsp;“Accounts”</span> > Select <span className="fw-bold"> &nbsp;“Email”.</span>
                                                        </li>
                                                        <li>
                                                            Enter email id and password and click <span className="fw-bold"> &nbsp;“Sign In”</span>
                                                        </li>
                                                        <li>
                                                            It will ask to choose account type POP3/IMAP (here we are using IMAP). Tap <span className="fw-bold"> &nbsp;“IMAP”</span>
                                                        </li>
                                                        <li>
                                                            It will show manual setup screen, fill up the field as per below screenshots and click <span className="fw-bold"> &nbsp;“Next”</span>
                                                            <p className="m-0"><span className="fw-bold"> &nbsp;Incoming Mail Server:</span> imap.entpmail.com/ pop.entpmail.com</p>
                                                            <p className="m-0"><span className="fw-bold"> &nbsp;Outgoing Mail Server:</span> smtp.entpmail.com </p>
                                                            <p className="m-0"><span className="fw-bold"> &nbsp;Ports:</span> </p>
                                                            <ul>
                                                                <li>POP3 SSL – 995 </li>
                                                                <li>IMAP SSL – 993</li>
                                                                <li>SMTP SSL – 465</li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            Enter account name and click <span className="fw-bold"> &nbsp;“DONE”.</span>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed p-0 custom-line-accordian" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#accordion4">
                                                    How to configure MAIL in <span className="fw-bold"> &nbsp;Apple iPhone </span> ?
                                                </button>
                                            </h2>
                                            <div id="accordion4" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                                <div className="accordion-body border-bottom ps-0 pe-0">
                                                    <ol style={{ lineHeight: '1.6' }}>
                                                        <li>
                                                            On the home screen tap <span className="fw-bold">&nbsp;“Settings”</span> go to <span className="fw-bold">&nbsp;“Mail”</span>
                                                        </li>
                                                        <li>
                                                            Tap <span className="fw-bold">&nbsp;“Accounts”</span>
                                                        </li>
                                                        <li>
                                                            Tap <span className="fw-bold">&nbsp;“Add Account”.</span>
                                                        </li>
                                                        <li>
                                                            Tap <span className="fw-bold">&nbsp;“Other”.</span>
                                                        </li>
                                                        <li>
                                                            Tap <span className="fw-bold">&nbsp;“Add Mail Account”.</span>
                                                        </li>
                                                        <li>
                                                            Enter the name, email, password, and desc and tap <span className="fw-bold">&nbsp;“Next”.</span>
                                                        </li>
                                                        <li>
                                                            Select Account type IMAP/POP3 (here we are using IMAP).
                                                            <p className="m-0"><span className="fw-bold">&nbsp;Incoming Mail Server:</span> imap.entpmail.com/pop.entpmail.com </p>
                                                            <p className="m-0"><span className="fw-bold">&nbsp;Outgoing Mail Server:</span> smtp.entpmail.com </p>
                                                            <p className="m-0"><span className="fw-bold">&nbsp;Ports:</span> </p>
                                                            <ul>
                                                                <li>POP3 SSL – 995 </li>
                                                                <li>IMAP SSL – 993 </li>
                                                                <li>SMTP SSL – 465</li>
                                                            </ul>
                                                            <p className="m-0">Enter the details as per screenshots click <span className="fw-bold">&nbsp;“Next”.</span></p>
                                                        </li>
                                                        <li>
                                                            Select desired settings and tap <span className="fw-bold">&nbsp;“Save”.</span>
                                                        </li>
                                                        <li>
                                                            Next, we are configuring your iPhone to sync your various IMAP folders and prevent duplicate folders from showing up. We call this Folder Mapping; below are the steps to do so.
                                                            <p className="m-0">Within the Mail settings window, select the account that you just added. Under IMAP, tap <span className="fw-bold">&nbsp;“Account”.</span></p>
                                                        </li>
                                                        <li>
                                                            Enter configuration as below and click <span className="fw-bold">&nbsp;“Done”.</span>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const { isEmailSettingVisible } = offcanvas
    return {
        isEmailSettingVisible
    }
}

const mapDispatchToProps = {
    showEmailSettingOffcanvas,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(Email)
