import SM_ACTN_DFL1 from "./SM_ACTN_DFL1"
import SM_BRDC_DFL1 from "./SM_BRDC_DFL1"
import SM_CNTR_DFL1 from './SM_CNTR_DFL1'
import Divider from "../Elements/Management/Divider"
import FabButton from './FabButton'
import SM_FOOT_DFL1 from './SM_FOOT_DFL1'
import SM_HEAD_DFL1 from './SM_HEAD_DFL1'
import SM_HEAD_DFL3 from './SM_HEAD_DFL3'
import FacebookMessenger from "./FacebookMessenger"
import CN_GLRY_DFL1 from './CN_GLRY_DFL1'
import GoogleAnalytics from "./GoogleAnalytics"
import CN_LIST_DFL1 from './CN_LIST_DFL1'
import CN_LIST_CARD from './CN_LIST_CARD'
import CN_LIST_CAR5 from './CN_LIST_CAR5'
import CN_LIST_SLDR from './CN_LIST_SLDR'
import CN_LIST_CASL from './CN_LIST_CASL'
import CN_CRSL_DFL1 from './CN_CRSL_DFL1'
import StripedTable from './CN_TABL_DFL1'
import CN_TSML_DFL1 from './CN_TSML_DFL1'
import Testimonial2 from "./Testimonial2"
import ThreeColumnBlock from './ThreeColumnBlock'
import CN_TIME_DFL1 from './CN_TIME_DFL1'
import SM_TWCL_DFL1 from './SM_TWCL_DFL1'
import SM_YUTB_DFL1 from "./SM_YUTB_DFL1"
import SM_MAPE_DFL1 from "./SM_MAPE_DFL1"
import SM_QLNK_DFL1 from "./SM_QLNK_DFL1"
import MarqueeBlock from "./MarqueeBlock"
import SM_INFO_DFL1 from "./SM_INFO_DFL1"
import SM_SOON_DFL1 from "./SM_SOON_DFL1"
import SM_POPP_DFL1 from "./SM_POPP_DFL1"
import CN_PLAN_DFL1 from "./CN_PLAN_DFL1"
import CN_TABL_DFL1 from "./CN_TABL_DFL1"
import SM_EMIL_DFL1 from "./SM_EMIL_DFL1"
import SM_SITE_DFL1 from "./SM_SITE_DFL1"
import SM_DOMN_DFL1 from "./SM_DOMN_DFL1"
import SM_CNTC_DFL1 from "./SM_CNTC_DFL1"
import SM_THRE_DFL1 from "./SM_THRE_DFL1"
import CN_MARQ_DFL1 from "./CN_MARQ_DFL1"
import SM_CUST_DFL1 from './SM_CUST_DFL1'
import SM_FORM_DFL1 from './SM_FORM_DFL1'
// import DeleteDialogueBox from "../Elements/Delete/DeleteDialogueBox"
// import ButtonModal from "../Elements/Modals/ButtonModal"
// import ImageModal from "../Elements/Modals/ImageModal"
import Story from "../Elements/Modals/Story"
import Test from "../Pages/test"
import DetailPage from "../Pages/DetailPage"
import SM_INST_DFL1 from './SM_INST_DFL1'
import SM_VMEO_DFL1 from './SM_VMEO_DFL1'
import SM_TWTO_DFL1 from './SM_TWTO_DFL1'
import SM_RGTR_DFL1 from './SM_RGTR_DFL1'
import EB_TABS_DFL1 from './EB_TABS_DFL1'
import SM_MARQ_DFL1 from './SM_MARQ_DFL1'
import SM_FACE_DFL1 from './SM_FACE_DFL1'
import SM_CRER_DFL1 from './SM_CRER_DFL1'
import CN_GLRY_SLDR from "./CN_GLRY_SLDR"
import SM_INFO_2CLF from './SM_INFO_2CLF'
import SM_INFO_2CRG from './SM_INFO_2CRG'
import PL_BUTN_DFL1 from "./PL_BUTN_DFL1"
import PL_BUTN_DFL2 from "./PL_BUTN_DFL2"
import SM_POUP_DFL1 from "./SM_POUP_DFL1"
import SM_HEAD_FIX1 from "./SM_HEAD_FIX1"
import SM_HEAD_DFL2 from "./SM_HEAD_DFL2"
import CN_LIST_GRID1 from "./CN_LIST_GRID1"
import CN_LIST_MSNY1 from "./CN_LIST_MSNY1"
import CN_LIST_SLDR1 from "./CN_LIST_SLDR1"
import CN_LIST_TIME1 from "./CN_LIST_TIME1"
import CN_LIST_TIME2 from "./CN_LIST_TIME2"
import CN_LIST_CAR2 from "./CN_LIST_CAR2"
import CN_CNTR_DFL1 from "./CN_CNTR_DFL1"
import SM_HERO_DFL1 from "./SM_HERO_DFL1"
import SM_SHPE_DFL1 from "./SM_SHPE_DFL1"
import SM_FOOT_NMFT from "./SM_FOOT_NMFT"
import CN_LIST_CAS3 from "./CN_LIST_CAS3"

export const dynamicBlocks = {
  SM_ACTN_DFL1,
  SM_BRDC_DFL1,
  SM_CNTR_DFL1,
  Divider,
  FabButton,
  SM_HEAD_DFL1,
  SM_FOOT_DFL1,
  FacebookMessenger,
  GoogleAnalytics,
  CN_LIST_DFL1,
  CN_LIST_CARD,
  CN_LIST_CAR5,
  CN_LIST_SLDR,
  CN_LIST_CASL,
  CN_CRSL_DFL1,
  StripedTable,
  CN_TSML_DFL1,
  Testimonial2,
  ThreeColumnBlock,
  CN_TIME_DFL1,
  SM_TWCL_DFL1,
  SM_YUTB_DFL1,
  SM_QLNK_DFL1,
  CN_GLRY_DFL1,
  MarqueeBlock,
  SM_INFO_DFL1,
  SM_SOON_DFL1,
  SM_POPP_DFL1,
  CN_PLAN_DFL1,
  CN_TABL_DFL1,
  SM_EMIL_DFL1,
  SM_SITE_DFL1,
  SM_DOMN_DFL1,
  SM_MAPE_DFL1,
  SM_CNTC_DFL1,
  SM_THRE_DFL1,
  CN_MARQ_DFL1,
  SM_CUST_DFL1,
  SM_FORM_DFL1,
  // DeleteDialogueBox,
  // ButtonModal,
  // ImageModal,
  Story,
  Test,
  DetailPage,
  SM_INST_DFL1,
  SM_VMEO_DFL1,
  SM_TWTO_DFL1,
  SM_RGTR_DFL1,
  EB_TABS_DFL1,
  SM_MARQ_DFL1,
  SM_FACE_DFL1,
  SM_CRER_DFL1,
  CN_GLRY_SLDR,
  SM_INFO_2CLF,
  SM_INFO_2CRG,
  PL_BUTN_DFL1,
  PL_BUTN_DFL2,
  SM_POUP_DFL1,
  SM_HEAD_FIX1,
  SM_HEAD_DFL2,
  SM_HEAD_DFL3,
  CN_LIST_GRID1,
  CN_LIST_MSNY1,
  CN_LIST_SLDR1,
  CN_LIST_TIME1,
  CN_LIST_TIME2,
  CN_LIST_CAR2,
  CN_CNTR_DFL1,
  SM_HERO_DFL1,
  SM_SHPE_DFL1,
  SM_FOOT_NMFT,
  CN_LIST_CAS3
}