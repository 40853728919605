import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'

const InfoBlock = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    showUpdateDataOffcanvas,
    setNewBlockType
}) => {
    const handleBlockUpdate = (msg) => {
        console.log('CLICKED')
        showOffcanvas({ isVisible: true, items:items, itemCount:'null', properties:properties, blockType: blockType, blockID:blockID, msg:msg})
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType' : blockID.slice(0, 2), 'BlockID' : blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        showUpdateDataOffcanvas({
          isEditVisible: true,
        //   itemID: itemID,
          items: items,
          blockID: blockID,
          properties:properties,
          blockType: blockType, 
          blockID:blockID, 
          msg:msg
        })
      }

    function handleShowButtonModal(buttonData, ID) {
        console.log(buttonData)
        handleBlockUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleImageUpdate = () => {
        showOffcanvas({ isVisible:true, items:items, properties:properties, blockType: 'SM', blockID:blockID, msg:''})
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({isDeleteVisible:true, deleteMessage:'Are your sure? You want to delete this block?', triggerMessage:'block', deleteID: blockName})
    }
    return (
        <div className="section p-0 position-relative" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : (properties && properties.backgroundValue !== undefined) ? properties.backgroundValue : '' , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div class={(properties && properties.container === 'true') || (properties.container === undefined || properties.container === '') ? 'container' : 'container-fluid'}>
                {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                    <div className="row">
                        {properties.blockHeading !== '' &&
                            <div className="col-12">
                                <h3 className="text-center mb-0" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{__html: properties.blockHeading}}></h3>
                            </div>
                        }
                        {properties.blockDescription !== '' &&
                            <div className="col-12">
                            <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{__html: properties.blockDescription}} />
                            </div>
                        }
                    </div>
                }
                {items[0] !== undefined && 
                    <>
                        <div className="w-100 text-center">
                            {items[0].image.length > 0 &&
                                <>
                                    <img src={items[0].image[0].src} alt={items[0].image[0].alt} className="imaged  square w-50 cursor-pointer" style={cssConverter(properties.imageCss)} onClick={handleNewEditItem} />
                                </>
                            }
                        </div>
                        <div className="pt-2 pb-1 ps-2 pe-2" style={{backgroundColor: properties.backgroundValue}}>
                            <h3 onClick={() => {handleNewEditItem()}} className='cursor-pointer' style={cssConverter(properties.titleCss)} dangerouslySetInnerHTML={{__html: items[0].title}} />
                            <div className='fs-14' onClick={() => {handleNewEditItem()}}>
                                <MDEditor.Markdown source={items[0].subtitle} style={cssConverter(properties.subtitleCss)} linkTarget="_blank" className='cursor-pointer bg-transparent'/><br />
                                <MDEditor.Markdown source={items[0].description} style={cssConverter(properties.descriptionCss)} linkTarget="_blank" className='cursor-pointer bg-transparent'/>
                            </div>
                            <p>
                                {items[0].button !== null && items[0].button.map((item,key) => {
                                    return <button key={key} className={`btn me-1 ${item.style && item.style !== '' ? item.style : 'btn-primary'}`} onClick={() => handleNewEditItem()}  style={cssConverter(properties.buttonCss)}>{item.title}</button>
                                })}
                            </p>
                        </div>
                    </>
                }
            </div>
            {/* <div className='d-flex pos-absglr'>
                <div className="col-6 col-md-6 text-end">
                    <span className="badge bg-success p-1 editBtnCss" onClick={() => {handleNewEditItem()}}>Edit</span>
                </div>
                <div className="col-6 col-md-6">
                    <span className="badge bg-danger p-1" style={{width: '55px', cursor: 'pointer'}} onClick={() => {handleDeleteBlock(blockID)}}>Delete</span>
                </div>
            </div> */}
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {
    showOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    showUpdateDataOffcanvas,
    setNewBlockType
}

export default connect(mapStateToProps,mapDispatchToProps)(InfoBlock)
