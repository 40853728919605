import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import ToolBar from '../Elements/Management/ToolBar';

function Testimonial2(props) {
    return (
        <div>
            <ToolBar />
            <div className="section mb-2">
                <Splide options={{ type: 'slide', perPage: 3, focus: 1, rewind: true, gap: 10, padding: 16, arrows: false, pagination: false, breakpoints: { 768: { perPage: 3 }, 991: { perPage: 4 }}}}>
                    <SplideSlide className="splide__slide">
                        <div className="card">
                            <img src="assets/img/sample/photo/d1.jpg" className="card-img-top" alt="Banner" />
                            <div className="card-body pt-2 text-center">
                                <h3 className="mb-0">Hussain Kothari</h3>
                                <p className="mb-1"><small>Frontend Developer</small></p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                                    Previous
                                </p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="splide__slide">
                        <div className="card">
                            <img src="assets/img/sample/photo/d2.jpg" className="card-img-top" alt="Banner" />
                            <div className="card-body pt-2 text-center">
                                <h3 className="mb-0">Amruta Patankar</h3>
                                <p className="mb-1"><small>Backend Developer</small></p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                                    Previous
                                </p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="splide__slide">
                        <div className="card">
                            <img src="assets/img/sample/photo/d4.jpg" className="card-img-top" alt="Banner" />
                            <div className="card-body pt-2 text-center">
                                <h3 className="mb-0">Saurabh Karve</h3>
                                <p className="mb-1"><small>CEO, Webweb Infotech</small></p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                                    Previous
                                </p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="splide__slide">
                        <div className="card">
                            <img src="assets/img/sample/photo/d1.jpg" className="card-img-top" alt="Banner" />
                            <div className="card-body pt-2 text-center">
                                <h3 className="mb-0">Hussain Kothari</h3>
                                <p className="mb-1"><small>Frontend Developer</small></p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                                    Previous
                                </p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="splide__slide">
                        <div className="card">
                            <img src="assets/img/sample/photo/d2.jpg" className="card-img-top" alt="Banner" />
                            <div className="card-body pt-2 text-center">
                                <h3 className="mb-0">Amruta Patankar</h3>
                                <p className="mb-1"><small>Backend Developer</small></p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.
                                    Previous
                                </p>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    )
}

export default Testimonial2
