import React from 'react'
import classNames from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Hits from '../Pages/hits'
import ReportPage from '../Pages/ReportPage'
import Location from '../Pages/Location'
import Referal from '../Pages/Referal'
import Device from '../Pages/Device'
import Click from '../Pages/Click'

const TABS_MAP = {
  hits: <Hits />,
  pages: <ReportPage />,
  locations: <Location />,
  referrals: <Referal />,
  devices: <Device />,
  clicks: <Click />
}

function Analytics({ activeTabName = "contact" }) {
  const search = useLocation().search
  const report = new URLSearchParams(search).get('report')

  const getClassNames = (tabName) => {
    return classNames('nav-link', { active: tabName === report })
  }

  const token = localStorage.getItem('token')
  const tokenData = (token) ? jwt_decode(token) : false
  const selectedDomain = tokenData.SelectedDomain.AliasDomain !== '' ? tokenData.SelectedDomain.AliasDomain : tokenData.SelectedDomain.Domain

  return (
    <>
      {/* <div className="extraHeader p-0 mt-1" style={{zIndex:1}} >
        <div className="appBottomMenu infinite-menu position-absolute">
          <Link className={getClassNames("hits")} href="#!" to="/analytics?report=hits">
            <div className="col">
              <ion-icon name="bar-chart-outline"></ion-icon>
              <strong>Hits</strong>
            </div>
          </Link>
          <Link className={getClassNames("pages")} href="#!" to="/analytics?report=pages" role="tab">
            <div className="col">
              <ion-icon name="newspaper-outline"></ion-icon>
              <strong>Pages</strong>
            </div>
          </Link>
          <Link className={getClassNames("locations")} href="#!" to="/analytics?report=locations">
            <div className="col">
              <ion-icon name="location-outline"></ion-icon>
              <strong>Locations</strong>
            </div>
          </Link>
          <Link className={getClassNames("referrals")} href="#!" to="/analytics?report=referrals">
            <div className="col">
              <ion-icon name="pie-chart-outline"></ion-icon>
              <strong>Referrals</strong>
            </div>
          </Link>
          <Link className={getClassNames("devices")} href="#!" to="/analytics?report=devices">
            <div className="col">
              <ion-icon name="phone-portrait-outline"></ion-icon>
              <strong>Devices</strong>
            </div>
          </Link>
          <Link className={getClassNames("clicks")} href="#!" to="/analytics?report=clicks">
            <div className="col">
              <ion-icon name="checkmark-done-outline"></ion-icon>
              <strong>Clicks</strong>
            </div>
          </Link>
        </div>
      </div> */}
      <div id="appCapsule" className="container">
        <div className="section-title justify-content-center">{selectedDomain}</div>
        <ul className="nav nav-tabs capsuled bg-light rounded-0" role="tablist" style={{boxShadow: '0px 0px 10px 0px gainsboro'}}>
            <li className="nav-item">
                <Link className={getClassNames("hits")} to="/visitorReport?report=hits" role="tab">
                    Hits
                </Link>
            </li>
            <li className="nav-item">
                <Link className={getClassNames("pages")} to="/visitorReport?report=pages" role="tab">
                    Pages
                </Link>
            </li>
            <li className="nav-item">
                <Link className={getClassNames("locations")} to="/visitorReport?report=locations" role="tab">
                    Locations
                </Link>
            </li>
            <li className="nav-item">
                <Link className={getClassNames("referrals")} to="/visitorReport?report=referrals" role="tab">
                    Referrals
                </Link>
            </li>
            <li className="nav-item">
                <Link className={getClassNames("devices")} to="/visitorReport?report=devices" role="tab">
                    Devices
                </Link>
            </li>
        </ul>
        {TABS_MAP[report]}
      </div>
    </>
  )
}

export default Analytics
