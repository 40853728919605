import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {
  hideEditShareBanner,
  showEditShareBanner
} from '../../../features/modal/offcanvasSlice'
import { updateImageById } from '../../../features/share/shareSlice'

const EditShareBanner = ({
  isEditBannerVisible,
  hideEditShareBanner,
  bannerData,
  bannerID,
  showEditShareBanner,
  updateImageById,
  imagesToShare,
  draggingItems,
  components,
  canvasText,
  canvasNumber,
  canvasEmail,
  setCanvasText,
  setCanvasNumber,
  setCanvasEmail
}) => {
  // console.log("draggingItems",draggingItems, bannerID);
  useEffect(() => {
    if (imagesToShare && imagesToShare.length > 0) {
      const currentImageTmp = imagesToShare[0]
      const { Image } = currentImageTmp
      console.log(currentImageTmp, Image)
    }
  }, [imagesToShare])
  
  useEffect(() => {
    if (bannerData) {
      const { LogoLeft, Language, FooterProperties = {} } = bannerData
      // console.log(LogoLeft)
      const parsedFootProperties = JSON.parse(FooterProperties)
      const { color1, color2, textColor1, textColor2, contact, email, site, qrcode } =
        parsedFootProperties
      // const logoPosition1 = document.getElementById('logoPosition1')
      // if (logoPosition1) {
      //   logoPosition1.checked = LogoLeft === '1'
      //   document.getElementById('logoPosition2').checked = LogoLeft === '0'
      // document.getElementById('logoPositionDropdown').value = LogoLeft
      document.getElementById('color1').value = color1
      document.getElementById('color2').value = color2
      // document.getElementById('textColor1').value = textColor1
      // document.getElementById('textColor2').value = textColor2
      
      // document.querySelector('input[name=phoneInfo]').checked = contact
      // document.querySelector('input[name=emailInfo]').checked = email
      // document.querySelector('input[name=websiteInfo]').checked = site
      // document.querySelector('input[name=qrcodeInfo]').checked = qrcode
      // }
    }
    // console.log("bannerID",bannerID);
  }, [bannerData, bannerID]);

  const updateBannerUI = () => {
    var updatedFooterProperties = {
      color1: document.getElementById('color1').value,
      color2: document.getElementById('color2').value,
      // textColor1: document.getElementById('textColor1').value,
      // textColor2: document.getElementById('textColor2').value,

      // email: document.querySelector('input[name=emailInfo]').checked,
      // site: document.querySelector('input[name=websiteInfo]').checked,
      // contact: document.querySelector('input[name=phoneInfo]').checked,
      // qrcode: document.querySelector('input[name=qrcodeInfo]').checked,
    }
    const inputs = {
      // LogoLeft: document.getElementById('logoPosition1').checked ? '1' : '0',
      // LogoLeft: document.getElementById('logoPositionDropdown').value,
      Image: bannerData.Image,
      Language: bannerData.Language,
      FooterProperties: JSON.stringify(updatedFooterProperties),
      Title: bannerData.Title
    }
    // console.log("inputs:",inputs);
    // console.log("bannerID",bannerID);
    showEditShareBanner({
      isEditBannerVisible: true,
      bannerData: { ...bannerData, ...inputs },
      bannerID: bannerID,
      draggingItems: document.querySelector('input[name=dragsdrop]:checked').value
    })
    updateImageById({ bannerData: { ...bannerData, ...inputs } });
    // setDraggingItem(document.querySelector('input[name=dragsdrop]:checked').value);
  }
  // console.log("bannerID",bannerID);
  return (
    <div>
      <div className="container-fluid py-2">
        <form>
          <div className="row">

            <div className={components.includes('colors') ? "col-6 col-md-6" : "d-none"}>
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="color1">
                    Background color
                  </label>
                  <input
                    type="color"
                    className="form-control p-0"
                    id="color1"
                    placeholder="Enter your name"
                    autoComplete="off"
                    onChange={updateBannerUI}
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>
            <div className={components.includes('colors') ? "col-6 col-md-6" : "d-none"}>
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="color2">
                    Primary Color
                  </label>
                  <input
                    type="color"
                    className="form-control p-0"
                    id="color2"
                    placeholder="Enter your name"
                    autoComplete="off"
                    onChange={updateBannerUI}
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>

            {/* <div className="col-6 col-md-2">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="language">
                    Logo Position
                  </label>
                  <select
                    className="form-control form-select"
                    id="logoPositionDropdown"
                    name="logoPositionDropdown"
                    onChange={updateBannerUI}
                  >
                    <option value="0">Top Right</option>
                    <option value="1">Top Left</option>
                    <option value="2">Bottom Left</option>
                    <option value="3">Bottom Right</option>
                  </select>
                </div>
              </div>
            </div> */}

            <div className={components.includes('showHide') ? 'col-12 col-md-12' : 'd-none'}>

              <div className="form-group boxed pb-0">
                <div className="input-wrapper">
                  {/* <label className="form-label h3" htmlFor="name5"></label> */}
                    <h3>Show/Hide</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-6 mb-2">
                  <div className="form-check form-check-inline me-2">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id="phone"
                      name="phoneInfo"
                      value="phone"
                      onChange={updateBannerUI}
                    /> */}
                    <label className="form-label" htmlFor="phoneNum">&nbsp;Mobile</label>
                    <input className='form-control' id='phoneNum' type='text' value={canvasNumber} onChange={(e) => setCanvasNumber(e.target.value)} />
                  </div>
                </div>
                <div class="col-6 col-md-6 mb-2">
                  <div className="form-check form-check-inline me-2">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id="email"
                      name="emailInfo"
                      value="email"
                      onChange={updateBannerUI}
                    /> */}
                    <label className="form-label" htmlFor="emailText">&nbsp;Email</label>
                    <input className='form-control' id='emailText' type='email' value={canvasEmail} onChange={(e) => setCanvasEmail(e.target.value)} />
                  </div>
                </div>
                <div class="col-6 col-md-6 mb-2">
                  <div className="form-check form-check-inline me-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="website"
                      name="websiteInfo"
                      value="website"
                    // onChange={updateBannerUI}
                    />
                    <label className="form-label" htmlFor="websiteUrl">&nbsp;Url</label>
                    <input className='form-control' id='UrlText' type='text' value={canvasText} onChange={(e) => setCanvasText(e.target.value)} />
                  </div>
                </div>
                <div class="col-6 col-md-6">
                  <div className="form-check form-check-inline me-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="qrcode"
                      name="qrcodeInfo"
                      value="qrcode"
                      disabled
                    // onChange={updateBannerUI}
                    />
                    <label className="form-check-label socialMedia" htmlFor="qrcode">
                      &nbsp;QR
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="textColor1">
                    Text Color 2
                  </label>
                  <input
                    type="color"
                    className="form-control p-0"
                    id="textColor1"
                    placeholder="Enter your name"
                    autoComplete="off"
                    onChange={updateBannerUI}
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="form-label" htmlFor="textColor2">
                    Text Color 2
                  </label>
                  <input
                    type="color"
                    className="form-control p-0"
                    id="textColor2"
                    placeholder="Enter your name"
                    autoComplete="off"
                    onChange={updateBannerUI}
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </div>
          </div> */}


          <div className={components.includes('adjust') ? "form-group boxed pb-0" : "d-none"}>
            <div className="input-wrapper">
              <label className="form-label" htmlFor="name5">
                Adjust Items
              </label>
            </div>
          </div>
          <div class={components.includes('adjust') ? "row" : "d-none"}>
            <div class="col-6 col-md-2">
              <div className="form-check form-check-inline me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="phoneAdjust"
                  name="dragsdrop"
                  value="phoneAdjust"
                  checked={draggingItems === "phoneAdjust"}
                  onChange={updateBannerUI}
                />
                <label className="form-check-label socialMedia" htmlFor="phoneAdjust">
                  &nbsp;Mobile
                </label>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div className="form-check form-check-inline me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="emailAdjust"
                  name="dragsdrop"
                  value="emailAdjust"
                  checked={draggingItems === "emailAdjust"}
                  onChange={updateBannerUI}
                />
                <label className="form-check-label socialMedia" htmlFor="emailAdjust">
                  &nbsp;Email
                </label>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div className="form-check form-check-inline me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="websiteAdjust"
                  name="dragsdrop"
                  value="websiteAdjust"
                  checked={draggingItems === "websiteAdjust"}
                  onChange={updateBannerUI}
                />
                <label className="form-check-label socialMedia" htmlFor="websiteAdjust">
                  &nbsp;Url
                </label>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div className="form-check form-check-inline me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="qrcodeAdjust"
                  name="dragsdrop"
                  value="qrcodeAdjust"
                  checked={draggingItems === "qrcodeAdjust"}
                  onChange={updateBannerUI}
                  disabled
                />
                <label className="form-check-label socialMedia" htmlFor="qrcodeAdjust">
                  &nbsp;QR
                </label>
              </div>
            </div>
            <div class="col-6 col-md-2">
              <div className="form-check form-check-inline me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id="logoAdjust"
                  name="dragsdrop"
                  value="logoAdjust"
                  checked={draggingItems === "logoAdjust" || draggingItems === undefined}
                  onChange={updateBannerUI}
                />
                <label className="form-check-label socialMedia" htmlFor="logoAdjust">
                  &nbsp;Logo
                </label>
              </div>
            </div>
          </div>


          {/* <div className="form-check form-check-inline me-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="site"
              name="siteInfo"
              value="site"
              onChange={updateBannerUI}
            />
            <label className="form-check-label socialMedia" htmlFor="site">
              &nbsp;Site
            </label>
          </div> */}
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = ({ offcanvas }) => {
  const { isEditBannerVisible, bannerData, bannerID, draggingItems } = offcanvas
  return {
    isEditBannerVisible,
    bannerData,
    bannerID,
    draggingItems
  }
}

const mapDispatchToProps = {
  hideEditShareBanner,
  showEditShareBanner,
  updateImageById,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditShareBanner)
