import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import { hideOgOffcanvas, showImageModal, setWhereToUpdateImage, newImageData } from '../../../features/modal/offcanvasSlice'

const OgOffcanvas = ({
    isOgVisible,
    hideOgOffcanvas,
    websiteDataAppearnace = {},
    showImageModal,
    setWhereToUpdateImage,
    imageData,
    isImageModalVisible,
    WhereToUpdateImage,
    newImageData
}) => {
    const [pageProperties, setPageProperties] = useState(null)
    const [disableOgSubmitButton, setDisableOgSubmitButton] = useState(false)
    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    
    const collectData = () => {
        Object.keys(websiteDataAppearnace['pages']).map((value, key) => {
            if (websiteDataAppearnace['pages'][value].hasOwnProperty('url')) {
                if (websiteDataAppearnace['pages'][value]['url'] === url) {
                    setPageProperties(websiteDataAppearnace['pages'][value]['properties'])
                }
            }
        })
    }

    // const setDefaultValues = (pageProperties) => {
    // }

    useEffect(() => {
        if (pageProperties !== null && pageProperties !== undefined) {
        }
    }, [pageProperties])

    const getItemObject = () => { return JSON.parse(JSON.stringify(pageProperties)) };

    const onOgTypeChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.ogType = e.target.value
        setPageProperties(pageProperties)
    }

    const onOgTitleChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.ogTitle = e.target.value
        setPageProperties(pageProperties)
    }

    const onOgDescriptionChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.ogDescription = e.target.value
        setPageProperties(pageProperties)
    }

    const onOgUrlChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.ogUrl = e.target.value
        setPageProperties(pageProperties)
    }

    const deleteOgImage = () => {
        const pageProperties = getItemObject()
        pageProperties.ogImage = ''
        setPageProperties(pageProperties)
    }

    const deleteTwitterImage = () => {
        const pageProperties = getItemObject()
        pageProperties.twitterImage = ''
        setPageProperties(pageProperties)
    }

    const deleteFacebookImage = () => {
        const pageProperties = getItemObject()
        pageProperties.facebookImage = ''
        setPageProperties(pageProperties)
    }

    const onTwitterTitleChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.twitterTitle = e.target.value
        setPageProperties(pageProperties)
    }

    const onTwitterDescriptionChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.twitterDescription = e.target.value
        setPageProperties(pageProperties)
    }

    const onTwitterUrlChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.twitterUrl = e.target.value
        setPageProperties(pageProperties)
    }

    const onFacebookTitleChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.facebookTitle = e.target.value
        setPageProperties(pageProperties)
    }

    const onFacebookDescriptionChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.facebookDescription = e.target.value
        setPageProperties(pageProperties)
    }

    const onFacebookUrlChange = (e) => {
        const pageProperties = getItemObject()
        pageProperties.facebookUrl = e.target.value
        setPageProperties(pageProperties)
    }

    function handleShowImageModal(imageData, ID, blockType, itemorbg) {
        setWhereToUpdateImage({
            blockType: itemorbg,
            ItemOrBg: itemorbg,
            ItemPosition: ID
        })
        showImageModal({
            isImageModalVisible: true,
            imageData: imageData,
            imageID: ID,
            imageType: 'other'
        })
        newImageData({ imageDataObj: imageData });
    }

    useEffect(() => {
            if (!isImageModalVisible && imageData !== undefined && imageData !== null && imageData.length !== 0 && WhereToUpdateImage !== null && WhereToUpdateImage !== undefined) {

                if (WhereToUpdateImage.ItemOrBg === 'PageOg' && pageProperties !== null) {
                    const ItemData = getItemObject()
                    ItemData.ogImage = imageData[0].src
                    setPageProperties(ItemData)
                    newImageData({ imageDataObj: [] })
                    setWhereToUpdateImage(null)
                }
                if (WhereToUpdateImage.ItemOrBg === 'TwitterOg' && pageProperties !== null) {
                    const ItemData = getItemObject()
                    ItemData.twitterImage = imageData[0].src
                    setPageProperties(ItemData)
                    newImageData({ imageDataObj: [] })
                    setWhereToUpdateImage(null)
                }
                if (WhereToUpdateImage.ItemOrBg === 'FacebookOg' && pageProperties !== null) {
                    const ItemData = getItemObject()
                    ItemData.facebookImage = imageData[0].src
                    setPageProperties(ItemData)
                    newImageData({ imageDataObj: [] })
                    setWhereToUpdateImage(null)
                }
            }
    }, [imageData, isImageModalVisible])

    const handleSubmitOgData = () => {
        setDisableOgSubmitButton(true)
    }
    return (
        <Offcanvas show={isOgVisible} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 pt-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Manage Open Graph</strong>
                    </div>
                    <span className="close-sidebar-button" style={{cursor:'pointer'}} onClick={() => {hideOgOffcanvas()}}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-2">
                    <form>
                        <div class="section-title">Open Graph</div>
                        <div className="wide-block pb-1">
                            
                                <div className="row mb-1">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic d-flex justify-content-between align-items-center">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="type5">Upload Image</label>
                                            </div>
                                            {pageProperties !== null && pageProperties.ogImage === '' &&
                                            <span className="badge bg-primary" onClick={() =>
                                                    handleShowImageModal(
                                                        [],
                                                        0,
                                                        'SM',
                                                        'PageOg'
                                                    )
                                                }>+ Add Media</span>
                                            }
                                        </div>
                                    </div>
                                    {pageProperties !== null && pageProperties.ogImage !== '' &&
                                        <div className="col-4 col-md-4">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <div className="d-flex justify-content-center align-items-center position-relative">
                                                        <img src={pageProperties !== null && pageProperties.ogImage} alt="" className="imaged w-100 border border-primary border-2 cursor-pointer" onClick={() =>
                                                            handleShowImageModal(
                                                                [{'src':pageProperties !== null && pageProperties.ogImage,'alt':'', 'style':''}],
                                                                0,
                                                                'SM',
                                                                'PageOg'
                                                            )
                                                        } />
                                                        <div className="badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer" onClick={() => deleteOgImage()}>
                                                            <ion-icon name="trash-outline" classname="md trash" aria-label="Delete" role="img" className="md hydrated"></ion-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="type5">Type</label>
                                        <select className="form-control form-select" value={pageProperties !== null && pageProperties.ogType} onChange={(e) => { onOgTypeChange(e) }} id="type5">
                                            <option value="">Select OG type</option>
                                            <option value="website">Website</option>
                                            <option value="article">Article</option>
                                            <option value="blog">Blog</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="title1">Title</label>
                                        <input type="text" className="form-control" value={pageProperties !== null && pageProperties.ogTitle} onChange={(e) => { onOgTitleChange(e) }} id="title1" placeholder="Enter OG Title" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="description5">Description</label>
                                        <textarea className="form-control" value={pageProperties !== null && pageProperties.ogDescription} onChange={(e) => { onOgDescriptionChange(e) }} id="description5" placeholder="Enter OG Description"></textarea>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="url1">URL</label>
                                        <input type="url" className="form-control" value={pageProperties !== null && pageProperties.ogUrl} onChange={(e) => { onOgUrlChange(e) }} id="url1" placeholder="Enter OG URL"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                        </div>
                        <div class="section-title">Twitter</div>
                        <div className="wide-block pb-1">

                                <div className="row mb-1">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic d-flex justify-content-between align-items-center">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="type5">Upload Image</label>
                                            </div>
                                            {pageProperties !== null && pageProperties.twitterImage === '' &&
                                            <span className="badge bg-primary" onClick={() =>
                                                    handleShowImageModal(
                                                        [],
                                                        0,
                                                        'SM',
                                                        'TwitterOg'
                                                    )
                                                }>+ Add Media</span>
                                            }
                                        </div>
                                    </div>
                                    {pageProperties !== null && pageProperties.twitterImage !== '' &&
                                        <div className="col-4 col-md-4">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <div className="d-flex justify-content-center align-items-center position-relative">
                                                        <img src={pageProperties !== null && pageProperties.twitterImage} alt="" className="imaged w-100 border border-primary border-2 cursor-pointer" onClick={() =>
                                                            handleShowImageModal(
                                                                [{'src':pageProperties !== null && pageProperties.twitterImage,'alt':'', 'style':''}],
                                                                0,
                                                                'SM',
                                                                'TwitterOg'
                                                            )
                                                        } />
                                                        <div className="badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer" onClick={() => deleteTwitterImage()}>
                                                            <ion-icon name="trash-outline" classname="md trash" aria-label="Delete" role="img" className="md hydrated"></ion-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="title1">Title</label>
                                        <input type="text" className="form-control" value={pageProperties !== null && pageProperties.twitterTitle} onChange={(e) => { onTwitterTitleChange(e) }} id="title1" placeholder="Enter OG Title" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="description5">Description</label>
                                        <textarea className="form-control" value={pageProperties !== null && pageProperties.twitterDescription} onChange={(e) => { onTwitterDescriptionChange(e) }} id="description5" placeholder="Enter OG Description"></textarea>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="url1">URL</label>
                                        <input type="url" className="form-control" value={pageProperties !== null && pageProperties.twitterUrl} onChange={(e) => { onTwitterUrlChange(e) }} id="url1" placeholder="Enter OG URL"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                        </div>
                        <div class="section-title">Facebook</div>
                        <div className="wide-block pb-1">

                        <div className="row mb-1">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic d-flex justify-content-between align-items-center">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="type5">Upload Image</label>
                                            </div>
                                            {pageProperties !== null && pageProperties.facebookImage === '' &&
                                            <span className="badge bg-primary" onClick={() =>
                                                    handleShowImageModal(
                                                        [],
                                                        0,
                                                        'SM',
                                                        'FacebookOg'
                                                    )
                                                }>+ Add Media</span>
                                            }
                                        </div>
                                    </div>
                                    {pageProperties !== null && pageProperties.facebookImage !== '' &&
                                        <div className="col-4 col-md-4">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <div className="d-flex justify-content-center align-items-center position-relative">
                                                        <img src={pageProperties !== null && pageProperties.facebookImage} alt="" className="imaged w-100 border border-primary border-2 cursor-pointer" onClick={() =>
                                                            handleShowImageModal(
                                                                [{'src':pageProperties !== null && pageProperties.facebookImage,'alt':'', 'style':''}],
                                                                0,
                                                                'SM',
                                                                'FacebookOg'
                                                            )
                                                        } />
                                                        <div className="badge position-absolute e-n1 t-n1 z-index-10 bg-danger cursor-pointer" onClick={() => deleteFacebookImage()}>
                                                            <ion-icon name="trash-outline" classname="md trash" aria-label="Delete" role="img" className="md hydrated"></ion-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="title1">Title</label>
                                        <input type="text" className="form-control" value={pageProperties !== null && pageProperties.facebookTitle} onChange={(e) => { onFacebookTitleChange(e) }} id="title1" placeholder="Enter Facebook Title" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="description5">Description</label>
                                        <textarea className="form-control" value={pageProperties !== null && pageProperties.facebookDescription} onChange={(e) => { onFacebookDescriptionChange(e) }} id="description5" placeholder="Enter OG Description"></textarea>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="url1">URL</label>
                                        <input type="url" className="form-control" value={pageProperties !== null && pageProperties.facebookUrl} onChange={(e) => { onFacebookUrlChange(e) }} id="url1" placeholder="Enter OG URL"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                        </div>
                        <div className="sidebar-buttons webweb-sidebar-buttons">
                            <button className="btn btn-block btn-success" onClick={handleSubmitOgData} style={{borderRadius: "initial"}} disabled={disableOgSubmitButton}>
                                <ion-icon name="checkmark-outline"></ion-icon> Update
                            </button>
                        </div>
                    </form>
                </div>
                
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({offcanvas, websiteData}) => {
    const {
        isOgVisible,
        imageData,
        isImageModalVisible,
        WhereToUpdateImage
    } = offcanvas
    const {
        appearance: websiteDataAppearnace
    } = websiteData
    return {
        isOgVisible,
        websiteDataAppearnace,
        imageData,
        isImageModalVisible,
        WhereToUpdateImage
    }
}
  
const mapDispatchToProps = {
    hideOgOffcanvas,
    showImageModal,
    setWhereToUpdateImage,
    newImageData
}

export default connect(mapStateToProps, mapDispatchToProps)(OgOffcanvas)
