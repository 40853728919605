import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import Offcanvas from 'react-bootstrap/Offcanvas'
import { hideFormOffcanvas, showDeleteModal, setContentBlockItem, setFlagsToCheckChanges, hideUpdateDataOffcanvas } from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import Form from 'react-bootstrap/Form'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import Nestable from 'react-nestable'
import 'react-nestable/dist/styles/index.css'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'


const FormOffcanvas = ({
    isFormVisible,
    hideFormOffcanvas,
    showDeleteModal,
    showAppToast,
    formID,
    ContentBlockItem,
    setContentBlockItem,
    FlagsToCheckChanges,
    setFlagsToCheckChanges,
    hideUpdateDataOffcanvas,
    setWebsiteData
}) => {
    const [formLayout, setFormLayout] = useState('');
    const [staticFormFields, setStaticFormFields] = useState([]);
    const [staticFormProperties, setStaticFormProperties] = useState({})
    // const [renderPageFlag, setRenderPageFlag] = useState(false)
    const [disableUpdateButton, setDisableUpdateButton] = useState(false);

    const [reorderItems, setReorderItems] = useState(false);

    const [buttonStyle, setButtonStyle] = useState(null)

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }

    const handleStyleList = () => {
        axios.get('/appearance/getButtonStyles').then(res => {
            if (res.data.message === 'Success') {
                setButtonStyle(res.data.data)
            } else {
                console.log('SOMETHING WENT WRONG')
            }
        })
    }

    useEffect(() => {
        if (buttonStyle === null) {
            handleStyleList();
        }
    }, [buttonStyle])


    const websiteJSON = fetchWebsiteJSON()

    useEffect(() => {
        if (isFormVisible) {
            axios.get('/form/' + selectedDomain + '/' + formID).then(res => {
                if (res.data.message === 'Success') {
                    res.data.data.items.map((value, key) => {
                        res.data.data.items[key].id = key
                    })
                    setStaticFormFields(res.data.data.items);
                    setStaticFormProperties(res.data.data.properties)
                    // setRenderPageFlag(true)
                    // console.log(staticFormFields)
                }
            })
        }
    }, [isFormVisible]);

    // useEffect(() => {
    //     if (renderPageFlag) {
    //         collectData()
    //     }
    // }, [renderPageFlag])

    const updateFormData = () => {
        setDisableUpdateButton(true);
        axios.put('/form/' + selectedDomain + '/' + formID, { "properties": staticFormProperties, "items": staticFormFields }).then(res => {
            setWebsiteData(res.data.data)
            setDisableUpdateButton(false)
            showAppToast({
                toastMessage: 'Updated Successfully ',
                background: 'success',
                timeout: '1000',
                icon: 'checkmark-circle-outline',
                position: 'toast-center'
            })
            hideFormOffcanvas();
            hideUpdateDataOffcanvas();
        })
    }

    const handleAddField = () => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField.push({
            "label": "",
            "formType": "text",
            "required": false,
            "icon": "",
            "otherRules": "",
            "value": "",
            "placeholder": "",
            "width": "12",
            "list": []
        })
        setStaticFormFields(tempstaticFormField)
        // setRenderPageFlag(true)
    }

    const getItemObject = () => { return JSON.parse(JSON.stringify(ContentBlockItem)) };


    const changeFlagsToCheckChanges = (fieldName) => {
        const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
        if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
            tempFlagsToCheckChanges[fieldName] = true;
            setFlagsToCheckChanges(tempFlagsToCheckChanges);
        }
    }

    const onTitleChange = (e) => {
        const ItemData = getItemObject();
        ItemData.title = e.target.value;
        changeFlagsToCheckChanges('title');
        setContentBlockItem(ItemData);
    }

    const handleFieldInput = (data, index) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField[index].formType = data;
        setStaticFormFields(tempstaticFormField);
        // setRenderPageFlag(true)
    }

    const handleDeleteField = (id) => {
        // console.log(id)
        // console.log(staticFormFields)
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField.splice(id, 1)
        setStaticFormFields(tempstaticFormField)
        // setRenderPageFlag(true)
        // console.log(staticFormFields)
    }

    const onChangeLabel = (value, id) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField[id].label = value;
        setStaticFormFields(tempstaticFormField);
        // console.log(value, id);
    }

    const dropdownChange = (value, id) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        const arr = value.split(",");
        tempstaticFormField[id].list = arr;
        setStaticFormFields(tempstaticFormField);
    }

    const onChangeRequired = (value, id) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField[id].required = value;
        setStaticFormFields(tempstaticFormField);
    }

    const onPlaceholder = (value, id) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField[id].placeholder = value;
        setStaticFormFields(tempstaticFormField);
    }

    const handleFieldWidth = (value, id) => {
        const tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField[id].width = value;
        setStaticFormFields(tempstaticFormField);
    }

    const onButtonTitleChange = (value, id) => {
        const tempstaticFormProperties = JSON.parse(JSON.stringify(staticFormProperties));
        tempstaticFormProperties[id] = value;
        console.log(tempstaticFormProperties);
        setStaticFormProperties(tempstaticFormProperties);
    }

    const renderItem = ({ item, index }) => {
        // console.log(item)
        return (
            <div className="container mb-1">
                <div className="row px-2">
                    <div className="col-md-12 d-flex align-items-center border-bottom py-2">
                        <div>
                            <ion-icon name="pause-outline" className="fs-5 text-muted"></ion-icon>
                        </div>
                        <div className="form-group basic p-0 pb-1">
                            <div className="input-wrapper d-flex">
                                <div className="col-md-2 d-flex justify-content-start align-items-center me-0">
                                    {
                                        item.formType === 'select' &&
                                        <ion-icon name="chevron-down-circle-sharp" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'date' &&
                                        <ion-icon name="calendar-number-sharp" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'email' &&
                                        <ion-icon name="at-circle-sharp" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'text' &&
                                        <ion-icon name="text-sharp" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'textarea' &&
                                        <ion-icon name="menu-outline" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'number' &&
                                        <div className="text-dark fs-13 text-bold">123</div>
                                    }
                                    {
                                        item.formType === 'upload' &&
                                        <ion-icon name="cloud-upload-outline" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'checkbox' &&
                                        <ion-icon name="checkbox-outline" className="text-dark fs-5"></ion-icon>
                                    }
                                    {
                                        item.formType === 'radio' &&
                                        <ion-icon name="radio-button-on-outline" className="text-dark fs-5"></ion-icon>
                                    }
                                </div>
                                <h4 className='mb-0 ms-2 fs-13'>
                                    {item.label}
                                    {item.required && <span className='text-danger fs-20 ms-1'>*</span>}
                                </h4>
                                {/* <input type="text" className="form-control" value={item.label} readOnly placeholder="Enter field title"
                                    autoComplete="off" style={{ height: '30px', fontSize: '13px', border: 'none' }} /> */}
 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let updatedItems = []

    const handleFormDelete = () => {
        hideFormOffcanvas();
        hideUpdateDataOffcanvas();
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this form?', triggerMessage: 'form', deleteID: formID })
    }

    const changeLayout = (v) => {
        setFormLayout(v);
        let tempstaticFormField = JSON.parse(JSON.stringify(staticFormFields));
        tempstaticFormField = tempstaticFormField.map(field => {
            field.width = v === 'grid' ? '6' : '12'
            return field
        });
        setStaticFormFields(tempstaticFormField);
    }

    useEffect(() => {
        const staticFormFieldsLength = staticFormFields.length;
        const FieldsWith12 = staticFormFields.filter(itm => itm.width === '12').length;
        const FieldsWith6 = staticFormFields.filter(itm => itm.width === '6').length;
        if(staticFormFieldsLength === FieldsWith12) {
            setFormLayout('fullwidth');
        } else if (staticFormFieldsLength === FieldsWith6) {
            setFormLayout('grid');
        } else { 
            setFormLayout('custom');
        }
        // console.log({ staticFormFieldsLength, FieldsWith12, FieldsWith6, staticFormFields, formLayout });
        
    }, [staticFormFields])
    

    return (
        <Offcanvas show={isFormVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
            <Offcanvas.Body className="offcanvas-body ps-0 pe-0 pt-0">
                <div className="profileBox webweb-profileBox">
                    <div className="in">
                        <strong>Manage Form</strong>
                    </div>
                    <span className="close-sidebar-button" style={{ cursor: 'pointer' }} onClick={() => { hideFormOffcanvas() }}>
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
                <div className="section full mb-2">
                    <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#formFieldTab" role="tab" style={{ height: '46px' }}>
                                    Form Fields
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#notificationTab" role="tab" style={{ height: '46px' }}>
                                    Configuration
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" style={{ marginTop: '50px' }}>
                        <div className="tab-pane fade show active" id="formFieldTab" role="tabpanel">
                            <div className="section-title">
                                <div className="row">
                                    <div className="col-12 col-md-12 mb-1">
                                        <div className="form-group basic p-0">
                                            <div className="input-wrapper">
                                                <h5 className="mb-0">Form Title</h5>
                                                <input type="text" className="form-control pe-0 fs-20" id="name5" placeholder="Enter form title" value={ContentBlockItem !== null && ContentBlockItem.title} onChange={(e) => { onTitleChange(e) }} autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 d-flex align-items-center justify-content-end">
                                        {
                                            reorderItems ?
                                                <span className="badge bg-primary ms-1" style={{ cursor: 'pointer' }} onClick={() => setReorderItems(false)}><ion-icon name="checkmark-circle-outline" className="fs-6"></ion-icon>&nbsp;Done</span>
                                                :
                                                <div className='row'>
                                                    <div className="col-md-6 d-flex">
                                                        <div className='px-1 badge bg-primary rounded-right-0'>
                                                        {
                                                            formLayout === 'grid' &&
                                                            <ion-icon name="grid" className="fs-6"></ion-icon>
                                                        }
                                                        {
                                                            formLayout === 'fullwidth' &&
                                                            <ion-icon name="layers" className="fs-6"></ion-icon>
                                                        }
                                                        {
                                                            formLayout === 'custom' &&
                                                            <ion-icon name="options" className="fs-6"></ion-icon>
                                                        }
                                                        </div>
                                                        <select className="form-control form-select px-1 pe-2 badge bg-outline-primary me-1 text-primary rounded-left-0 icon-sm" id='FormLayout' defaultValue={formLayout} value={formLayout} onChange={(e) => changeLayout(e.target.value)} placeholder='Select Form Layout'>
                                                            <option value='grid'>
                                                                Grid
                                                            </option>
                                                            <option value='fullwidth'>
                                                                Full Width
                                                            </option>
                                                            <option value='custom' disabled>
                                                                Custom
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 d-flex px-0">
                                                        <span className="badge bg-primary px-3" style={{ cursor: 'pointer' }} onClick={handleAddField}><ion-icon name="add-circle-outline" className="fs-6"></ion-icon>&nbsp;Add Field</span>
                                                        <span className="badge bg-primary ms-1 px-3" style={{ cursor: 'pointer' }} onClick={() => setReorderItems(true)}><ion-icon name="reorder-four-outline" className="fs-6"></ion-icon>&nbsp;Reorder</span>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                reorderItems ?
                                    <Nestable
                                        items={staticFormFields}
                                        renderItem={renderItem}
                                        maxDepth={1}
                                        handler={false}
                                        onChange={newItems => {
                                            updatedItems = newItems.items
                                            // console.log(updatedItems)
                                            setStaticFormFields(updatedItems);
                                        }}
                                        draggable={true}
                                    />
                                    :
                                    <>
                                        {
                                            staticFormFields.length > 0 && staticFormFields.map((item, index) => {
                                                return <div className="accordion mx-3" id='accordionExample1' key={index}>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header">
                                                            <button className="accordion-button collapsed p-0 icon-sm" type="button" data-bs-toggle="collapse"
                                                                data-bs-target={`#accordion1${index}`}>
                                                                    
                                                                <div className="col-md-2 d-flex justify-content-start align-items-center me-0">
                                                                    {
                                                                        item.formType === 'select' &&
                                                                        <ion-icon name="chevron-down-circle-sharp" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'date' &&
                                                                        <ion-icon name="calendar-number-sharp" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'email' &&
                                                                        <ion-icon name="at-circle-sharp" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'text' &&
                                                                        <ion-icon name="text-sharp" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'textarea' &&
                                                                        <ion-icon name="menu-outline" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'number' &&
                                                                        <div className="text-dark fs-13 text-bold">123</div>
                                                                    }
                                                                    {
                                                                        item.formType === 'upload' &&
                                                                        <ion-icon name="cloud-upload-outline" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'checkbox' &&
                                                                        <ion-icon name="checkbox-outline" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                    {
                                                                        item.formType === 'radio' &&
                                                                        <ion-icon name="radio-button-on-outline" className="text-dark fs-5"></ion-icon>
                                                                    }
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 d-flex align-items-center">
                                                                        <div className="form-group basic p-0 pb-1">
                                                                            <div className="input-wrapper position-relative">
                                                                                <input type="text" className="form-control h-30px fs-13 pe-0" value={item.label} onChange={(e) => onChangeLabel(e.target.value, index)} placeholder="Enter field title"
                                                                                    autoComplete="off" />
                                                                                <i className="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                                {item.required && <span className='text-danger fs-25 position-absolute e-0 t-1'>*</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 d-flex justify-content-end align-items-center me-2">
                                                                    <ion-icon name="trash-outline" className="text-danger fs-5" style={{ cursor: 'pointer' }} onClick={() => { handleDeleteField(index) }}></ion-icon>
                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`accordion1${index}`} className="accordion-collapse collapse" data-bs-parent='#accordionExample1'>
                                                            <div className="accordion-body" style={{ backgroundColor: '#f5f5f5' }}>
                                                                <form>
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            {(item.formType === 'select' || item.formType === 'checkbox' || item.formType === 'radio') &&
                                                                                <div className="form-group boxed">
                                                                                    <div className="input-wrapper">
                                                                                        <label className="form-label" htmlFor="DropdownOptions">{item.formType === 'select' && 'Dropdown '}Options</label>
                                                                                        <input type="text" className="form-control px-1" id='DropdownOptions' value={item.list.toString()} onChange={(e) => dropdownChange(e.target.value, index)} placeholder="Enter comma ',' saperated"
                                                                                            autoComplete="off" />
                                                                                        <i className="clear-input">
                                                                                            <ion-icon name="close-circle"></ion-icon>
                                                                                        </i>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {((item.formType === 'text') || (item.formType === 'email') || (item.formType === 'number') || (item.formType === 'textarea') || (item.formType === 'date') || (item.formType === 'upload')) &&
                                                                                <div className="form-group boxed">
                                                                                    <div className="input-wrapper">
                                                                                        <label className="form-label" htmlFor="Placeholder">Placeholder</label>
                                                                                        <input type="text" className="form-control px-2" value={item.placeholder} onChange={(e) => onPlaceholder(e.target.value, index)} id='Placeholder' placeholder="Enter placeholder"
                                                                                            autoComplete="off" />
                                                                                        <i className="clear-input">
                                                                                            <ion-icon name="close-circle"></ion-icon>
                                                                                        </i>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className='form-group boxed'>
                                                                                <label className="form-label mb-2 text-end" htmlFor="Required">Required</label>
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    checked={item.required}
                                                                                    onChange={(e) => onChangeRequired(e.target.checked, index)}
                                                                                    id={`Required${index}`}
                                                                                    label=""
                                                                                    className='d-flex justify-content-end'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-8">
                                                                            <div className="form-group boxed">
                                                                                <div className="input-wrapper">
                                                                                    <label className="form-label" htmlFor="Type">Type</label>
                                                                                    <select className="form-control form-select px-1" id='Type' onChange={(e) => { handleFieldInput(e.target.value, index) }}>
                                                                                        <option value="text" selected={item.formType === 'text'}>Text</option>
                                                                                        <option value="number" selected={item.formType === 'number'}>Number</option>
                                                                                        <option value="textarea"
                                                                                            selected={item.formType === 'textarea'}>Textarea</option>
                                                                                        <option value="date" selected={item.formType === 'date'}>Date</option>
                                                                                        <option value="select" selected={item.formType === 'select'}>Dropdown</option>
                                                                                        <option value="upload" selected={item.formType === 'upload'}>Upload</option>
                                                                                        <option value="email" selected={item.formType === 'email'}>Email</option>
                                                                                        <option value="checkbox" selected={item.formType === 'checkbox'}>Checkbox</option>
                                                                                        <option value="radio" selected={item.formType === 'radio'}>Radio</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group boxed">
                                                                                <div className="input-wrapper">
                                                                                    <label className="form-label text-end" htmlFor="Width">Width</label>
                                                                                    <select className="form-control form-select px-1" id='Width' defaultValue='12' value={item.width} onChange={(e) => { handleFieldWidth(e.target.value, index) }}>
                                                                                        <option value="4" selected={item.width === '4'}>33%</option>
                                                                                        <option value="6" selected={item.width === '6'}>50%</option>
                                                                                        <option value="12" selected={item.width === '12'}>100%</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </>
                            }



                            <div className="container mt-1">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group basic p-0 pb-1 position-relative">
                                            <div className="input-wrapper d-flex justify-content-between">
                                                <input type="text" className={`form-control btn border rounded-1 px-1 cursor-initial w-auto ${(staticFormProperties && staticFormProperties.submitBtnStyle) ? staticFormProperties.submitBtnStyle : ' btn-primary'}`} id="buttonTitle" value={(staticFormProperties && staticFormProperties.submitBtnTxt) ? staticFormProperties.submitBtnTxt : ''} onChange={(e) => onButtonTitleChange(e.target.value, 'submitBtnTxt')} placeholder="Enter button title"
                                                    autoComplete="off" style={{ cursor: 'initial'}} />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                                <div className='position-relative ps-1'>
                                                    <select className="form-control form-select cursor-pointer" title='Change Button Style' placeholder='Style' id="buttonStyleDropdown" onChange={(e) => { onButtonTitleChange(e.target.value, 'submitBtnStyle') }}>
                                                        <option value='' selected disabled>Style</option>
                                                        {(buttonStyle !== undefined && buttonStyle !== null) &&
                                                            <>
                                                                {Object.keys(buttonStyle).map((key, i) => {
                                                                    return <option key={i} value={key}>{buttonStyle[key]}</option>
                                                                })}
                                                            </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                                className="btn btn-block btn-danger position-absolute"
                                type="button"
                                style={{ borderRadius: 'initial', bottom: '40px' }}
                                onClick={handleFormDelete}
                            >
                                <ion-icon name="trash-outline"></ion-icon>{' '}
                                Delete Form
                            </button>

                            <div className="sidebar-buttons webweb-sidebar-buttons">
                                <button
                                    className="btn btn-block btn-success"
                                    type="button"
                                    onClick={updateFormData}
                                    style={{ borderRadius: 'initial' }}
                                    disabled={disableUpdateButton}
                                >
                                    <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                    Update
                                </button>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="notificationTab" role="tabpanel">
                            <div className="section full mt-2 mb-2">
                                <div className="section-title">Email Notification Settings</div>
                                <div className="wide-block pb-1 pt-1">
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="emailSubject">Email Subject</label>
                                            <input type="text" className="form-control" id="emailSubject" placeholder="Enter email subject" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="toEmails">To Email</label>
                                            <textarea id="toEmails" rows="2" placeholder="Enter to emails" className="form-control"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isFormVisible,
        formID,
        ContentBlockItem,
        FlagsToCheckChanges,
    } = offcanvas
    return {
        isFormVisible,
        formID,
        ContentBlockItem,
        FlagsToCheckChanges
    }
}

const mapDispatchToProps = {
    hideFormOffcanvas,
    showAppToast,
    showDeleteModal,
    setContentBlockItem,
    setFlagsToCheckChanges,
    hideUpdateDataOffcanvas,
    setWebsiteData
}

export default connect(mapStateToProps, mapDispatchToProps)(FormOffcanvas)
