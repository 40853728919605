import React from 'react'
import { Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

function Support(props) {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    return (
        <div id="appCapsule">
            <div className="section-title justify-content-center">{selectedDomain}</div>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="pilled" role="tabpanel">

                    <div className="section full p-0">
                        <ul className="nav nav-tabs capsuled bg-light rounded-0" role="tablist" style={{height: '50px', boxShadow: '0px 0px 10px 0px gainsboro'}}>
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#contact" role="tab">
                                    <ion-icon name="call-outline" className="fs-6"></ion-icon>
                                    Contact
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#liveChat" role="tab">
                                    <ion-icon name="chatbubbles-outline" className="fs-6"></ion-icon>
                                    Live Chat
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#tickets" role="tab">
                                    <ion-icon name="ticket-outline" className="fs-6"></ion-icon>
                                    Tickets
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-2">
                            <div className="tab-pane fade show active ps-1 pe-1" id="contact" role="tabpanel">
                                <div className="section full mt-2">
                                    <div className="pt-2 pb-2">
                                        <div className="row">
                                            <div className="col-12 col-md-12 text-center">
                                                <h2>Feel free to reach us.</h2>
                                            </div>
                                            {/* <div className="col-12 col-md-12 text-center mb-2">
                                                <a href="viewTicket.html" className="btn btn-primary me-1">
                                                    <ion-icon name="eye-outline"></ion-icon> View Support Tickets
                                                </a>
                                            </div>
                                            <div className="col-12 col-md-12 text-center mb-2">
                                                <a href="addTicket.html" className="btn btn-info me-1">
                                                    <ion-icon name="add-outline"></ion-icon> Create Support Ticket
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="mt-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-center">Phone</h5>
                                                        <p className="card-text text-center">Feel free to contact us.</p>
                                                        <p className="card-text text-center"><a href="tel:8097578727">+91 8097578727</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="mt-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-center">Email</h5>
                                                        <p className="card-text text-center">For any quarry connect with us through Mail. </p>
                                                        <p className="card-text text-center"><a href="mailTo:support@webweb.in">support@webweb.in</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade ps-1 pe-1" id="liveChat" role="tabpanel">
                                <iframe className="w-100" src="https://tawk.to/chat/5efcdc8c4a7c6258179bb5bb/default" id="liveChatWindow" frameborder="0" height="500"></iframe>
                            </div>
                            <div className="tab-pane fade" id="tickets" role="tabpanel">
                                <ul className="listview image-listview">
                                    
                                    <li className="custom-line">
                                        <Link to="/ticketsThread" className="item">
                                            <ion-icon name="ticket-outline" className="image"></ion-icon>
                                            <div className="in">
                                                <div>
                                                    Image Update
                                                    <header>There is an attached file to update image and.....</header>
                                                    <footer>1 hour ago</footer>
                                                </div>
                                                <span className="text-muted">Read More</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="custom-line">
                                        <Link to="/ticketsThread" className="item">
                                            <ion-icon name="ticket-outline" className="image"></ion-icon>
                                            <div className="in">
                                                <div>
                                                    Remove Page
                                                    <header>Remove 4th page name 'portfolio' from sitemap</header>
                                                    <footer>1 minute ago</footer>
                                                </div>
                                                <span className="text-muted">Read More</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="custom-line">
                                        <Link to="/ticketsThread" className="item">
                                            <ion-icon name="ticket-outline" className="image"></ion-icon>
                                            <div className="in">
                                                <div>
                                                    Content Update
                                                    <header>Line no 3 update 2019 to 2022 and update ....</header>
                                                    <footer>1 month ago</footer>
                                                </div>
                                                <span className="text-muted">Read More</span>
                                            </div>
                                        </Link>
                                    </li>
                                    
                                </ul>
                                <div className="section full mt-2">
                                    <div className="section-title">Fill the form to contact us *</div>
                                    <div className="wide-block pt-2 pb-2">
                                        <form>
                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <input type="text" className="form-control" id="name5" placeholder="Name" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <input type="email" className="form-control" id="email5" placeholder="Email" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="form-group boxed">
                                                <div className="input-wrapper">
                                                    <textarea id="address5" rows="4" className="form-control" placeholder="Message"></textarea>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>

                                            <div className="mt-1">
                                                <button type="submit" className="btn btn-primary btn-lg btn-block">
                                                    Send
                                                </button>
                                            </div>

                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Support;