import React from 'react'
import { connect } from 'react-redux'
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_CNTR_DFL1 = ({
  items = [], blockID, showEditOffcanvas, 
  properties, showDeleteModal, websiteDataBlocks, 
  showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType }) => {
    const handleBlockItemUpdate = itemID => {
      showEditOffcanvas({
        isEditVisible: true,
        itemID: itemID,
        items: items,
        blockID: blockID,
        properties:properties
      })
    }
  
    const handleNewEditItem = (itemID) => {
      setNewBlockType({ 'BlockType' : blockID.slice(0, 2), 'BlockID' : blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
      // console.log("whatIsKey",itemID, blockID);
      showUpdateDataOffcanvas({
        isEditVisible: true,
        itemID: itemID,
        items: items,
        blockID: blockID,
        properties:properties
      })
    }
  
    const handleDeleteContent = (blockName, deleteItemID) => {
      const contentName = websiteDataBlocks[blockName].items.contents.split(
        '.'
      )[1]
      showDeleteModal({
        isDeleteVisible: true,
        deleteMessage: 'Are your sure? You want to delete this content?',
        triggerMessage: 'content',
        deleteID: contentName,
        deleteItemID: deleteItemID
      })
    }
  
    function handleShowButtonModal(buttonData, ID) {
      handleBlockItemUpdate(ID)
      setTimeout(() => {
        showButtonModal({
          isButtonModalVisible: true,
          buttonData: buttonData,
          buttonID: ID
        })
      }, [1000])
      newButtonData({ buttonDataObj: buttonData })
    }

    return (
      <div>
          <div className="section full">
              <div className="wide-block pt-4 pb-3" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                  {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                      <div className="row">
                          {properties.blockHeading !== '' &&
                              <div className="col-12">
                                  <h3 className="text-center mb-0" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                              </div>
                          }
                          {properties.blockDescription !== '' &&
                              <div className="col-12">
                                  <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                              </div>
                          }
                      </div>
                  }
                  {/* <div className="position-absolute end-0 pe-1 text-end"><ion-icon name="create-outline" data-bs-toggle="offcanvas" data-bs-target="#PanelRight" className="text-secondary"></ion-icon></div> */}
                  <div className="profile-stats ps-2 pe-2 pt-2 overflow-scroll">
                      {items.map((item, key) => (
                          <div key={key} className='position-relative'>
                              <div className='d-flex pos-absglr z-index-1'>
                                  <div className="col-6 col-md-6 text-center">
                                      <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                  </div>
                                  <div className="col-6 col-md-6 text-center">
                                      <span className="badge bg-danger p-1 editBtnCss" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                                  </div>
                              </div>
                              <div>
                                  {item.image !== null && item.image.map((v, k) => {
                                      return <img src={v.src} width="100px" key={k} style={cssConverter(properties.imageCss)} />
                                  })}
                              </div>
                              <a href="#!" className="item">
                                  <strong dangerouslySetInnerHTML={{ __html: item.subtitle }} style={cssConverter(properties.subtitleCss)} />
                                  <h4 className="mb-0" dangerouslySetInnerHTML={{ __html: item.title }} style={cssConverter(properties.titleCss)} />
                              </a>
                          </div>
                      ))}
                  </div>
              </div>
          </div>
      </div>
    )
  }

  const mapStateToProps = ({ offcanvas, websiteData }) => {
    const { isVisible } = offcanvas
    const { blocks: websiteDataBlocks } = websiteData
    return {
      offcanvasVisible: isVisible,
      websiteDataBlocks
    }
  }
  
  const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas,
    setNewBlockType
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CN_CNTR_DFL1)
