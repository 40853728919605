import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import jwt_decode from 'jwt-decode'

ChartJS.register(ArcElement, Tooltip, Legend);

function Location(props) {

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const website_ID = tokenData.SelectedDomain.Website_ID

    const today = new Date()
    const numberOfDaysToMinus = 30
    const fdate = today.setDate(today.getDate() - numberOfDaysToMinus);
    const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

    const todayDate = new Date();
    const tdate = todayDate.setDate(todayDate.getDate());
    const defaultToDate  = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

    let [fromDate, setFromDate] = useState(defaultFromDate)
    let [toDate, setToDate] = useState(defaultToDate)
    const [locationVisitors, setLocationVisitors] = useState(null)
    const [cityData, setCityData] = useState([])
    const [countryData, setCountryData] = useState([])
    const [responseData, setResponseData] = useState({})

    const getData = () => {
        let locations = []
        let count = []
        axios.get('/analytics/locationReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{

            let responseArr = res.data.data;
            let cityArray = []
            let countryArray = []
            let locationReport = []

            Object.keys(res.data.data).forEach(function(key) {
                let locationArr = key.split(',');
                let city = locationArr[0];
                let country = locationArr[1];
                const found = cityArray.some(el => el.city === city);
                if (!found) cityArray.push({ city: city, count: 0 });

                const found1 = countryArray.some(el => el.country === country);
                if (!found1) countryArray.push({ country: country, count: 0 });

                locations.push(key);
                count.push(res.data.data[key]);

                locationReport.push({ 'location': key, 'count': res.data.data[key], 'city': locationArr[0], 'country': locationArr[1] });
			});

            for (var i = 0; i < locationReport.length; i++) {
                for (var j = 0; j < cityArray.length; j++) {
                    if (locationReport[i].city == cityArray[j].city) {
                        cityArray[j].count = cityArray[j].count + locationReport[i].count;
                    }
                }
                for (var j = 0; j < countryArray.length; j++) {
                    if (locationReport[i].country == countryArray[j].country) {
                        countryArray[j].count = countryArray[j].count + locationReport[i].count;
                    }
                }
            }

            setCityData(cityArray);
            setCountryData(countryArray);

            setResponseData(responseArr);

            setLocationVisitors({
                labels: locations,
                datasets: [
                {
                    label: '# of Visitors',
                    data: count,
                    backgroundColor: [
                    'rgba(255, 99, 132,1)',
                    'rgba(54, 162, 235,1)',
                    'rgba(255, 206, 86,1)',
                    'rgba(75, 192, 192,1)',
                    'rgba(153, 102, 255,1)',
                    'rgba(255, 159, 64,1)',
                    ],
                    borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
                ],
            })
        })
    }

    const handleFromDateChange = ev => setFromDate(ev.target.value);
    const handleToDateChange = ev => setToDate(ev.target.value);

    useEffect(() => {
        getData()
    }, [fromDate, toDate])

    return (
        <div className="section mt-2 card-body">
            <div className="row">
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">From</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={fromDate} onChange={handleFromDateChange}/>
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">To</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={toDate} onChange={handleToDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="row">
                    <div className="col-12 col-md-4 mb-2">
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-subtitle">Visitors</h5>
                            {locationVisitors && Object.keys(responseData).length > 0 &&
                                <Doughnut data={locationVisitors} />
                            }
                            { Object.keys(responseData).length === 0 &&
                                <div className="text-center text-danger">No Data Found</div>
                            }
                        </div>
                    </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-subtitle">City</h5>
                            <div className="row">
                                {cityData.map((item) => (
                                    <>
                                        <div className="col-6">
                                            {item.city}
                                        </div>
                                        <div className="col-6">
                                            {item.count}
                                        </div>
                                    </>
                                ))}
                                { Object.keys(responseData).length === 0 &&
                                    <div className="text-center text-danger">No Data Found</div>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 col-md-4 mb-2">
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-subtitle">Country</h5>
                            <div className="row">
                                {countryData.map((item) => (
                                    <>
                                        <div className="col-6">
                                            {item.country}
                                        </div>
                                        <div className="col-6">
                                            {item.count}
                                        </div>
                                    </>
                                ))}
                                { Object.keys(responseData).length === 0 &&
                                    <div className="text-center text-danger">No Data Found</div>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location
