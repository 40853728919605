import React from 'react'
import { connect } from "react-redux"
import {
    showEditOffcanvas,
    showUpdateDataOffcanvas,
    showDeleteModal,
    setNewBlockType
} from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_TIME_DFL1 = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    showUpdateDataOffcanvas,
    websiteDataBlocks,
    showDeleteModal,
    setNewBlockType
}) => {
    const handleBlockItemUpdate = (itemID) => {
        showEditOffcanvas({ isEditVisible: true, itemID: itemID, items: items, blockID: blockID, properties: properties })
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
            '.'
        )[1]
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'Are your sure? You want to delete this content?',
            triggerMessage: 'content',
            deleteID: contentName,
            deleteItemID: deleteItemID
        })
    }
    return (
        <div style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                <div className="row pt-4">
                    {properties.blockHeading !== '' &&
                        <div className="col-12">
                            <h3 className="text-center mb-0" dangerouslySetInnerHTML={{ __html: properties.blockHeading }}  style={cssConverter(properties.blockHeadingCss)}/>
                        </div>
                    }
                    {properties.blockDescription !== '' &&
                        <div className="col-12">
                            <p className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockDescription }}  style={cssConverter(properties.blockDescriptionCss)}/>
                        </div>
                    }
                </div>
            }
            <div className="section full shadow" style={{ backgroundColor: properties.backgroundValue, backgroundImage: properties.background && properties.background.image && properties.background.image, backgroundColor: properties.background && properties.background.color && properties.background.color }}>
                <div className="wide-block pt-3 pb-3">
                    <div className="timeline timed">
                        {items && items.map((item, key) => (
                            <div className="item"  style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                                <span className="time cursor-pointer" onClick={() => { handleBlockItemUpdate(key) }} dangerouslySetInnerHTML={{ __html: item.title }} style={cssConverter(item.titleCss)} />
                                <div className="dot"></div>
                                <div className="row">
                                    <div className="col-9 col-md-9">
                                        <div className="content">
                                            <h4 className="title cursor-pointer" onClick={() => { handleBlockItemUpdate(key) }}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className=' bg-transparent'  style={cssConverter(item.subtitleCss)} /></h4>
                                        </div>
                                    </div>
                                    <div className="col-3 col-md-3 d-flex justify-content-end">
                                        {/* <span className="badge bg-secondary p-1 editBtnCss" onClick={() => { handleBlockItemUpdate(key) }}>Edit</span> */}
                                        <span className="badge bg-success p-1 ms-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                        <span className="badge bg-danger p-1 ms-1 editBtnCss" onClick={() => { handleDeleteContent(blockID, key) }}>Delete</span>
                                        {/* <ion-icon name="color-palette-outline" className="fs-5 mb-1" style={{cursor:'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}></ion-icon> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {  } = offcanvas
    const { blocks: websiteDataBlocks } = websiteData
    return {
        websiteDataBlocks
    }
}

const mapDispatchToProps = {
    showEditOffcanvas,
    showUpdateDataOffcanvas,
    showDeleteModal,
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_TIME_DFL1)