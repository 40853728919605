import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom"
import { connect } from "react-redux"
import '@splidejs/splide/dist/css/splide.min.css'
import { dynamicBlocks } from '../Blocks/dynamicBlocks'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import ButtonModal from '../Elements/Modals/ButtonModal'
import ImageModal from '../Elements/Modals/ImageModal'
import AddBlockModal from '../Elements/Modals/AddBlockModal'
import Offcanvas from '../layout/Rightpanel/Offcanvas'
import EditContentOffcanvas from '../layout/Rightpanel/EditContentOffcanvas'
import NotFound from './NotFound'
import { showOffcanvas, hideOffcanvas, showEditOffcanvas, hideEditOffcanvas, showTemplateOffcanvas, showPropertyOffcanvas, showDeleteModal, showPageSetting, showPreviewModal, setResponsiveIframe, showSwitchModal, showAppearance, addPageModal, showPopupBlockOffcanvas, setMainBlockProps, setContentBlockItem, setContentItems } from '../../features/modal/offcanvasSlice'
import { setWebsiteData, setBlockRules, setLoading } from '../../features/websiteData/websiteDataSlice'
import { setCropperSizes } from '../../features/modal/offcanvasSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'
import ToolBar from '../Elements/Management/ToolBar'
import Properties from '../layout/Rightpanel/Properties'
import BlockListModal from '../Elements/Modals/BlockListModal'
import AddOffcanvas from '../layout/Rightpanel/AddOffcanvas'
import PageSetting from '../layout/Rightpanel/PageSetting'
import Modal from 'react-bootstrap/Modal'
import moment from "moment"
import FormOffcanvas from '../layout/Rightpanel/FormOffcanvas'
import AddBlockOffcanvas from '../layout/Rightpanel/AddBlockOffcanvas'
import MDEditor from '@uiw/react-md-editor'
import NewEditOffcanvas from '../layout/Rightpanel/newEditOffcanvas'
import ClonePageOffcanvas from '../layout/Rightpanel/clonePageOffcanvas'
import JSONModal from '../Elements/Modals/JSONModal'

const Builder = ({
    modalVisible,
    modalConfig,
    showDeleteModal,
    offcanvasVisible,
    editOffcanvasVisible,
    offcanvasItems,
    offcanvasMsg,
    offcanvasItemID,
    offcanvasProperties,
    offcanvasBlockID,
    offcanvasBlockType,
    offcanvasIsImageModalVisible,
    offcanvasImageData,
    offcanvasImageID,
    offcanvasIsButtonModalVisible,
    offcanvasButtonData,
    offcanvasButtonID,
    offcanvasIsPropertiesVisible,
    offcanvasPageName,
    websiteDataAppearnace = {},
    websiteDataBlocks = {},
    websiteDataContents = {},
    isAddOffcanvasVisible,
    addMessage,
    showPreviewModal,
    setBlockRules,
    showPageSetting,
    isPageSettingVisible,
    defaultActiveTab,
    device,
    setResponsiveIframe,
    runPartyParticals,
    cropperConfig,
    showSwitchModal,
    showAppearance,
    websites,
    type,
    domains,
    category,
    addPageModal,
    showPopupBlockOffcanvas,
    blocks,
    contents,
    encrypt,
    NewBlockType,
    setMainBlockProps,
    setContentBlockItem,
    setContentItems,
    setCropperSizes,
    loading,
    setLoading,
    functionObject,
    setFunctionObject
}) => {
    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    const [comps, setComps] = useState([])
    const [editPage, setEditPage] = useState(false)
    // const [pageId1, setPageId] = useState('')
    const [urlShow, setUrlShow] = useState(true)
    const [stopParty, setStopParty] = useState(true)
    const [runParty, setRunParty] = useState(true)
    const [cropperConfigData, setCropperConfigData] = useState(null)
    const [showModalSwitch, setShowModalSwitch] = useState(false)
    const [popupButtonFlag, setPopupButtonFlag] = useState(false)
    const [launchValue, setLaunchValue] = useState()
    const [role, setRole] = useState()
    const [aliasDomain, setAliasDomain] = useState()

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    // console.log(tokenData)
    const selectedDomain = tokenData.SelectedDomain.Domain
    // const aliasDomain = tokenData.SelectedDomain.AliasDomain
    // console.log(aliasDomain)
    const domainName = tokenData.SelectedDomain
    const aliasDomainValue = domainName.AliasDomain
    const roleID = tokenData.userInfo.RoleID

    let navigate = useNavigate

    // TODO: instead of webpages array, blocks should be passed as array of
    // Objects containing block name & data required to render it via props
    // const blocks = [
    //   {
    //     comonent: 'Header',
    //     data: { title: '', subTitle: '', menu: {} }
    //   }
    // ]

    useEffect(() => {
        setRole(roleID)
        const comps = renderPage()
        setComps(comps)
        // console.log(comps)
        // if (comps.length > 0) {
        //   {comps.map(value => {
        //     if (value.props.children[0].props.hasOwnProperty('blockID')) {
        //       console.log('first if')
        //       if (value.props.children[0].props.blockID.includes('POUP')) {
        //         // setPopupButtonFlag(true)
        //         console.log('second if')
        //       }  
        //     }
        //   })}
        // }
        axios.get('/block/rules').then(res => {
            setBlockRules({ blockRules: res.data.data })
        })
    }, [setBlockRules, websiteDataAppearnace, websiteDataBlocks, websiteDataContents, url])

    useEffect(() => {
        setAliasDomain(aliasDomainValue)
        // console.log(aliasDomain)
    }, [aliasDomainValue])

    useEffect(() => {
        if (cropperConfig === undefined) {
            axios.get('/info/style/cropperConfig').then(res => {
                if (res.data.message === 'Success') {
                    setCropperConfigData(res.data.data);
                    setCropperSizes(res.data.data);
                    localStorage.setItem('cropperConfig', JSON.stringify(res.data.data))
                } else {
                    console.log('SOMETHING WENT WRONG')
                }
            })
        } else {
            setCropperConfigData(cropperConfig)
        }
    }, [cropperConfig]);

    useEffect(() => {
        // console.log("NewBlockType",NewBlockType);
        if (NewBlockType !== null) {
            if (NewBlockType.BlockType === 'CN') {
                const getData = blocks[`${NewBlockType.BlockID}`];
                setMainBlockProps(getData.properties);
                setContentItems(getData.items);
                const itm = JSON.parse(JSON.stringify(contents[getData.items.contents.replace('contents.', '')].items[NewBlockType.ItemNo]));
                itm.button = itm.button.map((items) => {
                    let returnV1 = items;
                    if (returnV1.cta) {
                        returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                    }
                    return returnV1;
                })

                setContentBlockItem(itm);
            } else if (NewBlockType.BlockType === 'SM') {
                const getData = blocks[`${NewBlockType.BlockID}`];
                setMainBlockProps(getData.properties);
                setContentItems(getData.items);
                if (NewBlockType.BlockName === 'SM_CNTC' || NewBlockType.BlockName === 'SM_CNTR') {
                    // setContentBlockItem(getData.items[NewBlockType.ItemNo])

                    const itm = JSON.parse(JSON.stringify(getData.items[NewBlockType.ItemNo]));
                    itm.button = itm.button === null ? [] : itm.button.map((items) => {
                        let returnV1 = items;
                        if (returnV1.cta) {
                            returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                        }
                        return returnV1;
                    })

                    setContentBlockItem(itm);
                } else {
                    // setContentBlockItem(getData.items[0]);

                    const itm = JSON.parse(JSON.stringify(getData.items[0]));
                    itm.button = itm.button === null ? [] : itm.button.map((items) => {
                        let returnV1 = items;
                        if (returnV1.cta) {
                            returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                        }
                        return returnV1;
                    })

                    setContentBlockItem(itm);
                }
            } else {

            }
        }
    }, [NewBlockType]);


    // useEffect(() => {
    //   if (websiteDataAppearnace !== undefined && websiteDataAppearnace.hasOwnProperty('pages')) {
    //       // setNewPageName(websiteDataAppearnace.properties.sitemap[url].pageName)
    //   }
    // }, [websiteDataAppearnace, url])

    useEffect(() => {
        let appWidth = null
        if (device === 'mobile') {
            document.getElementById("appCapsule").style.width = "35%"
            appWidth = document.getElementById("appCapsule").offsetWidth
        }
        else if (device === 'desktop' || device === undefined || device === '') {
            document.getElementById("appCapsule").style.width = "100%"
            appWidth = document.getElementById("appCapsule").offsetWidth
        }
        if (appWidth > 411) {
            setUrlShow(true)
        } else {
            setUrlShow(false)
        }

        showPopupBlockOffcanvas({ isPopupBlockVisible: true })

    }, [device, url])

    useEffect(() => {
        if (runPartyParticals) {
            setStopParty(runPartyParticals)
            setRunParty(runPartyParticals)
            setTimeout(function () {
                setStopParty(false)
            }, 10000)

            setTimeout(function () {
                setRunParty(false)
            }, 15000)
        }
    }, [runPartyParticals])

    useEffect(() => {
        showPopupBlockOffcanvas({ isPopupBlockVisible: true })
    }, [])

    useEffect(() => {
        if (websiteDataAppearnace.hasOwnProperty('pages')) {
            let pageId = 'Not Found'
            let pageName = 'Not Found'
            Object.keys(websiteDataAppearnace.pages).map((value, key) => {
                if (websiteDataAppearnace.pages[value].url === url) {
                    pageId = value
                    websiteDataAppearnace.navigation.header.map((v, k) => {
                        if (v.hasOwnProperty('children')) {
                            websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
                                if (v1.page === pageId) {
                                    pageName = v1.text
                                }
                            })
                        } else {
                            if (v.page === pageId) {
                                pageName = v.text
                            }
                        }
                    })
                }
            })
            let pageBlocks = []
            const currentPage = pageId
            // console.log(currentPage)
            pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
            Object.keys(websiteDataBlocks).map((value, key) => {
                if (value.includes('PL_')) {
                    pageBlocks = [...pageBlocks, value]
                }
            })
            setPopupButtonFlag(false)
            // console.log(pageBlocks)
            if (typeof (pageBlocks) !== 'boolean') {
                pageBlocks.map(value => {
                    if (value.includes('POUP')) {
                        setPopupButtonFlag(true)
                    }
                })
            }
        }

    }, [websiteDataAppearnace, websiteDataBlocks])

    let pageBackground = ''
    // const renderPage = () => {
    //       if (websiteDataAppearnace.hasOwnProperty('pages')) {
    //         let pageId = 'Not Found'
    //         let pageName = 'Not Found'
    //         Object.keys(websiteDataAppearnace.pages).map((value, key) => {
    //           if (websiteDataAppearnace.pages[value].url === url) {
    //             pageId = value
    //             websiteDataAppearnace.navigation.header.map((v, k) => {
    //               if (v.hasOwnProperty('children')) {
    //                 websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
    //                   if (v1.page === pageId) {
    //                     pageName = v1.text
    //                   }
    //                 })
    //               } else {
    //                 if (v.page === pageId) {
    //                   pageName = v.text
    //                 }
    //               }
    //             })
    //           }
    //         })
    //         if (pageId === 'Not Found') {
    //             return <div><NotFound /></div>
    //         } else {
    //           pageBackground = (websiteDataAppearnace.hasOwnProperty('pages') ? websiteDataAppearnace.pages[pageId].properties.backgroundValue : '#eeeeee')
    //           const currentPage = pageId
    //           const pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
    //           if (pageBlocks) {
    //             let blockSeq = 0
    //             const blockCount = pageBlocks.length
    //             return pageBlocks.map(blockID => {
    //               const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
    //             if (blockJson) {
    //                 const blockProperties = blockJson.properties
    //                 let blockComponent = blockProperties.styleID
    //                 const blockType = blockComponent.substring(0, 2)
    //                 let blockStyleArr = blockComponent.split('_')
    //                 const blockStyle = blockStyleArr[2]
    //                 if (blockStyle === 'CARD') {
    //                   blockComponent = blockComponent
    //                 } else if (blockStyle === 'SLDR'){
    //                   blockComponent = blockComponent
    //                 } else if (blockStyle === 'CASL'){
    //                   blockComponent = blockComponent
    //                 } else if (blockStyle === '2CLF'){
    //                   blockComponent = blockComponent
    //                 } else if (blockStyle === '2CRG'){
    //                   blockComponent = blockComponent
    //                 } else {
    //                   blockComponent = blockStyleArr[0] + '_' + blockStyleArr[1] + '_DFL1'
    //                 }
    //                 const blockItems = getContentItems(blockID)
    //                 const itemCount = getItemCount(blockID)
    //                 const blockName = blockID.split('_')[1]
    //                 if (dynamicBlocks.hasOwnProperty(blockComponent)) {
    //                   let Component = dynamicBlocks[blockComponent]
    //                   blockSeq++
    //                   blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
    //                   return <div>{blockName !== 'POPUP' && <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} />}<div className="dynamicRender" style={{marginTop: '-13px'}}><Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} /></div></div>
    //                 } else {
    //                   let Component = dynamicBlocks[blockStyleArr[0] + '_' + blockStyleArr[1] + '_DFL1']
    //                   blockSeq++
    //                   blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
    //                   return <div>{blockName !== 'POPUP' && <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} />}<div className="dynamicRender" style={{marginTop: '-13px'}}><Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} /></div></div>
    //                 }
    //               } else {
    //                 return false
    //               }
    //             }
    //           } else {
    //             return <div><NotFound /></div>
    //           }
    //       }
    //     }

    const renderPage = () => {
        if (websiteDataAppearnace.hasOwnProperty('pages')) {
            let pageId = 'Not Found'
            let pageName = 'Not Found'
            Object.keys(websiteDataAppearnace.pages).map((value, key) => {
                if (websiteDataAppearnace.pages[value].url === url) {
                    pageId = value
                    websiteDataAppearnace.navigation.header.map((v, k) => {
                        if (v.hasOwnProperty('children')) {
                            websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
                                if (v1.page === pageId) {
                                    pageName = v1.text
                                }
                            })
                        } else {
                            if (v.page === pageId) {
                                pageName = v.text
                            }
                        }
                    })
                }
            })
            if (pageId === 'Not Found') {
                return <div><NotFound /></div>
            } else {
                pageBackground = (websiteDataAppearnace.hasOwnProperty('pages') ? websiteDataAppearnace.pages[pageId].properties.backgroundValue : '#eeeeee')
                const currentPage = pageId
                let pageBlocks = []
                pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
                Object.keys(websiteDataBlocks).map((value, key) => {
                    if (value.includes('PL_')) {
                        pageBlocks = [...pageBlocks, value]
                    }
                })
                if (pageBlocks) {
                    let blockSeq = 0
                    const blockCount = pageBlocks.length
                    return pageBlocks.map((blockID, key) => {
                        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
                        if (blockJson) {
                            const blockProperties = blockJson.properties
                            let blockComponent = blockProperties.styleID
                            const blockPropsStyle = blockProperties.style;
                            const blockType = blockComponent.substring(0, 2)
                            let blockStyleArr = blockComponent.split('_')
                            const blockStyle = blockStyleArr[2]
                            // if (blockStyle === 'CARD') {
                            //   blockComponent = blockComponent
                            // } else if (blockStyle === 'SLDR'){
                            //   blockComponent = blockComponent
                            // } else if (blockStyle === 'CASL'){
                            //   blockComponent = blockComponent
                            // } else if (blockStyle === '2CLF'){
                            //   blockComponent = blockComponent
                            // } else if (blockStyle === '2CRG'){
                            //   blockComponent = blockComponent
                            // } else if (blockStyle === '2CRG'){
                            //     blockComponent = blockComponent
                            // } else {
                            //   blockComponent = blockStyleArr[0] + '_' + blockStyleArr[1] + '_DFL1'
                            // } 
                            const blockItems = getContentItems(blockID)
                            const itemCount = getItemCount(blockID)
                            const blockName = blockID.split('_')[1]
                            // console.log(blockComponent)
                            if (dynamicBlocks.hasOwnProperty(blockComponent)) {
                                // console.log('IF')
                                let Component = dynamicBlocks[blockComponent]
                                // console.log(Component)
                                blockSeq++
                                blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                if (blockType === 'PL') {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            <div className="dynamicRender" style={{ marginTop: '-13px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            {
                                                !blockName.includes('POUP') &&
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                            }
                                            <div className="dynamicRender" style={{ marginTop: '-13px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>)
                                }
                            } else {
                                // console.log('ELSE')

                                let Component = dynamicBlocks[blockStyleArr[0] + '_' + blockStyleArr[1] + '_DFL1']
                                // console.log(blockType)
                                blockSeq++
                                blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                if (blockType === 'PL') {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            <div className="dynamicRender" style={{ marginTop: '-13px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                } else if (blockComponent.split('_').length > 3) {
                                    if (dynamicBlocks.hasOwnProperty(blockPropsStyle)) {
                                        ;
                                        let Component = dynamicBlocks[blockPropsStyle];
                                        blockSeq++
                                        blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                        return (
                                            <div data-color-mode="light" key={key}>
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                                <div className="dynamicRender" style={{ marginTop: '-13px' }}>
                                                    <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                                </div>
                                            </div>)
                                    }
                                } else {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            {
                                                blockName !== 'POPUP' &&
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                            }
                                            <div className="dynamicRender" style={{ marginTop: '-13px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        } else {
                            return <div className="dynamicRender" key={key}>No Block Found</div>
                        }
                    })
                } else {
                    return false
                }


            }
        } else {
            return <div><NotFound /></div>
        }
    }

    const getItemCount = (blockID) => {
        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
        if (blockJson) {
            const blockProperties = blockJson.properties
            const blockComponent = blockProperties.styleID
            const blockType = blockComponent.substring(0, 2)
            if (blockType === 'CN') {
                const contentType = blockJson.items.contents.split('.')[0]
                const contentID = blockJson.items.contents.split('.')[1]
                let contentItems = websiteDataContents.hasOwnProperty(contentID) ? websiteDataContents[contentID].items : false
                return contentItems
            } else {
                return blockJson.items
            }
        } else {
            return false
        }
    }

    const getContentItems = (blockID) => {
        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
        if (blockJson) {
            const blockProperties = blockJson.properties
            const blockComponent = blockProperties.styleID
            const blockType = blockComponent.substring(0, 2)
            if (blockType === 'CN') {
                const contentType = blockJson.items.contents.split('.')[0]
                const contentID = blockJson.items.contents.split('.')[1]
                // const contents = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
                let contentItems = websiteDataContents.hasOwnProperty(contentID) ? websiteDataContents[contentID].items : false
                let reqItems = blockJson.items.numItems
                const filter = blockJson.items.itemFilters
                if (filter === 'all') {
                    return contentItems
                }
                if (filter === 'last') {
                    let arr1 = []
                    contentItems.forEach(element => {
                        arr1.unshift(element)
                    });
                    contentItems = arr1
                    if (contentItems.length <= reqItems) {
                        return contentItems
                    }
                }
                if (filter === 'first') {
                    if (contentItems.length <= reqItems) {
                        return contentItems
                    }
                }
                if (filter === 'random') {
                    var randIndexArr = []
                    if (reqItems >= contentItems.length) {
                        while (randIndexArr.length < contentItems.length) {
                            var r = Math.floor(Math.random() * contentItems.length)
                            if (randIndexArr.indexOf(r) === -1) randIndexArr.push(r)
                        }
                        reqItems = contentItems.length
                    } else {
                        while (randIndexArr.length < reqItems) {
                            var r = Math.floor(Math.random() * contentItems.length)
                            if (randIndexArr.indexOf(r) === -1) randIndexArr.push(r)
                        }
                    }
                }
                var temp = []
                for (let index = 0; index < reqItems; index++) {
                    const itemIndex = (filter === 'random') ? randIndexArr[index] : index
                    if (index < contentItems.length) {
                        temp[index] = contentItems[itemIndex]
                    }
                }
                return temp
            } else {
                return blockJson.items
            }
        } else {
            return false
        }
    }

    renderPage(offcanvasPageName)

    const editPageName = () => {
        setEditPage(!editPage)
    }

    // const updatePageName = (newPageName) => {
    //   setEditPage(!editPage)
    //   axios.put('/page/'+ selectedDomain + '/'+ url, {pageName: newPageName}).then(res=>{
    //     if (res.data.message === 'Success') {
    //         setWebsiteData(res.data.data)
    //         showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
    //     } else {
    //         showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
    //     }
    //   })
    // }

    const handleDeletePage = () => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this page?', triggerMessage: 'page', deleteID: url })
    }

    const handlePageSetting = (activeTabName) => {
        showPageSetting({ isPageSettingVisible: true, defaultActiveTab: activeTabName })
    }

    const handlePreviewModal = (e) => {
        e.stopPropagation()
        showPreviewModal({ isPreviewVisible: true })
    }

    const handlePreview = (e) => {
        e.stopPropagation()
        console.log('Preview');
        window.open('https://' + selectedDomain + '/' + url)
        // showPopupBlockOffcanvas({isPopupBlockVisible: true})
    }

    const handlePopupBlock = (e) => {
        e.stopPropagation()
        showPopupBlockOffcanvas({ isPopupBlockVisible: true })
    }

    const handleSwitchModal = () => {
        // console.log('SWITCH MODAL CLICKED')
        showSwitchModal({ isSwitchModalVisible: true, switchModalMessage: 'changePage', blockID: 'SM_HEAD' })
        // setShowModalSwitch(true)
    }

    const handleBack = () => {
        window.history.go(-1)
    }

    const handleRefresh = () => {
        axios.get('/user/refresh?domain=' + selectedDomain + '&type=website').then(res => {
            // console.log('Refresh Token')
            localStorage.setItem('token', res.data.data)
        })
        if (functionObject.fetchWebsite) {
            const { fetchWebsite } = functionObject;
            fetchWebsite();
        }
    }

    // const hideModalSwitch = () => {
    //   setShowModalSwitch(false)
    // }

    const switchWebsiteDomain = (switchTo, type) => {
        navigate('/load?domain=' + switchTo + '&type=' + type)
        // hideSwitchModal()
    }

    const handleParentPrevention = (e) => {
        e.stopPropagation()
    }

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }
    const websiteJSON = fetchWebsiteJSON()

    const handleDNSManage = () => {
        navigate('/dns?type=dns')
        // hideSwitchModal()
    }

    // const handlesSearchDomain = () => {
    //   console.log('search Domain')
    //   navigate('/searchDomain')
    // }

    const handleLaunchSite = (e) => {
        e.preventDefault()
        // console.log('Site Launched')
        setLaunchValue(true)
        axios.get('/website/publishWebsite/' + selectedDomain).then(res => {
            // setResponsiveIframe({runPartyParticals: true})
            showAppToast({ toastMessage: 'Your changes updated to your live website', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
            setLaunchValue(false)
        })
    }

    return (
        <div id="appCapsule" className="pb-0" style={{ backgroundColor: pageBackground, marginTop: '65px' }}>
            <div className="wide-block p-0 mb-3 webweb-responsive-addressbar">
                <div className="row w-100 m-0" style={{ paddingLeft: '1%', paddingRight: '12px', backgroundColor: '#fbfbfb', boxShadow: '0 2px 4px 0 rgb(0 0 0 / 2%), 0 1px 3px 0 rgb(0 0 0 / 20%)' }}>
                    <div className="col-2 col-md-1 col-lg-1">
                        <div className="w-100 d-flex" style={{ paddingTop: '10px' }}>
                            <div className="w-50">
                                <button type='button' className='use-button' onClick={handleBack} >
                                    <ion-icon name="arrow-back-outline" className="fs-5 text-secondary" style={{ cursor: 'pointer' }}></ion-icon>
                                </button>
                            </div>
                            <div className="w-50">
                                {
                                    loading ?
                                        <div className="">
                                            <ion-icon name="refresh-outline" className="fs-5 text-secondary rotatingAnimation" style={{ cursor: 'pointer' }}></ion-icon>
                                        </div>
                                        :
                                        <button type='button' className='use-button' onClick={handleRefresh}>
                                            <ion-icon name="refresh-outline" className="fs-5 text-secondary" style={{ cursor: 'pointer' }}></ion-icon>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-md-9 col-lg-10 p-0">
                        <div
                            className="form-group boxed"
                        >
                            <div className="input-wrapper">
                                <div
                                    className="form-control headerButton rounded-pill custom-disabled-color d-flex align-items-center"
                                    style={{ paddingLeft: '10px !important', fontSize: '14px', height: '28px' }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <ion-icon
                                            name="lock-closed"
                                            data-bs-toggle="tooltip"
                                            data-placement="bottom"
                                            data-bs-html="true"
                                            title="<ion-icon name='lock-closed-outline'></ion-icon> Your connection is secure"
                                            style={{
                                                position: 'absolute',
                                                fontSize: '18px !important',
                                                zIndex: 1
                                            }}
                                            class="text-success header-address-bar"
                                        ></ion-icon>
                                        <div className='d-flex w-100 cursor-pointer' style={!urlShow ? { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '60%' } : {}}>

                                            {aliasDomain === '' &&
                                                <div className='d-flex'>
                                                    <button type='button' className="text-secondary d-flex border-0" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'maxWidth': '100%', 'overflow': 'hidden' }}
                                                        onClick={handleSwitchModal}>&nbsp;https://{selectedDomain}/{url} </button>
                                                    <button type='button' className='use-button' onClick={(e) => { handlePreview(e) }}>
                                                        <ion-icon name="open-outline"></ion-icon>
                                                    </button>
                                                </div>
                                            }

                                            {aliasDomain !== '' &&
                                                <div className='d-flex' style={{ 'maxWidth': '80%' }}>
                                                    <button type='button' className="text-secondary d-flex border-0" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', 'maxWidth': '100%', 'overflow': 'hidden' }}
                                                        onClick={handleSwitchModal}>&nbsp;https://{aliasDomain}/{url} </button>
                                                    <button type='button' className='use-button' onClick={(e) => { handlePreview(e) }}>
                                                        <ion-icon name="open-outline" ></ion-icon>
                                                    </button>
                                                </div>
                                            }

                                            <button type='button' className="text-danger border-0" onClick={() => handlePageSetting('seo')}>
                                                &nbsp;&nbsp;Page Settings
                                            </button>

                                            {/* <span className="text-danger" onClick={handleSwitchModal}>
                        &nbsp;&nbsp;Change Page
                      </span> */}

                                            {popupButtonFlag &&
                                                <span className="text-danger d-none d-sm-block" style={{ position: 'absolute', right: 13, top: 2 }} onClick={(e) => { handlePopupBlock(e) }}>
                                                    &nbsp;&nbsp;Popup Block
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 col-md-2 col-lg-1 d-flex align-items-center justify-content-md-center">
                        {(aliasDomain !== '' && aliasDomain !== null && aliasDomain !== undefined) &&
                            <>
                                {launchValue &&
                                    <button className="btn btn-primary btn-sm" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                        Publishing...
                                    </button>
                                }
                                {!launchValue &&
                                    <div>
                                        {role === 'Customer' &&
                                            <button className="btn btn-primary btn-sm" onClick={handleLaunchSite}>Publish</button>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {(aliasDomain === '' || aliasDomain === null || aliasDomain === undefined) &&
                            <Link to="/searchDomain" className="text-primary" style={{ fontSize: '10px' }}>
                                <button className="btn btn-success btn-sm">Go Live</button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
            {comps}
            <ButtonModal show={offcanvasIsButtonModalVisible} buttonData={offcanvasButtonData} buttonID={offcanvasButtonID}></ButtonModal>
            <ImageModal show={offcanvasIsImageModalVisible} imageData={offcanvasImageData} imageID={offcanvasImageID} contentBlockID={offcanvasBlockID}></ImageModal>
            <AddBlockModal config={modalConfig} show={modalVisible}></AddBlockModal>
            {offcanvasVisible &&
                <Offcanvas show={offcanvasVisible} items={offcanvasItems} properties={offcanvasProperties} blockID={offcanvasBlockID} blockType={offcanvasBlockType} itemID={offcanvasItemID} message={offcanvasMsg}></Offcanvas>
            }
            {editOffcanvasVisible &&
                <EditContentOffcanvas show={editOffcanvasVisible} items={offcanvasItems} properties={offcanvasProperties} blockID={offcanvasBlockID} blockType={offcanvasBlockType} itemID={offcanvasItemID} message={offcanvasMsg}></EditContentOffcanvas>
            }
            <Properties show={offcanvasIsPropertiesVisible}></Properties>
            <BlockListModal></BlockListModal>
            <AddOffcanvas show={isAddOffcanvasVisible} message={addMessage}></AddOffcanvas>
            <PageSetting show={isPageSettingVisible} message={defaultActiveTab} />
            <FormOffcanvas></FormOffcanvas>
            {websiteDataAppearnace !== null && websiteDataAppearnace !== undefined && Object.keys(websiteDataAppearnace).length > 0 &&
                <AddBlockOffcanvas></AddBlockOffcanvas>
            }
            <NewEditOffcanvas show={editOffcanvasVisible} items={offcanvasItems} properties={offcanvasProperties} blockID={offcanvasBlockID} blockType={offcanvasBlockType} itemID={offcanvasItemID} message={offcanvasMsg}></NewEditOffcanvas>
            <ClonePageOffcanvas></ClonePageOffcanvas>
            <JSONModal />
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData, utilities }) => {
    const {
        isVisible,
        isEditVisible,
        blockID,
        items,
        msg,
        itemID,
        properties,
        blockType,
        isImageModalVisible,
        imageData,
        imageID,
        isButtonModalVisible,
        buttonData,
        buttonID,
        isPropertiesVisible,
        pageName,
        isAddOffcanvasVisible,
        addMessage,
        isPageSettingVisible,
        defaultActiveTab,
        runPartyParticals,
        NewBlockType
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        blocks: websiteDataBlocks,
        contents: websiteDataContents,
        blocks,
        contents,
        encrypt,
        blockRules,
        loading
    } = websiteData
    const {
        device,
        websites,
        domains
    } = utilities
    return {
        offcanvasVisible: isVisible,
        editOffcanvasVisible: isEditVisible,
        offcanvasBlockID: blockID,
        offcanvasItems: items,
        offcanvasMsg: msg,
        offcanvasItemID: itemID,
        offcanvasProperties: properties,
        offcanvasBlockType: blockType,
        offcanvasIsImageModalVisible: isImageModalVisible,
        offcanvasImageData: imageData,
        offcanvasImageID: imageID,
        offcanvasIsButtonModalVisible: isButtonModalVisible,
        offcanvasButtonData: buttonData,
        offcanvasButtonID: buttonID,
        offcanvasIsPropertiesVisible: isPropertiesVisible,
        offcanvasPageName: pageName,
        websiteDataAppearnace,
        websiteDataBlocks,
        websiteDataContents,
        isAddOffcanvasVisible,
        blocks,
        contents,
        encrypt,
        NewBlockType,
        addMessage,
        blockRules,
        isPageSettingVisible,
        defaultActiveTab,
        device,
        runPartyParticals,
        utilitiesWebsites: websites,
        utilitiesDomains: domains,
        loading
    }
}

const mapDispatchToProps = {
    showOffcanvas,
    hideOffcanvas,
    showEditOffcanvas,
    hideEditOffcanvas,
    showTemplateOffcanvas,
    showPropertyOffcanvas,
    showDeleteModal,
    setWebsiteData,
    showAppToast,
    setBlockRules,
    showPageSetting,
    showPreviewModal,
    setResponsiveIframe,
    showSwitchModal,
    showAppearance,
    addPageModal,
    showPopupBlockOffcanvas,
    setMainBlockProps,
    setContentBlockItem,
    setContentItems,
    setCropperSizes,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(Builder)