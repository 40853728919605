import React from 'react'
import { connect } from "react-redux"
import { showEditOffcanvas } from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_MARQ_DFL1 = ({
    items = [],
    blockID,
    properties,
    showEditOffcanvas
}) => {
    console.log(items, blockID, properties)
    const handleBlockItemUpdate = itemID => {
        showEditOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties:properties
        })
    }
    return (
        <div className="section full" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div className="wide-block pt-2 pb-2">
                <div className="row" style={{height: '56px'}}>
                    <div className="col-3 col-md-3 d-flex align-items-center"  dangerouslySetInnerHTML={{ __html: properties.blockHeading }} style={cssConverter(properties.blockHeadingCss)} />
                    <div className="col-9 col-md-9 d-flex align-items-center">
                        <marquee>
                            <p className="d-flex mb-0">
                                {items.map((value, key) => {        
                                    return <><a href="#!" onClick={() => {handleBlockItemUpdate(key)}}><MDEditor.Markdown source={value.description} linkTarget="_blank" className="card-text text-center" style={cssConverter(value.descriptionCss)} />
                                    </a>&nbsp;&nbsp;&nbsp;&nbsp;</>
                                })}
                            </p>
                        </marquee>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
      isVisible
    } = offcanvas
    return {
      offcanvasVisible: isVisible
    }
}
  
const mapDispatchToProps = {
    showEditOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_MARQ_DFL1)
