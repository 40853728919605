import React from 'react'

function SM_EMIL_DFL1(props) {
    return (
        <div>
            <div className="section wide-block" style={{backgroundColor: props.properties.backgroundValue}}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <img src="assets/img/webweb-business-email.svg" alt="avatar" className="image w-100 rounded-3" />
                            </div>
                            <div className="col-md-6 col-12">
                                <form>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="businessemail">Business Email</label>
                                            <input type="text" className="form-control" id="businessemail" placeholder="Business Email" autoComplete="off" value="username@example.com" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="businessname">Business Name</label>
                                            <input type="text" className="form-control" id="businessname" placeholder="Business Name" autoComplete="off" value="Business Name" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="individualname">Individual Name</label>
                                            <input type="text" className="form-control" id="individualname" placeholder="Individual Name" autoComplete="off" value="Individual Name" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="email5">Alternate E-mail</label>
                                            <input type="email" className="form-control" id="email5" placeholder="Alternate E-mail" value="Alternate E-mail" autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Whatsapp No.</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Whatsapp No." value="Whatsapp No." />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SM_EMIL_DFL1
