import React, { useState, useEffect } from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useLocation, useNavigate, Link, useParams } from "react-router-dom"
import GetStartedLogin from './GetStartedLogin'
import config from '../../config'
import AllCategories from './AllCategories'
import OtpValidation from './OtpValidation'
import { connect } from "react-redux"
import { getCountryCallingCode } from 'react-phone-number-input'
import axios from 'axios'
import { setWebsiteData } from '../../features/websiteData/websiteDataSlice'
import { setToken } from '../../features/auth/authSlice'
import AppToast from "../Elements/Notification/AppToast"
import { showAppToast, hideAppToast, tempWebsiteJSON, tempWebsiteToken } from '../../features/utilities/appToastSlice'
import DomainSearchnew from './DomainSearchnew'
import BuildingWebsite from './BuildingWebsite'
import PublishWebsiteAnimate from './PublishWebsiteAnimate'
import GetStarted from './GetStarted'


export function isValidDomain(domain) {
    const regex = /^(?!:\/\/)(?![^\n]+:\/\/)(?:[a-zA-Z0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF.-]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,63}$/;
    return regex.test(domain);
}

export function sanitizeString(str) {
    // Remove spaces and special characters using regular expressions
    const sanitized = str.replace(/\s+/g, '').replace(/[^\w\s]/gi, '');

    // Convert the string to lowercase
    const lowercase = sanitized.toLowerCase();

    return lowercase;
}

function InitiateWebsite({
    showAppToast,
    hideAppToast,
    appToastVisible,
    appToastMessage,
    appToastIcon,
    appToastBackground,
    appToastPosition,
    setWebsiteData,
    setToken,
    setLoadingLoaderScreen
}) {
    // const { width, height } = useWindowSize();
    const width = window.innerWidth;
    const height = window.innerHeight;
    const [saveToken, setSaveToken] = useState(null);

    const [activeSection, setActiveSections] = useState('Categories');
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableVerifyBtn, setDisableVerifyBtn] = useState(false);

    const [disableSearchBtn, setDisableSearchBtn] = useState(false);

    const [loadingLoader, setLoadingLoader] = useState(false);

    const [searchDomainName, setSearchDomainName] = useState(null);
    const [domainList, setDomainList] = useState({});

    const [OTP, setOTP] = useState('');

    const [countryCode, setCountryCode] = useState('+91')
    const [websitePostData, setWebsitePostData] = useState({
        'BusinessName': null,
        'CategoryName': null,
        'CountryCode': '+91',
        'Location': null,
        'WhatsappNumber': '',
        'isDomainFree': 'true',
        'DomainName': ''
    });

    const [websiteJSON, setWebsiteJOSN] = useState(null);

    function formatString(str) {
        const words = str.split('-');
        const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const formattedString = formattedWords.join(' ');
        return formattedString;
    }

    const navigate = useNavigate();
    const search = useLocation().search;
    const step = new URLSearchParams(search).get('category');
    useEffect(() => {
        if (step === null) {
            navigate('/build-website?category');
            setActiveSections('Categories')
        }
        if (step !== undefined && step !== null && step !== '') {
            setActiveSections('Location');
            const getCategory = formatString(step);
            const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
            tempwebsitePostData.CategoryName = getCategory;
            setWebsitePostData(tempwebsitePostData);
        }
        // console.log(step);
    }, [step]);


    const onBuisnessNameEnter = (val) => {
        const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
        tempwebsitePostData.BusinessName = val;
        setWebsitePostData(tempwebsitePostData);
    }

    const onPhoneNumberEnter = (val) => {
        const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
        const getVal = val !== undefined ? val : '+91';
        tempwebsitePostData.CountryCode = countryCode;
        // console.log({tempwebsitePostData, getVal})
        const codeLength = countryCode.length
        if (getVal.slice(0, codeLength) === countryCode) {
            // setMobileNumber(val)
            tempwebsitePostData.WhatsappNumber = getVal;
        } else {
            tempwebsitePostData.WhatsappNumber = tempwebsitePostData.WhatsappNumber.replace(countryCode, getVal);
            // setMobileNumber(mobileNumber.replace(tempwebsitePostData.CountryCode, val))
        }
        // console.log({tempwebsitePostData, getVal, websitePostData})
        setWebsitePostData(tempwebsitePostData);
    }

    const handleCountryChange = (event) => {
        const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
        console.log(event)
        // const countryCode = getCountryCallingCode(event)
        setCountryCode(`+${getCountryCallingCode(event)}`);
        tempwebsitePostData.CountryCode = event === undefined ? '+91' : `+${getCountryCallingCode(event)}`;
        // setMobileNumberCC(`+${getCountryCallingCode(event)}`)
        // if (countryCode !== '91') {
        //   setEmailInput('')
        //   setShowEmailInput(true)
        // } else {
        //   setShowEmailInput(false)
        //   setEmailInput('')
        // }
        setWebsitePostData(tempwebsitePostData);
      }

    const onConnectDomain = (val) => {
        console.log(val);
        const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
        tempwebsitePostData.isDomainFree = val;
        setWebsitePostData(tempwebsitePostData);
    }

    const submitBuildForm = () => {
        setDisableBtn(true);
        setLoadingLoader(true)
        if (websitePostData.WhatsappNumber !== null) {

            // axios to get otp

            // setTimeout(() => {
            //     setActiveSections('Validation');
            //     setLoadingLoader(false);
            // }, 1000);

            axios.get(`${config.apiUrl}/website/sendOTP/${websitePostData.WhatsappNumber}`)
            .then(res => {
                if (res.data.data === 'OTP Sent') {
                    // console.log(res.data);
                    // showAppToast({ toastMessage: 'WE HAVE SENT OTP TO YOUR MOBILE NUMBER', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    setActiveSections('Validation');
                    setLoadingLoader(false)
                } else {
                    console.log('SOMETHING WENT WRONG')
                    showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    setDisableBtn(false);
                    setLoadingLoader(false)
                }
            }).catch((error) => {
                console.log('SOMETHING WENT WRONG')
                showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                setDisableBtn(false);
                setLoadingLoader(false)
            })

        } else {
            showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            setDisableBtn(false);
            setLoadingLoader(false)
        }
    }

    const submitVerifyForm = () => {
        setDisableVerifyBtn(true)
        setLoadingLoader(true)
        if (OTP.toString().trim().length === 6) {

            // setTimeout(() => {
            //     setSaveToken({ authToken: tempWebsiteToken() });
            //     setActiveSections('DomainSearch');
            //     setLoadingLoader(false);
            // }, 1000);

            // axios call to otp verify

            axios.get(`${config.apiUrl}/website/verifyOTP/${websitePostData.WhatsappNumber}/${OTP}`).then(res => {
                if (res.data.message === 'Success') {
                    console.log(res.data);
                    // localStorage.setItem('token', res.data.data);
                    // setSaveToken({ authToken: tempWebsiteToken() });
                    setSaveToken({ authToken: res.data.data });
                    // showAppToast({ toastMessage: 'VERIFIED', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                    setActiveSections('DomainSearch');
                    setLoadingLoader(false);
                } else {
                    console.log('SOMETHING WENT WRONG')
                    showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                    setDisableVerifyBtn(false);
                    setLoadingLoader(false)
                }
            }).catch((error) => {
                showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                setDisableVerifyBtn(false);
                setLoadingLoader(false)
            })
        } else {
            showAppToast({ toastMessage: 'Enter OTP', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            setDisableVerifyBtn(false);
            setLoadingLoader(false)
        }
    }

    const onEnterExistingDomain = (val) => {
        const tempwebsitePostData = JSON.parse(JSON.stringify(websitePostData));
        tempwebsitePostData.DomainName = val;
        setWebsitePostData(tempwebsitePostData);
    }

    const BuildWebsite = () => {
        if (isValidDomain(websitePostData.DomainName)) {
            createWebsite();
        } else {
            showAppToast({ toastMessage: 'Enter valid domian name!', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' });
        }
    }

    const BookDomainAndBuildWebsite = (DomainName) => {
        // ------------- Write the function to book domain and build website
        createWebsite(DomainName);
    }

    function createWebsite(DomainName) {
        const TempWebsitePostData = JSON.parse(JSON.stringify(websitePostData));

        // ------------ Check if user skipped domain book step
        if (DomainName === 'Skip') {
            TempWebsitePostData.DomainName = '';
        } else {
            TempWebsitePostData.DomainName = DomainName;
        }
        TempWebsitePostData.WhatsappNumber = Number(TempWebsitePostData.WhatsappNumber.replace(TempWebsitePostData.CountryCode, ''));
        TempWebsitePostData.CountryCode = Number(TempWebsitePostData.CountryCode.replace('+', ''));
        TempWebsitePostData.Location = JSON.stringify(TempWebsitePostData.Location);
        TempWebsitePostData.CategoryName = TempWebsitePostData.CategoryName.toLowerCase();
        setLoadingLoader(true)

        console.log(TempWebsitePostData);
        // setActiveSections('WebsiteGenerate');


        // setActiveSections('WebsiteGenerate');
        // setLoadingLoader(false);
        // setTimeout(() => {
        //     setWebsiteData(tempWebsiteJSON);
        //     setWebsiteJOSN(tempWebsiteJSON);
        // }, 4000);

        // ----------- Axios call for building website

        axios.put(`${config.apiUrl}/website/createWebsite`, TempWebsitePostData).then(res => {
            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data.json);
                setWebsiteJOSN(res.data.data.json);
                if(res.data.data.token !== undefined) {
                     setSaveToken({ authToken: res.data.data.token });
                }
                setActiveSections('WebsiteGenerate');
                setLoadingLoader(false)
            } else {
                console.log('SOMETHING WENT WRONG')
                showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                setDisableVerifyBtn(false);
                setLoadingLoader(false)
            }
        }).catch((error) => {
            showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            setDisableVerifyBtn(false);
            setLoadingLoader(false)
        })
    }

    const searchDomain = (domainVal) => {
        setLoadingLoader(true);
        setDisableSearchBtn(true)
        if (domainVal === undefined && searchDomainName === null) {
            setDisableSearchBtn(false);
            return
        }

        const tempDomain = domainVal !== undefined ? sanitizeString(domainVal) : sanitizeString(searchDomainName)
        axios.get(`${config.apiUrl}/domain?domain=${tempDomain}`).then(res => {
            if (res.data) {
                // console.log(res.data);
                setDomainList(res.data.data);
                setDisableSearchBtn(false);
                setLoadingLoader(false);
            } else {
                console.log('SOMETHING WENT WRONG')
                showAppToast({ toastMessage: 'Oops! Something Went Wrong.', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                setDisableSearchBtn(false);
                setLoadingLoader(false);
            }
        })
    }

    const setTokenAndWebsiteJSON = () => {
        // console.log(saveToken);
        localStorage.setItem('token', saveToken.authToken);
        setToken(saveToken);
        navigate(`/load?domain=${websiteJSON.appearance.properties.url}&type=website`);
        setLoadingLoaderScreen(true);
        setTimeout(() => {
            setLoadingLoaderScreen(false);
        }, 4000);
    }

    useEffect(() => {
        console.log(websitePostData);
        if ((websitePostData.BusinessName !== null && websitePostData.BusinessName.trim().length < 3) || (websitePostData.WhatsappNumber !== null && (websitePostData.WhatsappNumber.toString().trim().length < 7 || websitePostData.WhatsappNumber.toString().trim().length > 15)) || websitePostData.BusinessName === null || websitePostData.WhatsappNumber === null) {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }

    }, [websitePostData])

    useEffect(() => {
        if (activeSection === 'DomainSearch') {
            setDisableBtn(false);
            if (searchDomainName === null && websitePostData.BusinessName !== null) {
                // console.log(activeSection, websitePostData.BusinessName)
                setSearchDomainName(websitePostData.BusinessName);
                searchDomain(websitePostData.BusinessName);
            }
        }

    }, [activeSection])

    return (
        <div className='position-relative overflow-hidden'>
            {
                activeSection === 'Categories' &&
                <AllCategories />
            }
            {
                activeSection === 'Location' &&
                <>
                    <GetStartedLogin
                        websitePostData={websitePostData}
                        setWebsitePostData={setWebsitePostData}
                        onBuisnessNameEnter={onBuisnessNameEnter}
                        onPhoneNumberEnter={onPhoneNumberEnter}
                        onConnectDomain={onConnectDomain}
                        submitBuildForm={submitBuildForm}
                        disableBtn={disableBtn}
                        handleCountryChange={handleCountryChange} />
                </>
            }
            {
                activeSection === 'Validation' &&
                <OtpValidation
                    OTP={OTP}
                    setOTP={setOTP}
                    disableVerifyBtn={disableVerifyBtn}
                    submitVerifyForm={submitVerifyForm} />
            }
            {
                activeSection === 'DomainSearch' &&
                <DomainSearchnew
                    websitePostData={websitePostData}
                    setWebsitePostData={setWebsitePostData}
                    onEnterExistingDomain={onEnterExistingDomain}
                    onConnectDomain={onConnectDomain}
                    BuildWebsite={BuildWebsite}
                    searchDomain={searchDomain}
                    searchDomainName={searchDomainName}
                    setSearchDomainName={setSearchDomainName}
                    domainList={domainList}
                    disableSearchBtn={disableSearchBtn}
                    BookDomainAndBuildWebsite={BookDomainAndBuildWebsite}
                    createWebsite={createWebsite} />
            }
            {
                activeSection === 'WebsiteGenerate' &&
                <BuildingWebsite
                    BuildWebsite={BuildWebsite}
                    websiteJSON={websiteJSON}
                    setActiveSections={setActiveSections} />
            }

            {
                activeSection === 'Published' &&
                <>
                    <PublishWebsiteAnimate
                        url={websiteJSON !== null && websiteJSON.appearance.properties.url}
                        setTokenAndWebsiteJSON={setTokenAndWebsiteJSON} />



                    <Confetti
                        width={width}
                        height={height}
                    />
                </>
            }

            {
                appToastVisible &&
                <AppToast
                    message={appToastMessage}
                    show={appToastVisible}
                    background={appToastBackground}
                    icon={appToastIcon}
                    position={appToastPosition}
                />
            }

            {
                loadingLoader &&
                <div className="loaderLoading">
                    <div class="spinner-border text-primary col-md-3 me-2" role="status"></div>
                </div>
            }


        </div>
    )
}

const mapStateToProps = ({ appToast }) => {
    const {
        isVisible,
        toastMessage,
        background,
        icon,
        position
    } = appToast

    return {
        appToastVisible: isVisible,
        appToastMessage: toastMessage,
        appToastBackground: background,
        appToastIcon: icon,
        appToastPosition: position
    }
}

const mapDispatchToProps = {
    showAppToast,
    hideAppToast,
    setWebsiteData,
    setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(InitiateWebsite)