import React from 'react';
import Logo from '../../images/webweb.svg'
import { connect } from "react-redux"
import { hidePreviewModal } from '../../../features/modal/offcanvasSlice';

const PreviewHeader = ({
    selectedDomain,
    pageName,
    hidePreviewModal
}) => {
    console.log(pageName)
    return (
        <div class="appHeader">
            <div class="row w-100 d-flex align-items-center">
                <div class="webweb-w-10 d-flex justify-content-center">
                    <a href="#!" class="headerButton">
                        <img src={Logo} class="img-fluid" alt="Brand" width="45x45" />
                    </a>
                </div>
                <div class="webweb-w-80 dropdown">
                    <ion-icon name="lock-closed" data-bs-toggle="tooltip" data-placement="bottom" data-bs-html="true" title="<ion-icon name='lock-closed-outline'></ion-icon> Your connection is secure" style={{position: "absolute",fontSize: "18px !important",zIndex: 1}} class="text-success header-address-bar"></ion-icon>
                    <a href="#!"><ion-icon name="cloud-upload-outline" style={{position: "absolute",fontSize: "18px !important",zIndex: 1}} class="text-secondary header-address-bar-left"></ion-icon></a>
                    <div class="form-group boxed" data-bs-toggle="modal" data-bs-target="#switchWebsite">
                        <div class="input-wrapper">
                            <input type="text" class="form-control headerButton header-input-bar rounded-pill custom-disabled-color" placeholder={"https://" + selectedDomain + '/' + pageName} readonly />
                        </div>
                    </div>
                </div>
                <div class="webweb-w-10 dropdown d-flex justify-content-end text-secondary fs-3">
                    <span class="dropdown">
                        <span class="headerButton" onClick={() => {hidePreviewModal()}} style={{cursor:'pointer'}}>
                            <ion-icon name="close-circle-outline"></ion-icon>
                        </span>
                    </span>&nbsp;&nbsp;
                    <span class="dropdown">
                        <span class="headerButton" data-bs-toggle="dropdown" style={{cursor: "pointer"}}>
                            <ion-icon name="ellipsis-vertical"></ion-icon>
                        </span>
                        <div class="webweb-dropdown-menu dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#!">
                                <ion-icon name="mail-outline"></ion-icon> Email
                            </a>
                            <a class="dropdown-item" href="#!">
                                <ion-icon name="share-social-outline"></ion-icon> Share
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#!">
                                <ion-icon name="search-outline"></ion-icon> Search Domain
                            </a>
                            <a class="dropdown-item" href="support.html">
                                <ion-icon name="headset-outline"></ion-icon> Support
                            </a>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}
  
const mapDispatchToProps = {
    hidePreviewModal
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewHeader)