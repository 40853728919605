import React from 'react'

function AddBlockModal(props) {
    return (
        <div class="modal fade modalbox" id="AddBlockModal" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Block</h5>
                        <span data-bs-dismiss="modal" class="text-primary">Close</span>
                    </div>
                    <div class="modal-body p-0">

                        <ul class="listview image-listview flush mb-2">
                            <div class="listview-title mt-2">A</div>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Frank Boehm</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar2.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Sophie Asveld</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar3.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Rickie Baroch</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar4.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Carmelita Marsham</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar5.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Isaac Hunt</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="listview image-listview flush mb-2">
                            <div class="listview-title mt-2">B</div>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar6.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Gabriel Moreira</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar7.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Nicolina Lindholm</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar8.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Emmalynn Mazzia</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar9.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Regina Pollastro</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar10.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Tania Pearson</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="Avatar" class="image" />
                                    <div class="in">
                                        <div>Frank Boehm</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBlockModal
