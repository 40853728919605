import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { connect } from 'react-redux'
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
// import { showUpdateDataOffcanvas } from '../../features/updateData/updateDataSlice'
import '@splidejs/splide/dist/css/splide.min.css'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_CRSL_DFL1 = ({ 
  items = [], blockID, showEditOffcanvas, 
  properties, showDeleteModal, websiteDataBlocks, 
  showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType }) => {
  const handleBlockItemUpdate = itemID => {
    showEditOffcanvas({
      isEditVisible: true,
      itemID: itemID,
      items: items,
      blockID: blockID,
      properties:properties
    })
  }

  const handleNewEditItem = (itemID) => {
    setNewBlockType({ 'BlockType' : blockID.slice(0, 2), 'BlockID' : blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
    console.log({itemID, items, blockID, properties});
    showUpdateDataOffcanvas({
      isEditVisible: true,
      itemID: itemID,
      items: items,
      blockID: blockID,
      properties:properties
    })
  }

  const handleDeleteContent = (blockName, deleteItemID) => {
    const contentName = websiteDataBlocks[blockName].items.contents.split(
      '.'
    )[1]
    showDeleteModal({
      isDeleteVisible: true,
      deleteMessage: 'Are your sure? You want to delete this content?',
      triggerMessage: 'content',
      deleteID: contentName,
      deleteItemID: deleteItemID
    })
  }

  function handleShowButtonModal(buttonData, ID) {
    handleBlockItemUpdate(ID)
    setTimeout(() => {
      showButtonModal({
        isButtonModalVisible: true,
        buttonData: buttonData,
        buttonID: ID
      })
    }, [1000])
    newButtonData({ buttonDataObj: buttonData })
  }

  return (
    <div>
      <div className="section full" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
        <Splide
          options={{
            // type: 'slide',
            // perPage: 3,
            // focus: 1,
            // rewind: true,
            // gap: 8,
            // padding: 16,
            // arrows: false,
            // pagination: false,
            // slideFocus: true,
            // fixWidth: 95,
            // breakpoints: {
            //   200: { perPage: 1 },
            //   411: { perPage: 1 },
            //   768: { perPage: 1 },
            //   991: { perPage: 2 }
            // }
            perPage: 1,
            rewind: true,
            type: "loop",
            gap: 0,
            arrows: (items && items.length > 1) ? true : false,
            pagination: (items && items.length > 1) ? true : false
          }}
        >
          {items.map((item, key) => {
              const firstImage = (item.image && item.image.length > 0) ? item.image[0].src : ''
              return (
                <SplideSlide key={key}>
                  <div className="card bg-dark text-white"  style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                    <img
                      src={firstImage}
                      className="card-img overlay-img rounded-0"
                      alt="Slider Img"
                      style={cssConverter(item.imageCss)}
                    />
                    <div className="d-flex align-items-center justify-content-center text-center rounded-0 position-absolute center-center">
                      <div className={(properties.container === undefined || properties.container === null || properties.container === 'true') ? "container" : "container-fluid"}>
                        <h1 className="overflow-auto cursor-pointer" onClick={() => {handleNewEditItem(key)}} dangerouslySetInnerHTML={{__html: item.title}} style={cssConverter(item.titleCss)}/>
                        <span onClick={() => {handleNewEditItem(key)}}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="overflow-auto text-light bg-transparent cursor-pointer" onClick={() => {handleNewEditItem(key)}}  style={cssConverter(item.subtitleCss)} /></span>
                        {/* <p className="overflow-auto text-light">{item.subtitle}</p> */}
                        <div className="mt-2">
                          {item.button.map((value, key) => {
                            return <button className={`btn btn-sm me-1 ${value.style && value.style !== '' ? value.style : 'btn-primary'}`} key={key} onClick={() => handleNewEditItem(key)} style={cssConverter(item.buttonCss)}>{value.title}</button>
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="position-absolute w-100" style={{top: '10px', right: '0px'}}>
                    <div className="card-body pt-2">
                        <div className="row">
                          {/* <div className="col-5 col-md-5 text-end">
                            <span className="badge bg-secondary p-1 editBtnCss" onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                          </div> */}
                          <div className="col-6 col-md-6 text-end">
                            <span className="badge bg-success p-1 editBtnCss" onClick={() => {handleNewEditItem(key)}}>Edit</span>
                          </div> 
                          <div className="col-6 col-md-6">
                            <span className="badge bg-danger p-1 editBtnCss" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </SplideSlide>
              )
          })}
        </Splide>
      </div>
    </div>
  )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
  const { isVisible } = offcanvas
  const { blocks: websiteDataBlocks } = websiteData
  return {
    offcanvasVisible: isVisible,
    websiteDataBlocks
  }
}

const mapDispatchToProps = {
  showEditOffcanvas,
  showDeleteModal,
  showButtonModal,
  newButtonData,
  showUpdateDataOffcanvas,
  setNewBlockType
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CN_CRSL_DFL1)
