import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import axios from '../../AxiosConfig';
import { showDeleteModal, showEditDns } from '../../features/modal/offcanvasSlice'
import EditDns from '../layout/Rightpanel/EditDns'

const DnsManagement = ({
    showDeleteModal,
    showEditDns,
    actualDomain
}) => {
    const [activeTab, setActiveTab] = useState('arecord');
    const [disbleSaveBtn, setDisableBtn] = useState(false);

    const handleDeletePage = () => {
        showDeleteModal({isDeleteVisible:true, deleteMessage:'Are your sure you want to delete this droplet', triggerMessage:'droplet', deleteID: 0})
    }

    const handleEditDnsOffcanvas = () => {
        showEditDns({isDnsOffcanvasVisible:true})
    }

    const handleDnsRecordSwitch = (data) => {
        setActiveTab(data)
    }

    function endsWithIn(domainName) {
        // Use a regular expression to check for the .in ending
        const regex = /\.in$/;
        return regex.test(domainName);
    }

    const [allNS, setAllNS] = useState([]);

    const getAllNSRecorder = () => {
        axios.get(`/eppClient/getHost/${actualDomain}`).then((response) => {
            setAllNS(response.data.data);
        }).catch((error) => {
            console.error(error)
        });
    }

    useEffect(() => {
        if(actualDomain) {
            getAllNSRecorder();
        }
    }, [actualDomain])
    
    const updateNS = (NS, position) => {
        const tempLs = JSON.parse(JSON.stringify(allNS));
        tempLs[position] = NS.trim();
        setAllNS(tempLs)
    }

    const AddNewInput = () => {
        setAllNS([...allNS, '']);
    }

    const SendUpdatedNSRecords = () => {
        setDisableBtn(true);
        let tempLs = JSON.parse(JSON.stringify(allNS));
        tempLs = tempLs.filter(item => (item && item.trim() !== ''))
        console.log(tempLs);
            axios.post(`/eppClient/updateHost`, { 'Domain': actualDomain, "host": tempLs }).then((response) => {
                // setAllNS(response.data.data);
                getAllNSRecorder();
                setDisableBtn(false);
            }).catch((error) => {
                console.error(error)
                setDisableBtn(false);
            });
    }

    const getLimitToAdd = localStorage.getItem('cropperConfig') ? JSON.parse(localStorage.getItem('cropperConfig')) : {'MaxNS': 5};
    const take = getLimitToAdd.MaxNS;
    return (
        <>
            <div className='row flex-column'>
                {
                    endsWithIn(actualDomain) ?
                    <>    
                        <div className="col-12 col-md-3 mt-1 d-flex justify-content-between">
                            <label className="form-label mb-0">Enter Name Servers</label>
                            <button className='btn btn-primary' disabled={allNS?.length >= 5 || allNS?.length >= take} onClick={() => AddNewInput()}>Add New</button>
                        </div>
                        {
                            allNS?.map((server, i) => {
                                return (
                                    <div className="col-12 col-md-3" key={i}>
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" id="nm1" value={server} onChange={(e) => updateNS(e.target.value, i)} placeholder={`Name Server ${i+1}`}
                                                    autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="col-12 col-md-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="text" className="form-control" id="nm2" placeholder="Name Server 2"
                                        autoComplete="off" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="text" className="form-control" id="nm3" placeholder="Name Server 3"
                                        autoComplete="off" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="text" className="form-control" id="nm4" placeholder="Name Server 4"
                                        autoComplete="off" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="text" className="form-control" id="nm5" placeholder="Name Server 5"
                                        autoComplete="off" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-12 col-md-3">
                            <button className='btn btn-primary' disabled={disbleSaveBtn} onClick={() => SendUpdatedNSRecords()}>Save</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-12 col-md-12 mt-1 py-5">
                            <h3 className="form-label mb-0 text-center">You don't have "<b>.in</b>" domain</h3>
                        </div>
                    </>
                }
            </div>
            {/* <ul className="nav nav-tabs capsuled" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#aRecord" role="tab">
                        A
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#aaaaRecord" role="tab">
                        AAAA
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#cnameRecord" role="tab">
                        CNAME
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#mxRecord" role="tab">
                        MX
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#txtRecord" role="tab">
                        TXT
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#nsRecord" role="tab">
                        NS
                    </a>
                </li>
            </ul> */}
            {/* <div className="form-group boxed">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="dnsManage">Select Record</label>
                    <select className="form-control form-select" onChange={(e) => {handleDnsRecordSwitch(e.target.value)}} id="dnsManage">
                        <option value="arecord">A Record</option>
                        <option value="aaarecord">AAA Record</option>
                        <option value="cnamerecord">CNAME</option>
                        <option value="mxrecord">MX Record</option>
                        <option value="txtrecord">TXT Record</option>
                        <option value="nsrecord">NS Record</option>
                    </select>
                </div>
            </div>
            <div className="tab-content mt-2">
                <div className={(activeTab === 'arecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="aRecord" role="tabpanel">
                    Use @ to create the record at the root of the domain or enter a hostname to create it elsewhere. A records are for IPv4 addresses only and tell a request where your domain should direct to.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="directTo5">Will Direct To</label>
                                        <input type="text" className="form-control" id="directTo5" placeholder="Select resource or enter IP"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 pt-3 d-flex align-items-center">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div  className={(activeTab === 'aaarecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="aaaRecord" role="tabpanel">
                    Use @ to create the record at the root of the domain or enter a hostname to create it elsewhere. AAAA records are for IPv6 addresses only and tell a request where your domain should direct to.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="directTo5">Will Direct To</label>
                                        <input type="text" className="form-control" id="directTo5" placeholder="Select resource or enter IPv6"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 d-flex align-items-center pt-3">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={(activeTab === 'cnamerecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="cnameRecord" role="tabpanel">
                    CNAME records act as an alias by mapping a hostname to another hostname.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="directTo5">IS AN ALIAS OF</label>
                                        <input type="text" className="form-control" id="directTo5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 d-flex align-items-center pt-3">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={(activeTab === 'mxrecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="mxRecord" role="tabpanel">
                    MX records specify the mail servers responsible for accepting emails on behalf of your domain, and priority value if your provider has a number of mail servers for contingency.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-4 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="directTo5">MAIL PROVIDERS MAIL SERVER</label>
                                        <input type="text" className="form-control" id="directTo5" placeholder="aspmx.l.google.com"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="priority5">Priority</label>
                                        <input type="number" className="form-control" id="priority5" placeholder="e.g. 10" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-items-center pt-3">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={(activeTab === 'txtrecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="txtRecord" role="tabpanel">
                    TXT records are used to associate a string of text with a hostname. These are primarily used for verification.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-12 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="value5">Value</label>
                                        <input type="text" className="form-control" id="value5" placeholder="Paste TXT string here"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 pt-3 d-flex align-items-center">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={(activeTab === 'nsrecord') ? 'tab-pane fade show active' : 'tab-pane fade'} id="nsRecord" role="tabpanel">
                    NS records specify the servers which are providing DNS services for your domain. You can use these to create subzones if you need to direct part of your traffic to another DNS service.
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="hostName5">Hostname</label>
                                        <input type="text" className="form-control" id="hostName5" placeholder="Enter @ or hostname"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="nameserver5">Will Direct To</label>
                                        <input type="text" className="form-control" id="nameserver5" placeholder="Enter nameserver"
                                            autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-1">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="ttl5">TTL (Seconds)</label>
                                        <input type="number" className="form-control" id="ttl5" placeholder="Enter TTL" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 d-flex align-items-center pt-3">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <button className="btn btn-primary btn-block">Create Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="listview-title">Domain Details</div>
            <ul className="listview image-listview">
                <li className="custom-line">
                    <span className="item">
                        <div className="in">
                            <div>
                                <header>A</header>
                                api1.amruta.in
                                <footer>3600</footer>
                            </div>
                            <span style={{cursor: 'pointer'}}><ion-icon name="create-outline" className="text-secondary fs-5" onClick={handleEditDnsOffcanvas}></ion-icon>&nbsp;<ion-icon name="trash-outline" className="text-danger fs-5" onClick={() => {handleDeletePage()}}></ion-icon></span>
                        </div>
                    </span>
                </li>
                <li className="custom-line">
                    <span className="item">
                        <div className="in">
                            <div>
                                <header>A</header>
                                api1.amruta.in
                                <footer>3600</footer>
                            </div>
                            <span style={{cursor: 'pointer'}}><ion-icon name="create-outline" className="text-secondary fs-5"></ion-icon>&nbsp;<ion-icon name="trash-outline" className="text-danger fs-5"></ion-icon></span>
                        </div>
                    </span>
                </li>
                <li className="custom-line">
                    <span className="item">
                        <div className="in">
                            <div>
                                <header>A</header>
                                api1.amruta.in
                                <footer>3600</footer>
                            </div>
                            <span style={{cursor: 'pointer'}}><ion-icon name="create-outline" className="text-secondary fs-5"></ion-icon>&nbsp;<ion-icon name="trash-outline" className="text-danger fs-5"></ion-icon></span>
                        </div>
                    </span>
                </li>
            </ul> */}
        </>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isDnsOffcanvasVisible
    } = offcanvas
    return {
        isDnsOffcanvasVisible
    }
  }
  
  const mapDispatchToProps = {
    showDeleteModal,
    showEditDns
  }

export default connect(mapStateToProps, mapDispatchToProps)(DnsManagement)
