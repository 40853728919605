import React from 'react'
import { connect } from 'react-redux'
import { showDeleteModal } from '../../features/modal/offcanvasSlice'

const DnsTransfer = ({
    showDeleteModal
}) => {
    function copyToClipboard() {
        var textBox = document.getElementById("transferCode")
        textBox.select()
        document.execCommand("copy")
    }

    const confirmationPopup = (data) => {
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'Are your sure? You want to '+data+' your domain?',
            triggerMessage: 'domainSetting',
            deleteID: data,
            deleteItemID: data
        })
    }
    return (
        <div className="section full">
            <div className="mt-2 mb-2">
                <p className="mb-1">For security purpose your domain locked to get transferred out.</p>
                {/* <form> */}
                    {/* <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="transferCode5">Transfer Code</label>
                            <input type="text" className="form-control" id="transferCode5" placeholder="Your transfer code"
                                autoComplete="off" />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div> */}
                    <button className="btn btn-success mt-1 me-1" onClick={() => {confirmationPopup('unlock')}}><ion-icon name="lock-open-outline"></ion-icon> Unlock</button>
                    <button className="btn btn-danger mt-1" onClick={() => {confirmationPopup('lock')}}><ion-icon name="lock-closed-outline"></ion-icon> Lock</button>
                {/* </form> */}
            </div>
            <div>
                <div className="card mt-1">
                    <div className="card-body">
                        <h5 className="card-title text-center">Transfer Code</h5>
                        <p className="card-text text-center">
                            <div className="row">
                                <div className="col-10 col-md-10">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control" id="transferCode" placeholder="Enter your name"
                                                autoComplete="off" value="asg5456jd#!hkjas" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 col-md-2 d-flex align-items-center">
                                    <button type="button" className="btn btn-icon btn-outline-secondary" onClick={copyToClipboard}>
                                        <ion-icon name="copy-outline"></ion-icon>
                                    </button>
                                </div>
                            </div>
                            
                            {/* <button type="button" className="btn btn-facebook">
                                <ion-icon name="logo-facebook"></ion-icon>
                                Facebook
                            </button> */}
                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
  const mapDispatchToProps = {
    showDeleteModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(DnsTransfer)
