import React from 'react'
import { connect } from 'react-redux'
import { showSwitchModal, showNavigationOffcanvas, showOffcanvas, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'
import { useNavigate } from 'react-router-dom'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const SM_FOOT_DFL1 = ({
    websiteDataAppearance = { properties: {} },
    items,
    properties,
    blockType,
    showSwitchModal,
    blockID,
    showNavigationOffcanvas,
    showOffcanvas,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}) => {
    const {
        properties: websiteDataAppearanceProperties,
        navigation
    } = websiteDataAppearance

    const openSwitchModal = () => {
        showSwitchModal({ isSwitchModalVisible: true, switchModalMessage: 'changePage', blockID: blockID })
    }

    const handleSocialMedia = () => {
        showNavigationOffcanvas({ isNavigationVisible: true, blockID: blockID, triggerMessage: 'socialMedia' })
    }

    const handleNewEditItem = (msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': 0 });
        showUpdateDataOffcanvas({
            isEditVisible: true,
            //   itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties,
            blockType: blockType,
            blockID: blockID,
            msg: msg
        })
    }

    let navigate = useNavigate()

    const handleLogoUpdate = () => {
        navigate(
            '/build?url=' + websiteDataAppearance.properties.defaultPage
        )
    }

    const handleBlockUpdate = (msg) => {
        // console.log('CLICKED')
        showOffcanvas({ isVisible: true, items: items, itemCount: 'null', properties: properties, blockType: blockType, blockID: blockID, msg: msg })
    }

    const handleDeleteBlock = (blockName) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockName })
    }
    return (
        <div>
            <div style={{backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : properties.backgroundValue}}>
                <div className="section full position-relative">
                    <div className="pt-4 pb-3" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : properties.backgroundValue , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                        <div className="border-0">
                            <div className="row">
                                <div className="col-md-2 col-4 p-1">
                                    {
                                        items[0].image[0] &&
                                        <img src={items[0].image[0].src} alt={items[0].image[0].alt} className="w-100 mb-2" style={cssConverter(properties.imageCss, { maxWidth: '85px', cursor: 'pointer' })} onClick={handleLogoUpdate} />
                                    }
                                </div>
                                <div className="col-12 col-md-3 col-4 p-1">
                                    <div className="footer-title">
                                        <div className="form-group boxed">
                                            <div className="input-wrapper">
                                                <label className="form-label" htmlFor="menuList" style={cssConverter(properties.titleCss)}>Quick Links</label>
                                                <ul className="ps-1" style={{ listStyle: 'none' }}>
                                                    {navigation.footer.map((value, key) => {
                                                        return <div key={key}>
                                                            {(value.hasOwnProperty('children')) &&
                                                                <>
                                                                    {(value.children.length !== 0) ?
                                                                        <>
                                                                            {value.children.map((v, k) => {
                                                                                return <li className="pe-1" key={k} onClick={openSwitchModal} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: v.title }} />
                                                                            })}
                                                                        </>
                                                                        :
                                                                        <li className="pe-1 cursor-pointer" onClick={openSwitchModal} dangerouslySetInnerHTML={{ __html: value.title }} />
                                                                    }
                                                                </>
                                                            }
                                                            {(!value.hasOwnProperty('children')) &&
                                                                <li className="pe-1" onClick={openSwitchModal} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: value.title }} />
                                                            }
                                                        </div>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7 col-4 p-1 ps-2">
                                    <div className="footer-title">
                                        <p className="mb-2" style={cssConverter(properties.descriptionCss)}>{items[0].description}</p>
                                        <ul className="d-flex p-0" style={{ listStyleType: 'none' }} onClick={handleSocialMedia}>
                                            <li className="pe-1 cursor-pointer"><ion-icon name="logo-facebook" className="text-info fs-2"></ion-icon></li>
                                            <li className="pe-1 cursor-pointer"><ion-icon name="logo-instagram" className="text-warning fs-2"></ion-icon></li>
                                            <li className="pe-1 cursor-pointer"><ion-icon name="logo-linkedin" className="text-info fs-2"></ion-icon></li>
                                            <li className="pe-1 cursor-pointer"><ion-icon name="logo-youtube" className="text-danger fs-2"></ion-icon></li>
                                            <li className="pe-1">
                                                <span className="btn btn-primary btn-sm" style={{ padding: '3px' }}><ion-icon name="add-outline" className="text-light fs-5 m-0"></ion-icon></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', display: 'flex' }}>
                        <div className="col-6 col-md-6 text-end">
                            <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem() }}>Edit</span>
                        </div>
                        <div className="col-6 col-md-6">
                            <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteBlock(blockID) }}>Delete</span>
                        </div>
                    </div> */}
                </div>
                <div className="section pt-2 pb-2 custom-footer-block" style={{ backgroundColor: websiteDataAppearanceProperties.backgroundValue, background: '#0606063b'}}>
                    <div className="row">
                        {/* <div className="col-md-6 col-6">
                            © 2022 WEBWEB Infotech
                        </div> */}
                        <div className="col-md-12 col-12 d-flex justify-content-start justify-content-md-end text-light">
                            Made With <a href="https://webweb.ai.in/" target="_blank" rel="noreferrer" class="text-light fw-bolder">&nbsp;WEBWEB AI</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ websiteData }) => {
    const {
        appearance: websiteDataAppearance
    } = websiteData

    return {
        websiteDataAppearance
    }
}

const mapDispatchToProps = {
    showSwitchModal,
    showNavigationOffcanvas,
    showOffcanvas,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_FOOT_DFL1)
