import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { hideUpdateDataOffcanvas, setWhereToUpdateImage, setContentBlockItem, setActiveOffcanvasParentTab, setActiveOffcanvasChildTab, setActiveOffcanvasChild2Tab } from '../../../features/modal/offcanvasSlice'
import { setWebsiteData, setLoading } from '../../../features/websiteData/websiteDataSlice';
import { showAppToast } from '../../../features/utilities/appToastSlice'
import LayersTab from '../../Elements/Management/LayersTab'
import StylesTab from '../../Elements/Management/StylesTab'
import JSONTab from '../../Elements/Management/JSONTab'

const NewEditOffcanvas = ({
    ContentBlockItem,
    setContentBlockItem,
    setWhereToUpdateImage,
    newImage,
    isEditVisible,
    hideUpdateDataOffcanvas,
    items = [],
    itemID,
    properties,
    blockID,
    setWebsiteData,
    showAppToast,
    websiteDataBlocks,
    isImageModalVisible,
    WhereToUpdateImage,
    FlagsToCheckChanges,
    MainBlockProps,
    NewBlockType,
    ContentItems,
    ContentItemsArray,
    setActiveOffcanvasParentTab,
    ActiveOffcanvasParentTab,
    setActiveOffcanvasChildTab,
    ActiveOffcanvasChildTab,
    setActiveOffcanvasChild2Tab,
    ActiveOffcanvasChild2Tab,
    setLoading
}) => {

    // const [blockStyleCode, setBlockStyleCode] = useState('')

    // useEffect(() => {
    //     if (blockID !== '' && blockID !== undefined) {
    //       setBlockStyleCode(websiteDataBlocks[blockID].properties.style)
    //     }
    // }, [websiteDataBlocks, blockID])

    // const setBlockData = () => {
    //     document.getElementsByName('blockHeading')[0].value =
    //       properties.blockHeading
    //     document.getElementsByName('blockDescription')[0].value =
    //       properties.blockDescription
    // }

    // useEffect(() => {
    //     if (properties !== undefined) {
    //       if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
    //         setBlockData()
    //       }
    //     }
    // }, [properties])
    const [UpdateButton, disUpdateButton] = useState(false);
    const token = localStorage.getItem('token')
    const tokenData = token ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const getItemObject = () => { return JSON.parse(JSON.stringify(ContentBlockItem)) };

    useEffect(() => {
        if ((isImageModalVisible === false) && (WhereToUpdateImage !== null)) {
            console.log(WhereToUpdateImage, newImage)
            if (WhereToUpdateImage.blockType === 'CN') {
                const ItemData = getItemObject();
                ItemData.image[WhereToUpdateImage.position].src = newImage.src;
                ItemData.image[WhereToUpdateImage.position].alt = newImage.alt;
                setContentBlockItem(ItemData);
                setWhereToUpdateImage(null);
            }
        }
    }, [isImageModalVisible])

    async function updatePropSM() {
        const Props = {}
        // console.log(MainBlockProps)
        if (FlagsToCheckChanges.blockHeading) Props.blockHeading = MainBlockProps.blockHeading;
        if (FlagsToCheckChanges.blockHeadingCss) Props.blockHeadingCss = MainBlockProps.blockHeadingCss;
        if (FlagsToCheckChanges.blockDescription) Props.blockDescription = MainBlockProps.blockDescription;
        if (FlagsToCheckChanges.blockDescriptionCss) Props.blockDescriptionCss = MainBlockProps.blockDescriptionCss;
        if (FlagsToCheckChanges.css) Props.css = MainBlockProps.css;
        if (FlagsToCheckChanges.titleCss) Props.titleCss = MainBlockProps.titleCss;
        if (FlagsToCheckChanges.subtitleCss) Props.subtitleCss = MainBlockProps.subtitleCss;
        if (FlagsToCheckChanges.descriptionCss) Props.descriptionCss = MainBlockProps.descriptionCss;
        if (FlagsToCheckChanges.structureCss) Props.structureCss = MainBlockProps.structureCss;
        if (FlagsToCheckChanges.container) Props.container = MainBlockProps.container;
        if (FlagsToCheckChanges.imageCss) Props.imageCss = MainBlockProps.imageCss;
        if (FlagsToCheckChanges.buttonCss) Props.buttonCss = MainBlockProps.buttonCss;
        if (FlagsToCheckChanges.background) Props.background = MainBlockProps.background;
        if (FlagsToCheckChanges.background) Props.backgroundType = MainBlockProps.backgroundType;
        if (FlagsToCheckChanges.background) Props.backgroundValue = MainBlockProps.backgroundValue;
        if (FlagsToCheckChanges.overlay) Props.overlay = MainBlockProps.overlay;
        setLoading(true)
        // console.log(Props, tokenData);
        const text = await axios
            .put('/block/' + selectedDomain + '/' + blockID, Props)
            .then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({
                        toastMessage: 'PROPERTIES UPDATED ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false)
                    //   hideEditOffcanvas()

                } else {
                    showAppToast({
                        toastMessage: 'FAIL',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false)
                    //   hideEditOffcanvas()
                }
                setLoading(false)
                return 'Done';
            }).catch((error) => {
                setLoading(false);
                showAppToast({
                    toastMessage: 'FAIL ',
                    background: 'danger',
                    timeout: '1000',
                    icon: 'close-circle-outline',
                    position: 'toast-center'
                })
            })

        return text;
    }

    async function updateItemSM() {
        let ItemData = [ContentBlockItem]
        if (NewBlockType !== null && (NewBlockType.BlockName === 'SM_CNTC' || NewBlockType.BlockName === 'SM_CNTR' || NewBlockType.BlockName === 'SM_MAPE')) {
            ItemData = JSON.parse(JSON.stringify(ContentItems));
            if (FlagsToCheckChanges.title) ItemData[NewBlockType.ItemNo].title = ContentBlockItem.title;
            if (FlagsToCheckChanges.subtitle) ItemData[NewBlockType.ItemNo].subtitle = ContentBlockItem.subtitle;
            if (FlagsToCheckChanges.description) ItemData[NewBlockType.ItemNo].description = ContentBlockItem.description;
            if (FlagsToCheckChanges.image) ItemData[NewBlockType.ItemNo].image = ContentBlockItem.image;
            if (FlagsToCheckChanges.button) ItemData[NewBlockType.ItemNo].button = ContentBlockItem.button;
            if (FlagsToCheckChanges.url) ItemData[NewBlockType.ItemNo].url = ContentBlockItem.url;
        } else {
            if (FlagsToCheckChanges.title) ItemData.title = ContentBlockItem.title;
            if (FlagsToCheckChanges.subtitle) ItemData.subtitle = ContentBlockItem.subtitle;
            if (FlagsToCheckChanges.description) ItemData.description = ContentBlockItem.description;
            if (FlagsToCheckChanges.image) ItemData.image = ContentBlockItem.image;
            if (FlagsToCheckChanges.button) ItemData.button = ContentBlockItem.button;
            if (FlagsToCheckChanges.url) ItemData.url = ContentBlockItem.url;
        }
        // console.log(ItemData);
        setLoading(true);
        const blockIdName = NewBlockType !== null ? NewBlockType.BlockID : false;
        if (blockIdName !== false) {
            await axios
                .put(
                    '/block/' + selectedDomain + '/' + blockIdName + '/items',
                    ItemData
                )
                .then(res => {
                    if (res.data.message === 'Success') {
                        setWebsiteData(res.data.data)
                        showAppToast({
                            toastMessage: 'UPDATED SUCCESSFULLY',
                            background: 'success',
                            timeout: '1000',
                            icon: 'checkmark-circle-outline',
                            position: 'toast-center'
                        })
                        disUpdateButton(false)
                    } else {
                        showAppToast({
                            toastMessage: 'FAIL ',
                            background: 'danger',
                            timeout: '1000',
                            icon: 'close-circle-outline',
                            position: 'toast-center'
                        })
                        disUpdateButton(false)
                    }
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    showAppToast({
                        toastMessage: 'FAIL ',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                })
        }
    }

    async function updatePropCN() {
        const Props = {}
        // console.log(MainBlockProps)
        if (FlagsToCheckChanges.blockHeading) Props.blockHeading = MainBlockProps.blockHeading;
        if (FlagsToCheckChanges.blockHeadingCss) Props.blockHeadingCss = MainBlockProps.blockHeadingCss;
        if (FlagsToCheckChanges.blockDescription) Props.blockDescription = MainBlockProps.blockDescription;
        if (FlagsToCheckChanges.blockDescriptionCss) Props.blockDescriptionCss = MainBlockProps.blockDescriptionCss;
        if (FlagsToCheckChanges.css) Props.css = MainBlockProps.css;
        if (FlagsToCheckChanges.countInRow) Props.countInRow = MainBlockProps.countInRow;
        if (FlagsToCheckChanges.structureCss) Props.structureCss = MainBlockProps.structureCss;
        if (FlagsToCheckChanges.container) Props.container = MainBlockProps.container;
        if (FlagsToCheckChanges.background) Props.background = MainBlockProps.background;
        if (FlagsToCheckChanges.background) Props.backgroundType = MainBlockProps.backgroundType;
        if (FlagsToCheckChanges.background) Props.backgroundValue = MainBlockProps.backgroundValue;
        if (FlagsToCheckChanges.overlay) Props.overlay = MainBlockProps.overlay;
        setLoading(true);
        const t2 = await axios
            .put('/block/' + selectedDomain + '/' + blockID, Props)
            .then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({
                        toastMessage: 'PROPERTIES UPDATED ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false);
                    //   hideEditOffcanvas()
                    hideUpdateDataOffcanvas();
                    setLoading(false)
                } else {
                    setLoading(false);
                    showAppToast({
                        toastMessage: 'FAIL',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false)
                    //   hideEditOffcanvas()
                    hideUpdateDataOffcanvas()
                }
                return 'Done'
            }).catch((error) => {
                showAppToast({
                    toastMessage: 'FAIL ',
                    background: 'danger',
                    timeout: '1000',
                    icon: 'close-circle-outline',
                    position: 'toast-center'
                })
                setLoading(false)
            })
        return t2
    }

    async function updateItemsRCN() {
        // if (ContentItems !== null) {
            setLoading(true);
        const t3 = await axios
            .put(
                '/content/reorder/' + selectedDomain + '/' + ContentItems.contents.split('.')[1] + '/' + blockID,
                { reorderArr: ContentItemsArray !== null ? ContentItemsArray : [], items: ContentItems }
            )
            .then(res => {
                if (res.data.message === 'Success') {
                    setWebsiteData(res.data.data)
                    showAppToast({
                        toastMessage: 'Reordered Successfully ',
                        background: 'success',
                        timeout: '1000',
                        icon: 'checkmark-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false)
                    setLoading(false);
                } else {
                    showAppToast({
                        toastMessage: 'FAIL ',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                    disUpdateButton(false)
                    setLoading(false);
                }
                return 'Done'
            })
            .catch((error) => {
                console.error(error)
                setLoading(false);
                showAppToast({
                    toastMessage: 'FAIL ',
                    background: 'danger',
                    timeout: '1000',
                    icon: 'close-circle-outline',
                    position: 'toast-center'
                })
            })

        return t3
        // }
    }

    async function updateItemCN() {
        if (ContentBlockItem !== null && ContentItems !== null && NewBlockType !== null) {
            setLoading(true);
            await axios
                .put(
                    '/content/' + selectedDomain + '/' + ContentItems.contents.split('.')[1] + '/' + NewBlockType.ItemNo,
                    ContentBlockItem
                )
                .then(res => {
                    if (res.data.message === 'Success') {
                        setWebsiteData(res.data.data)
                        showAppToast({
                            toastMessage: 'UPDATED SUCCESSFULLY',
                            background: 'success',
                            timeout: '1000',
                            icon: 'checkmark-circle-outline',
                            position: 'toast-center'
                        })
                        disUpdateButton(false)
                        setLoading(false);
                    } else {
                        showAppToast({
                            toastMessage: 'FAIL ',
                            background: 'danger',
                            timeout: '1000',
                            icon: 'close-circle-outline',
                            position: 'toast-center'
                        })
                        disUpdateButton(false)
                        setLoading(false);
                    }
                }).catch((error) => {
                    setLoading(false);
                    showAppToast({
                        toastMessage: 'FAIL ',
                        background: 'danger',
                        timeout: '1000',
                        icon: 'close-circle-outline',
                        position: 'toast-center'
                    })
                })
        }
    }

    const updateLayersData = () => {
        disUpdateButton(true);
        if (tokenData !== false && tokenData.userInfo.RoleID != 'Moderator' && NewBlockType !== null && NewBlockType.BlockType === 'SM') {
            if (FlagsToCheckChanges.titleCss || FlagsToCheckChanges.subtitleCss || FlagsToCheckChanges.descriptionCss || FlagsToCheckChanges.blockHeading || FlagsToCheckChanges.blockHeadingCss || FlagsToCheckChanges.blockDescription || FlagsToCheckChanges.blockDescriptionCss || FlagsToCheckChanges.countInRow || FlagsToCheckChanges.structureCss || FlagsToCheckChanges.imageCss || FlagsToCheckChanges.buttonCss || FlagsToCheckChanges.css || FlagsToCheckChanges.background || FlagsToCheckChanges.overlay || FlagsToCheckChanges.container) {
                updatePropSM().then((result) => {
                    // console.log(result);
                    if (FlagsToCheckChanges.title || FlagsToCheckChanges.subtitle || FlagsToCheckChanges.description || FlagsToCheckChanges.image || FlagsToCheckChanges.button || FlagsToCheckChanges.url) {
                        updateItemSM();
                    }
                });
            } else {
                if (FlagsToCheckChanges.title || FlagsToCheckChanges.subtitle || FlagsToCheckChanges.description || FlagsToCheckChanges.image || FlagsToCheckChanges.button || FlagsToCheckChanges.url) {
                    updateItemSM();
                }
            }
        }
        if (tokenData !== false && tokenData.userInfo.RoleID != 'Moderator' && NewBlockType !== null && NewBlockType.BlockType === 'CN') {
            if (FlagsToCheckChanges.blockHeading || FlagsToCheckChanges.blockHeadingCss || FlagsToCheckChanges.blockDescription || FlagsToCheckChanges.blockDescriptionCss || FlagsToCheckChanges.countInRow || FlagsToCheckChanges.css || FlagsToCheckChanges.structureCss || FlagsToCheckChanges.background || FlagsToCheckChanges.overlay || FlagsToCheckChanges.container) {
                updatePropCN().then((result) => {
                    // console.log(result)
                    if ((FlagsToCheckChanges.itemFilters || FlagsToCheckChanges.numItems || FlagsToCheckChanges.contents) && ContentItems !== null) {
                        updateItemsRCN().then((result2) => {
                            // console.log(result2)
                        });
                    } else {
                        if (FlagsToCheckChanges.title || FlagsToCheckChanges.titleCss || FlagsToCheckChanges.subtitle || FlagsToCheckChanges.description || FlagsToCheckChanges.subtitleCss || FlagsToCheckChanges.descriptionCss || FlagsToCheckChanges.image || FlagsToCheckChanges.button || FlagsToCheckChanges.imageCss || FlagsToCheckChanges.buttonCss || FlagsToCheckChanges.additionalDetails || FlagsToCheckChanges.itemCss  || FlagsToCheckChanges.itemBackground || FlagsToCheckChanges.itemOverlay) {
                            updateItemCN();
                        }
                    }
                })
            } else {
                if ((FlagsToCheckChanges.itemFilters || FlagsToCheckChanges.numItems || FlagsToCheckChanges.contents) && ContentItems !== null) {
                    updateItemsRCN().then((result) => {
                        console.log(result)
                        if (FlagsToCheckChanges.title || FlagsToCheckChanges.titleCss || FlagsToCheckChanges.subtitle || FlagsToCheckChanges.description || FlagsToCheckChanges.subtitleCss || FlagsToCheckChanges.descriptionCss || FlagsToCheckChanges.image || FlagsToCheckChanges.button || FlagsToCheckChanges.imageCss || FlagsToCheckChanges.buttonCss || FlagsToCheckChanges.additionalDetails || FlagsToCheckChanges.itemCss  || FlagsToCheckChanges.itemBackground || FlagsToCheckChanges.itemOverlay) {
                            updateItemCN();
                        }
                    });;
                } else {
                    if (FlagsToCheckChanges.title || FlagsToCheckChanges.titleCss || FlagsToCheckChanges.subtitle || FlagsToCheckChanges.description || FlagsToCheckChanges.subtitleCss || FlagsToCheckChanges.descriptionCss || FlagsToCheckChanges.image || FlagsToCheckChanges.button || FlagsToCheckChanges.imageCss || FlagsToCheckChanges.buttonCss || FlagsToCheckChanges.additionalDetails || FlagsToCheckChanges.itemCss  || FlagsToCheckChanges.itemBackground || FlagsToCheckChanges.itemOverlay) {
                        updateItemCN();
                    }
                }
            }
        }

        hideUpdateDataOffcanvas();
        disUpdateButton(false);
    }

    const addForm = () => {
        disUpdateButton(true);
        setLoading(true)
        axios.post(`/form/${selectedDomain}/${NewBlockType.BlockID}/${NewBlockType.ItemNo}`)
        .then((res) => {
            setWebsiteData(res.data.data)
            showAppToast({
                toastMessage: 'FORM ADDED SUCCESSFULLY',
                background: 'success',
                timeout: '1000',
                icon: 'checkmark-circle-outline',
                position: 'toast-center'
            })
            hideUpdateDataOffcanvas();
            disUpdateButton(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!isEditVisible) {
            disUpdateButton(false);
        }
    }, [isEditVisible])


        return (
            <Offcanvas show={isEditVisible} placement={'end'} backdrop={true} scroll={true} id="appCapsule">
                <Offcanvas.Body className="offcanvas-body ps-1 pe-1 overflowX-hidden">
                    <div className="profileBox webweb-profileBox">
                        <div className="in">
                            <strong>Edit</strong>
                        </div>
                        <span className="close-sidebar-button" onClick={() => { hideUpdateDataOffcanvas() }} style={{ cursor: 'pointer' }}>
                            <ion-icon name="close"></ion-icon>
                        </span>
                    </div>
                    <div className="section full mb-0">
                        <div className="custom-extraHeader p-0 position-absolute">
                            <ul className="nav nav-tabs lined" role="tablist">
                                <li className="nav-item">
                                    <button className={ActiveOffcanvasParentTab === 'Layers' ? "nav-link active" : "nav-link"} style={{ height: '46px' }} onClick={() => setActiveOffcanvasParentTab('Layers')}>
                                        Contents
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={ActiveOffcanvasParentTab === 'Styles' ? "nav-link active" : "nav-link"} style={{ height: '46px' }} onClick={() => setActiveOffcanvasParentTab('Styles')}>
                                        Styles
                                    </button>
                                </li>
                                {/* <li className="nav-item">
                                    <button className={ActiveOffcanvasParentTab === 'JSON' ? "nav-link active" : "nav-link"} style={{ height: '46px' }} onClick={() => setActiveOffcanvasParentTab('JSON')}>
                                    JSON
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                        <div className="tab-content" style={{ marginTop: '35px' }}>
                            <div className={ActiveOffcanvasParentTab === 'Layers' ? "tab-pane fade show active overflowX-hidden" : "tab-pane fade"} id="layersTab" role="tabpanel">
                                <LayersTab 
                                setActiveOffcanvasChildTab={setActiveOffcanvasChildTab} 
                                ActiveOffcanvasChildTab={ActiveOffcanvasChildTab}
                                NewBlockType={NewBlockType}
                                addForm={addForm} />
                                <div className='position-bottom-btn' style={{ 'position': 'absolute', 'bottom': '4px', 'left': '7px', 'right': '7px' }} onClick={() => updateLayersData()}>
                                    <button className='btn btn-primary w-100' disabled={UpdateButton}>Update</button>
                                </div>
                            </div>

                            <div className={ActiveOffcanvasParentTab === 'Styles' ? "tab-pane fade show active overflowX-hidden" : "tab-pane fade"} id="stylesTab" role="tabpanel">
                                <StylesTab 
                                setActiveOffcanvasChildTab={setActiveOffcanvasChild2Tab} 
                                LayersActiveTab={ActiveOffcanvasChildTab}
                                ActiveOffcanvasChildTab={ActiveOffcanvasChild2Tab} />
                                <div className='position-bottom-btn' style={{ 'position': 'absolute', 'bottom': '4px', 'left': '7px', 'right': '7px' }} onClick={() => updateLayersData()}>
                                    <button className='btn btn-primary w-100' disabled={UpdateButton}>Update</button>
                                </div>
                            </div>

                            {/* <div className={ActiveOffcanvasParentTab === 'JSON' ? "tab-pane fade show active" : "tab-pane fade"} id="JSONTab" role="tabpanel">
                                <JSONTab></JSONTab>
                            </div> */}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        )
    }


const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isEditVisible,
        ContentBlockItem,
        FlagsToCheckChanges,
        NewBlockType,
        MainBlockProps,
        ContentItems,
        ContentItemsArray,
        ActiveOffcanvasParentTab,
        ActiveOffcanvasChildTab,
        ActiveOffcanvasChild2Tab
    } = offcanvas
    const {
        blocks: websiteDataBlocks
    } = websiteData
    return {
        isEditVisible,
        NewBlockType,
        MainBlockProps,
        FlagsToCheckChanges,
        ContentBlockItem,
        websiteDataBlocks,
        ContentItems,
        ContentItemsArray,
        ActiveOffcanvasParentTab,
        ActiveOffcanvasChildTab,
        ActiveOffcanvasChild2Tab
    }
}

const mapDispatchToProps = {
    hideUpdateDataOffcanvas,
    setContentBlockItem,
    setWhereToUpdateImage,
    setWebsiteData,
    showAppToast,
    setActiveOffcanvasParentTab,
    setActiveOffcanvasChildTab,
    setActiveOffcanvasChild2Tab,
    setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEditOffcanvas)
