import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { showHtmlEditor } from '../../features/modal/offcanvasSlice'

const BlockDetail = ({
    showHtmlEditor
}) => {
    const handleHtmlEditor = () => {
        showHtmlEditor({isCodeEditorVisible:true})
    }
    return (
        <div id="appCapsule">
            <div className="section full mt-1">
                <Link to="/addTemplate" className="message-divider d-flex align-items-center justify-content-center">
                    <ion-icon name="arrow-back-outline"></ion-icon> Back
                </Link>
                <div className="border-0 ps-2 pe-2">
                    <form className="search-form">
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value="query" style={{height: '34px'}} />
                            <button className="btn h-custom btn-secondary" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                <ion-icon name="search-sharp" className="fs-6"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div> 
                <ul className="listview image-listview mt-1">
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    List
                                    <footer><span className="badge badge-secondary" onClick={handleHtmlEditor}>Default</span> <span className="badge badge-secondary" onClick={handleHtmlEditor}>Card</span> <span className="badge badge-secondary" onClick={handleHtmlEditor}>Slider</span> <span className="badge badge-secondary" onClick={handleHtmlEditor}>Card Slider</span></footer>
                                </div>
                                <span className="badge badge-secondary">Public</span>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src="assets/img/sample/avatar/avatar7.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Breadcrumb
                                    <footer><span className="badge badge-secondary">Default</span></footer>
                                </div>
                                <span className="badge badge-warning">Private</span>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Info
                                    <footer><span className="badge badge-secondary">Default</span> <span className="badge badge-secondary">Image Right</span> <span className="badge badge-secondary">Image Left</span></footer>
                                </div>
                                <span className="badge badge-warning">Private</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="fab-button text bottom-center" style={{left: '60%'}}>
                <a href="!#" className="fab">
                    <ion-icon name="add-outline"></ion-icon>
                    Add Block
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
const mapDispatchToProps = {
    showHtmlEditor
}

export default connect(mapStateToProps,mapDispatchToProps)(BlockDetail)