import React from 'react';
import Logo from '../images/icon/webweb-logo.png'
const DomainSearchnew = ({ websitePostData, setWebsitePostData, onConnectDomain, onEnterExistingDomain, BuildWebsite, searchDomain, searchDomainName, setSearchDomainName, domainList , disableSearchBtn, createWebsite, BookDomainAndBuildWebsite }) => {

    return (
        <div id="appCapsule" className="pt-0 pb-0">
            <div className="container-fluid buildForm domainSelection">
                <div className="row">
                    <div className="col-md-5  mt-4">
                        <div className="image-logo justify-content-start d-flex mb-4 ps-5">
                            <img className="logo-img" src={Logo} />
                        </div>
                        {
                            websitePostData.isDomainFree === 'true' &&
                            <form>

                                <div className="form-group boxed form-boxed ps-5 pt-3">
                                    <div className="input-wrapper">
                                        <h1 className="authenticate-phone">Find a New DOMAIN <br />For Your BUSINESS</h1>
                                        {/* <span className="domain-text"></span> */}
                                    </div>
                                </div>
                                <div className="search-form ps-5">
                                    <div className="form-group searchbox search-box-domain w-75">
                                        <input type="text" value={searchDomainName} onChange={(e) => setSearchDomainName(e.target.value)} className="form-control form-control-lg w-100" placeholder="Domain Search"/>
                                        <i className="input-icon">
                                            <ion-icon name="search-outline"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                                <div className="validate-btn mt-4 d-flex ps-5">
                                    {/* justify-content-center */}
                                    <button className="btn btn-lg btn-primary submit-btn-build-website" disabled={disableSearchBtn} onClick={() => searchDomain()} type="button">
                                        Search Domains
                                    </button>
                                    <button className="btn btn-lg btn-outline-primary shadowed ms-2" type="button" onClick={() => onConnectDomain('false')}>
                                        I have Domain
                                    </button>
                                </div>
                            </form>
                        }
                        {
                            websitePostData.isDomainFree === 'false' &&
                            <form>
                                <div className="form-group boxed form-boxed ps-5 pt-3">
                                    <div className="input-wrapper">
                                        <h1 className="authenticate-phone"> Enter your existing Domain name</h1>
                                        {/* <span className="domain-text"></span> */}
                                    </div>
                                </div>
                                <div className="form-group searchbox search-form ps-5">
                                    <input type="text" className="form-control form-control-lg w-100" id="name5" placeholder="example.com" autocomplete="off"  value={websitePostData.DomainName} onChange={(e) => onEnterExistingDomain(e.target.value)} />
                                    <i className="input-icon1 ms-1 mt-1">
                                        <ion-icon name="globe-outline"></ion-icon>
                                    </i>
                                </div>
                                <div className="validate-btn mt-4 d-flex ps-5">
                                    {/* justify-content-center */}
                                    <button className="btn btn-lg btn-primary submit-btn-build-website" type="button" onClick={() => BuildWebsite()}>
                                        Build Website
                                    </button>
                                    <button className="btn btn-lg btn-outline-primary shadowed ms-2" type="button" onClick={() => onConnectDomain('true')}>
                                        Find a Domain
                                    </button>
                                </div>
                            </form>
                        }

                    </div>

                    <div className="col-md-7 position-relative only-map">
                        <div className="find-domain">
                            <div className="skip-btn  mt-3 me-5">
                                <button className="skip-btn-text me-1 mb-1" type='button' onClick={() => createWebsite('Skip')}>Skip</button>
                            </div>

                            <div className="list list-main">

                            <ul className="listview simple-listview domain-list flush bg-transparent no-line text-light mt-5 pt-5 ms-5 ps-5">
                                {
                                    Object.keys(domainList).length > 0 && Object.keys(domainList).map((domain, i) => {
                                        return (
                                            <>
                                                {
                                                    domainList[domain].status === 'available' &&
                                                    <li key={i}>
                                                        <div className="">
                                                            <p className="domain-name mb-0">{domain}</p>
                                                            <p className="domain-name-sub-text mb-0">Rs. {domainList[domain].price}</p>
                                                        </div>
                                                        <button type="button" className="btn btn-primary me-1 mb-1 btn-sm" onClick={() => BookDomainAndBuildWebsite(domain)}>Select</button>
                                                    </li>
                                                }
                                            </>
                                        )
                                    })
                                }
                                </ul>

                            </div>
                        </div>

                        <div className="shape shape-start shape-fluid-y svg-shim text-white">
                            <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"></path></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default DomainSearchnew;