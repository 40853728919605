import React from 'react'
import { connect } from "react-redux"
import { showOffcanvas } from '../../features/modal/offcanvasSlice'

const SM_THRE_DFL1 = ({
    items = []
}) => {
    return (
        <div>
            <ul className="nav nav-tabs capsuled pt-4 pb-4 bg-white" role="tablist">
                {items.map((value, key) => {
                    return <li className="nav-item">
                        <a className={'nav-link active'} data-bs-toggle="tab" href={key} role="tab">
                            {value.title}
                        </a>
                    </li>
                })}
            </ul>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
      isVisible
    } = offcanvas
    return {
      offcanvasVisible: isVisible
    }
}
  
const mapDispatchToProps = {
    showOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_THRE_DFL1)
