import React from 'react'
import ComingSoon from '../images/coming-soon.svg'

const ChatBot = ({
    
}) => {
    return (
        <div id="appCapsule">
            <div className="section mt-1 text-center">
                <img src={ComingSoon} width="280px" />
                <h1>Coming Soon</h1>
            </div>
        </div>
    )
}

export default ChatBot