import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  selectedItems: -1
}

export const manageBlockSlice = createSlice({
  name: 'manageBlock',
  initialState,
  reducers: {
    showManageBlock: (state, action) => {
      const {
        items,
        properties,
        blockType,
        seq,
        blockID
      } = action.payload
      state.show = true
      state.items = items
      state.properties = properties
      state.blockType = blockType
      state.seq = seq
      state.blockID = blockID
    },
    hideManageBlock: (state, action) => {
      state.show = false
    }
  }
})

export const { showManageBlock, hideManageBlock } = manageBlockSlice.actions;


export default manageBlockSlice.reducer
