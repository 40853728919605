import React from 'react'

function SM_FACE_DFL1(props) {
    return (
        <div>
            Smart facebook Page Iframe
        </div>
    )
}

export default SM_FACE_DFL1
