import React, { useState, useEffect } from 'react';
import Logo from '../images/icon/webweb-logo.png'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

const BuildingWebsite = ({ websiteJSON, setActiveSections }) => {
  // new Splide( '.splide' ).mount( { AutoScroll } );
  const [stepsAnimation1, setStepsAnimation1] = useState({
    'Step': 1,
    'Title': 'Choosing Colors',
    'Status': 'Processing',
    'Value': [],
    'Type': 'Color'
  })

  const [stepsAnimation2, setStepsAnimation2] = useState({
    'Step': 2,
    'Title': 'Selecting Font',
    'Status': 'Waiting',
    'Value': [],
    "Type": 'Outline'
  })

  const [stepsAnimation3, setStepsAnimation3] = useState({
    'Step': 3,
    'Title': 'Generating Logo',
    'Status': 'Waiting',
    'Value': [],
    'Type': 'Image'
  })

  const [stepsAnimation4, setStepsAnimation4] = useState({
    'Step': 4,
    'Title': 'Creating Pages',
    'Status': 'Waiting',
    'Value': [],
    "Type": 'Outline'
  })

  const [stepsAnimation5, setStepsAnimation5] = useState({
    'Step': 5,
    'Title': 'Selecting Blocks',
    'Status': 'Waiting',
    'Value': [],
    "Type": 'Outline'
  })

  const [stepsAnimation6, setStepsAnimation6] = useState({
    'Step': 6,
    'Title': 'Genrating Contents',
    'Status': 'Waiting',
    'Value': [],
    "Type": 'Outline'
  })

  const [stepsAnimation7, setStepsAnimation7] = useState(
    {
      'Step': 7,
      'Title': 'Publishing Website',
      'Status': 'Waiting',
      'Value': [],
      "Type": 'Outline'
    }
  )

  function getJSONFromState(state) {
    return JSON.parse(JSON.stringify(state));
  }

  function setProcessing(num) {
    if (num === 2) {
      const temp = getJSONFromState(stepsAnimation2);
      temp.Status = 'Processing';
      setStepsAnimation2(temp);
    }

    if (num === 3) {
      const temp = getJSONFromState(stepsAnimation3);
      temp.Status = 'Processing';
      setStepsAnimation3(temp);
    }

    if (num === 4) {
      const temp = getJSONFromState(stepsAnimation4);
      temp.Status = 'Processing';
      setStepsAnimation4(temp);
    }

    if (num === 5) {
      const temp = getJSONFromState(stepsAnimation5);
      temp.Status = 'Processing';
      setStepsAnimation5(temp);
    }

    if (num === 6) {
      const temp = getJSONFromState(stepsAnimation6);
      temp.Status = 'Processing';
      setStepsAnimation6(temp);
    }

    if (num === 7) {
      const temp = getJSONFromState(stepsAnimation7);
      temp.Status = 'Processing';
      setStepsAnimation7(temp);
    }
  }

  function animateCompleteChoosingColors() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation1));
    temp.Status = 'Done';
    const colors = Object.keys(websiteJSON.appearance.properties.colors.light).map(key => {
      return websiteJSON.appearance.properties.colors.light[key]
    });
    temp.Value = colors;
    console.log(temp);
    setStepsAnimation1(temp)
    setProcessing(2)
  }

  function animateCompleteChoosingFonts() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation2));
    temp.Status = 'Done';
    const fontName = websiteJSON.appearance.properties.font.fontName;
    temp.Value = [fontName];
    setStepsAnimation2(temp);
    setProcessing(3)
  }

  function animateCompleteGeneratingLogo() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation3));
    temp.Status = 'Done';
    const image = websiteJSON.blocks.SM_HEAD1.items[0].image[0].src;
    temp.Value = [image];
    setStepsAnimation3(temp);
    setProcessing(4)
  }

  function animateCompleteCreatingPages() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation4));
    temp.Status = 'Done';
    const pages = websiteJSON.appearance.navigation.header.map((page) => {
      return page.title
    });
    temp.Value = pages;
    setStepsAnimation4(temp);
    setProcessing(5)
  }

  function animateCompleteSelectingBlocks() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation5));
    temp.Status = 'Done';
    temp.Value = ['Header', 'Footer', 'Info'];
    setStepsAnimation5(temp);
    setProcessing(6)
  }

  function animateCompleteGeneratingContents() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation6));
    temp.Status = 'Done';
    temp.Value = ['Done'];
    setStepsAnimation6(temp);
    setProcessing(7)
  }

  function animateCompletePublishingWebsite() {
    const temp = JSON.parse(JSON.stringify(stepsAnimation7));
    temp.Status = 'Done';
    temp.Value = ['Published'];
    setStepsAnimation7(temp);
    setTimeout(() => {
      setActiveSections('Published');
    }, 2000)
  }

  useEffect(() => {
    if (websiteJSON !== null) {
      setTimeout(() => {
        animateCompleteChoosingColors();
      }, 3000);

      setTimeout(() => {
        animateCompleteChoosingFonts();
      }, 6000);

      setTimeout(() => {
        animateCompleteGeneratingLogo();
      }, 9000);

      setTimeout(() => {
        animateCompleteCreatingPages();
      }, 15000);

      setTimeout(() => {
        animateCompleteSelectingBlocks();
      }, 21000);

      setTimeout(() => {
        animateCompleteGeneratingContents();
      }, 27000);

      setTimeout(() => {
        animateCompletePublishingWebsite();
      }, 30000);
    }
  }, [websiteJSON]);

  return (
    <div id="appCapsule" className="pt-0 pb-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5  mt-4">
            <div className="image-logo justify-content-start d-flex mb-4 ps-5">
              <img class="logo-img" src={Logo} />
            </div>
            <div>

              <div class="form-group boxed ps-5 BuildingWebsite">
                <div class="input-wrapper">
                  <h1 class="authenticate-phone pt-3 mb-0 mb-lg-5 pb-3 pb-lg-5">Building your Website <br />using AI</h1>
                </div>
                <div className='d-none d-lg-block'>
                  {
                    stepsAnimation1.Status === 'Processing' &&
                    <>
                      {
                        websiteJSON === null ?
                          <ColorsSelectoinAnimation color={['#ffff00', '#cccccc', '#f1f1f1', '#000000']} />
                          :
                          <ColorsSelectoinAnimation color={Object.keys(websiteJSON.appearance.properties.colors.light).map(key => {
                            return websiteJSON.appearance.properties.colors.light[key]
                          })} />
                      }
                    </>
                  }

                  {
                    stepsAnimation2.Status === 'Processing' &&
                    <FontsAnimation />
                  }
                </div>
              </div>
            </div>

          </div>


          <div className="col-md-7 position-relative only-map ">
            <div class="building-website row py-md-5 BuildingWebsite">

              <StepsComponents step={stepsAnimation1} />
              <StepsComponents step={stepsAnimation2} />
              <StepsComponents step={stepsAnimation3} />
              <StepsComponents step={stepsAnimation4} />
              <StepsComponents step={stepsAnimation5} />
              <StepsComponents step={stepsAnimation6} />
              <StepsComponents step={stepsAnimation7} />

              {/* <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1">
                    <div class="spinner-border text-primary col-md-3 me-2" role="status"></div>
                  </div>
                  <div class="col-6 col-md-3">Choosing Colors</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 square-1"></div>
                    <div class="col-md-3 square-2"></div>
                    <div class="col-md-3 square-3"></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class=" col-2 col-md-1">
                    <div class="round-filled-circle">
                    </div>
                  </div>
                  <div class="col-6 col-md-3">Selecting Fonts</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <span class="badge bg-primary font-badge w-75">Font</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1"><div class="spinner-border text-primary col-md-3 me-2" role="status"></div></div>
                  <div class="col-6 col-md-3">Genrating Logo</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 square-1"></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1">
                    <div class="me-2" role="status"><ion-icon name="checkmark-circle" class="checkmark-circle-sign"></ion-icon></div>
                  </div>
                  <div class="col-8 col-md-3">Creating Pages</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 me-2">
                      <span class="badge rounded-pill bg-primary font-badge">Page-1</span>
                    </div>
                    <div class="col-md-3 me-2">
                      <span class="badge rounded-pill bg-primary font-badge">Page-2</span>
                    </div>
                    <div class="col-md-3">
                      <span class="badge rounded-pill bg-primary font-badge">Page-3</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1"><div class="spinner-border text-primary col-md-3 me-2" role="status"></div></div>
                  <div class="col-6 col-md-3">Selecting Blocks</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 square-1"></div>
                    <div class="col-md-3 square-2"></div>
                    <div class="col-md-3 square-3"></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1"><div class="spinner-border text-primary col-md-3 me-2" role="status"></div></div>
                  <div class="col-6 col-md-3">Genrating Contents</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 square-1"></div>
                    <div class="col-md-3 square-2"></div>
                    <div class="col-md-3 square-3"></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
                <div class="row w-100">
                  <div class="col-2 col-md-1"><div class="spinner-border text-primary col-md-3 me-2" role="status"></div></div>
                  <div class="col-6 col-md-3">Publishing Website</div>
                  <div class="col-4 col-md-5 d-flex color-boxes-inner">
                    <div class="col-md-3 square-1"></div>
                    <div class="col-md-3 square-2"></div>
                    <div class="col-md-3 square-3"></div>
                  </div>
                </div>
              </div> */}

            </div>

            <div className="shape shape-start shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"></path></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export function StepsComponents({ step }) {
  return (
    <div class="col-lg-10 choosing-color d-flex justify-content-center pt-5 ms-5 ps-5">
      <div class="row w-100 align-items-center">
        <div class="col-2 col-md-1" title={step.Status}>
          {
            (step.Status === 'Processing') &&
            <div class="spinner-border text-primary col-md-3 me-2" role="status"></div>
          }
          {
            (step.Status === 'Waiting') &&
            <div class="me-2" role="status"><ion-icon name="hourglass-outline" class="checkmark-circle-sign"></ion-icon></div>
          }
          {
            step.Status === 'Done' &&
            <div class="me-2" role="status"><ion-icon name="checkmark-circle" class="checkmark-circle-sign"></ion-icon></div>
          }
          {
            step.Status === 'Cancel' &&
            <div class="me-2" role="status"><ion-icon name="close-circle-outline"></ion-icon></div>
          }
        </div>
        <div class="col-6 col-md-3">{step.Title}</div>

        {
          (step.Type === 'Color') &&
          <>
            {
              step.Value.length > 0 ?
                <div class="col-4 col-md-5 d-flex color-boxes-inner">
                  {
                    step.Value.map((color, j) => {
                      return <>
                        {
                          j < 4 &&
                          <div class="col-md-3 square-1" key={j} style={{ backgroundColor: color, '--delay': j * 1000 }} />
                        }
                      </>
                    })
                  }
                </div>
                :
                <div class="col-4 col-md-5 d-flex ">
                  <h4 className="text-white">Selecting Colors</h4>
                </div>
            }
          </>
        }

        {
          (step.Type === 'Outline') &&
          <>
            {
              step.Value.length > 0 ?
                <div class="col-4 col-md-5 d-flex color-boxes-inner">
                  {
                    step.Value.map((val, j) => {
                      return (
                        <>
                          {
                            j < 3 &&
                            <div class="me-2" key={j} style={{ '--delay': j * 1000 }}>
                              <span class="badge rounded-pill bg-primary font-badge">
                                {val}
                              </span>
                            </div>
                          }
                        </>
                      )
                    })
                  }

                  {
                    step.Value.length > 3 &&
                    <div class="me-2">
                      <span class="badge rounded-pill bg-primary font-badge">
                        ...
                      </span>
                    </div>
                  }
                </div>
                :
                <div class="col-4 col-md-5 d-flex ">
                  <h4 className="text-white">Generating Data</h4>
                </div>
            }
          </>
        }

        {
          (step.Type === 'Image') &&
          <>
            {
              step.Value.length > 0 ?
                <div class="col-4 col-md-5 d-flex color-boxes-inner">
                  {
                    step.Value.map((val, j) => {
                      return (
                        <div class="col-md-3 me-2" key={j} style={{ '--delay': j * 1000 }}>
                          <img src={val} style={{ 'height': '40px', 'width': 'auto' }} />
                        </div>
                      )
                    })
                  }
                </div>
                :
                <div class="col-4 col-md-5 d-flex ">
                  <h4 className="text-white">Generating Data</h4>
                </div>
            }
          </>

        }
      </div>
    </div>
  )
}

export function ColorsSelectoinAnimation({ color }) {
  console.log(color);
  return (
    <>
      {/* <div className="colorSelection" style={{ background: `linear-gradient(90deg, ${color[0]} 0%, ${color[1]} 23%, ${color[2]} 49%, ${color[3]} 73%)`}}> */}
      <div className="colorSelection" style={{ '--color1': color[0], '--color2': color[1], '--color3': color[2], '--color4': color[3] }} />
    </>
  )
}

export function FontsAnimation() {
  return (
    <>
      <div className="fontLinesContainer">
        <div className="d-flex fontLines fontLine1">
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Sans-Serif
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Railway
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Arial
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Helvetica
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Garamond
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Calibri
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Futura
          </div>
        </div>

        <div className="d-flex fontLines fontLine2">
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Sans-Serif
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Railway
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Arial
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Helvetica
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Garamond
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Calibri
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Futura
          </div>
        </div>

        <div className="d-flex fontLines fontLine3">
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Sans-Serif
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Railway
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Arial
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Helvetica
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Garamond
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Calibri
          </div>
          <div class="col-md-3 badge bg-primary me-2 font-badge w-25">
            Futura
          </div>
        </div>
      </div>
    </>
  )
}

export default BuildingWebsite;