import React, { useState, useEffect } from 'react'

function FormCode( { formtTitle, items, forms }) {
    const [formFields, setFormFields] = useState([]);
    const [formProps, setFormProps] = useState(null);
    useEffect(() => {
        if (items !== undefined && items.length > 0 && Object.keys(forms).length > 0) {
            let formID = '';
            const f1 = items.filter(item => item.form !== '');
            formID = f1.length > 0 ? f1[0].form.split('.')[1] : '';
            
            if(formID !== '') {
                setFormFields(forms[formID].items);
                // console.log(forms[formID], formID);
                setFormProps(forms[formID].properties);
            }
        }
    }, [items, forms])
    return (
        <div>
            <form>
                <div className="row">
                    <h3 dangerouslySetInnerHTML={{ __html: formtTitle }}></h3>
                </div>
                <div className='row'>
                    {formFields.map(value => {
                        return <>
                            <div className={`col-md-${value.width}`}>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <label className="form-label" htmlFor="name5">{value.label} {value.required && <span className='h6'>*</span>}</label>
                                        <input type={'text'} className="form-control" id="name5" placeholder={value.placeholder}
                                            autoComplete="off" readOnly />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                </div>
                {
                    formProps !== null &&
                    <div className='row'>
                        <div className='col-md-12'>
                            <button type='button' className={`btn ${formProps.submitBtnStyle}`}>{formProps.submitBtnTxt}</button>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}

export default FormCode