import React, { useState } from 'react'

const DnsContact = ({
    
}) => {
    const [activeTab, setActiveTab] = useState('registrant')
    const handleDnsRecordSwitch = (data) => {
        setActiveTab(data)
    }
    return (
        <>
            <div className="form-group boxed">
                <div className="input-wrapper">
                    <label className="form-label" htmlFor="dnsManage">Select Record</label>
                    <select className="form-control form-select" onChange={(e) => {handleDnsRecordSwitch(e.target.value)}} id="dnsManage">
                        <option value="registrant">Registrant</option>
                        <option value="administrative">Administrative</option>
                        <option value="billing">Billing</option>
                        <option value="technical">Technical</option>
                    </select>
                </div>
            </div>
            <div>
                <div className="tab-content mt-2">
                    <div className={(activeTab === 'registrant') ? 'tab-pane fade show active' : 'tab-pane fade'} id="registrant" role="tabpanel">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="name5">Name</label>
                                            <input type="text" className="form-control" id="name5" placeholder="Enter your name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="organization5">Organization</label>
                                            <input type="text" className="form-control" id="organization5" placeholder="Enter your organization name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="email5">E-mail</label>
                                            <input type="email" className="form-control" id="email5" placeholder="E-mail address"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Phone</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Enter your phone number" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="city5">City</label>
                                            <input type="text" className="form-control" id="city5" placeholder="Enter your city"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="country5">Country</label>
                                            <select className="form-control form-select" id="country5">
                                                <option value="0">India</option>
                                                <option value="1">USA</option>
                                                <option value="2">UK</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="zipCode5">Zip Code</label>
                                            <input type="tel" className="form-control" id="zipCode5" placeholder="Enter your zip code" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="address5">Address</label>
                                            <textarea id="address5" rows="6" className="form-control"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <button
                                    className="btn btn-block btn-success"
                                    type="submit"
                                    disabled={true}
                                    >
                                        <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={(activeTab === 'administrative') ? 'tab-pane fade show active' : 'tab-pane fade'} id="administrative" role="tabpanel">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="name5">Name</label>
                                            <input type="text" className="form-control" id="name5" placeholder="Enter your name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="organization5">Organization</label>
                                            <input type="text" className="form-control" id="organization5" placeholder="Enter your organization name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="email5">E-mail</label>
                                            <input type="email" className="form-control" id="email5" placeholder="E-mail address"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Phone</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Enter your phone number" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="city5">City</label>
                                            <input type="text" className="form-control" id="city5" placeholder="Enter your city"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="country5">Country</label>
                                            <select className="form-control form-select" id="country5">
                                                <option value="0">India</option>
                                                <option value="1">USA</option>
                                                <option value="2">UK</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="zipCode5">Zip Code</label>
                                            <input type="tel" className="form-control" id="zipCode5" placeholder="Enter your zip code" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="address5">Address</label>
                                            <textarea id="address5" rows="6" className="form-control"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <button
                                    className="btn btn-block btn-success"
                                    type="submit"
                                    disabled={true}
                                    >
                                        <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={(activeTab === 'billing') ? 'tab-pane fade show active' : 'tab-pane fade'} id="billing" role="tabpanel">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="name5">Name</label>
                                            <input type="text" className="form-control" id="name5" placeholder="Enter your name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="organization5">Organization</label>
                                            <input type="text" className="form-control" id="organization5" placeholder="Enter your organization name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="email5">E-mail</label>
                                            <input type="email" className="form-control" id="email5" placeholder="E-mail address"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Phone</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Enter your phone number" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="city5">City</label>
                                            <input type="text" className="form-control" id="city5" placeholder="Enter your city"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="country5">Country</label>
                                            <select className="form-control form-select" id="country5">
                                                <option value="0">India</option>
                                                <option value="1">USA</option>
                                                <option value="2">UK</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="zipCode5">Zip Code</label>
                                            <input type="tel" className="form-control" id="zipCode5" placeholder="Enter your zip code" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="address5">Address</label>
                                            <textarea id="address5" rows="6" className="form-control"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <button
                                    className="btn btn-block btn-success"
                                    type="submit"
                                    disabled={true}
                                    >
                                        <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={(activeTab === 'technical') ? 'tab-pane fade show active' : 'tab-pane fade'} id="technical" role="tabpanel">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="name5">Name</label>
                                            <input type="text" className="form-control" id="name5" placeholder="Enter your name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="organization5">Organization</label>
                                            <input type="text" className="form-control" id="organization5" placeholder="Enter your organization name"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="email5">E-mail</label>
                                            <input type="email" className="form-control" id="email5" placeholder="E-mail address"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Phone</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Enter your phone number" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="city5">City</label>
                                            <input type="text" className="form-control" id="city5" placeholder="Enter your city"
                                                autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="country5">Country</label>
                                            <select className="form-control form-select" id="country5">
                                                <option value="0">India</option>
                                                <option value="1">USA</option>
                                                <option value="2">UK</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="zipCode5">Zip Code</label>
                                            <input type="tel" className="form-control" id="zipCode5" placeholder="Enter your zip code" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="address5">Address</label>
                                            <textarea id="address5" rows="6" className="form-control"></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <button
                                    className="btn btn-block btn-success"
                                    type="submit"
                                    disabled={true}
                                    >
                                        <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DnsContact
