import React from 'react'

function ButtonDetailsOnEditItem({btn}) {
    return (
        <>
            {
                btn.buttonDetails ?
                    <>
                        {
                            btn.buttonDetails.Period &&
                            <div className='row'>
                                <div className='col-12'>
                                    <h5>Period</h5>
                                </div>
                                <div className='col-12 d-flex flex-wrap'>
                                    {
                                        Object.entries(btn.buttonDetails.Period).map(([key, value]) => (
                                            <div className="badge bg-primary me-1 mb-1 px-2" title={key}>
                                                {value}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {
                            btn.buttonDetails.Pricing &&
                            <div className='row mt-1'>
                                <div className='col-12'>
                                    <h5>Pricing</h5>
                                </div>
                                <div className='col-12 d-flex flex-wrap'>
                                    {
                                        Object.entries(btn.buttonDetails.Pricing).map(([key, value]) => (
                                            <div className="badge bg-primary me-1 mb-1 px-2" title={key}>
                                                {value}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </>
                    :
                    <></>
            }
        </>
    )
}

export default ButtonDetailsOnEditItem