import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_TSML_DFL1 = ({
    items= [],
    properties
}) => {
    return (
        <div>
            <div className="section mb-2" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                <div className="card comment-box pt-2 pb-2">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators webweb-carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            {/* {items.map(item => ( */}
                                <div className="carousel-item active">
                                    <img src={items[0].image[0].src} alt={items[0].image[0].alt} className="imaged w64 rounded" style={cssConverter(items.imageCss)} />
                                    <h4 className="card-title">{items[0].title}</h4>
                                    <div className="card-text"><MDEditor.Markdown source={items[0].subtitle} linkTarget="_blank" style={cssConverter(items.subtitleCss)} /></div>
                                    <div className="text">
                                        <MDEditor.Markdown source={items[0].description} linkTarget="_blank" style={cssConverter(items.descriptionCss)} />
                                    </div>
                                </div>
                            {/* ))} */}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span aria-hidden="true">
                                <ion-icon name="chevron-back-outline" className="text-secondary fs-1"></ion-icon>
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span aria-hidden="true">
                                <ion-icon name="chevron-forward-outline" className="text-secondary fs-1"></ion-icon>
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CN_TSML_DFL1
