import React from 'react'
import ToolBar from '../Elements/Management/ToolBar'

function MarqueeBlock(props) {
    return (
        <div>
            <ToolBar />
            <div className="section full shadow">
                <p className="bg-primary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet leo eget maximus ultricies.</p>
            </div>
        </div>
    )
}

export default MarqueeBlock
