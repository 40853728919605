import React, { useState, useEffect } from 'react'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'

function ReportPage(props) {

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const website = tokenData.SelectedDomain.Domain
    const website_ID = tokenData.SelectedDomain.Website_ID

    const today = new Date()
    const numberOfDaysToMinus = 30
    const fdate = today.setDate(today.getDate() - numberOfDaysToMinus);
    const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

    const todayDate = new Date();
    const tdate = todayDate.setDate(todayDate.getDate());
    const defaultToDate  = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

    let [fromDate, setFromDate] = useState(defaultFromDate)
    let [toDate, setToDate] = useState(defaultToDate)
    const [hitsByPages, setHitsByPages] = useState([])

    const handleFromDateChange = ev => setFromDate(ev.target.value);
    const handleToDateChange = ev => setToDate(ev.target.value);

    const getData = () => {
        axios.get('/analytics/hitsByPages/'+ website +'/'+ fromDate +'/' + toDate).then(res=>{

            setHitsByPages(res.data.data)
        })
    }

    useEffect(() => {
        getData()
    }, [fromDate, toDate])

    return (
        <div className="section mt-2 card-body">
            <div className="row mb-2">
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">From</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={fromDate} onChange={handleFromDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">To</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off"  defaultValue={toDate} onChange={handleToDateChange}/>
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wide-block p-0">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Url</th>
                                <th scope="col">Total Hits</th>
                                <th scope="col">Unique Hits</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hitsByPages.map((user) => (
                                <tr>
                                    <td>{user.Url}</td>
                                    <td>{user.hits}</td>
                                    <td>{user.uniqueHits}</td>
                                </tr>
                            ))}
                            { hitsByPages.length === 0 && 
                                <div className="text-center text-danger">No Data Found</div>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ReportPage
