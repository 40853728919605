import React from 'react'

function SM_CRER_DFL1(props) {
    return (
        <div>
            Smart Career Page
        </div>
    )
}

export default SM_CRER_DFL1
