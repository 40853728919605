import React from 'react'

function MarketPlace(props) {
    return (
        <div id="appCapsule" className="container">
            <div className="section mt-2 mb-2 user-post">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <div className="float-left">
                                <a href="!#" className="text-black">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded me-05" />
                                    सौरभ कर्वे
                                </a>
                            </div> 
                            <div className="text-muted float-right">
                                5 mins ago
                            </div>
                        </div>
                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="avatar" className="imaged w-100" />
                        <footer className="row mt-1">
                            <div className="col-8">
                                <button className="btn btn-sm me-2"><ion-icon name="thumbs-up-outline"></ion-icon>52</button>
                                <button className="btn btn-sm"><ion-icon name="thumbs-down-outline"></ion-icon>3</button>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-sm"><ion-icon name="share-social-outline"></ion-icon></button>
                            </div>
                        </footer>
                        <div className="divider mt-2 mb-2"></div>
                    </div>
                    <div className="col-12">
                        <div className="section-title">
                            <div className="float-left">
                                <a href="!#" className="text-black">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded me-05" />
                                    सौरभ कर्वे
                                </a>
                            </div> 
                            <div className="text-muted float-right">
                                5 mins ago
                            </div>
                        </div>
                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="avatar" className="imaged w-100" />
                        <footer className="row mt-1">
                            <div className="col-8">
                                <button className="btn btn-sm me-2"><ion-icon name="thumbs-up-outline"></ion-icon>52</button>
                                <button className="btn btn-sm"><ion-icon name="thumbs-down-outline"></ion-icon>3</button>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-sm"><ion-icon name="share-social-outline"></ion-icon></button>
                            </div>
                        </footer>
                        <div className="divider mt-2 mb-2"></div>
                    </div>
                    <div className="col-12">
                        <div className="section-title">
                            <div className="float-left">
                                <a href="!#" className="text-black">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded me-05" />
                                    सौरभ कर्वे
                                </a>
                            </div> 
                            <div className="text-muted float-right">
                                5 mins ago
                            </div>
                        </div>
                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="avatar" className="imaged w-100" />
                        <footer className="row mt-1">
                            <div className="col-8">
                                <button className="btn btn-sm me-2"><ion-icon name="thumbs-up-outline"></ion-icon>52</button>
                                <button className="btn btn-sm"><ion-icon name="thumbs-down-outline"></ion-icon>3</button>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-sm"><ion-icon name="share-social-outline"></ion-icon></button>
                            </div>
                        </footer>
                        <div className="divider mt-2 mb-2"></div>
                    </div>
                    <div className="col-12">
                        <div className="section-title">
                            <div className="float-left">
                                <a href="!#" className="text-black">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded me-05" />
                                    सौरभ कर्वे
                                </a>
                            </div> 
                            <div className="text-muted float-right">
                                5 mins ago
                            </div>
                        </div>
                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="avatar" className="imaged w-100" />
                        <footer className="row mt-1">
                            <div className="col-8">
                                <button className="btn btn-sm me-2"><ion-icon name="thumbs-up-outline"></ion-icon>52</button>
                                <button className="btn btn-sm"><ion-icon name="thumbs-down-outline"></ion-icon>3</button>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-sm"><ion-icon name="share-social-outline"></ion-icon></button>
                            </div>
                        </footer>
                        <div className="divider mt-2 mb-2"></div>
                    </div>
                    <div className="col-12">
                        <div className="section-title">
                            <div className="float-left">
                                <a href="!#" className="text-black">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="imaged w24 rounded me-05" />
                                    सौरभ कर्वे
                                </a>
                            </div> 
                            <div className="text-muted float-right">
                                5 mins ago
                            </div>
                        </div>
                        <img src="assets/img/sample/avatar/avatar8.jpg" alt="avatar" className="imaged w-100" />
                        <footer className="row mt-1">
                            <div className="col-8">
                                <button className="btn btn-sm me-2"><ion-icon name="thumbs-up-outline"></ion-icon>52</button>
                                <button className="btn btn-sm"><ion-icon name="thumbs-down-outline"></ion-icon>3</button>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-sm"><ion-icon name="share-social-outline"></ion-icon></button>
                            </div>
                        </footer>
                        <div className="divider mt-2 mb-2"></div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MarketPlace
