import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import Add from '../../Elements/Modals/AllAddModal'
import Logo from '../../images/webweb.svg'
import { showSidebarToggle, showFooterActionSheet } from '../../../features/modal/offcanvasSlice'
import { setActiveMenu } from '../../../features/utilities/utilitiesSlice'
import FooterActionSheet from '../../Elements/Modals/FooterActionSheet'

const Footer = ({
    websiteDataAppearnace = {},
    websiteDataBlocks = {},
    showSidebarToggle,
    sidebarToggle,
    setActiveMenu,
    showFooterActionSheet
}) => {
    const [websiteLogo, setWebsiteLogo] = useState('')

    let navigate = useNavigate()
    const handleManageSite = () => {
        console.log(websiteDataAppearnace)
        navigate("/build?url=" + websiteDataAppearnace.properties.defaultPage)
    }

    useEffect(() => {
        if (websiteDataBlocks) {
            if (websiteDataBlocks.hasOwnProperty('SM_HEAD1')) {
                if (websiteDataBlocks['SM_HEAD1'].items.length > 0) {
                    if (websiteDataBlocks['SM_HEAD1'].items[0].image.length > 0) {
                        setWebsiteLogo(websiteDataBlocks['SM_HEAD1'].items[0].image[0].src)
                    }
                }
            }
        }
    }, [websiteDataBlocks])

    const handleSidebarToggle = () => {
        showSidebarToggle({sidebarToggle: !sidebarToggle})
    }

    const handleActiveMenu = (name) => {
        setActiveMenu({activeMenu: name});
        if(name === 'connect'){
            navigate("/forms");
        } else if(name === 'digitalMarketing'){
            navigate("/searchEngineOptimization");
        }
    }

    const handleWebsiteActionSheet = (sheetName) => {
        showFooterActionSheet({isFooterActionVisible: true, actionSheetOf: sheetName})
    }

    return (
        <>
            <div class="section full mt-2 custom-appBottomMenu">
                <div class="appBottomMenu">
                {/* custom-appBottomMenu */}
                    {/* <a href="#!" class="item text-secondary">
                        <div class="col" onClick={showSidebarOffcanvas}>
                            <ion-icon name="menu-outline" class="fs-4"></ion-icon>
                            <strong>Manage</strong>
                        </div>
                    </a> */}
                    {/* /visitorReport?report=hits */}
                    <Link to="/overview" class="item text-secondary">
                        <div class="col d-flex justify-content-center align-items-center flex-column">
                            {/* {websiteLogo !== '' &&
                                <div style={{height: '26px', width: '26px', backgroundImage: 'url('+ websiteLogo + ')', backgroundSize: 'contain', borderRadius: '100px'}}>
                                .
                                </div>
                            } */}
                            {/* {websiteLogo === '' && */}
                                <ion-icon name="home-outline" class="fs-4"></ion-icon>
                            {/* } */}
                            <strong>Home</strong>
                        </div>
                    </Link>
                    <a href="#!" class="item text-secondary" onClick={() => {handleWebsiteActionSheet('Website')}}>
                    {/* onClick={handleManageSite} */}
                        <div class="col">
                            {/* <img src={Logo} class="img-fluid" alt="Brand" width="26x26" /> */}
                            <ion-icon name="earth-outline" class="fs-4"></ion-icon>
                            <strong>Website</strong>
                        </div>
                    </a>
                    {/* <Link to="/forms" class="item">
                        <div class="col">
                            <ion-icon name="headset-outline"></ion-icon>
                            <strong>Enquiries</strong>
                        </div>
                    </Link> */}
                    <a href="#!" class="item" onClick={() => {handleWebsiteActionSheet('Digital Marketing')}}>
                        <div class="col">
                            <ion-icon name="radio-outline"></ion-icon>
                            <strong>Digital Marketing</strong>
                        </div>
                    </a>
                    <a href="#!" class="item" onClick={() => {handleWebsiteActionSheet('Customer')}}>
                        <div class="col">
                            {/* <ion-icon name="chatbubbles-outline"></ion-icon> */}
                            <ion-icon name="person-outline"></ion-icon>
                            <strong>Customer</strong>
                        </div>
                    </a>
                    {/* <a href="javascript:void(0)" onClick={handleSidebarToggle} class="item">
                        <div class="col">
                            <ion-icon name="menu-outline"></ion-icon>
                            <strong>Menu</strong>
                        </div>
                    </a> */}
                </div>
            </div>
            <Add />
            <FooterActionSheet />
        </>
    );
}

const mapStateToProps = ({ websiteData, offcanvas }) => {
    const {
        appearance: websiteDataAppearnace,
        blocks: websiteDataBlocks
      } = websiteData
      const {
        sidebarToggle
      } = offcanvas 
    return {
        websiteDataAppearnace,
        websiteDataBlocks,
        sidebarToggle
    }
  }
  
  const mapDispatchToProps = {
    showSidebarToggle,
    setActiveMenu,
    showFooterActionSheet
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)