import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Navbar } from 'react-bootstrap'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { showOffcanvas, showButtonModal, newButtonData, showSwitchModal } from '../../features/modal/offcanvasSlice'
import { setWebsiteData, cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'
import SM_HEAD_DFL1 from './SM_HEAD_DFL1'
import CTA from '../bitComponents/cta'

const icon = 'document-text-outline'

const SM_HEAD_DFL3 = ({
  items,
  websiteDataAppearance = { properties: {} },
  websiteDataBlocks,
  websiteDataData,
  blockID
}) => {

  const token = localStorage.getItem('token')
  const tokenData = (token) ? jwt_decode(token) : false
//   console.log(tokenData)
  let { properties: websiteDataAppearanceProperties, navigation } = websiteDataAppearance
//   console.log(navigation)
//   console.log(navigation['topHeader'])

  if(tokenData.userInfo.RoleID === 'Moderator') {
    const categoryConfig = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('category')))))
    navigation = JSON.parse(categoryConfig.StructureConfig).navigation
    // console.log(navigation)
  }

  return (
    <div id={blockID}>
        <div>
            <div className="d-none d-sm-block d-md-block d-lg-block mx-auto" style={{width: '90%'}}>
                <div className="row mt-1">
                    <div className="col-12 col-md-6 d-flex align-items-center text-secondary"  style={{height: '65px'}}>
                        {navigation['topHeader'] && navigation['topHeader'].map((value,key) => {
                            return <>
                                {key < 2 &&
                                <>
                                    {value['type'] === 'call' &&
                                        <CTA ctaIcon={'call-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'page' &&
                                        <CTA ctaIcon={'document-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'image' &&
                                        <CTA ctaIcon={'image-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'document' &&
                                        <CTA ctaIcon={'document-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'email' &&
                                        <CTA ctaIcon={'mail-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'whatsapp' &&
                                        <CTA ctaIcon={'logo-whatsapp'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'payment' &&
                                        <CTA ctaIcon={'wallet-outline'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                    }
                                    {value['type'] === 'external' &&
                                        <>
                                            {value['url'].includes('facebook') &&
                                                <CTA ctaIcon={'logo-facebook'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                            }
                                            {value['url'].includes('twitter') &&
                                                <CTA ctaIcon={'logo-twitter'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                            }
                                            {value['url'].includes('linkedin') &&
                                                <CTA ctaIcon={'logo-linkedin'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                            }
                                            {value['url'].includes('youtube') &&
                                                <CTA ctaIcon={'logo-youtube'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                            }
                                            {value['url'].includes('instagram') &&
                                                <CTA ctaIcon={'logo-instagram'} ctaTitle={value['title']} ctaUI={'roundButton'}></CTA>
                                            }
                                        </>
                                    }
                                </>
                            }
                            </>
                        })}
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end text-secondary"  style={{height: '65px'}}>
                    {navigation['topHeader'] && navigation['topHeader'].map((value,key) => {
                            return <>
                                {key > 1 &&
                                <>
                                    {value['type'] === 'call' &&
                                        <CTA ctaIcon={'call-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'page' &&
                                        <CTA ctaIcon={'document-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'image' &&
                                        <CTA ctaIcon={'image-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'document' &&
                                        <CTA ctaIcon={'document-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'email' &&
                                        <CTA ctaIcon={'mail-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'whatsapp' &&
                                        <CTA ctaIcon={'logo-whatsapp'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'payment' &&
                                        <CTA ctaIcon={'wallet-outline'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                    }
                                    {value['type'] === 'external' &&
                                        <>
                                            {value['url'].includes('facebook') &&
                                                <CTA ctaIcon={'logo-facebook'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                            }
                                            {value['url'].includes('twitter') &&
                                                <CTA ctaIcon={'logo-twitter'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                            }
                                            {value['url'].includes('linkedin') &&
                                                <CTA ctaIcon={'logo-linkedin'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                            }
                                            {value['url'].includes('youtube') &&
                                                <CTA ctaIcon={'logo-youtube'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                            }
                                            {value['url'].includes('instagram') &&
                                                <CTA ctaIcon={'logo-instagram'} ctaTitle={value['title']} ctaUI={'button'}></CTA>
                                            }
                                        </>
                                    }
                                </>
                            }
                            </>
                        })}
                    </div>
                </div>
            </div>
            <div className="webweb-header2">
                <SM_HEAD_DFL1 items={items} websiteDataAppearance={websiteDataAppearance = { properties: {} }} websiteDataBlocks={websiteDataBlocks} websiteDataData={websiteDataData} blockID={blockID}></SM_HEAD_DFL1>
            </div>
        </div>
      
    </div>
  )
}

const mapStateToProps = ({ websiteData }) => {
  const { 
    appearance: websiteDataAppearance,
    blocks: websiteDataBlocks,
    data: websiteDataData
  } = websiteData

  return {
    websiteDataAppearance,
    websiteDataData,
    websiteDataBlocks
  }
}

const mapDispatchToProps = { 
  showOffcanvas,
  setWebsiteData,
  showAppToast,
  showButtonModal,
  newButtonData,
  showSwitchModal
 }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SM_HEAD_DFL3)