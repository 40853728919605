import React from 'react'
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title } from '@uiw/react-md-editor'
import { Link } from 'react-router-dom'

const ManageEvent = () => {
    return (
        <div id="appCapsule" class="d-flex align-items-center justify-content-center">
            <div class="container mt-2">
                <Link to="/build?url=events">
                    <h6 class="d-flex text-secondary">
                        <ion-icon name="arrow-back-outline"></ion-icon>&nbsp;Back
                    </h6>
                </Link>
                <div class="card w-100">
                    <div class="card-body pt-0 pb-0">
                        <div class="card-title mt-2 mb-1 text-center">Event Title</div>
                        <div class="section full">
                            <div class="pb-1 pt-1">

                                <form>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="name1">Upload Image</label>
                                                </div>
                                            </div>
                                            <div class="custom-file-upload mb-1" id="fileUpload1">
                                                <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg" />
                                                <label for="fileuploadInput">
                                                    <span>
                                                        <strong>
                                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                                            <i>Tap to Upload</i>
                                                        </strong>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-8 col-md-8">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="title1">Title</label>
                                                    <input type="text" class="form-control" id="title1" placeholder="Enter your name" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="charges">Charges</label>
                                                    <input type="number" class="form-control" id="charges" placeholder="Charges" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="fromDate">From Date</label>
                                                    <input type="date" class="form-control" id="fromDate" placeholder="From Date" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="toDate">To Date</label>
                                                    <input type="date" class="form-control" id="toDate" placeholder="To Date" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="duration">Duration</label>
                                                    <input type="text" class="form-control" id="duration" placeholder="Duration" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-6">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="level">Difficulty Level</label>
                                                    <select class="form-control form-select" id="level">
                                                        <option value="easy">Easy</option>
                                                        <option value="medium">Medium</option>
                                                        <option value="difficult">Difficult</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="description">Description</label>
                                                </div>
                                            </div>
                                            <MDEditor height={200} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{backgroundColor: 'white',color: '#333333'}} />
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact1">Contact 1 (optional)</label>
                                                    <input type="text" class="form-control" id="contact1" placeholder="Contact 1" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact2">Contact 2 (optional)</label>
                                                    <input type="text" class="form-control" id="contact2" placeholder="Contact 2" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact3">Contact 3 (optional)</label>
                                                    <input type="text" class="form-control" id="contact3" placeholder="Contact 3" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 mt-2">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact3">Things To Carry</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 mb-1">
                                            <div class="row">
                                                <div class="col-12 col-md-4">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb1" />
                                                        <label class="form-check-label" for="customCheckb1">Shoes suitable for trekking & hiking with Socks</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb2" />
                                                        <label class="form-check-label" for="customCheckb2">Slippers / Floaters</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb3" />
                                                        <label class="form-check-label" for="customCheckb3">Comfortable and breathable clothes</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb4" />
                                                        <label class="form-check-label" for="customCheckb4">Towel & Small Napkin</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb5" />
                                                        <label class="form-check-label" for="customCheckb5">Bedding (Sleeping bag / blanket)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb6" />
                                                        <label class="form-check-label" for="customCheckb6">Warm clothes for the night</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb7" />
                                                        <label class="form-check-label" for="customCheckb7">Small backpack for carrying snacks & water</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb8" />
                                                        <label class="form-check-label" for="customCheckb8">Rucksack </label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb9" />
                                                        <label class="form-check-label" for="customCheckb9">Hat or cap to protect from the sun</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb10" />
                                                        <label class="form-check-label" for="customCheckb10">Sunscreen, Insect repellent</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb11" />
                                                        <label class="form-check-label" for="customCheckb11">Personal toiletries</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb12" />
                                                        <label class="form-check-label" for="customCheckb12">Personal Medicines (If any)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb13" />
                                                        <label class="form-check-label" for="customCheckb13">Headlamp or Torch with extra batteries</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb14" />
                                                        <label class="form-check-label" for="customCheckb14">Water bottles 1L x 2  (Avoid Single Use Bottle)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb15" />
                                                        <label class="form-check-label" for="customCheckb15">Water bottles 1L x 3 (Avoid Single Use Bottle)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb16" />
                                                        <label class="form-check-label" for="customCheckb16">Water bottles 1L x 4 (Avoid Single Use Bottle)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb17" />
                                                        <label class="form-check-label" for="customCheckb17">Dry Snacks (Biscuits, Cakes, Dry Fruits, etc.)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb18" />
                                                        <label class="form-check-label" for="customCheckb18">Packed Lunch / Dinner for the day</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb19" />
                                                        <label class="form-check-label" for="customCheckb19">Mobile, Camera, Sunglasses (Optional)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb20" />
                                                        <label class="form-check-label" for="customCheckb20">Plate, Spoon, Bowl</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb21" />
                                                        <label class="form-check-label" for="customCheckb21">Cup for Tea (Melamine/ Steel)</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb22" />
                                                        <label class="form-check-label" for="customCheckb22">Swimming Clothes</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb23" />
                                                        <label class="form-check-label" for="customCheckb23">Empty Tiffin Box</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb24" />
                                                        <label class="form-check-label" for="customCheckb24">Notebook & Pen</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb25" />
                                                        <label class="form-check-label" for="customCheckb16">Cycling Helmet</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb26" />
                                                        <label class="form-check-label" for="customCheckb26">A well-maintained bicycle suitable for the terrain</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="customCheckb27" />
                                                        <label class="form-check-label" for="customCheckb27">Spare tube, tire pump, and basic repair tools</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-12 col-md-12 mt-1">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact3">Inclusions</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 mb-2">
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion1" />
                                                        <label class="form-check-label" for="inclusion1">Guidance and support by experienced volunteers</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion2" />
                                                        <label class="form-check-label" for="inclusion2">Safety gear and equipment required for the activity</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion3" />
                                                        <label class="form-check-label" for="inclusion3">Food and accommodation (tent / open) as per the itinerary</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion4" />
                                                        <label class="form-check-label" for="inclusion4">Necessary permits and entry fees</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion5" />
                                                        <label class="form-check-label" for="inclusion5">Local Transportation to and from the starting point of the activity</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion6" />
                                                        <label class="form-check-label" for="inclusion6">Basic medical aid</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="inclusion7" />
                                                        <label class="form-check-label" for="inclusion7">Group Accident Insurance</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-12 col-md-12 mt-1">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="contact3">Exclusions</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 mb-2">
                                            <div class="row">
                                                <div class="col-12 col-md-12">
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="exclusions1" />
                                                        <label class="form-check-label" for="exclusions1">Personal expenses, such as snacks</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="exclusions2" />
                                                        <label class="form-check-label" for="exclusions2">Any additional expenses not mentioned in the itinerary</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="exclusions3" />
                                                        <label class="form-check-label" for="exclusions3">Transportation to and from the event location</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="exclusions4" />
                                                        <label class="form-check-label" for="exclusions4">Insurance for accidents and medical emergencies</label>
                                                    </div>
                                                    <div class="form-check mb-1">
                                                        <input type="checkbox" class="form-check-input" id="exclusions5" />
                                                        <label class="form-check-label" for="exclusions5">Any expenses incurred due to unforeseen circumstances, such as natural calamities or roadblocks.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {/* <div class="col-12 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="youtube">Youtube</label>
                                                    <input type="text" class="form-control" id="youtube" placeholder="Youtube Link" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="facebook">Facebook</label>
                                                    <input type="text" class="form-control" id="facebook" placeholder="Facebook Link" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="instagram">Instagram</label>
                                                    <input type="text" class="form-control" id="instagram" placeholder="Instagram Link" />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="col-12 col-md-12 text-center">
                                            <button class="btn btn-success w-50">+ Add Content</button>
                                        </div>
                                        <div class="col-12 col-md-12 text-center">
                                            <button class="btn btn-primary w-50">Update</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageEvent
