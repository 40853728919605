import React from 'react'
import { connect } from "react-redux"

const SM_DOMN_DFL1 = ({
    items = [],
    properties
}) => {
    return (
        <div>
            <div className="section wide-block" style={{backgroundColor: properties.backgroundValue}}>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-0">Search Domain Name</h2>
                        <p className="text-center mb-1">Find Perfect Domain name for your Business</p>
                        <div className="" style={{top:'56px',zIndex:200}}>
                            <div className="form-group boxed p-2">
                                <div className="input-wrapper">
                                    <div className="input-group form-group boxed p-0">
                                        <input type="text" className="form-control" placeholder="Search Domain" aria-label="Search Domain" aria-describedby="button-addon2" value="Search Domain" style={{height:'35px'}} readOnly />
                                        <button className="btn h-custom btn-success" type="button" id="button-addon2" style={{height:'auto !important'}} disabled>
                                            <ion-icon name="search-sharp" className="me-0"></ion-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {items.map((item,key) => {
                                return <div className="col-6 col-md-2 mb-1">
                                    <div className="card bg-warning text-center">
                                        <div className="card-body">
                                            <h5>{item.title}</h5>
                                            <h3><span className="text-light"></span>{item.subtitle}</h3>
                                        </div>
                                    </div>
                                </div>
                            })}
                            {/* <div className="col-6 col-md-2 mb-1">
                                <div className="card bg-warning text-center">
                                    <div className="card-body">
                                        <h5>.in</h5>
                                        <h3><span className="text-light">₹599</span> / year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 mb-1">
                                <div className="card bg-warning text-center">
                                    <div className="card-body">
                                        <h5>.net</h5>
                                        <h3><span className="text-light">₹799</span> / year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 mb-1">
                                <div className="card bg-warning text-center">
                                    <div className="card-body">
                                        <h5>.org</h5>
                                        <h3><span className="text-light">₹799</span> / year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 mb-1">
                                <div className="card bg-warning text-center">
                                    <div className="card-body">
                                        <h5>.co</h5>
                                        <h3><span className="text-light">₹999</span> / year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 mb-1">
                                <div className="card bg-warning text-center">
                                    <div className="card-body">
                                        <h5>.biz</h5>
                                        <h3><span className="text-light">₹699</span> / year</h3>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
  const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SM_DOMN_DFL1)
