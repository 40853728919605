import React, { useState } from 'react'
import BackgroundTab from './BackgroundTab'
import BlockTab from './BlockTab'
import ContentTab from './ContentTab'
import MediaUpdate from './MediaUpdate'
import StylesTab from './StylesTab'

const LayersTab = ({
    items = [],
    itemID,
    properties,
    blockID,
    setActiveOffcanvasChildTab,
    NewBlockType,
    ActiveOffcanvasChildTab,
    addForm
}) => {

    const [tabReturn, setTabReturn] = useState('');
    // console.log(ActiveOffcanvasChildTab);
    return (
        <div className="tab-pane fade show active" id="pilled" role="tabpanel">
            <div className="section full ms-1 me-1">
                {
                    tabReturn === '' ?
                        <>
                            <div className={(ActiveOffcanvasChildTab === 'Content' || (NewBlockType !== null && NewBlockType.BlockType !== 'CN')) ? '' : 'd-none'}>
                                <ContentTab addForm={addForm} setTabReturn={setTabReturn}></ContentTab>
                                {/* <div className='border-top mt-1' /> */}
                            </div>
                            <div className={(ActiveOffcanvasChildTab === 'Block' || (NewBlockType !== null && NewBlockType.BlockType !== 'CN')) ? '' : 'd-none'}>
                                <div className='container px-1'>
                                    <BlockTab setTabReturn={setTabReturn}></BlockTab>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-md-12 d-flex align-items-center">
                                            <button className='badge bg-primary cursor-pointer me-1 border-0' onClick={() => setTabReturn('')}>
                                                <ion-icon name="arrow-back"></ion-icon>
                                            </button>
                                            <h4 className="mb-0">{tabReturn}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StylesTab tabReturn={tabReturn} />
                        </>
                }
                {/* <div className="nav row" role="tablist">
                    <button className={`nav-link ${ActiveOffcanvasChildTab === 'Content' ? 'active' : '' } btn btn-sm btn-block btn-outline-primary col m-1 fs-10 ps-1 pe-1`} style={{height: '26px'}} onClick={() => setActiveOffcanvasChildTab('Content')}>
                        Content
                    </button>
                    <button className={`nav-link ${ActiveOffcanvasChildTab === 'Block' ? 'active' : '' } btn btn-sm btn-block btn-outline-primary col m-1 fs-10 ps-1 pe-1`} style={{height: '26px'}} role="tab" onClick={() => setActiveOffcanvasChildTab('Block')}>
                        Block
                    </button>
                    <button className={`nav-link ${ActiveOffcanvasChildTab === 'Background' ? 'active' : '' } btn btn-sm btn-block btn-outline-primary col m-1 fs-10 ps-1 pe-1`} style={{height: '26px'}} role="tab" onClick={() => setActiveOffcanvasChildTab('Background')}>
                        Background
                    </button>
                </div> */}
                {/* <div className="tab-content mt-2">
                    <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Content' ? 'show active' : ''}`} id="contentTab" role="tabpanel">
                    
                    </div> */}
                {/* items={items} itemID={itemID} properties={properties} blockID={blockID} */}
                {/* <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Block' ? 'show active' : ''}`} id="blockTab" role="tabpanel" style={{'overflowX': 'hidden'}}>
                    </div> */}
                {/* <div className={`tab-pane fade  ${ActiveOffcanvasChildTab === 'Background' ? 'show active' : ''}`} id="backgroundTab" role="tabpanel">
                        <BackgroundTab />
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default LayersTab
