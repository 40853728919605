import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import CardSelector from './Cards/CardSelector'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_LIST_CAR2 = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    websiteDataBlocks,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas,
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible: true, itemID: itemID, items: items, blockID: blockID, properties: properties, seq: seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID: itemID, items: items, blockID: blockID, properties: properties, seq: seq })
        navigate('/detailPage')
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
            '.'
        )[1]
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'Are your sure? You want to delete this content',
            triggerMessage: 'content',
            deleteID: contentName,
            deleteItemID: deleteItemID
        })
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    let cardWidth = 4;
    if (properties.countInRow === 2) {
        cardWidth = 6
    } else if (properties.countInRow === 3) {
        cardWidth = 4
    } else if (properties.countInRow === 4) {
        cardWidth = 3
    } else if (properties.countInRow === 6) {
        cardWidth = 2
    } else if (properties.countInRow === 1) {
        cardWidth = 12
    }
    console.log(properties.container)

    return (
        <div>
            <div className="section full" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: (properties && properties.background && properties.background.color && properties.background.color !== undefined) ? `${properties.background.color}` : properties.backgroundValue , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                <div class={(properties && properties.container === 'true') || properties.container === undefined || properties.container === '' ? 'container' : 'container-fluid'}>
                    <div className="row p-2 pt-4 pb-4">
                        {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                            <div className="row">
                                {properties.blockHeading !== '' &&
                                    <div className="col-12">
                                        <h3 className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockHeading }}  style={cssConverter(properties.blockHeadingCss)}/>
                                    </div>
                                }
                                {properties.blockDescription !== '' &&
                                    <div className="col-12">
                                        <p className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockDescription }}  style={cssConverter(properties.blockDescriptionCss)} />
                                    </div>
                                }
                            </div>
                        }
                        {tempItems && tempItems.map((item, key) => {
                            // const firstImage = (item && item.image && item.image.length > 0) ? item.image[0].src : ''
                            return (
                                <div className={(cardWidth) === '' ? `col-md-4` : `col-md-${cardWidth} col-6 pb-2`}>
                                    <CardSelector cardType={'CARD_DFL1'} item={item} handleNewEditItem={handleNewEditItem} handleDeleteContent={handleDeleteContent} key={key} keys={key} blockID={blockID} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isEditVisible
    } = offcanvas
    const {
        blocks: websiteDataBlocks
    } = websiteData
    return {
        editOffcanvasVisible: isEditVisible,
        websiteDataBlocks
    }
}

const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas,
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_CAR2)
