import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { showEditOffcanvas, showAddOffcanvas, showButtonModal, newButtonData, showDeleteModal, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import SampleImage from '../images/avatar3.jpg'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_LIST_TIME2 = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    showAddOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal,
    websiteDataBlocks, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible:true, itemID:itemID, items:items, blockID: blockID, properties:properties,seq:seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID:itemID, items:items, blockID: blockID, properties:properties, seq:seq })
        navigate('/detailPage')
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
          '.'
        )[1]
        showDeleteModal({
          isDeleteVisible: true,
          deleteMessage: 'Are your sure? You want to delete this content?',
          triggerMessage: 'content',
          deleteID: contentName,
          deleteItemID: deleteItemID
        })
    }

    return (
        <div style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                <div className="row pt-4">
                    {properties.blockHeading !== '' &&
                        <div className="col-12">
                            <h3 className="text-center" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                        </div>
                    }
                    {properties.blockDescription !== '' &&
                        <div className="col-12">
                        <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                        </div>
                    }
                </div>
            }
            <div className="section full">
                TIME 2
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
      isVisible
    } = offcanvas
    const { 
        blocks: websiteDataBlocks 
    } = websiteData
    return {
      offcanvasVisible: isVisible,
      websiteDataBlocks
    }
}
  
const mapDispatchToProps = {
    showEditOffcanvas,
    showAddOffcanvas,
    showButtonModal,
    newButtonData,
    showDeleteModal, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_TIME2)
