import React from 'react'

const SM_FORM_DFL1 = (props) => {
    console.log(props)
    return (
        <div>
           <div className="section full pt-2 pb-2 border bg-white">
                <div className="wide-block d-flex justify-content-center align-items-center border-0" style={{backgroundColor: props.properties.backgroundValue, height: '50px'}}>
                    <button className="btn btn-secondary btn-sm" disabled>Edit Form</button>
                </div>
            </div>
        </div>
    )
}

export default SM_FORM_DFL1
