import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_LIST_CARD = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    websiteDataBlocks,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas,
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible: true, itemID: itemID, items: items, blockID: blockID, properties: properties, seq: seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID: itemID, items: items, blockID: blockID, properties: properties, seq: seq })
        navigate('/detailPage')
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
            '.'
        )[1]
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'Are your sure? You want to delete this content',
            triggerMessage: 'content',
            deleteID: contentName,
            deleteItemID: deleteItemID
        })
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
            showButtonModal({
                isButtonModalVisible: true,
                buttonData: buttonData,
                buttonID: ID
            })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }
    return (
        <div>
            <div className="section full" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                <div class={(properties && properties.container === 'true') || (properties.container === undefined || properties.container === '') ? 'container' : 'container-fluid'}>
                    <div className="row p-2 pt-4 pb-4">
                        {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                            <div className="row">
                                {properties.blockHeading !== '' &&
                                    <div className="col-12">
                                        <h3 className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockHeading }}  style={cssConverter(properties.blockHeadingCss)}/>
                                    </div>
                                }
                                {properties.blockDescription !== '' &&
                                    <div className="col-12">
                                        <p className="text-center" dangerouslySetInnerHTML={{ __html: properties.blockDescription }}  style={cssConverter(properties.blockDescriptionCss)} />
                                    </div>
                                }
                            </div>
                        }
                        {tempItems && tempItems.map((item, key) => {
                            const firstImage = (item && item.image && item.image.length > 0) ? item.image[0].src : ''
                            return (<div className="col-md-3 col-6" key={key}>
                                <div className="card position-relative" style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                                    <img src={firstImage} className="card-img-top" alt="image" />
                                    <div className="card-body">
                                        <h5 className="card-title cursor-pointer" onClick={() => { handleBlockItemUpdate(key) }} dangerouslySetInnerHTML={{ __html: item.title }}  style={cssConverter(item.titleCss)}/>
                                        <span onClick={() => { handleBlockItemUpdate(key) }}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="card-text cursor-pointer bg-transparent"  style={cssConverter(item.subtitleCss)}/></span>
                                        <span onClick={() => { handleBlockItemUpdate(key) }}><MDEditor.Markdown source={item.description} linkTarget="_blank" className="card-text cursor-pointer bg-transparent" onClick={() => { handleBlockItemUpdate(key) }} style={cssConverter(item.descriptionCss)} /></span>
                                        <div className="mt-2">
                                            {item.button.map((value, key) => {
                                                return <button className={`btn btn-sm me-1 ${value.style && value.style !== '' ? value.style : 'btn-primary'}`} key={key} onClick={() => handleShowButtonModal(item.button, key)} style={cssConverter(item.buttonCss)}>{value.title}</button>
                                            })}
                                        </div>
                                    </div>
                                    <div className='d-flex pos-absglr'>
                                        {/* <div className="col-4 col-md-4 text-end">
                                            <span className="badge bg-secondary p-1" style={{width: '55px', cursor: 'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                        </div> */}
                                        <div className="col-6 col-md-6 text-end">
                                            <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <span className="badge bg-danger p-1 editBtnCss" onClick={() => { handleDeleteContent(blockID, key) }}>Delete</span>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
                {/* <ul className="listview image-listview pt-3" style={{backgroundColor: properties.backgroundValue}}>
                    {tempItems && tempItems.map((item, key) => {
                        const firstImage = (item && item.image && item.image.length > 0) ? item.image[0].src : ''  
                        return (<li className="custom-line" onClick={() => {handleBlockItemUpdate(key)}} style={{cursor:'pointer'}}>
                            <a href="javascript:void(0)" className="item">
                                <img src={firstImage} alt='avtar' className="image rounded custom-cart-image" onClick={(e) => {handleDetailInfo(e, key)}}/>
                                <div className="in">
                                    <div>
                                        {item.title}
                                    </div>
                                    <span className="text-muted">
                                        {item.button.map(value => {
                                            return <button className="btn btn-primary btn-sm me-1" disabled>{value.title}</button>
                                        })}
                                        Edit
                                    </span>
                                </div>
                            </a>
                            <p className="ms-2 pb-2">
                                {item.subtitle}
                            </p>
                        </li>)
                    })}
                </ul> */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isEditVisible
    } = offcanvas
    const {
        blocks: websiteDataBlocks
    } = websiteData
    return {
        editOffcanvasVisible: isEditVisible,
        websiteDataBlocks
    }
}

const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas,
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_CARD)
