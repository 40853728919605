import React from 'react'

function SM_MARQ_DFL1(props) {
    return (
        <div>
            Smart Marquee 
        </div>
    )
}

export default SM_MARQ_DFL1
