import React, { useState } from 'react'
import { connect } from "react-redux"
import { setResponsiveIframe } from '../../../features/modal/offcanvasSlice'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'

function PreviewFooter({
    setResponsiveIframe
}) {
    const [launchValue, setLaunchValue] = useState()
    const [activeDesktop, setActiveDesktop] = useState(true)
    const [activeMobile, setActiveMobile] = useState(false)

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const handlePreviewMobile = (e) => {
        e.preventDefault()
        setResponsiveIframe({isResponsive: true})
        document.getElementById("dynamicIframe").style.width = "35%"
        setActiveMobile(true)
        setActiveDesktop(false)
    }
    
    const handlePreviewDesktop = (e) => {
        e.preventDefault()
        document.getElementById("dynamicIframe").style.width = "100%"
        setActiveDesktop(true)
        setActiveMobile(false)
        setResponsiveIframe({isResponsive: false})
    }

    const handleLaunchSite = (e) => {
        e.preventDefault()
        console.log('Site Launched')
        setLaunchValue(true)
        axios.get('/website/publishWebsite/'+ selectedDomain).then(res=>{
            setResponsiveIframe({runPartyParticals: true})
            setLaunchValue(false)
        })
        
    }

    return (
        <div class="section full mt-2">
            <div class="appBottomMenu">
                <a href="#!" className={(activeDesktop) ? 'item active' : 'item'} onClick={handlePreviewDesktop}>
                    <div class="col">
                        <ion-icon name="desktop-outline"></ion-icon>
                        <strong>Desktop</strong>
                    </div>
                </a>
                {!launchValue &&
                    <a href="!#" class="item" onClick={handleLaunchSite}>
                        <div class="col" style={{paddingBottom:'12px'}}>
                            <div class="action-button large">
                                <ion-icon name="rocket-outline" style={{transform:'rotate(-43deg)'}}></ion-icon>
                            </div>
                            <strong>Launch</strong>
                        </div>
                    </a>
                }
                {launchValue &&
                    <button class="btn btn-outline-muted border-0" type="button" disabled>
                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                        Publishing...
                    </button>
                }
                <a href="#!" className={activeMobile ? 'item active' : 'item'} onClick={handlePreviewMobile}>
                    <div class="col">
                        <ion-icon name="phone-portrait-outline"></ion-icon>
                        <strong>Mobile</strong>
                    </div>
                </a>
            </div>
        </div>
    );
}

const mapStateToProps = () => {
    return {}
  }
  
  const mapDispatchToProps = {
      setResponsiveIframe
  }

export default connect(mapStateToProps, mapDispatchToProps)(PreviewFooter)