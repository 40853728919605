import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import jwt_decode from 'jwt-decode';
import StyleEditor from 'react-style-editor';
import BlockLayout from './BlockLayout';
import ManageProperties from './ManageProperties';
import FormProperties from './FormProperties';
import { setMainBlockProps, setContentBlockItem, setFlagsToCheckChanges, setShowHtmlJsCssModal } from '../../../features/modal/offcanvasSlice';
import BackgroundTab from './BackgroundTab';

const StylesTab = ({
    setActiveOffcanvasChildTab,
    ActiveOffcanvasChildTab,
    setMainBlockProps,
    MainBlockProps,
    setContentBlockItem,
    ContentBlockItem,
    isEditVisible,
    NewBlockType,
    FlagsToCheckChanges,
    setFlagsToCheckChanges,
    tabReturn,
    LayersActiveTab,
    setShowHtmlJsCssModal
}) => {
    const [mainblockStructureCssproperty, setMainBlockStructureCssProperty] = useState(null);
    const [mainblockHeadingCssproperty, setMainBlockHeadingCssProperty] = useState(null);
    const [mainblockDescriptionCssproperty, setMainBlockDescriptionCssProperty] = useState(null);
    const [mainTitleCssproperty, setMainTitleCssProperty] = useState(null);
    const [mainSubTitleCssproperty, setMainSubTitleCssProperty] = useState(null);
    const [mainDescriptionCssproperty, setMainDescriptionCssProperty] = useState(null);
    const [mainImageCssproperty, setMainImageCssProperty] = useState(null);
    const [mainButtonCssproperty, setMainButtonCssProperty] = useState(null);
    const [blockContainer, setBlockContainer] = useState(true);

    const [itemStructureCssproperty, setItemStructureCssProperty] = useState(null);

    const [checkItemClicked, setCheckItemClicked] = useState(null);
    const getBlockProps = () => { return JSON.parse(JSON.stringify(MainBlockProps)) };
    const getItemProps = () => { return JSON.parse(JSON.stringify(ContentBlockItem)) };

    const changeFlagsToCheckChanges = (fieldName) => {
        const tempFlagsToCheckChanges = JSON.parse(JSON.stringify(FlagsToCheckChanges));
        if (tempFlagsToCheckChanges[fieldName] !== undefined && tempFlagsToCheckChanges[fieldName] === false) {
            tempFlagsToCheckChanges[fieldName] = true;
            setFlagsToCheckChanges(tempFlagsToCheckChanges);
        }
    }
    // console.log({FlagsToCheckChanges});
    useEffect(() => {
        if (MainBlockProps !== null && MainBlockProps.structureCss !== undefined && mainblockStructureCssproperty === null && isEditVisible === true) {
            setMainBlockStructureCssProperty(MainBlockProps.structureCss)
        } else if (MainBlockProps !== null && MainBlockProps.structureCss === undefined && mainblockStructureCssproperty === null && isEditVisible === true) {
            setMainBlockStructureCssProperty({});
        }

        if (MainBlockProps !== null && MainBlockProps.blockHeadingCss !== undefined && mainblockHeadingCssproperty === null && isEditVisible === true) {
            setMainBlockHeadingCssProperty(MainBlockProps.blockHeadingCss)
        } else if (MainBlockProps !== null && MainBlockProps.blockHeadingCss === undefined && mainblockHeadingCssproperty === null && isEditVisible === true) {
            setMainBlockHeadingCssProperty({});
        }

        if (MainBlockProps !== null && MainBlockProps.blockDescriptionCss !== undefined && mainblockHeadingCssproperty === null && isEditVisible === true) {
            setMainBlockDescriptionCssProperty(MainBlockProps.blockDescriptionCss)
        } else if (MainBlockProps !== null && MainBlockProps.blockDescriptionCss === undefined && mainblockHeadingCssproperty === null && isEditVisible === true) {
            setMainBlockDescriptionCssProperty({});
        }

        if (MainBlockProps !== null && MainBlockProps.container !== undefined && isEditVisible === true) {
            if (MainBlockProps.container === 'true' || MainBlockProps.container === true) {
                setBlockContainer(true);
            } else {
                setBlockContainer(false);
            }
        } else if (MainBlockProps !== null && MainBlockProps.container === undefined && isEditVisible === true) {
            setBlockContainer(true);
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            if (MainBlockProps !== null && MainBlockProps.titleCss !== undefined && mainTitleCssproperty === null && isEditVisible === true) {
                setMainTitleCssProperty(MainBlockProps.titleCss)
            } else if (MainBlockProps !== null && MainBlockProps.titleCss === undefined && mainTitleCssproperty === null && isEditVisible === true) {
                setMainTitleCssProperty({});
            }

            if (MainBlockProps !== null && MainBlockProps.subtitleCss !== undefined && mainSubTitleCssproperty === null && isEditVisible === true) {
                setMainSubTitleCssProperty(MainBlockProps.subtitleCss)
            } else if (MainBlockProps !== null && MainBlockProps.subtitleCss === undefined && mainSubTitleCssproperty === null && isEditVisible === true) {
                setMainSubTitleCssProperty({});
            }

            if (MainBlockProps !== null && MainBlockProps.descriptionCss !== undefined && mainDescriptionCssproperty === null && isEditVisible === true) {
                setMainDescriptionCssProperty(MainBlockProps.descriptionCss)
            } else if (MainBlockProps !== null && MainBlockProps.descriptionCss === undefined && mainDescriptionCssproperty === null && isEditVisible === true) {
                setMainDescriptionCssProperty({});
            }


            if (MainBlockProps !== null && MainBlockProps.buttonCss !== undefined && mainButtonCssproperty === null && isEditVisible === true) {
                setMainButtonCssProperty(MainBlockProps.buttonCss)
            } else if (MainBlockProps !== null && MainBlockProps.buttonCss === undefined && mainButtonCssproperty === null && isEditVisible === true) {
                setMainButtonCssProperty({});
            }


            if (MainBlockProps !== null && MainBlockProps.imageCss !== undefined && mainImageCssproperty === null && isEditVisible === true) {
                setMainImageCssProperty(MainBlockProps.imageCss)
            } else if (MainBlockProps !== null && MainBlockProps.imageCss === undefined && mainImageCssproperty === null && isEditVisible === true) {
                setMainImageCssProperty({});
            }
        }


    }, [MainBlockProps]);

    useEffect(() => {
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            if (ContentBlockItem !== null && ContentBlockItem.titleCss !== undefined && mainTitleCssproperty === null && isEditVisible === true) {
                setMainTitleCssProperty(typeof (ContentBlockItem.titleCss) === 'object' ? ContentBlockItem.titleCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.titleCss === undefined && mainTitleCssproperty === null && isEditVisible === true) {
                setMainTitleCssProperty({});
            }

            if (ContentBlockItem !== null && ContentBlockItem.subtitleCss !== undefined && mainSubTitleCssproperty === null && isEditVisible === true) {
                setMainSubTitleCssProperty(typeof (ContentBlockItem.subtitleCss) === 'object' ? ContentBlockItem.titleCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.subtitleCss === undefined && mainSubTitleCssproperty === null && isEditVisible === true) {
                setMainSubTitleCssProperty({});
            }

            if (ContentBlockItem !== null && ContentBlockItem.descriptionCss !== undefined && mainDescriptionCssproperty === null && isEditVisible === true) {
                setMainDescriptionCssProperty(typeof (ContentBlockItem.descriptionCss) === 'object' ? ContentBlockItem.titleCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.descriptionCss === undefined && mainDescriptionCssproperty === null && isEditVisible === true) {
                setMainDescriptionCssProperty({});
            }

            if (ContentBlockItem !== null && ContentBlockItem.buttonCss !== undefined && mainButtonCssproperty === null && isEditVisible === true) {
                setMainButtonCssProperty(typeof (ContentBlockItem.buttonCss) === 'object' ? ContentBlockItem.titleCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.buttonCss === undefined && mainButtonCssproperty === null && isEditVisible === true) {
                setMainButtonCssProperty({});
            }

            if (ContentBlockItem !== null && ContentBlockItem.imageCss !== undefined && mainImageCssproperty === null && isEditVisible === true) {
                setMainImageCssProperty(typeof (ContentBlockItem.imageCss) === 'object' ? ContentBlockItem.titleCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.imageCss === undefined && mainImageCssproperty === null && isEditVisible === true) {
                setMainImageCssProperty({});
            }

            if (ContentBlockItem !== null && ContentBlockItem.itemCss !== undefined && mainTitleCssproperty === null && isEditVisible === true) {
                console.log(typeof (ContentBlockItem.itemCss), Object.keys(ContentBlockItem.itemCss).length);
                setItemStructureCssProperty((typeof (ContentBlockItem.itemCss) === 'object' && Object.keys(ContentBlockItem.itemCss).length > 0) ? ContentBlockItem.itemCss : {})
            } else if (ContentBlockItem !== null && ContentBlockItem.itemCss === undefined && mainTitleCssproperty === null && isEditVisible === true) {
                setItemStructureCssProperty({});
            }

        }
    }, [ContentBlockItem, MainBlockProps])

    useEffect(() => {
        if (isEditVisible === false) {
            setMainBlockStructureCssProperty(null);
            setMainBlockHeadingCssProperty(null);
            setMainBlockDescriptionCssProperty(null);
            setMainTitleCssProperty(null);
            setMainSubTitleCssProperty(null);
            setMainDescriptionCssProperty(null);
            setMainImageCssProperty(null);
            setMainButtonCssProperty(null);
            setBlockContainer(true);
            setCheckItemClicked(null);
        }
        if (isEditVisible === true && ActiveOffcanvasChildTab === 'Content' && checkItemClicked === null) {
            setCheckItemClicked(true);
        }
    }, [isEditVisible])


    const changeContainer = (e) => {
        // setBlockContainer(e.target.checked);
        const blockData = getBlockProps();
        if (e.target.checked === true) {
            blockData.container = 'true';
        } else {
            blockData.container = 'false';
        }
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges('container');
    }

    const ChangedBlockStructureCssProps = (props, backup) => {
        const blockData = getBlockProps();
        // console.log(props, blockData.structureCss, backup);
        if ((blockData.structureCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
            blockData.structureCss = props;
            setMainBlockProps(blockData);
            changeFlagsToCheckChanges('structureCss');
        }

    }

    const ChangedBlockHeadingCssProps = (props) => {
        const blockData = getBlockProps();
        if ((blockData.blockHeadingCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
            blockData.blockHeadingCss = props;
            setMainBlockProps(blockData);
            changeFlagsToCheckChanges('blockHeadingCss');
        }
    }

    const ChangedBlockDescriptionCssProps = (props) => {
        const blockData = getBlockProps();
        if ((blockData.blockDescriptionCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
            blockData.blockDescriptionCss = props;
            setMainBlockProps(blockData);
            changeFlagsToCheckChanges('blockDescriptionCss');
        }
    }

    const ChangedTitleCssProps = (props) => {
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            const blockData = getBlockProps();
            if ((blockData.titleCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.titleCss = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('titleCss');
            }
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            const blockData = getItemProps();
            if ((blockData.titleCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.titleCss = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('titleCss');
            }
        }
    }

    const ChangedSubTitleCssProps = (props) => {
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            const blockData = getBlockProps();
            if ((blockData.subtitleCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.subtitleCss = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('subtitleCss');
            }
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            const blockData = getItemProps();
            if ((blockData.subtitleCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.subtitleCss = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('subtitleCss');
            }
        }
    }

    const ChangedDescriptionCssProps = (props) => {
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            const blockData = getBlockProps();
            if ((blockData.descriptionCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.descriptionCss = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('descriptionCss');
            }
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            const blockData = getItemProps();
            if ((blockData.descriptionCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.descriptionCss = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('descriptionCss');
            }
        }
    }

    const ChangedImageCssProps = (props) => {
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            const blockData = getBlockProps();
            if ((blockData.imageCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.imageCss = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('imageCss');
            }
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            const blockData = getItemProps();
            if ((blockData.imageCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.structureCss).length !== 0)) {
                blockData.imageCss = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('imageCss');
            }
        }
    }

    const ChangedButtonCssProps = (props) => {
        // console.log(props)
        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'SM') {
            const blockData = getBlockProps();
            if ((blockData.buttonCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.buttonCss).length !== 0)) {
                blockData.buttonCss = props;
                setMainBlockProps(blockData);
                changeFlagsToCheckChanges('buttonCss');
            }
        }

        if (MainBlockProps !== null && MainBlockProps.style.slice(0, 2) === 'CN') {
            const blockData = getItemProps();
            if ((blockData.buttonCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.buttonCss).length !== 0)) {
                blockData.buttonCss = props;
                setContentBlockItem(blockData);
                changeFlagsToCheckChanges('buttonCss');
            }
        }
    }

    const ChangedItemStructureCssProps = (props) => {
        const blockData = getItemProps();
        console.log(blockData);
        if ((blockData.itemCss !== JSON.stringify(props)) || (Object.keys(props).length !== 0 && Object.keys(blockData.itemCss).length !== 0)) {
            blockData.itemCss = props;
            setContentBlockItem(blockData);
            changeFlagsToCheckChanges('itemCss');
        }
    }

    const onBlockCssChange = (e) => {
        // console.log(e, typeof(e));
        const blockData = getBlockProps();
        blockData.css = e;
        setMainBlockProps(blockData);
        changeFlagsToCheckChanges('css');
    }
    
    const token = localStorage.getItem('token');
    const tokenData = (token) ? jwt_decode(token) : false;
    const RoleID = (tokenData !== undefined && tokenData !== null && tokenData !== false) ? tokenData.userInfo.RoleID : 'Customer';
    return (
        <div className="tab-pane fade show active" id="pilled" role="tabpanel">
            {
                tabReturn === 'Block Heading' &&
                <div className="section full ms-1 me-1">
                    {mainblockHeadingCssproperty !== null &&
                        <ManageProperties
                            id="1"
                            isEditVisible={isEditVisible}
                            propsObject={mainblockHeadingCssproperty}
                            fontPreivew
                            optionsList={['fontSize', 'fontColor', 'border', 'textAlignment', 'textTransform', 'textShadow', 'margin', 'padding']}
                            onChangeProps={ChangedBlockHeadingCssProps} />
                    }
                </div>
            }
            {
                tabReturn === 'Block Description' &&
                <div className="section full ms-1 me-1">
                    {mainblockDescriptionCssproperty !== null &&
                        <ManageProperties
                            id="2"
                            isEditVisible={isEditVisible}
                            propsObject={mainblockDescriptionCssproperty}
                            fontPreivew
                            optionsList={['fontSize', 'fontColor', 'border', 'textAlignment', 'textTransform', 'textShadow', 'margin', 'padding']}
                            onChangeProps={ChangedBlockDescriptionCssProps}
                        />
                    }
                </div>
            }
            {
                tabReturn === 'Title' &&
                <div className="section full ms-1 me-1">
                    {mainTitleCssproperty !== null &&
                        <ManageProperties
                            id="3"
                            isEditVisible={isEditVisible}
                            propsObject={mainTitleCssproperty}
                            fontPreivew
                            optionsList={['fontSize', 'fontColor', 'border', 'textAlignment', 'textTransform', 'textShadow', 'margin', 'padding']}
                            onChangeProps={ChangedTitleCssProps}
                        />
                    }
                </div>
            }

            {
                tabReturn === 'Subtitle' &&
                <div className="section full ms-1 me-1">
                    {mainSubTitleCssproperty !== null &&
                        <ManageProperties
                            id="4"
                            isEditVisible={isEditVisible}
                            propsObject={mainSubTitleCssproperty}
                            fontPreivew
                            optionsList={['fontSize', 'fontColor', 'border', 'textAlignment', 'textTransform', 'textShadow', 'margin', 'padding']}
                            onChangeProps={ChangedSubTitleCssProps}
                        />
                    }
                </div>
            }

            {
                tabReturn === 'Description' &&
                <div className="section full ms-1 me-1">
                    {mainDescriptionCssproperty !== null &&
                        <ManageProperties
                            id="5"
                            isEditVisible={isEditVisible}
                            propsObject={mainDescriptionCssproperty}
                            fontPreivew
                            optionsList={['fontSize', 'fontColor', 'border', 'textAlignment', 'textTransform', 'textShadow', 'margin', 'padding']}
                            onChangeProps={ChangedDescriptionCssProps}
                        />
                    }
                </div>
            }

            {
                tabReturn === 'Media' &&
                <div className="section full ms-1 me-1">
                    {mainImageCssproperty !== null &&
                        <ManageProperties
                            id="6"
                            isEditVisible={isEditVisible}
                            propsObject={mainImageCssproperty}
                            ImagePreivew
                            optionsList={['border', 'margin', 'padding']}
                            onChangeProps={ChangedImageCssProps} />
                    }
                </div>
            }

            {
                tabReturn === 'Buttons/Links' &&
                <div className="section full ms-1 me-1">
                    {mainButtonCssproperty !== null &&
                        <ManageProperties
                            id="7"
                            isEditVisible={isEditVisible}
                            propsObject={mainButtonCssproperty}
                            optionsList={['fontSize', 'fontColor', 'textTransform', 'border', 'margin', 'padding']}
                            onChangeProps={ChangedButtonCssProps} />
                    }
                </div>
            }

            {
                (tabReturn === undefined || tabReturn === false) &&
                <div className="section full ms-1 me-1">
                    <div className="nav justify-content-between px-0" role="tablist">

                        <button className={`${ActiveOffcanvasChildTab === 'Layout' ? 'active' : ''} btn btn-sm btn-outline-primary mb-0 fs-10 px-3`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Layout')}>
                            Layout
                        </button>
                        <button className={`${ActiveOffcanvasChildTab === 'Background' ? 'active' : ''} btn btn-sm btn-outline-primary mb-0 fs-10 px-3`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Background')}>
                            Background
                        </button>
                        <button className={`${ActiveOffcanvasChildTab === 'Structure' ? 'active' : ''} btn btn-sm btn-outline-primary mb-0 fs-10 px-3`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Structure')}>
                            Structure
                        </button>
                        {
                        (RoleID === 'Admin' || localStorage.getItem('nnj')) &&
                            <button className={`${ActiveOffcanvasChildTab === 'CSS' ? 'active' : ''} btn btn-sm btn-outline-primary mb-0 fs-10 px-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('CSS')}>
                                <ion-icon name="code-slash-outline" class="me-0"></ion-icon>
                            </button>
                        }
                        {/* <button className={`${ActiveOffcanvasChildTab === 'BlockHeading' ? 'active' : ''} btn btn-sm btn-outline-primary col-3 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('BlockHeading')}>
                                Block Heading
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'BlockDescription' ? 'active' : ''} btn btn-sm btn-outline-primary col-3 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('BlockDescription')}>
                                Block Description
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'Title' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Title')}>
                                Title
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'Subtitle' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Subtitle')}>
                                Subtitle
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'Description' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Description')}>
                                Description
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'Images' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Images')}>
                                Images
                            </button>
                            <button className={`${ActiveOffcanvasChildTab === 'Buttons' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Buttons')}>
                                Buttons
                            </button> */}
                        {/* <button className={`${ActiveOffcanvasChildTab === 'Forms' ? 'active' : ''} btn btn-sm btn-outline-primary col-4 mb-1 fs-10 ps-1 pe-1`} style={{ height: '26px' }} role="tab" onClick={() => setActiveOffcanvasChildTab('Forms')}>
                                Forms
                            </button> */}
                    </div>
                    <div className="tab-content mt-2">
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Layout' ? ' show active' : ''}`} id="layoutTab" role="tabpanel">
                            <BlockLayout checkItemClicked={checkItemClicked !== null && checkItemClicked === true && checkItemClicked}></BlockLayout>
                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Background' ? ' show active' : ''}`} id="BackgroundTab" role="tabpanel">
                            <BackgroundTab LayersActiveTab={LayersActiveTab} />
                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Structure' ? ' show active' : ''}`} id="structureTab" role="tabpanel">

                            {
                                (LayersActiveTab === 'Block' || (NewBlockType && NewBlockType.BlockType && NewBlockType.BlockType === 'SM')) ?
                                <>
                                    {mainblockStructureCssproperty !== null &&
                                        <>
                                            <div class="form-group basic mb-0 border-top">
                                                <div class="input-wrapper">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-check form-switch webweb-custom-switch-sm">
                                                                <label htmlFor="SwitchCheckDefaultContainer" style={{ paddingRight: '3px', fontSize: '11px' }}>Container Fluid</label>
                                                                <input class="form-check-input" type="checkbox" id="SwitchCheckDefaultContainer" onChange={(e) => changeContainer(e)} checked={blockContainer} />
                                                                <label class="form-check-label w-50" htmlFor="SwitchCheckDefaultContainer" style={{ paddingLeft: '48px', fontSize: '11px' }}>Container</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ManageProperties
                                                id="0"
                                                isEditVisible={isEditVisible}
                                                propsObject={mainblockStructureCssproperty}
                                                optionsList={['border', 'margin', 'padding']}
                                                onChangeProps={ChangedBlockStructureCssProps}
                                                className='mt-0' />
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {itemStructureCssproperty !== null &&
                                        <>
                                            <ManageProperties
                                                id="0"
                                                isEditVisible={isEditVisible}
                                                propsObject={itemStructureCssproperty}
                                                optionsList={['border', 'margin', 'padding']}
                                                onChangeProps={ChangedItemStructureCssProps}
                                                className='mt-0' />
                                        </>
                                    }
                                </>
                            }
                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'CSS' ? ' show active' : ''}`} id="CSSTab" role="tabpanel">
                            <div className="col-12 col-md-12">
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <div className="row">
                                            <div className="col-6 col-md-6 mb-2">
                                                <label className="form-label" htmlFor="v2blockDescription">Block Custom CSS</label>
                                            </div>
                                        </div>
                                        <StyleEditor value={MainBlockProps !== null && MainBlockProps.css} onChange={(e) => onBlockCssChange(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 d-flex gap-2">
                                {/* <button className='btn btn-primary btn-sm' onClick={() => setShowHtmlJsCssModal('CSS')}><ion-icon name="create-outline"></ion-icon> CSS</button> */}
                                <button className='btn btn-primary btn-sm' onClick={() => setShowHtmlJsCssModal('HTML')}><ion-icon name="create-outline"></ion-icon> HTML</button>
                                {/* <button className='btn btn-primary btn-sm' onClick={() => setShowHtmlJsCssModal('JS')}><ion-icon name="create-outline"></ion-icon> JS</button> */}
                            </div>
                        </div>
                        {/* <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'BlockHeading' ? ' show active' : ''}`} id="blockHeadingTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'BlockDescription' ? ' show active' : ''}`} id="blockDescriptionTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Title' ? ' show active' : ''}`} id="titleTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Subtitle' ? ' show active' : ''}`} id="subtitleTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Description' ? ' show active' : ''}`} id="descriptionTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Images' ? ' show active' : ''}`} id="imageTab" role="tabpanel">

                        </div>
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Buttons' ? ' show active' : ''}`} id="buttonTab" role="tabpanel">

                        </div> */}
                        <div className={`tab-pane fade ${ActiveOffcanvasChildTab === 'Forms' ? ' show active' : ''}`} id="formsTab" role="tabpanel">
                            <FormProperties></FormProperties>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ websiteData, offcanvas }) => {

    const {
        MainBlockProps,
        ContentBlockItem,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges
    } = offcanvas
    return {
        MainBlockProps,
        ContentBlockItem,
        isEditVisible,
        NewBlockType,
        FlagsToCheckChanges
    }
}

const mapDispatchToProps = {
    setMainBlockProps,
    setContentBlockItem,
    setFlagsToCheckChanges,
    setShowHtmlJsCssModal
}

export default connect(mapStateToProps, mapDispatchToProps)(StylesTab)
