import React from 'react'

function SM_VMEO_DFL1(props) {
    return (
        <div>
            Vimeo Player
        </div>
    )
}

export default SM_VMEO_DFL1
