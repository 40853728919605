import React, { useState, useEffect } from 'react';

const SelectComponent = ({ value, setValue, options, className }) => {
    const changeOption = (e) => {
        const getOption = options.filter(item => e.target.value === item.value)
        // console.log(e.target.value, getOption);
        setValue(getOption[0]);
    }
    return (
        <select className={`form-control form-select ${className}`} onChange={(e) => changeOption(e)}>
            {
                value !== null && value.value !== undefined && options.length === 0 &&
                <option value={value.value} selected>{value.label}</option>
            }
            {
                options.length !== 0 ?
                <>
                    {
                        options.map((items, i) => {
                            return <option value={items.value} selected={(value !== null && value.length !== 0) ? (items.value === value.value) : false} key={i}>{items.label}</option>
                        })
                    }
                </>
                :
                <option disabled selected>No options</option>
            }
        </select>
    )
}

export default SelectComponent