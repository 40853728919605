import React from 'react'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

function SM_TWCL_DFL1(props) {
    console.log(props)
    return (
        <div>
            <div className="section wide-block pt-4 pb-2" style={{backgroundColor: props.properties.backgroundValue}}>
                {(props.properties.blockHeading !== '' || props.properties.blockDescription !== '') &&
                    <div className="row">
                        {props.properties.blockHeading !== '' &&
                            <div className="col-12">
                                <h3 className="text-center" style={cssConverter(props.properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: props.properties.blockHeading }} />
                            </div>
                        }
                        {props.properties.blockDescription !== '' &&
                            <div className="col-12">
                            <p className="text-center" style={cssConverter(props.properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: props.properties.blockDescription }} />
                            </div>
                        }
                    </div>
                }
                {props.items[0].image[0].src !== '' &&
                    <div className="row p-1">
                        <div className="col-3 col-md-3">
                            <img src={props.items[0].image[0].src} style={cssConverter(props.properties.imageCss)} alt={props.items[0].image[0].alt} className="imaged w-100" />
                        </div>
                        <div className="col-9 col-md-9">
                            <h2 style={cssConverter(props.properties.titleCss)} dangerouslySetInnerHTML={{ __html: props.items[1].title }} />
                            <p style={cssConverter(props.properties.subtitleCss)} dangerouslySetInnerHTML={{ __html: props.items[1].subtitle }} />
                            <p style={cssConverter(props.properties.descriptionCss)} dangerouslySetInnerHTML={{ __html: props.items[1].description }} />
                        </div>
                    </div>
                }
                {props.items[0].image[0].src === '' &&
                    <div className="row p-1">
                        <div className="col-9 col-md-9">
                            <h2 style={cssConverter(props.properties.titleCss)} dangerouslySetInnerHTML={{ __html: props.items[0].title }} />
                            <p style={cssConverter(props.properties.subtitleCss)} dangerouslySetInnerHTML={{ __html: props.items[0].subtitle }} />
                            <p style={cssConverter(props.properties.descriptionCss)} dangerouslySetInnerHTML={{ __html: props.items[0].description }} />
                        </div>
                        <div className="col-3 col-md-3">
                            <img src={props.items[1].image[0].src} alt={props.items[1].image[0].alt} className="imaged w-100" style={cssConverter(props.properties.imageCss)} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default SM_TWCL_DFL1
