import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig';
import { showSwitchModal, hideSwitchModal, hidePageSetting, showPageSetting } from '../../../features/modal/offcanvasSlice'
import { getDomainName } from '../../../features/auth/authSlice';


function SettingCard({ websiteName, showPageSettings, searchIn }) {
    // console.log(websiteName);
    if(websiteName.children && websiteName.children.length > 0) {
        const childCards = websiteName.children.map((page, i) => {
            return (
                <SettingCard websiteName={page} showPageSettings={showPageSettings} searchIn={searchIn} key={i} />
            )
        })
        return childCards;
    }
    return (
        <>
            {
                (searchIn.trim() === '' || 
                websiteName.title?.toLowerCase().includes(searchIn.trim().toLowerCase()) || 
                websiteName.url?.toLowerCase().includes(searchIn.trim().toLowerCase()) ||
                websiteName.metaDescription?.toLowerCase().includes(searchIn.trim().toLowerCase())) &&
                <div className="col-md-4 mb-1">
                    <div className="card googleSearchResultCard h-100">
                        <div className="card-body d-flex flex-column">
                            <div className='position-absolute e-2 t-2 d-flex gap-1 position-relative'>
                                <div className="dropdown googleSearchResultCardActions">
                                    <button className="btn btn-outline-primary btn-icon btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                    </button>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" target='_blank' href={`https://${getDomainName()}/${websiteName.url}`} >Open Page</a>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to={`/build?url=${websiteName.url}`}>Edit Page</Link>
                                        <button className="dropdown-item" href="#" onClick={() => showPageSettings(websiteName.url)}>Page Settings</button>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item" href="#">Clone Page</button>
                                        <button className="dropdown-item" href="#">Delete Page</button>
                                    </div>
                                </div>
                            </div>
                            {/* onClick={() =>  goToPage(websiteName.url)} */}
                            <div className='googleSearchResultCardTop' >
                                {/* <img className="faviconImage" src={websiteDataAppearance.properties.favicon.length > 0 ? websiteDataAppearance.properties.favicon : '/img/sample/photo/1.png'} /> */}
                                <div className='PageTitleDescription'>
                                    <h5 className='text-truncate mb-0'>
                                        {websiteName.title}
                                    </h5>
                                        <a href={`https://${getDomainName()}/${websiteName.url}`} target='_blank'>
                                            <span className='text-truncate' style={{ 'width': 'fit-content' }}>
                                                    https://{getDomainName()}/{websiteName.url}
                                            </span>
                                        </a>
                                </div>
                            </div>
                            <p className='pagedescriptionText'>
                                {websiteName.metaDescription}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


function PageSettingsCards({
    showPageSetting,
    pageList = [],
    openPageSettings = false,
    websiteDataAppearance }) {
    const [AllPages, setAllPages] = useState([]);
    const [showPages, setShowPages] = useState([]);
    const [searchIn, setSearchIn] = useState('');
    const [activeMenu, setActiveMenu] = useState('');
    const navigate = useNavigate();

    const returnPageItems = (pageObjectID, hasChildren) => {
        // console.log(pageObjectID, hasChildren, websiteDataAppearance.pages[pageObjectID]);
        let pageData;
        let pageItem;
        if (websiteDataAppearance.pages[pageObjectID] && hasChildren.type === '') {
            pageData = JSON.parse(JSON.stringify(hasChildren));
            pageItem = {
                ...pageData,
                'url': websiteDataAppearance.pages[pageObjectID].url,
                'metaDescription': websiteDataAppearance.pages[pageObjectID].properties.metaDescription,
                'ogImage': websiteDataAppearance.pages[pageObjectID].properties.ogImage
            }
        } else {
            pageData = JSON.parse(JSON.stringify(hasChildren));
            pageItem = { ...pageData };
        }

        return pageItem
    }

    function GetAllPagesInMenuArray(headerMenu) {
        // console.log({ headerMenu });
        const headerMenuArray = headerMenu.map((item) => {
            const tempItem = { ...returnPageItems(item.page, item) };
            if (item.children && item.children.length > 0) {
                // console.log({ tempItem });
                tempItem.children = GetAllPagesInMenuArray(tempItem.children);
            }
            return tempItem
        })

        return headerMenuArray
    }

    useEffect(() => {
        // console.log({pageList, showPages});
        if (pageList.length > 0) {
            const getPages = GetAllPagesInMenuArray(pageList);
            setTimeout(() => {
                setAllPages(JSON.parse(JSON.stringify(getPages)));
                setShowPages(JSON.parse(JSON.stringify(getPages)));
            }, 100);
            // console.log({getPages});
        }
    }, [pageList])

    useEffect(() => {
        if (activeMenu !== '') {
            const filteredPages = AllPages.filter((page) => page.page === activeMenu);
            // console.log(filteredPages[0].children);
            if (filteredPages.length > 0 && filteredPages[0].children && filteredPages[0].children.length > 0) {
                setShowPages(filteredPages[0].children)
            } else {
                setShowPages(filteredPages)
            }
        } else {
            setShowPages(JSON.parse(JSON.stringify(AllPages)));
        }
    }, [activeMenu])



    // function showFilteredPages(AllPages2) {
    //     if (searchIn && searchIn.length > 0) {
    //         const filteredPages = AllPages2.filter((page) => page.title.toLowerCase().includes(searchIn.toLowerCase()) || page.url.toLowerCase().includes(searchIn.toLowerCase()));
    //         // console.log(filteredPages, AllPages2);
    //         setShowPages(filteredPages);
    //     } else {
    //         setShowPages(JSON.parse(JSON.stringify(AllPages2)));
    //     }
    // }


    // useEffect(() => {
    //     showFilteredPages(AllPages);
    // }, [searchIn])

    function goToPage(page) {
        navigate(`/build?url=${page}`);
    }

    function showPageSettings(page) {
        if (!openPageSettings) {
            navigate(`/build?url=${page}`);
        } else {
            navigate(`${window.location.pathname}?url=${page}`);
            showPageSetting({ 'defaultActiveTab': 'seo', 'isPageSettingVisible': true });
        }
    }

    return (
        <>
            <div className='d-flex mb-3 justify-content-between align-items-center flex-wrap'>
                <div className='d-flex align-items-center flex-wrap gap-1'>
                    <button className={`btn ${'' === activeMenu ? 'btn-primary' : 'btn-outline-primary'} me-1 btn-sm`} onClick={() => setActiveMenu('')}>All</button>
                    {
                        AllPages.map((menu) => {
                            return (
                                <button className={`btn ${menu.page === activeMenu ? 'btn-primary' : 'btn-outline-primary'} me-1 btn-sm`} onClick={() => setActiveMenu(menu.page)}>{menu.title}</button>
                            )
                        })
                    }
                </div>
                <div>
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <input type="text" className="form-control ps-5" id="name5" value={searchIn} placeholder="Search page" onChange={(e) => setSearchIn(e.target.value)} autocomplete="off" />
                            <i className="input-icon1 position-absolute t-0 mt-1 ms-1 fs-25">
                                <ion-icon name="search-outline"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            {
                showPages !== '' &&
                <>
                    <div className='row'>
                        {
                            showPages.map((websiteName, i) => {
                                return (
                                    <SettingCard websiteName={websiteName} key={i} showPageSettings={showPageSettings} searchIn={searchIn} />
                                )
                            })
                        }
                    </div>
                </>
            }
        </>
    )
}

const mapStateToProps = ({ utilities, websiteData, auth, offcanvas }) => {
    const {
        // websites
    } = utilities
    const {
        appearance: websiteDataAppearance
    } = websiteData
    const {
        isPageSettingVisible,
        defaultActiveTab
    } = offcanvas
    const {
        ID
    } = auth
    return {
        websiteDataAppearance,
        isPageSettingVisible,
        defaultActiveTab,
        // websites,
        ID
    }
}

const mapDispatchToProps = {
    showSwitchModal,
    hideSwitchModal,
    hidePageSetting,
    showPageSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSettingsCards)