import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import axios from '../../AxiosConfig';
import { showSwitchModal, hideSwitchModal, hidePageSetting, showPageSetting } from '../../features/modal/offcanvasSlice'
import PageSetting from '../layout/Rightpanel/PageSetting';
import PageSettingsCards from '../Elements/Comps/PageSettingsCards';

const AllPages = ({ websiteDataAppearance, showSwitchModal, hideSwitchModal, ID, hidePageSetting, isPageSettingVisible, showPageSetting, defaultActiveTab }) => {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams({ 'url': '' })
  const [AllPagesArray, setAllPagesArray] = useState([]);


  useEffect(() => {
    // console.log(websiteDataAppearance);

    if (websiteDataAppearance?.pages) {
      setAllPagesArray(websiteDataAppearance.navigation.header);
    }
  }, [websiteDataAppearance])


  useEffect(() => {
    if (!isPageSettingVisible) {
      // setSearchParams({ 'url': '' })
      navigate('/managePages');
    }
  }, [isPageSettingVisible]);

  return (
    <div id="appCapsule">
      {/* <div className="section-title justify-content-center border-bottom">{selectedDomain}</div> */}
      <div className="section mb-2 mt-2">
        <h3 className='text-primary'>Web Pages</h3>
        <PageSettingsCards pageList={AllPagesArray} openPageSettings />
      </div>
      <PageSetting />
    </div>
  )
}

const mapStateToProps = ({ utilities, websiteData, auth, offcanvas }) => {
  const {
    // websites
  } = utilities
  const {
    appearance: websiteDataAppearance
  } = websiteData
  const {
    isPageSettingVisible,
    defaultActiveTab
  } = offcanvas
  const {
    ID
  } = auth
  return {
    websiteDataAppearance,
    isPageSettingVisible,
    defaultActiveTab,
    // websites,
    ID
  }
}

const mapDispatchToProps = {
  showSwitchModal,
  hideSwitchModal,
  hidePageSetting,
  showPageSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(AllPages)