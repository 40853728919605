import React from 'react'
import ComIcon from '../images/icon/com-red.svg'
import { connect } from "react-redux"
import { showDomainAdminOffcanvas } from '../../features/modal/offcanvasSlice'

const Domains = ({
    showDomainAdminOffcanvas
}) => {
    const handleDomainAdmin = () => {
        showDomainAdminOffcanvas({isDomainAdminVisible: true})
    }
    return (
        <div id="appCapsule">
            <div className="section full mt-1">
                <div className="border-0 ps-2 pe-2">
                    <form className="search-form">
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value="query" style={{height: '34px'}} />
                            <button className="btn h-custom btn-secondary" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                <ion-icon name="search-sharp" className="fs-6"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div>
                <ul className="listview image-listview mt-1">
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src={ComIcon} alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>The English School Panvel</header>
                                    theenglishschoolpanvel.com
                                    <footer>Bigrock</footer>
                                    <footer>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={handleDomainAdmin}>Admin</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={handleDomainAdmin}>Technical</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={handleDomainAdmin}>Registrant</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={handleDomainAdmin}>Billing</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={handleDomainAdmin}>Transfer Key</span>
                                    </footer>
                                </div>
                                <div>
                                    <header className="text-muted" style={{fontSize: '11px'}}>20 September 2022</header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src={ComIcon} alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>kaizen Lenguistic</header>
                                    kaizenlenguistic.com
                                    <footer>GoDaddy</footer>
                                    <footer>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Admin</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Technical</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Registrant</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Billing</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Transfer Key</span>
                                    </footer>
                                </div>
                                <div>
                                    <header className="text-muted" style={{fontSize: '11px'}}>20 December 2022</header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="custom-line">
                        <a href="#" className="item">
                            <img src={ComIcon} alt="image" className="image" />
                            <div className="in">
                                <div>
                                    <header>MGM Hospital Belapur</header>
                                    mgmhospitalcbd.com
                                    <footer>Hostinger</footer>
                                    <footer>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Admin</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Technical</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Registrant</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Billing</span>
                                        <span className="badge badge-secondary" style={{marginRight: '5px', marginBottom: '5px'}}>Transfer Key</span>
                                    </footer>
                                </div>
                                <div>
                                    <header className="text-muted" style={{fontSize: '11px'}}>20 January 2022</header>
                                </div>
                                {/* <span className="text-muted">Edit</span> */}
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="fab-button text bottom-center" style={{left: '60%'}}>
                <a href="!#" className="fab">
                    <ion-icon name="add-outline"></ion-icon>
                    Add Domain
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
                
    }
}
  
const mapDispatchToProps = {
    showDomainAdminOffcanvas
}

export default connect(mapStateToProps,mapDispatchToProps)(Domains)