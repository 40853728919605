import React from 'react'

function Divider(props) {
    return (
        <div class="divider bg-secondary mt-3 mb-3">
            <div class="icon-box bg-secondary">
                <ion-icon name="eye-off-outline"></ion-icon>
            </div>
        </div>
    )
}

export default Divider
