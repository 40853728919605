import React from 'react';
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

function Extraheader({ activeTabName = "activity" }) {
    const search = useLocation().search
    const formType = new URLSearchParams(search).get('formType')
    const getClassNames = (tabName) => {
        return classNames('item', { active: tabName === formType })
    }
    return (
        <div className="extraHeader p-0 mt-1" style={{zIndex:1}} >
            <div className="appBottomMenu infinite-menu position-absolute">
                <Link className={getClassNames("contact")} href="#!" to="/interact?formType=contact">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Contact</strong>
                    </div>
                </Link>
                <Link className={getClassNames("register")} href="#!" to="/interact?formType=register" role="tab">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Registration</strong>
                    </div>
                </Link>
                <Link className={getClassNames("feedback")} href="#!" to="/interact?formType=feedback">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Feedback</strong>
                    </div>
                </Link>
                <Link className={getClassNames("payment")} href="#!" to="/interact?formType=payment">
                    <div className="col">
                        <ion-icon name="wallet-outline"></ion-icon>
                        <strong>Payment</strong>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Extraheader;