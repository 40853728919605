import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig' 

export const fetchImagesToShare = createAsyncThunk(
  'shareSlice/fetchImagesToShare',
  async () => {
    const response = await axios.get('/share?page=1&size=10')
    const { data } = response
    return data
  }
)
const initialState = {
  imagesToShare: [],
  loadingImages: 'loading',
  draggingItem: 'logoAdjust'
}

export const shareSlice = createSlice({
  name: 'shareSlice',
  initialState,
  reducers: {
    updateImageById: (state, action) => {
      const bannerDataTemp = action.payload.bannerData
      const currentImagesToShare = state.imagesToShare
      const targetImageIndex = currentImagesToShare.findIndex(image => image['ID'] === bannerDataTemp['ID'])
      currentImagesToShare[targetImageIndex] = bannerDataTemp
      // console.log(targetImageIndex);
      state.imagesToShare = currentImagesToShare
    },
    setDraggingItem: (state, action) => {
      state.draggingItem = action.payload;
    }
  },
  extraReducers: {
    [fetchImagesToShare.fulfilled]: (state, action) => {
      const { data }  = action.payload
      const tempData = data.map((item, i) => {
        item.ID = i;
        return item;
      })
      // console.log(tempData);
      state.loadingImages = 'loaded'
      state.imagesToShare = tempData
    }
  }
})

export function getContrastColor(background) {
  // Remove the "#" symbol and convert to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Calculate relative luminance
  const calculateRelativeLuminance = (color) => {
    const [r, g, b] = color.map((c) => c / 255);
    const srgb = (c) => (c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4);
    return 0.2126 * srgb(r) + 0.7152 * srgb(g) + 0.0722 * srgb(b);
  };

  // Calculate contrast ratio
  const calculateContrastRatio = (background, color) => {
    const backgroundLuminance = calculateRelativeLuminance(hexToRgb(background));
    const colorLuminance = calculateRelativeLuminance(hexToRgb(color));

    const contrastRatio = (Math.max(backgroundLuminance, colorLuminance) + 0.05) /
      (Math.min(backgroundLuminance, colorLuminance) + 0.05);

    return contrastRatio;
  };

  // Check contrast ratio and return text color
  const contrastRatioBlack = calculateContrastRatio(background, "#000000");
  const contrastRatioWhite = calculateContrastRatio(background, "#FFFFFF");

  if (contrastRatioBlack >= 4.5) {
    return "#000000"; // Black
  } else if (contrastRatioWhite >= 4.5) {
    return "#FFFFFF"; // White
  } else {
    return null; // Insufficient contrast
  }
}


export const { updateImageById, setDraggingItem } = shareSlice.actions

export default shareSlice.reducer
