import React from 'react'

const cta = ({
    ctaIcon,
    ctaTitle,
    ctaUI
}) => {
    return (
        <div>
            {ctaUI === 'roundButton' &&
                <div className="me-1 d-flex align-items-center justify-content-center">
                    <a href="javascript:void(0)" >
                        <div className="btn btn-outline-secondary p-0 me-1" style={{height: '35px', width: '35px', borderRadius: '100%'}}>
                            <ion-icon name={ctaIcon} className="me-0 fs-6"></ion-icon>
                        </div>
                        <span className="text-secondary">{ctaTitle}</span>
                    </a>
                </div>
            }
            {ctaUI === 'button' &&
                <div className="me-1">
                    <a href="javascript:void(0)" >
                        <div className="btn btn-secondary btn-sm me-1">
                            {ctaTitle}
                        </div>
                    </a>
                </div>
            }
        </div>
    )
}

export default cta
