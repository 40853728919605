import React from 'react'

function SM_INST_DFL1(props) {
    return (
        <div>
            Smart Instagram Page
        </div>
    )
}

export default SM_INST_DFL1
