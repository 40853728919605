import React from 'react';

function ComingSoon(props) {
    return (
        <div id="appCapsule">
            <div className="section mt-5 d-flex align-items-center justify-content-center" style={{'height': '80vh'}}>
                <h2 className='text-center'>
                    Coming Soon
                </h2>
            </div>
        </div>
    )
}

export default ComingSoon