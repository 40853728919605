import React from 'react'
import { connect } from 'react-redux'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'
import { showOffcanvas, showButtonModal, newButtonData, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'

function SM_CNTR_DFL1({ blockID, blockType, items, properties, seq, setNewBlockType, showUpdateDataOffcanvas }) {
    // console.log(props)
    const handleNewEditItem = (itemID, msg) => {
        setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log(itemID);
        showUpdateDataOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties: properties
        })
    }

    return (
        <div>
            <div className="section full">
                <div className="wide-block pt-4 pb-3" style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                    {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                        <div className="row">
                            {properties.blockHeading !== '' &&
                                <div className="col-12">
                                    <h3 className="text-center mb-0" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                                </div>
                            }
                            {properties.blockDescription !== '' &&
                                <div className="col-12">
                                    <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                                </div>
                            }
                        </div>
                    }
                    {/* <div className="position-absolute end-0 pe-1 text-end"><ion-icon name="create-outline" data-bs-toggle="offcanvas" data-bs-target="#PanelRight" className="text-secondary"></ion-icon></div> */}
                    <div className="profile-stats ps-2 pe-2 pt-2 overflow-scroll">
                        {items.map((item, key) => (
                            <div key={key} className='position-relative'>
                                <div className='d-flex pos-absglr z-index-1'>
                                    <div className="col-12 col-md-12 text-center">
                                        <span className="badge bg-success p-1 editBtnCss" onClick={() => { handleNewEditItem(key) }}>Edit</span>
                                    </div>
                                </div>
                                <div>
                                    {item.image !== null && item.image.map((v, k) => {
                                        return <img src={v.src} width="100px" key={k} style={cssConverter(properties.imageCss)} />
                                    })}
                                </div>
                                <a href="#!" className="item">
                                    <strong dangerouslySetInnerHTML={{ __html: item.subtitle }} style={cssConverter(properties.subtitleCss)} />
                                    <h4 className="mb-0" dangerouslySetInnerHTML={{ __html: item.title }} style={cssConverter(properties.titleCss)} />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_CNTR_DFL1)
