import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { connect } from "react-redux"
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData } from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'

const CN_PLAN_DFL1 = ({
    items = [],
    properties,
    showEditOffcanvas,
    blockID,
    websiteDataBlocks,
    showDeleteModal,
    showButtonModal,
    newButtonData
}) => {
    const handleBlockItemUpdate = itemID => {
        showEditOffcanvas({
            isEditVisible: true,
            itemID: itemID,
            items: items,
            blockID: blockID,
            properties:properties
        })
    }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
          '.'
        )[1]
        showDeleteModal({
          isDeleteVisible: true,
          deleteMessage: 'Are your sure? You want to delete this content?',
          triggerMessage: 'content',
          deleteID: contentName,
          deleteItemID: deleteItemID
        })
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
    }
    return (
        <div className="section full pb-4 pt-4" style={{backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <Splide options={{ type: 'slide',perPage: 3, focus: 1, rewind: true, gap: 8, padding: 16, arrows: false, pagination: false,slideFocus:true, fixWidth:95, breakpoints: { 200: { perPage: 1 },411: { perPage: 1 },768: { perPage: 1 }, 991: { perPage: 2 }}}}>
                {items.map((item, key) => {
                    return <SplideSlide>
                        <div className="card text-center position-relative">
                            <div className="card-body">
                                <span onClick={() => {handleBlockItemUpdate(key)}} style={{cursor:'pointer'}}><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" /></span>
                                <h5 className="card-title mt-3" onClick={() => {handleBlockItemUpdate(key)}} style={{cursor:'pointer'}}><sup><span className="fs-4">₹</span><span className="fs-3">{item.title}</span></sup>/Month</h5>
                                <p className="card-text" onClick={() => {handleBlockItemUpdate(key)}} style={{cursor:'pointer'}}>
                                    <MDEditor.Markdown source={item.description} linkTarget="_blank" />
                                </p>
                                {item.button.map(value => {
                                    <a href="#!" className="btn btn-primary" onClick={() => handleShowButtonModal(item.button,key)}>{value.title}</a>
                                })}
                            </div>
                            <div className='d-flex pos-absglr'>
                                <div className="col-6 col-md-6 text-end">
                                <span className="badge bg-secondary p-1 cursor-pointer w-55px" onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                </div>
                                <div className="col-6 col-md-6">
                                <span className="badge bg-danger p-1 cursor-pointer w-55px" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                })}
            </Splide>
        </div>
    )
}

const mapStateToProps = ({websiteData}) => {
    const { 
        blocks: websiteDataBlocks 
    } = websiteData
    return {
        websiteDataBlocks
    }
  }
  
const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal,
    showButtonModal,
    newButtonData
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_PLAN_DFL1)