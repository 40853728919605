import React from 'react'

function ErrorModal(props) {
    return (
        <div class="modal fade dialogbox" id="errorMessage" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-icon text-danger">
                        <ion-icon name="close-circle"></ion-icon>
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">Error</h5>
                    </div>
                    <div class="modal-body">
                        This is a dialog message
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <span class="btn" data-bs-dismiss="modal">CLOSE</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorModal
