import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import Nestable from 'react-nestable'
import 'react-nestable/dist/styles/index.css'
import {
  hideOffcanvas,
  showImageModal,
  showButtonModal,
  showDeleteModal,
  newImageData,
  newButtonData,
  showNavigationOffcanvas,
  showFormOffcanvas
} from '../../../features/modal/offcanvasSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import AddImage from '../../images/addImage.png'
import MDEditor, { bold, commands, italic, image, unorderedListCommand, orderedListCommand, checkedListCommand, link, title } from '@uiw/react-md-editor'

const RightPanel = ({
  show,
  items = [],
  itemCount,
  message,
  itemID,
  newImage,
  deleteItemID,
  properties,
  blockType,
  blockID,
  hideOffcanvas,
  showImageModal,
  showButtonModal,
  showDeleteModal,
  websiteDataAppearnace,
  websiteDataBlocks,
  websiteDataContents,
  setWebsiteData,
  showAppToast,
  blockid = '',
  imageDataObj,
  buttonDataObj,
  newImageData,
  blockRules,
  newButtonData,
  showNavigationOffcanvas,
  showFormOffcanvas
}) => {
  let navigate = useNavigate()
  const [query, setQuery] = useState('')
  const [propValue, setValue] = useState('')
  const [contentItems, setContentItems] = useState([])
  const [sitemapList, setSitemapList] = useState([{}])
  const [noItems, setNoItems] = useState(0)
  const [visibleField, setVisibleField] = useState([])
  const [maxButton, setMaxButton] = useState(0)
  const [maxImage, setMaxImage] = useState(0)
  const [itemFilterType, setItemFilterType] = useState('')
  const [disableUpdateButton, disUpdateButton] = useState(false)
  const [blockStyleCode, setBlockStyleCode] = useState('')
  const [value, setDescriptionValue] = useState('')
  const [smartDescriptionValue, setSmartDescriptionValue] = useState('')
  const [smartSubtitleValue, setSmartSubtitleValue] = useState('')
  const [blockStyleList, setBlockStyleList] = useState({})

  const fetchWebsiteJSON = () => {
    const webDoc = localStorage.getItem('website')
    const res = webDoc ? JSON.parse(atob(webDoc)) : false
    if (res) {
      return res
    } else {
      return false
    }
  }

  const websiteJSON = fetchWebsiteJSON()
  if (blockID !== '' && blockID !== undefined) {
    var blockProperties = websiteDataBlocks[blockID].items
    var styleID = websiteDataBlocks[blockID].properties.styleID
  }

  useEffect(() => {
    if (blockID !== '' && blockID !== undefined) {
      setItemFilterType(blockProperties.itemFilters)
      setNoItems(blockProperties.numItems)
      const blockStyle = websiteDataBlocks[blockID].properties.style

      if (blockRules <= 0) {
        navigate('/load')
      } else {
        if (blockRules.hasOwnProperty(blockStyle)) {
          setVisibleField(blockRules[blockStyle].fields)
          setMaxButton(blockRules[blockStyle].maxButton)
          setMaxImage(blockRules[blockStyle].maxImage)
        } else {
          setVisibleField(blockRules['CN'].fields)
          setMaxButton(blockRules['CN'].maxButton)
          setMaxImage(blockRules['CN'].maxImage)
        }
      }
    }
  }, [blockID, blockRules])

  useEffect(() => {
    if (blockID !== '' && blockID !== undefined) {
      setBlockStyleCode(websiteDataBlocks[blockID].properties.style)
      setQuery(websiteDataBlocks[blockID].properties.backgroundType)
      setValue(websiteDataBlocks[blockID].properties.backgroundValue)
    }
  }, [websiteDataBlocks, blockID])

  const token = localStorage.getItem('token')
  const tokenData = token ? jwt_decode(token) : false
  const selectedDomain = tokenData.SelectedDomain.Domain

  const collectData = () => {
    if (itemID !== undefined && itemID !== -1) {
      setInputValues()
    } else {
      if (blockType === 'SM') {
        // if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
          setInputValuesSmart()
        // }
      }
    }
  }

  function handleShowImageModal(imageData, ID) {
    console.log(JSON.stringify(imageData), ID);
    let imageType = 'other'
    if (blockStyleCode === 'SM_HEAD' || blockStyleCode === 'SM_FOOT') {
      imageType = 'logo'
    } else if (blockStyleCode === 'SM_BRDC') {
      imageType = 'pageImage'
    } else if (blockStyleCode === 'CN_CRSL') {
      imageType = 'slider'
    } else if (blockStyleCode === 'CN_GLRY') {
      imageType = 'gallery'
    } else {
      imageType = 'other'
    }
    showImageModal({
      isImageModalVisible: true,
      imageData: imageData,
      imageID: ID,
      imageType: imageType
    })
    newImageData({ imageDataObj: imageData })
  }

  function handleShowButtonModal(buttonData, ID) {
    showButtonModal({
      isButtonModalVisible: true,
      buttonData: buttonData,
      buttonID: ID
    })
    newButtonData({ buttonDataObj: buttonData })
  }

  const toolbar = [
    {
      name: 'bold',
      action: function customFunction(editor) {
        editor.toggleBold()
      },
      className: 'fa fa-bold',
      title: 'Bold'
    },
    {
      name: 'italic',
      action: function customFunction(editor) {
        editor.toggleItalic()
      },
      className: 'fa fa-italic',
      title: 'Italic'
    },
    // {
    //     name: "quote",
    //     action: function customFunction(editor){
    //         editor.toggleBlockquote()
    //     },
    //     className: "fa fa-quote-left",
    //     title: "Quote",
    // },
    '|',
    {
      name: 'unorderedList',
      action: function customFunction(editor) {
        editor.toggleUnorderedList()
      },
      className: 'fa fa-list-ul',
      title: 'Unordered List'
    },
    {
      name: 'orderedList',
      action: function customFunction(editor) {
        editor.toggleOrderedList()
      },
      className: 'fa fa-list-ol',
      title: 'Ordered List'
    },
    '|',
    {
      name: 'image',
      action: function customFunction(editor) {
        editor.drawImage()
      },
      className: 'fa fa-image',
      title: 'Image'
    },
    {
      name: 'link',
      action: function customFunction(editor) {
        editor.drawLink()
      },
      className: 'fa fa-link',
      title: 'Link'
    },
    "|",
    {
        name: "fullscreen",
        action: function customFunction(editor) {
            editor.toggleFullScreen();
        },
        className: "fa fa-arrows-alt no-disable no-mobile",
        title: "Full Screen"
    }
    // {
    //     name: "preview",
    //     action: function customFunction(editor) {
    //         editor.togglePreview();
    //     },
    //     className: "fa fa-eye no-disable",
    //     title: "Preview"
    // }
  ]

  const handleStepUp = () => {
    setNoItems(noItems + 1)
  }

  const handleStepDown = () => {
    if (noItems > 0) {
      setNoItems(noItems - 1)
    }
  }

  const sitemapData = websiteJSON.appearance.navigation.header
  const activePage = websiteJSON.appearance.properties.defaultPage

  const getSitemapList = (sitemap) => {
    let list = []
    sitemapData.map((value) => {
      if (!value.hasOwnProperty('children')) {
        list.push(value)
      } else {
        value.children.map(val => {
          list.push(val)
        })
      }
    })
    setSitemapList(list)
    console.log(list)
  }

  useEffect(() => {
    getSitemapList(sitemapData)
    let count = 0
    setContentItems(
      items.map(item => {
        let tempList = {
          id: count,
          title: item.title,
          icon: 'pause-outline',
          image: item.image
        }
        count++
        return tempList
      })
    )
  }, [items])

  // useEffect(() => {
  //     if (imageDataObj !== undefined && imageDataObj !== []) {
  //         imageDataObj = imageDataObj
  //     } else {
  //         imageDataObj = []
  //     }
  // }, [imageDataObj])

  useEffect(() => {
    if (itemID !== -1 && itemID !== undefined) {
      setDescriptionValue(items[itemID].description)
    } else {
      items.map((value, key) => {
        if (value.description !== '') {
          setSmartDescriptionValue(value.description)
        }
        if (value.subtitle !== '') {
          setSmartSubtitleValue(value.subtitle)
        }
      })
    }
  }, [items, itemID])

  let updatedItems = []
  const updateContentOrder = () => {
    disUpdateButton(true)
    const contents = websiteDataBlocks[blockID].items.contents
    const itemFilters = itemFilterType
      ? itemFilterType
      : websiteDataBlocks[blockID].items.itemFilters
    const numItems = noItems
      ? noItems
      : websiteDataBlocks[blockID].items.numItems
    const contentID = websiteDataBlocks[blockID].items.contents.split('.')[1]

    let updatedData = {}
    updatedData = {
      contents: contents,
      itemFilters: itemFilters,
      numItems: numItems
    }
    var contentItemData = websiteDataBlocks[blockID].items
    contentItemData = updatedData
    if(tokenData.userInfo.RoleID != 'Moderator') {
      axios
        .put(
          '/content/reorder/' + selectedDomain + '/' + contentID + '/' + blockID,
          { reorderArr: updatedItems, items: contentItemData }
        )
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'Reordered Successfully ',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL ',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          }
        })
    } else {
      const selectedCategoryID = tokenData.SelectedDomain.ID
      const categoryPageID = localStorage.getItem('selectedPageID')
      axios
        .put(
          '/content/reorder/' + selectedCategoryID + '/' + categoryPageID + '/' + contentID + '/' + blockID,
          { reorderArr: updatedItems, items: contentItemData }
        )
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'Reordered Successfully ',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL ',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          }
        })
    } 
  }

  const updateBlockProperties = () => {
    disUpdateButton(true)
    
    if(tokenData.userInfo.RoleID != 'Moderator') {
      axios
        .put('/block/' + selectedDomain + '/' + blockID, {
          backgroundValue: query === 'none' ? '' : propValue,
          backgroundType: query,
          blockDescription: (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') ? document.getElementsByName('blockDescription')[0].value : '',
          blockHeading: (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') ? document.getElementsByName('blockHeading')[0].value : ''
        })
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'PROPERTIES UPDATED ',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          }
        })
      } else {
        const selectedCategoryID = tokenData.SelectedDomain.ID
        const categoryPageID = localStorage.getItem('selectedPageID')
        axios
        .put('/block/' + selectedCategoryID + '/' + categoryPageID + '/' + blockID, {
          backgroundValue: query === 'none' ? '' : propValue,
          backgroundType: query,
          blockDescription: document.getElementsByName('blockDescription')[0]
            .value,
          blockHeading: document.getElementsByName('blockHeading')[0].value
        })
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'PROPERTIES UPDATED ',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            newImageData({ imageDataObj: [] })
            hideOffcanvas()
          }
        })
      }

    var checkedCheckboxes = []
    checkedCheckboxes.push(
      document.querySelectorAll('[name="inputNavCheckbox"]:checked').value
    )
    // if (checkedCheckboxes > 0) {
    // } else {

    // }
  }

  const handleDeleteContent = (blockName, deleteItemID) => {
    const contentName = websiteDataBlocks[blockName].items.contents.split(
      '.'
    )[1]
    showDeleteModal({
      isDeleteVisible: true,
      deleteMessage: 'Are your sure? You want to delete this content?',
      triggerMessage: 'content',
      deleteID: contentName,
      deleteItemID: deleteItemID
    })
  }

  const getImage = (items, key, prpty) => {
    if (Array.isArray(items)) {
      switch (prpty) {
        case 'src':
          return items.length > key ? items[key].src : AddImage

        case 'alt':
          return items.length > key ? items[key].alt : 'Avatar'

        default:
          return ''
      }
    } else {
      return false
    }
  }

  const renderItem = ({ item }) => {
    return (
      <ul className="listview image-listview">
        <li className="custom-line">
          <a href="#!" className="item pt-0 pb-0 ps-0">
            <div className="icon-box bg-light">
              <ion-icon name="pause-outline"></ion-icon>
            </div>
            <div className="icon-box bg-light">
              <img
                src={getImage(item.image, 0, 'src')}
                alt={getImage(item.image, 0, 'alt')}
                className="image"
              />
            </div>
            <div className="in">
              <div style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', width: '67%'}}>{item.title}</div>
            </div>
          </a>
        </li>
      </ul>
    )
  }

  const setInputValues = () => {
    document.getElementById('titleCheckbox').checked =
      items[itemID].title.length > 0 ? true : false
    document.getElementById('subtitleCheckbox').checked =
      items[itemID].subtitle.length > 0 ? true : false
    document.getElementById('descriptionCheckbox').checked =
      items[itemID].description.length > 0 ? true : false
    document.getElementById('imageCheckbox').checked =
      items[itemID].image.length > 0 ? true : false
    // document.getElementById('urlCheckbox').checked = (items[itemID].url.length > 0) ? true: false

    document.getElementById('titleCheckbox').disabled =
      items[itemID].title.length > 0 ? false : true
    document.getElementById('subtitleCheckbox').disabled =
      items[itemID].subtitle.length > 0 ? false : true
    document.getElementById('descriptionCheckbox').disabled =
      items[itemID].description.length > 0 ? false : true
    // document.getElementById('urlCheckbox').disabled = (items[itemID].url.length > 0) ? false: true
    document.getElementById('imageCheckbox').disabled =
      items[itemID].image.length > 0 ? false : true

    document.getElementsByName('title')[0].value = items[itemID].title
    document.getElementsByName('subtitle')[0].value = items[itemID].subtitle
    // document.getElementsByName("descriptions")[0].value = items[itemID].description
    // document.getElementsByName("ur")[0].value = items[itemID].url
    // document.getElementsByName("image")[0].value = items[itemID].image
  }

  const setInputValuesSmart = () => {
    items.map((value, key) => {
      if (blockStyleCode === 'SM_FOOT') {
        if (visibleField.includes('image')) {
          document.getElementsByName('image' + key)[0].value = value.image
        } 
        if (visibleField.includes('button')) {
          document.getElementsByName('button' + key)[0].value = value.button
        } 
        if (visibleField.includes('title')) {
          document.getElementsByName('title' + key)[0].value = value.title
        }
      } else {
        if (value.form === '') {
          if (visibleField.includes('title')) {
            document.getElementsByName('title' + key)[0].value = value.title
          }
          // if (visibleField.includes('subtitle')) {
          //   document.getElementsByName('subtitle' + key)[0].value =
          //     value.subtitle
          // }
          if (visibleField.includes('image')) {
            document.getElementsByName('image' + key)[0].value = value.image
          }
          if (visibleField.includes('button')) {
            document.getElementsByName('button' + key)[0].value = value.button
          }
          if (visibleField.includes('url')) {
            document.getElementsByName('url' + key)[0].value = value.url
          }
        }
      }
    })
  }

  const onChange = (value) => {
    setDescriptionValue(value)
  }

  const smartDescription = (smartDescriptionValue) => {
    setSmartDescriptionValue(smartDescriptionValue)
  }

  const smartSubtitle = (smartSubtitleValue) => {
    setSmartSubtitleValue(smartSubtitleValue)
  }

  const handleSubmit = event => {
    event.preventDefault()
    setDescriptionValue(value)
    const inputs = {
      title: document.getElementById('titleCheckbox').checked
        ? document.getElementsByName('title')[0].value
        : '',
      subtitle: document.getElementById('subtitleCheckbox').checked
        ? document.getElementsByName('subtitle')[0].value
        : '',
      description: document.getElementById('descriptionCheckbox').checked
        ? value
        : value,
      // "image": [{
      //     "src": (document.getElementById('imageCheckbox').checked) ? document.getElementsByName("image")[0].value : "",
      //     "alt": (document.getElementById('titleCheckbox').checked) ? document.getElementsByName("title")[0].value : ""
      // }],
      image:
        imageDataObj === undefined || imageDataObj === []
          ? items[itemID].image
          : imageDataObj,
      button:
        buttonDataObj === undefined || buttonDataObj === []
          ? items[itemID].button
          : buttonDataObj,
      category: '',
      url: '',
      form: '',
      content: ''
    }
    disUpdateButton(true)

    var checkedCheckboxes = document.querySelectorAll(
      '[name="inputField"]:checked'
    ).length

    if (checkedCheckboxes > 0) {
      if (blockID !== '') {
        const blockIdName = websiteDataBlocks[blockID].items.contents.split(
          '.'
        )[1]

        if(tokenData.userInfo.RoleID != 'Moderator') {
          axios
            .put(
              '/content/' + selectedDomain + '/' + blockIdName + '/' + itemID,
              inputs
            )
            .then(res => {
              if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({
                  toastMessage: 'UPDATED SUCCESSFULLY',
                  background: 'success',
                  timeout: '1000',
                  icon: 'checkmark-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                disUpdateButton(false)
                hideOffcanvas()
              } else {
                showAppToast({
                  toastMessage: 'FAIL ',
                  background: 'danger',
                  timeout: '1000',
                  icon: 'close-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                disUpdateButton(false)
                hideOffcanvas()
              }
            })
          } else {
            const selectedCategoryID = tokenData.SelectedDomain.ID
            const categoryPageID = localStorage.getItem('selectedPageID')
            axios
            .put(
              '/content/' + selectedCategoryID + '/' + categoryPageID + '/' + blockIdName + '/' + itemID,
              inputs
            )
            .then(res => {
              if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({
                  toastMessage: 'UPDATED SUCCESSFULLY',
                  background: 'success',
                  timeout: '1000',
                  icon: 'checkmark-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                disUpdateButton(false)
                hideOffcanvas()
              } else {
                showAppToast({
                  toastMessage: 'FAIL ',
                  background: 'danger',
                  timeout: '1000',
                  icon: 'close-circle-outline',
                  position: 'toast-center'
                })
                newImageData({ imageDataObj: [] })
                disUpdateButton(false)
                hideOffcanvas()
              }
            })
          }
      }
    } else {
      showAppToast({
        toastMessage: 'Please Check atleast 1 checkbox',
        background: 'warning',
        timeout: '1000',
        icon: 'warning-outline',
        position: 'toast-center'
      })
    }
  }

  const handleSmartBlockSubmit = e => {
    e.preventDefault()
    setSmartDescriptionValue(smartDescriptionValue)
    setSmartSubtitleValue(smartSubtitleValue)
    disUpdateButton(true)
    let newArr = []
    items.map((value, key) => {
      var inputs = {
        // "title": (document.getElementById('titleCheckbox').checked) ? document.getElementsByName("title")[0].value : "",
        title: visibleField.includes('title')
          ? document.getElementsByName('title' + key)[0].value
          : '',
        // "subtitle": (document.getElementById('subtitleCheckbox').checked) ? document.getElementsByName("subtitle")[0].value : "",
        subtitle: visibleField.includes('subtitle')
          // ? document.getElementsByName('subtitle' + key)[0].value
          ? smartSubtitleValue
          : '',
        description: visibleField.includes('description')
          ? smartDescriptionValue
          : '',
        // "image": imageDataObj,
        image: visibleField.includes('image')
          ? imageDataObj !== undefined && imageDataObj !== ''
            ? imageDataObj
            : value.image
          : [],
        // "button": buttonDataObj,
        button: visibleField.includes('button')
          ? buttonDataObj !== undefined && buttonDataObj !== ''
            ? buttonDataObj
            : value.button
          : [],
        category: '',
        url: visibleField.includes('url')
          ? document.getElementsByName('url' + key)[0].value
          : '',
        form: '',
        content: ''
      }
      newArr.push(inputs)
    })

    if(tokenData.userInfo.RoleID != 'Moderator') {
      axios
        .put('/block/' + selectedDomain + '/' + blockID + '/items', newArr)
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'Updated Successfully',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            // newImageData({imageDataObj: []})
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL ',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            // newImageData({imageDataObj: []})
            hideOffcanvas()
          }
        })
    } else {
      const selectedCategoryID = tokenData.SelectedDomain.ID
      const categoryPageID = localStorage.getItem('selectedPageID')
      axios
        .put('/block/' + selectedCategoryID + '/' + categoryPageID + '/' + blockID + '/items', newArr)
        .then(res => {
          if (res.data.message === 'Success') {
            setWebsiteData(res.data.data)
            showAppToast({
              toastMessage: 'Updated Successfully',
              background: 'success',
              timeout: '1000',
              icon: 'checkmark-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            // newImageData({imageDataObj: []})
            hideOffcanvas()
          } else {
            showAppToast({
              toastMessage: 'FAIL ',
              background: 'danger',
              timeout: '1000',
              icon: 'close-circle-outline',
              position: 'toast-center'
            })
            disUpdateButton(false)
            // newImageData({imageDataObj: []})
            hideOffcanvas()
          }
        })
    } 
  }

  const handleDeleteSmartBlock = (event, index, arr) => {
    event.preventDefault()
    showDeleteModal({
      isDeleteVisible: true,
      deleteMessage: 'Are your sure? You want to delete this item?',
      triggerMessage: 'smartContent',
      deleteID: arr,
      deleteItemID: index,
      blockID: blockID
    })
  }

  const handleInputLength = (data, trigger) => {
    if (data.length > 0) {
      document.getElementById(trigger + 'Checkbox').checked = true
      document.getElementById(trigger + 'Checkbox').disabled = false
    } else {
      document.getElementById(trigger + 'Checkbox').checked = false
      document.getElementById(trigger + 'Checkbox').disabled = true
    }
  }

  const updateHeaderSitemap = position => {
    disUpdateButton(true)
    let activeSitemap = []
    if (position === 'header') {
      saveReorderSitemap()
      {
        sitemapList.map((value, key) => {
          if (document.getElementById('input' + key).checked) {
            activeSitemap.push(value.url)
          }
        })
      }
    }
    if (position === 'footer') {
      {
        sitemapList.map((value, key) => {
          if (document.getElementById('inputFooter' + key).checked) {
            activeSitemap.push(value.url)
          }
        })
      }
    }

    axios
      .post(
        '/appearance/navigationUpdate/' + selectedDomain + '/' + position,
        activeSitemap
      )
      .then(res => {
        if (res.data.message === 'Success') {
          localStorage.setItem(
            'website',
            btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data))))
          )
          setWebsiteData(res.data.data)
          showAppToast({
            toastMessage: 'UPDATED SUCCESSFULLY',
            background: 'success',
            timeout: '1000',
            icon: 'checkmark-circle-outline',
            position: 'toast-center'
          })
          disUpdateButton(false)
          hideOffcanvas()
        } else {
          showAppToast({
            toastMessage: 'FAIL ',
            background: 'danger',
            timeout: '1000',
            icon: 'close-circle-outline',
            position: 'toast-center'
          })
          disUpdateButton(false)
          hideOffcanvas()
        }
      })
  }

  const BlockStyleName = () => {
    return (
      <>
        <div className="icon-box bg-primary">
          <ion-icon name="list-outline"></ion-icon>
        </div>
      </>
    )
  }

  useEffect(() => {
    if (show) {
      axios.get('/block/getBlockStyles/' + properties.style + '/' + selectedDomain).then(res => {
        if (res.data.message === 'Success') {
          setBlockStyleList(res.data.data)
        } else {
          console.log('FAIL BLOCK STYLE LIST')
        }
      })
    }
  }, [show])

  useEffect(() => {
    if (properties !== undefined) {
      if (blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') {
        setBlockData()
      }
    }
  }, [properties])

  const handleBlockStyleUpdate = newStyleName => {
    const styleData = newStyleName.split('_')
    const blockType = styleData[0]
    const blockName = styleData[1]
    const data = { style: blockType + '_' + blockName, styleID: newStyleName }
    if(tokenData.userInfo.RoleID != 'Moderator') {
      axios.put('/block/' + selectedDomain + '/' + blockID, data).then(res => {
        if (res.data.message === 'Success') {
          setWebsiteData(res.data.data)
          showAppToast({
            toastMessage: 'PROPERTIES UPDATED ',
            background: 'success',
            timeout: '1000',
            icon: 'checkmark-circle-outline',
            position: 'toast-center'
          })
          hideOffcanvas()
        } else {
          showAppToast({
            toastMessage: 'FAIL',
            background: 'danger',
            timeout: '1000',
            icon: 'close-circle-outline',
            position: 'toast-center'
          })
          hideOffcanvas()
        }
      })
    } else {
      const selectedCategoryID = tokenData.SelectedDomain.ID
      const categoryPageID = localStorage.getItem('selectedPageID')
      axios.put('/block/' + selectedCategoryID + '/' + categoryPageID + '/' + blockID, data).then(res => {
        if (res.data.message === 'Success') {
          setWebsiteData(res.data.data)
          showAppToast({
            toastMessage: 'PROPERTIES UPDATED ',
            background: 'success',
            timeout: '1000',
            icon: 'checkmark-circle-outline',
            position: 'toast-center'
          })
          hideOffcanvas()
        } else {
          showAppToast({
            toastMessage: 'FAIL',
            background: 'danger',
            timeout: '1000',
            icon: 'close-circle-outline',
            position: 'toast-center'
          })
          hideOffcanvas()
        }
      })
    }
  }

  const setBlockData = () => {
    document.getElementsByName('blockHeading')[0].value =
      properties.blockHeading
    document.getElementsByName('blockDescription')[0].value =
      properties.blockDescription
  }

  const sitemapPageList = (sitemap, activePage) => {
    let list = []
    let count = 0
    // sitemap.map(item => {
    //   const icon = 'document-text-outline'
    //   item = item.replace('^', '')
    //   if (sitemap.hasOwnProperty(item)) {
    //     if (sitemap[item].hasOwnProperty('navigation')) {
    //       var children = []
    //       sitemap[item].sitemap.map(value => {
    //         const isActive =
    //           sitemap[value].url === '/' + activePage ? true : false
    //         children.push({
    //           id: count,
    //           page: sitemap[value].pageName,
    //           url: sitemap[value].url,
    //           isActive: isActive,
    //           icon: icon
    //         })
    //       })

    //       list.push({
    //         id: count,
    //         page: item,
    //         url: '',
    //         isActive: false,
    //         icon: icon,
    //         children: children
    //       })
    //       count++
    //     } else if (sitemap[item].hasOwnProperty('pageName')) {
    //       const isActive = sitemap[item].url === '/' + activePage ? true : false
    //       list.push({
    //         id: count,
    //         page: sitemap[item].pageName,
    //         url: sitemap[item].url,
    //         isActive: isActive,
    //         icon: icon
    //       })
    //       count++
    //     }
    //   } else {
    //   }
    //   return true
    // })
    return list
  }
  const sitemap = sitemapPageList(sitemapData, activePage)
  let updatedSitemap = []

  const renderPageList = ({ item }) => {
    // document.getElementById('input' + item.id).checked = true
    return (
      <ul className="listview image-listview">
        <li className="custom-line">
          <a href="#!" className="item pt-0 pb-0 ps-0">
            <div className="icon-box webweb-bg-secondary">
              <ion-icon name="pause-outline"></ion-icon>
            </div>
            <div className="in">
              <div>{item.page}</div>
            </div>
          </a>
        </li>
      </ul>
    )
  }

  const saveReorderSitemap = () => {
    if (updatedSitemap.length === 0) {
      updatedSitemap = sitemapPageList(sitemapData, activePage)
    }
    axios
      .put('/page/reorder/' + selectedDomain, { reorderArr: updatedSitemap })
      .then(res => {
        if (res.data.message === 'Success') {
          setWebsiteData(res.data.data)
          showAppToast({
            toastMessage: 'Reordered Successfully ',
            background: 'success',
            timeout: '1000',
            icon: 'checkmark-circle-outline',
            position: 'toast-center'
          })
        } else {
          console.log('REORDER ERROR')
        }
      })
  }

  const handleNavigation = () => {
    showNavigationOffcanvas({isNavigationVisible: true, blockID: blockID, triggerMessage: ''})
    hideOffcanvas()
  }

  const handleEditForm = (ID) => {
    const formID = ID.split('.')[1]
    showFormOffcanvas({isFormVisible: true, formID: formID})
  }

  return (
    <div>
      <Offcanvas
        show={show}
        placement={'end'}
        backdrop={true}
        scroll={true}
        onEntered={collectData}
        id="manageBlockModal"
      >
        <Offcanvas.Body className="offcanvas-body mt-4 mb-5 ps-0 pe-0">
          <div className="profileBox webweb-profileBox">
            <div className="in">
              <strong>Edit Block</strong>
            </div>
            <span
              className="close-sidebar-button"
              style={{ cursor: 'pointer' }}
              onClick={hideOffcanvas}
            >
              <ion-icon name="close"></ion-icon>
            </span>
          </div>
          <div className="section full mb-2">
            <div className="custom-extraHeader position-absolute p-0">
              <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                  <a
                    className={
                      message !== 'changeBackground'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    data-bs-toggle="tab"
                    href="#content1"
                    role="tab"
                    style={{ height: '46px' }}
                  >
                    Content
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#styleTab"
                    role="tab"
                    style={{ height: '46px' }}
                  >
                    Style
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      message === 'changeBackground'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    data-bs-toggle="tab"
                    href="#properties1"
                    role="tab"
                    style={{ height: '46px' }}
                  >
                    Properties
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" style={{ marginTop: '50px' }}>
              <div
                className={
                  message !== 'changeBackground'
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
                id="content1"
                role="tabpanel"
              >
                <div className="section full mt-1">
                  {(itemID === undefined || itemID === -1) &&
                    (blockType === 'SM' || blockType === 'PL') && (
                      <div className="accordion" id="accordionExample1">
                        {items.map((value, key) => (
                          <>
                            {value.form !== '' &&
                              (blockStyleCode === 'SM_CNTC' || blockStyleCode === 'SM_EMIL' || blockStyleCode === 'SM_FORM') && (
                              <div className="accordion-body">
                                <div className="text-end"></div>
                                <button
                                 className="btn btn-secondary btn-sm btn-block"
                                   onClick={() => {handleEditForm(value.form)}}
                                >
                                  Edit Form
                                </button>
                              </div>
                            )}
                            <form onSubmit={handleSmartBlockSubmit}>
                              <div className="accordion-item">
                                  
                                  {(value.form === '' ||
                                    !value.hasOwnProperty('form')) && (
                                    <div className="accordion-body pb-0">
                                      <div className="text-end"></div>
                                      {visibleField.includes('image') && (
                                        <>
                                          <span>
                                            {value.image && value.image !== null && (
                                              <div className="form-group basic">
                                                <div className="input-wrapper">
                                                  <div className="row">
                                                    <div className="col-12 col-md-12 mb-1">
                                                      <label
                                                        className="form-label mb-0"
                                                        htmlFor="image"
                                                      >
                                                        <div className="row">
                                                          <div className="col-6 col-md-6">
                                                            Image
                                                          </div>
                                                          <div className="col-6 text-end">
                                                            <div className="webweb-checkbox form-check">
                                                              <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="smartImageCheckbox"
                                                                name="inputField"
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor="smartImageCheckbox"
                                                              ></label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </label>
                                                    </div>
                                                    {value.image.map((img, index) => (
                                                      <div
                                                        className="col-4 col-md-4 mb-1"
                                                        style={{ cursor: 'pointer' }}
                                                      >
                                                        <div className="d-flex justify-content-center align-items-center">
                                                          <img
                                                            src={img.src}
                                                            alt={img.alt}
                                                            className="imaged w-100 border border-primary border-2"
                                                            onClick={() =>
                                                              handleShowImageModal(
                                                                [img],
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ))}
                                                    {value.image.length < maxImage && (
                                                      <div
                                                        className="col-4 col-md-6 mb-1"
                                                        style={{ cursor: 'pointer' }}
                                                      >
                                                        <div className="d-flex justify-content-center align-items-center">
                                                          {/* <img
                                                            src={AddImage}
                                                            alt="Avatar"
                                                            className="imaged w-100 border border-primary border-2"
                                                            onClick={() =>
                                                              handleShowImageModal(
                                                                value.image,
                                                                value.image.length
                                                              )
                                                            }
                                                          /> */}
                                                          <button type="button" className="btn btn-primary btn-sm me-1 mb-1" onClick={() =>
                                                              handleShowImageModal(
                                                                value.image,
                                                                value.image.length
                                                              )
                                                            }>
                                                              <ion-icon name="image-outline"></ion-icon>
                                                              Add Image
                                                          </button>
                                                        </div>
                                                      </div>
                                                    )}
                                                    <input
                                                      type="hidden"
                                                      name={'image' + key}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </span>
                                        </>
                                      )}
                                      {blockStyleCode !== 'SM_HEAD' && (
                                        <div>
                                          {/* {blockStyleCode !== 'SM_FOOT' && ( */}
                                            <div>
                                              {visibleField.includes('title') && (
                                                <div className="form-group basic">
                                                  <div className="input-wrapper">
                                                    <label
                                                      className="form-label mb-0"
                                                      htmlFor="title"
                                                    >
                                                      <div className="row">
                                                        <div className="col-6 col-md-6">
                                                          Title
                                                        </div>
                                                        <div className="col-6 text-end">
                                                          <div className="webweb-checkbox form-check">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              id="smarTitleCheckbox"
                                                              name="inputField"
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="smarTitleCheckbox"
                                                            ></label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control form-control-sm"
                                                      id="title"
                                                      placeholder="Enter Title"
                                                      autoComplete="off"
                                                      name={'title' + key}
                                                    />
                                                    <i className="clear-input">
                                                      <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                  </div>
                                                </div>
                                              )}
                                              {visibleField.includes(
                                                'subtitle'
                                              ) && (
                                                <div className="form-group basic">
                                                  <div className="input-wrapper custom-subtitle">
                                                    <label
                                                      className="form-label mb-0"
                                                      htmlFor="subtitle"
                                                    >
                                                      <div className="row">
                                                        <div className="col-6 col-md-6">
                                                          Subtitle
                                                        </div>
                                                        <div className="col-6 text-end">
                                                          <div className="webweb-checkbox form-check">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              id="smartSubtitleCheckbox"
                                                              name="inputField"
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="smartSubtitleCheckbox"
                                                            ></label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </label>
                                                    <MDEditor height={200} value={smartSubtitleValue} onChange={smartSubtitle} commands={[bold, italic, title]} extraCommands={[]} preview='edit' style={{backgroundColor: 'white',color: '#333333'}} />
                                                    {/* <textarea
                                                      rows="1"
                                                      className="form-control"
                                                      placeholder="Enter Subtitle"
                                                      name={'subtitle' + key}
                                                    ></textarea>
                                                    <i className="clear-input">
                                                      <ion-icon name="close-circle"></ion-icon>
                                                    </i> */}
                                                  </div>
                                                </div>
                                              )}
                                              {visibleField.includes('url') && (
                                                <div className="form-group basic">
                                                  <div className="input-wrapper">
                                                    <label
                                                      className="form-label mb-0"
                                                      htmlFor="title"
                                                    >
                                                      <div className="row">
                                                        <div className="col-6 col-md-6">
                                                          URL
                                                        </div>
                                                        <div className="col-6 text-end">
                                                          <div className="webweb-checkbox form-check">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              id="smartUrlCheckbox"
                                                              name="inputField"
                                                            />
                                                            <label
                                                              className="form-check-label"
                                                              htmlFor="smartUrlCheckbox"
                                                            ></label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control form-control-sm"
                                                      id="url"
                                                      name={'url' + key}
                                                      placeholder="Enter url"
                                                      autoComplete="off"
                                                    />
                                                    <i className="clear-input">
                                                      <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          {/* // )} */}
                                          {visibleField.includes(
                                            'description'
                                          ) && (
                                            <div className="form-group basic">
                                              <div className="input-wrapper">
                                                <label
                                                  className="form-label mb-0"
                                                  htmlFor="description"
                                                >
                                                  <div className="row">
                                                    <div className="col-6 col-md-6">
                                                      Description
                                                    </div>
                                                    <div className="col-6 text-end">
                                                      <div className="webweb-checkbox form-check">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          id="smartDescriptionCheckbox"
                                                          name="inputField"
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="smartDescriptionCheckbox"
                                                        ></label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </label>
                                                {/* <SimpleMDE
                                                  options={{
                                                    autofocus: true,
                                                    spellChecker: false,
                                                    nativeSpellcheck: false,
                                                    toolbar: toolbar,
                                                    status: false,
                                                    minHeight: '150px',
                                                    padding: '100px'
                                                  }}
                                                  onChange={smartDescription}
                                                  value={smartDescriptionValue}
                                                /> */}
                                                <MDEditor height={200} value={smartDescriptionValue} onChange={smartDescription} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{backgroundColor: 'white',color: '#333333'}} />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {visibleField.includes('button') && (
                                        <span>
                                          {value.button && value.button !== null && (
                                            <ul className="listview image-listview text flush">
                                              {value.button.map((btn, index) => (
                                                <li className="custom-line">
                                                  <a
                                                    href="#!"
                                                    className="item ps-0"
                                                    onClick={() =>
                                                      handleShowButtonModal(
                                                        value.button,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <div className="in">
                                                      <div>
                                                        <button
                                                          type="button"
                                                          className="btn btn-primary btn-sm"
                                                          disabled
                                                        >
                                                          {/* <ion-icon name="logo-facebook"></ion-icon> */}
                                                          <span
                                                            className={btn.icon}
                                                          ></span>
                                                          &nbsp;
                                                          {btn.title}
                                                        </button>
                                                      </div>
                                                      <span className="text-muted">
                                                        Edit
                                                      </span>
                                                    </div>
                                                  </a>
                                                </li>
                                              ))}
                                              {value.button.length <
                                                maxButton && (
                                                <li className="custom-line">
                                                  <a
                                                    href="#!"
                                                    className="item ps-0"
                                                    onClick={() =>
                                                      handleShowButtonModal(
                                                        value.button,
                                                        value.button.length
                                                      )
                                                    }
                                                  > 
                                                    <div className="in">
                                                      <div>
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-secondary btn-sm"
                                                        >
                                                          <ion-icon name="add-outline"></ion-icon>
                                                          Button
                                                        </button>
                                                      </div>
                                                      <span className="text-muted">
                                                        Add
                                                      </span>
                                                    </div>
                                                  </a>
                                                </li>
                                              )}
                                              <input
                                                type="hidden"
                                                name={'button' + key}
                                              />
                                            </ul>
                                          )}
                                        </span>
                                      )}
                                      {(blockStyleCode === 'SM_HEAD' || blockStyleCode === 'SM_FOOT') &&
                                        <div className="form-group basic">
                                          <div className="input-wrapper">
                                            <label
                                              className="form-label mb-2"
                                              htmlFor="navigation"
                                            >
                                              <div className="row">
                                                <div className="col-12 col-md-12">
                                                  Navigation
                                                </div>
                                              </div>
                                            </label>
                                            <button className="btn btn-secondary btn-sm btn-block" type="button" onClick={handleNavigation}>Manage Navigation</button>
                                          </div>
                                        </div>
                                        
                                      }
                                    </div>
                                  )}
                                {/* </div> */}
                                <div className="sidebar-buttons webweb-sidebar-buttons">
                                  <button
                                    className="btn btn-block btn-success"
                                    type="submit"
                                    style={{ borderRadius: 'initial' }}
                                    disabled={disableUpdateButton}
                                  >
                                    <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                    Update
                                  </button>
                                </div>
                              </div>
                            </form>
                          </>
                        ))}
                      </div>
                    )}
                    {itemID !== undefined && itemID !== -1 && (
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          {/* <h2 className="accordion-header webweb-accordian-bg">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={'#accordionItem'}
                              style={{ backgroundColor: '#eeeeee' }}
                            >
                              <ion-icon name="grid-outline"></ion-icon>
                              {items[itemID].title}
                              <span
                                className="acc-trash-icon"
                                style={{
                                  position: 'absolute',
                                  right: '15px',
                                  zIndex: 10
                                }}
                              >
                                <ion-icon
                                  name="trash-outline"
                                  onClick={() => {
                                    handleDeleteContent(blockID, itemID)
                                  }}
                                  className="text-danger fs-6"
                                  style={{ cursor: 'pointer' }}
                                ></ion-icon>
                              </span>
                            </button>
                          </h2> */}
                          <div
                            id={'accordionItem'}
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="text-end pe-4">
                              <ion-icon
                                  name="trash-outline"
                                  onClick={() => {
                                    handleDeleteContent(blockID, itemID)
                                  }}
                                  className="text-danger fs-6"
                                  style={{ cursor: 'pointer' }}
                              ></ion-icon>
                            </div>
                            <form onSubmit={handleSubmit}>
                              <div className="accordion-body">
                                <div className="text-end"></div>
                                {visibleField.includes('image') && (
                                  <span>
                                    <div className="form-group basic">
                                      <div className="input-wrapper">
                                        <label
                                          className="form-label mb-0"
                                          htmlFor="title"
                                        >
                                          <div className="row">
                                            <div className="col-6">Image</div>
                                            <div className="col-6 text-end">
                                              <div className="webweb-checkbox form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id="imageCheckbox"
                                                  name="inputField"
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="imageCheckbox"
                                                ></label>
                                              </div>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <span>
                                      {items[itemID].image && items[itemID].image !== null && (
                                        <div className="row">
                                          {items[itemID].image.map(
                                            (img, index) => (
                                              <div
                                                className="col-4 col-md-4 mb-1"
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <div className="d-flex justify-content-center align-items-center">
                                                  <img
                                                    src={img.src}
                                                    alt={img.alt}
                                                    className="imaged w-100 border border-primary border-2"
                                                    onClick={() =>
                                                      handleShowImageModal(
                                                        items[itemID].image,
                                                        index
                                                      )
                                                    }
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="image"
                                                    value={img.src}
                                                  />
                                                </div>
                                              </div>
                                            )
                                          )}
                                          {items[itemID].image.length <
                                            maxImage && (
                                            <div
                                              className="col-4 col-md-4 mb-1"
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <div className="d-flex justify-content-center align-items-center">
                                                <img
                                                  src={AddImage}
                                                  alt="Avatar"
                                                  className="imaged w-100 border border-primary border-2"
                                                  onClick={() =>
                                                    handleShowImageModal(
                                                      items[itemID].image,
                                                      items[itemID].image.length
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </span>
                                  </span>
                                )}
                                {visibleField.includes('title') && (
                                  <div className="form-group basic">
                                    <div className="input-wrapper">
                                      <label className="form-label mb-0" htmlFor="title">
                                        <div className="row">
                                          <div className="col-6">Title</div>
                                          <div className="col-6 text-end">
                                            <div className="webweb-checkbox form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="titleCheckbox"
                                                name="inputField"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="titleCheckbox"
                                              ></label>
                                            </div>
                                          </div>
                                        </div>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Enter Title"
                                        autoComplete="off"
                                        name="title"
                                        onChange={e => {
                                          handleInputLength(
                                            e.target.value,
                                            'title'
                                          )
                                        }}
                                      />
                                      <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                      </i>
                                    </div>
                                  </div>
                                )}
                                {visibleField.includes('subtitle') && (
                                  <div className="form-group basic">
                                    <div className="input-wrapper">
                                      <label className="form-label mb-0" htmlFor="name5">
                                        <div className="row">
                                          <div className="col-6">Subtitle</div>
                                          <div className="col-6 text-end">
                                            <div className="webweb-checkbox form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="subtitleCheckbox"
                                                name="inputField"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="subtitleCheckbox"
                                              ></label>
                                            </div>
                                          </div>
                                        </div>
                                      </label>
                                      <textarea
                                        id="address5"
                                        rows="1"
                                        className="form-control"
                                        placeholder="Enter Subtitle"
                                        name="subtitle"
                                        onChange={e => {
                                          handleInputLength(
                                            e.target.value,
                                            'subtitle'
                                          )
                                        }}
                                      ></textarea>
                                      <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                      </i>
                                    </div>
                                  </div>
                                )}
                                {visibleField.includes('url') && (
                                  <div className="form-group basic">
                                    <div className="input-wrapper">
                                      <label className="form-label mb-0" htmlFor="url">
                                        <div className="row">
                                          <div className="col-6">URL</div>
                                          <div className="col-6 text-end">
                                            <div className="webweb-checkbox form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="urlCheckbox"
                                                name="inputField"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="urlCheckbox"
                                              ></label>
                                            </div>
                                          </div>
                                        </div>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Enter URL"
                                        autoComplete="off"
                                        name="url"
                                        onChange={e => {
                                          handleInputLength(e.target.value, 'url')
                                        }}
                                      />
                                      <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                      </i>
                                    </div>
                                  </div>
                                )}
                                {visibleField.includes('description') && (
                                  <div className="form-group basic">
                                    <div className="input-wrapper">
                                      <label
                                        className="form-label mb-0"
                                        htmlFor="lablename5"
                                      >
                                        <div className="row">
                                          <div className="col-6">Description</div>
                                          <div className="col-6 text-end">
                                            <div className="webweb-checkbox form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="descriptionCheckbox"
                                                name="inputField"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="descriptionCheckbox"
                                              ></label>
                                            </div>
                                          </div>
                                        </div>
                                      </label>
                                      {/* <SimpleMDE
                                        options={{
                                          autofocus: true,
                                          spellChecker: false,
                                          nativeSpellcheck: false,
                                          toolbar: toolbar,
                                          status: false,
                                          minHeight: '150px',
                                          padding: '100px'
                                        }}
                                        onChange={onChange}
                                        value={value}
                                      /> */}
                                      <MDEditor height={200} value={value} onChange={onChange} commands={[bold, italic, title, commands.divider, unorderedListCommand, orderedListCommand, checkedListCommand, commands.divider, image, link]} extraCommands={[]} preview='edit' style={{backgroundColor: 'white',color: '#333333'}} />
                                    </div>
                                  </div>
                                )}
                                {visibleField.includes('button') && (
                                  <span>
                                    {items[itemID].button && items[itemID].button !== null && (
                                      <ul className="listview image-listview text flush">
                                        {items[itemID].button.map(
                                          (btn, index) => (
                                            <li className="custom-line">
                                              <a
                                                href="#!"
                                                className="item ps-0"
                                                onClick={() =>
                                                  handleShowButtonModal(
                                                    items[itemID].button,
                                                    index
                                                  )
                                                }
                                              >
                                                <div className="in">
                                                  <div>
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary btn-sm"
                                                      disabled
                                                    >
                                                      <span
                                                        className={btn.icon}
                                                      ></span>
                                                      &nbsp;
                                                      {btn.title}
                                                    </button>
                                                  </div>
                                                  <span className="text-muted">
                                                    Edit
                                                  </span>
                                                </div>
                                              </a>
                                            </li>
                                          )
                                        )}
                                        {items[itemID].button.length <
                                          maxButton && (
                                          <li className="custom-line">
                                            <a
                                              href="#!"
                                              className="item ps-0"
                                              onClick={() =>
                                                handleShowButtonModal(
                                                  items[itemID].button,
                                                  items[itemID].button.length
                                                )
                                              }
                                            >
                                              <div className="in">
                                                <div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-outline-secondary btn-sm"
                                                  >
                                                    <ion-icon name="add-outline"></ion-icon>
                                                    Button
                                                  </button>
                                                </div>
                                                <span className="text-muted">
                                                  Add
                                                </span>
                                              </div>
                                            </a>
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </span>
                                )}
                              </div>
                              <div className="sidebar-buttons webweb-sidebar-buttons">
                                <button
                                  className="btn btn-block btn-success"
                                  type="submit"
                                  style={{ borderRadius: 'initial' }}
                                  disabled={disableUpdateButton}
                                >
                                  <ion-icon name="checkmark-outline"></ion-icon>{' '}
                                  Update
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                    {message === 'blockProperties' && (
                      <div className="wide-block pb-2 border-bottom-0">
                        <div className="row">
                          <div className="col-6 col-md-6">
                            <div className="form-group basic">
                              <div className="input-wrapper">
                                <label className="form-label" htmlFor="filter">
                                  Filter
                                </label>
                                <select
                                  className="form-control form-select"
                                  id="filter"
                                  value={itemFilterType}
                                  onChange={e => {
                                    setItemFilterType(e.target.value)
                                  }}
                                >
                                  <option value="first">First</option>
                                  <option value="last">Last</option>
                                  <option value="all">All</option>
                                  <option value="random">Random</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-md-6 d-flex align-items-center">
                            {/* <div className="stepper stepper-md">
                              <a
                                href="#!"
                                className="stepper-button stepper-down"
                                onClick={() => {
                                  handleStepDown()
                                }}
                              >
                                -
                              </a>
                              <input
                                type="text"
                                className="form-control"
                                value={noItems}
                                onChange={e => setNoItems(e.target.value)}
                                disabled
                              />
                              <a
                                href="#!"
                                className="stepper-button stepper-up"
                                onClick={() => {
                                  handleStepUp()
                                }}
                              >
                                +
                              </a>
                            </div> */}
                            <div className="form-group basic">
                              <div className="input-wrapper">
                                <label className="form-label" htmlFor="count">
                                  Count
                                </label>
                                <select
                                  className="form-control form-select"
                                  id="count"
                                  value={noItems}
                                  onChange={e => setNoItems(e.target.value)}
                                >
                                  {itemCount.map((value,key) => {
                                    return <option value={key + 1}>{key + 1}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: '-16px', marginRight: '-16px' }}
                        >
                          <div className="listview-title mb-0">
                            <span>Reorder Item</span>
                          </div>
                          <Nestable
                            items={contentItems}
                            renderItem={renderItem}
                            maxDepth={1}
                            handler={false}
                            onChange={newItems => {
                              updatedItems = newItems.items
                            }}
                          />
                        </div>
                        <div className="sidebar-buttons webweb-sidebar-buttons">
                          <button
                            className="btn btn-block btn-success"
                            style={{ borderRadius: 'initial' }}
                            onClick={() => {
                              updateContentOrder()
                            }}
                            disabled={disableUpdateButton}
                          >
                            <ion-icon name="checkmark-outline"></ion-icon> Update
                          </button>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="styleTab"
                role="tabpanel"
              >
                <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                        <div className="section full mb-2 mt-2">
                          <div className="row ms-1 me-1">
                              {(blockStyleList.length > 0) ? (
                                <>
                                  {blockStyleList.map(value => {
                                    return (
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center" style={{cursor: 'pointer'}}>
                                          <div style={{height:'145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: (styleID === value.blockID) ? '' : 'gray', backgroundImage: 'url(' + value.thumbnail + ')', backgroundPosition: 'center', border: '2px solid #edcd2f'}} className={(styleID === value.blockID) ? 'd-flex justify-content-center align-items-center text-dark flex-column' : 'd-flex justify-content-center align-items-center text-light flex-column border'} onClick={() => {
                                            handleBlockStyleUpdate(value.blockID)
                                          }}>
                                          </div>
                                          {value.blockName}
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <>
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 text-center" style={{cursor: 'pointer'}}>
                                    <div style={{height:'145px', width: 'auto', backgroundSize: 'cover', borderRadius: '8px', backgroundColor: 'gray', border: '2px solid #edcd2f'}} className="d-flex justify-content-center align-items-center text-dark flex-column">
                                    </div>
                                    Default
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="sidebar-buttons webweb-sidebar-buttons d-flex justify-content-center" style={{height: '40px'}}>
                            <h6 className="mb-0">Select to Change Style </h6>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div
                className={
                  message === 'changeBackground'
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
                id="properties1"
                role="tabpanel"
              >
                <div className="section full mt-1">
                  
                  <div className="accordion" id="accordionExample1">
                    <div className="accordion-item">
                      <h2 className="accordion-header webweb-accordian-bg">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordionBackgroundImage"
                        >
                          <ion-icon name="grid-outline"></ion-icon>
                          Change Background Image
                        </button>
                      </h2>
                      <div
                        id="accordionBackgroundImage"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body pb-1">
                          <div className="text-end"></div>
                          <div className="row">
                            <div className="col-12 col-md-12">
                              <div className="form-check form-check-inline me-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioInline"
                                  id="radioInlineDefault1"
                                  onChange={e => setQuery(e.target.value)}
                                  value={'color'}
                                  checked={query === 'color' ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radioInlineDefault1"
                                >
                                  {' '}
                                  Color{' '}
                                </label>
                              </div>

                              <div className="form-check form-check-inline me-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioInline"
                                  id="radioInlineDefault2"
                                  onChange={e => setQuery(e.target.value)}
                                  value={'image'}
                                  checked={query === 'image' ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radioInlineDefault2"
                                >
                                  {' '}
                                  Image
                                </label>
                              </div>

                              <div className="form-check form-check-inline me-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioInline"
                                  id="radioInlineDefault3"
                                  onChange={e => setQuery(e.target.value)}
                                  value={'none'}
                                  checked={
                                    query === 'none' || query === ''
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="radioInlineDefault3"
                                >
                                  {' '}
                                  None
                                </label>
                              </div>
                            </div>

                            {query === 'image' && (
                              <div className="col-4 col-md-4 mb-2">
                                <div className="d-flex justify-content-center align-items-center">
                                  <ion-icon
                                    name="create-outline"
                                    className="position-absolute"
                                  ></ion-icon>
                                  <img
                                    src="assets/img/sample/avatar/avatar1.jpg"
                                    alt="avatar"
                                    className="imaged w-100 border"
                                  />
                                </div>
                              </div>
                            )}
                            {query === 'color' && (
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <input
                                    type="color"
                                    className="form-control form-control-sm"
                                    id="title"
                                    value={propValue}
                                    autoComplete="off"
                                    onChange={e => setValue(e.target.value)}
                                  />
                                  <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                  </i>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {(blockStyleCode !== 'SM_HEAD' && blockStyleCode !== 'SM_FOOT') && (
                      <div className="accordion-item">
                        <h2 className="accordion-header webweb-accordian-bg">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#blockData"
                          >
                            <ion-icon name="grid-outline"></ion-icon>
                            Block Heading / Description
                          </button>
                        </h2>
                        <div
                          id="blockData"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample1"
                        >
                          <div className="accordion-body pb-1">
                            <div className="text-end"></div>
                            <div className="row">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label
                                    className="form-label mb-1"
                                    htmlFor="blockHeading"
                                  >
                                    <div className="row">
                                      <div className="col-12">Block Heading</div>
                                    </div>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    placeholder="Enter block heading"
                                    autoComplete="off"
                                    name="blockHeading"
                                  />
                                  <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label
                                    className="form-label mb-1"
                                    htmlFor="blockDescription"
                                  >
                                    <div className="row">
                                      <div className="col-12">
                                        Block Description
                                      </div>
                                    </div>
                                  </label>
                                  {/* <input type="text" className="form-control form-control-sm" placeholder="Enter block description" autoComplete="off" name="blockDescription"/> */}
                                  <textarea
                                    rows="1"
                                    className="form-control form-control-sm"
                                    placeholder="Enter block description"
                                    name="blockDescription"
                                  ></textarea>
                                  <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="sidebar-buttons webweb-sidebar-buttons">
                    <button
                      className="btn btn-block btn-success"
                      style={{ borderRadius: 'initial' }}
                      onClick={() => {
                        updateBlockProperties()
                      }}
                      disabled={disableUpdateButton}
                    >
                      <ion-icon name="checkmark-outline"></ion-icon> Update
                    </button>
                  </div>

                  {/* {blockStyleCode === 'SM_HEAD' && (
                    <>
                      <div className="sidebar-buttons webweb-sidebar-buttons">
                        <button
                          className="btn btn-block btn-success"
                          style={{ borderRadius: 'initial' }}
                          onClick={() => {
                            updateHeaderSitemap('header')
                          }}
                          disabled={disableUpdateButton}
                        >
                          <ion-icon name="checkmark-outline"></ion-icon> Update
                        </button>
                      </div>
                    </>
                  )}
                  {blockStyleCode === 'SM_FOOT' && (
                    <>
                      <div className="sidebar-buttons webweb-sidebar-buttons">
                        <button
                          className="btn btn-block btn-success"
                          style={{ borderRadius: 'initial' }}
                          onClick={() => {
                            updateHeaderSitemap('footer')
                          }}
                          disabled={disableUpdateButton}
                        >
                          <ion-icon name="checkmark-outline"></ion-icon> Update
                        </button>
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
  const {
    isImageModalVisible,
    imageData,
    imageDataObj,
    buttonDataObj,
    isButtonModalVisible,
    buttonData,
    blockid,
    newImageData,
    itemCount
  } = offcanvas
  const {
    appearance: websiteDataAppearnace,
    blocks: websiteDataBlocks,
    contents: websiteDataContents,
    blockRules
  } = websiteData
  return {
    offcanvasIsImageModalVisible: isImageModalVisible,
    offcanvasImageData: imageData,
    offcanvasIsButtonModalVisible: isButtonModalVisible,
    offcanvasButtonData: buttonData,
    websiteDataAppearnace,
    websiteDataBlocks,
    websiteDataContents,
    blockid,
    imageDataObj,
    buttonDataObj,
    blockRules,
    newImageData,
    itemCount
  }
}

const mapDispatchToProps = {
  hideOffcanvas,
  showImageModal,
  showButtonModal,
  showDeleteModal,
  setWebsiteData,
  showAppToast,
  newImageData,
  newButtonData,
  showNavigationOffcanvas,
  showFormOffcanvas
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightPanel)