import React, { useState, useEffect } from 'react'
import axios from '../../AxiosConfig'
import { connect } from "react-redux"
import { showHtmlEditor } from '../../features/modal/offcanvasSlice'

const AddTemplate = ({
    showHtmlEditor
}) => {
    const [templateData, setTemplateData] = useState(null)
    const [blockData, setBlockData] = useState(null)
    const [blockListSection, setBlockListSection] = useState(false)
    const [blockStyleSection, setBlockStyleSection] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [blockStyleData, setBlockStyleData] = useState(null)

    const [activeTemplate, setActiveTemplate] = useState('')
    const [activeBlock, setActiveBlock] = useState('')

    useEffect(() => {
        axios.get('/info/getTemplateList').then(res=>{
            if (res.data.message === 'Success') {
                setTemplateData(res.data.data)
                console.log(res.data.data)
            } else {
              console.log('SOMETHING WENT WRONG')
            }
        })
    }, [])

    const setBlockList = (themeName) => {
        setBlockListSection(true)
        setActiveTemplate(themeName)
        Object.keys(templateData.Templates).map((value, key) => {
            if (value === themeName) {
                setBlockData(templateData['Templates'][value])
                setTemplateName(value)
            }
        })
    }

    const setBlockStyleList = (styleName) => {
        setBlockStyleSection(true)
        setActiveBlock(styleName)
        Object.keys(templateData.Templates[templateName]['blocks']).map((value, key) => {
            if (value === styleName) {
                setBlockStyleData(templateData.Templates[templateName]['blocks'][value]['styles'])
            }
        })
    }

    const handleHtmlEditor = () => {
        showHtmlEditor({isCodeEditorVisible:true})
    }

    return (
        <div id="appCapsule">
            <div className="section full mt-1">
                <div className="border-0 ps-2 pe-2">
                    <form className="search-form">
                        <div className="input-group form-group boxed p-0">
                            <input type="text" className="form-control" placeholder="Search Image" aria-label="Recipient's username" aria-describedby="button-addon2" value="query" style={{height: '34px'}} />
                            <button className="btn h-custom btn-secondary" type="submit" id="button-addon2" style={{height:'auto !important'}}>
                                <ion-icon name="search-sharp" className="fs-6"></ion-icon>
                            </button>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="row p-1">
                            <div className="col-md-6">
                                <div className="listview-title p-0">Templates</div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <span className="badge badge-secondary" style={{marginRight: '2px'}}>
                                    {templateData !== null && templateData.TemplatesCount}
                                </span>
                                <span className="badge badge-primary" style={{cursor: 'pointer'}}>
                                    <ion-icon name="add-outline"></ion-icon>
                                </span>
                            </div>
                        </div>
                        <ul className="listview image-listview">
                            {templateData !== null &&
                                <>
                                    {Object.keys(templateData.Templates).map((value, key) => {
                                        return <>
                                        {activeTemplate !== value &&
                                            <li>
                                                <a href="javascript:void(0)" onClick={() => {setBlockList(value)}} className="item">
                                                    <div className="icon-box bg-secondary">
                                                        {key + 1}
                                                    </div>
                                                    <div className="in">
                                                        {value}
                                                        <span className="badge badge-primary">{templateData.Templates[value]['blocksCount']}</span>
                                                    </div>
                                                </a>
                                            </li>
                                        }
                                        {activeTemplate === value &&
                                            <li className="webweb-bg-secondary">
                                                <a href="javascript:void(0)" onClick={() => {setBlockList(value)}} className="item">
                                                    <div className="icon-box bg-secondary">
                                                        {key + 1}
                                                    </div>
                                                    <div className="in">
                                                        {value}
                                                        <span className="badge badge-primary">{templateData.Templates[value]['blocksCount']}</span>
                                                    </div>
                                                </a>
                                            </li>
                                        }</>
                                    })}
                                </>
                            }
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <div className="row p-1">
                            <div className="col-md-6">
                                <div className="listview-title p-0">Blocks</div>
                            </div>
                            <div className="col-md-6 text-end">
                                <span className="badge badge-secondary">{blockData !== null && blockData.blocksCount}</span>
                            </div>
                        </div>
                        {blockListSection &&
                            <ul className="listview image-listview">
                                {blockData !== null &&
                                    <>
                                        {Object.keys(blockData['blocks']).map((value, key) => {
                                            return <>
                                                {activeBlock !== value &&
                                                    <li>
                                                        <a href="javascript:void(0)" className="item" onClick={() => {setBlockStyleList(value)}}>
                                                            <div className="icon-box bg-secondary">
                                                                {key + 1}
                                                            </div>
                                                            <div className="in">
                                                                {value}
                                                                <span className="badge badge-primary">{blockData['blocks'][value]['styleCount']}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                                {activeBlock === value &&
                                                    <li className="webweb-bg-secondary">
                                                        <a href="javascript:void(0)" className="item" onClick={() => {setBlockStyleList(value)}}>
                                                            <div className="icon-box bg-secondary">
                                                                {key + 1}
                                                            </div>
                                                            <div className="in">
                                                                {value}
                                                                <span className="badge badge-primary">{blockData['blocks'][value]['styleCount']}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                            </>
                                        })}
                                    </>
                                }
                            </ul>
                        }
                    </div>
                    <div className="col-md-4">
                        <div className="row p-1">
                            <div className="col-md-6">
                                <div className="listview-title p-0">Styles 
                                    &nbsp;&nbsp;{templateData !== null && templateData.StyleCount}
                                </div>
                            </div>
                            <div className="col-md-6 text-end">
                                <span className="badge badge-primary" style={{cursor: 'pointer'}}>
                                    <ion-icon name="add-outline"></ion-icon>
                                </span>
                            </div>
                        </div>
                        {blockStyleSection &&
                            <ul className="listview image-listview">
                                {blockStyleData !== null &&
                                    <>
                                        {blockStyleData.map((value, key) => {
                                            return <li>
                                                <a href="javascript:void(0)" className="item" onClick={handleHtmlEditor}>
                                                    <div className="icon-box bg-secondary">
                                                        {key + 1}
                                                    </div>
                                                    <div className="in">
                                                        {value}
                                                    </div>
                                                </a>
                                            </li>
                                        })}
                                    </>
                                }
                            </ul>
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
const mapDispatchToProps = {
    showHtmlEditor
}

export default connect(mapStateToProps,mapDispatchToProps)(AddTemplate)