import React from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import Share from './Share'
import GetBoost  from './GetBoost'
import EmailCampaign  from './EmailCampaign'

function Boost() {
    const search = useLocation().search
    const type = new URLSearchParams(search).get('type')
    const getClassNames = (tabName) => {
        return classNames('nav-link', { active: tabName === type })
    }
    return (
        <div>
            {/* <div className="extraHeader" style={{zIndex: 10}}>
                <ul className="nav nav-tabs lined" role="tablist">
                    <li className="nav-item">
                        <Link className={getClassNames("share")} to="/boost?type=share" href="#!" role="tab">
                            Share Block
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={getClassNames("getBoost")} to="/boost?type=getBoost" href="#!" role="tab">
                            Get Boost
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={getClassNames("emailCampaign")} to="/boost?type=emailCampaign" href="#!" role="tab">
                            Email Campaign
                        </Link>
                    </li>
                </ul>
            </div> */}
            <div id="appCapsule">
                {type === 'share' &&
                    <Share />
                }
                {type === 'getBoost' &&
                    <GetBoost />
                }
                {type === 'emailCampaign' &&
                    <EmailCampaign />
                }
            </div>
        </div>
    )
}

export default Boost
