import React from 'react'

function FabButton(props) {
    return (
        <div className="fab-button text bottom-center" style={{bottom:"70px"}}>
            <a href={props.redirectTo} className="fab">
                <ion-icon name="add-outline"></ion-icon>
                {props.value}
            </a>
        </div>
    )
}

export default FabButton
