import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideHtmlEditor } from '../../../features/modal/offcanvasSlice'
import { EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const HtmlEditor = ({
    hideHtmlEditor,
    isCodeEditorVisible
}) => {
    return (
        <div>
            <Modal show={isCodeEditorVisible} centered class="modal fade modalbox" data-bs-backdrop="false" tabindex="-1" role="dialog">
                <div class="modal-content" style={{position: 'fixed', top:0, left:0, bottom:0,right:0}}>
                    <Modal.Header style={{boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%)", padding: '0px'}}>
                        <div class="row w-100">
                            <div class="col-6 col-md-6 d-flex align-items-center p-2">
                                <h3 class="mb-0">HTML Editor</h3>
                            </div>
                            <div class="col-6 col-md-6 d-flex justify-content-end text-secondary fs-3 align-items-center">
                                <span class="dropdown">
                                    <span class="headerButton" style={{cursor: "pointer"}} onClick={() => {hideHtmlEditor()}}>
                                        <ion-icon name="close-outline"></ion-icon>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body class="modal-body">
                        <div>
                            <div class="card shadow-none">
                                <div>
                                    <p class="card-text">
                                        <Editor
                                            // editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            // onEditorStateChange={this.onEditorStateChange}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isCodeEditorVisible
    } = offcanvas
    return {
        isCodeEditorVisible
    }
  }
  
  const mapDispatchToProps = {
    hideHtmlEditor
  }

export default connect(mapStateToProps,mapDispatchToProps)(HtmlEditor)