import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import axios from '../../AxiosConfig'
import moment from 'moment'
import NoEnquiry from '../images/NoEnquiry.svg'

const Forms = ({
    
}) => {

    const [formList, setFormList] = useState({})
    const search = useLocation().search
    const ID = new URLSearchParams(search).get('id')

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.AliasDomain !== '' ? tokenData.SelectedDomain.AliasDomain : tokenData.SelectedDomain.Domain
    useEffect(() => {
        axios.get('/analytics/getFormsList/' + selectedDomain).then(res=>{
            if (res.data.message === 'Success') {
                setFormList(res.data.data)
            } else {
                console.log('SOMETHING WENT WRONG')
            }
        })
    }, [])

    return (
        <div id="appCapsule">
            <div className="section-title justify-content-center">{selectedDomain}</div>
            <ul className="listview image-listview">
                {Object.keys(formList).map(key => {
                    return (<li>
                        <Link to={'/formDetail?formType=' + key} className="item">
                            <ion-icon name="document-text-outline" class="rounded-0 fs-2 me-1"></ion-icon>
                            <div className="in">
                                <div>
                                    {key}
                                    <footer>{moment(formList[key]['LastUpdatedOn']).startOf('hour').fromNow()}</footer>
                                </div>
                                <span className="badge badge-secondary">Unread {formList[key]['UnreadCount']}</span>
                            </div>
                        </Link>
                    </li>)
                })}
            </ul>
            {formList.length === 0 &&
                <div className="text-center">
                    <div className="error-page">
                        <img src={NoEnquiry} alt="No Enquiry" className="w-100" />
                        <h1 className="title">No Enquiries Found</h1>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = () => {
    return {}
  }
  
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Forms)
