import React from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { showEditOffcanvas, showDeleteModal, showButtonModal, newButtonData, showUpdateDataOffcanvas, setNewBlockType } from '../../features/modal/offcanvasSlice'
import MDEditor from '@uiw/react-md-editor'
import { cssConverter } from '../../features/websiteData/websiteDataSlice'

const CN_LIST_SLDR = ({
    items = [],
    blockID,
    showEditOffcanvas,
    properties,
    seq,
    showDeleteModal,
    websiteDataBlocks,
    showButtonModal,
    newButtonData, 
    showUpdateDataOffcanvas, 
    setNewBlockType
}) => {
    let navigate = useNavigate()
    let contentID = null
    const handleBlockItemUpdate = (itemID) => {
        contentID = itemID
        showEditOffcanvas({ isEditVisible:true, itemID:itemID, items:items, blockID: blockID, properties:properties,seq:seq })
    }
    const tempItems = items ? items : []

    const handleDetailInfo = (e, itemID) => {
        e.stopPropagation()
        console.log(itemID)
        showEditOffcanvas({ itemID:itemID, items:items, blockID: blockID, properties:properties, seq:seq })
        navigate('/detailPage')
    }

    const handleNewEditItem = (itemID) => {
        setNewBlockType({ 'BlockType' : blockID.slice(0, 2), 'BlockID' : blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': itemID });
        // console.log("whatIsKey",itemID, blockID);
        showUpdateDataOffcanvas({
          isEditVisible: true,
          itemID: itemID,
          items: items,
          blockID: blockID,
          properties:properties
        })
      }

    const handleDeleteContent = (blockName, deleteItemID) => {
        const contentName = websiteDataBlocks[blockName].items.contents.split(
          '.'
        )[1]
        showDeleteModal({
          isDeleteVisible: true,
          deleteMessage: 'Are your sure? You want to delete this content?',
          triggerMessage: 'content',
          deleteID: contentName,
          deleteItemID: deleteItemID
        })
    }

    function handleShowButtonModal(buttonData, ID) {
        handleBlockItemUpdate(ID)
        setTimeout(() => {
          showButtonModal({
            isButtonModalVisible: true,
            buttonData: buttonData,
            buttonID: ID
          })
        }, [1000])
        newButtonData({ buttonDataObj: buttonData })
      }
    return (
        <div style={{backgroundColor: properties.backgroundValue, backgroundImage: properties && properties.background && properties.background.image && `url${properties.background.image}`, backgroundColor: properties && properties.background && properties.background.color && `${properties.background.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
            <div  className={properties.container === 'true' ? 'container pt-4 pb-4' : 'pt-4 pb-4'}>
                {(properties.blockHeading !== '' || properties.blockDescription !== '') &&
                    <div className="row">
                        {properties.blockHeading !== '' &&
                            <div className="col-12">
                                <h3 className="text-center" style={cssConverter(properties.blockHeadingCss)} dangerouslySetInnerHTML={{ __html: properties.blockHeading }} />
                            </div>
                        }
                        {properties.blockDescription !== '' &&
                            <div className="col-12">
                            <p className="text-center" style={cssConverter(properties.blockDescriptionCss)} dangerouslySetInnerHTML={{ __html: properties.blockDescription }} />
                            </div>
                        }
                    </div>
                }
                <Splide
                options={{
                    perPage: 4,
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                        1080: {
                            perPage: 4,
                        },
                    },
                    rewind: true,
                    type: "loop",
                    gap: 10,
                    arrows: false,
                    pagination: false
                }}
                >
                    {items.map((item, key) => {
                        const firstImage = (item.image && item.image.length > 0) ? item.image[0].src : ''
                        return(<SplideSlide key={key}>
                            <div>
                                <div className="card position-relative"  style={cssConverter(item.itemCss, {backgroundImage: item && item.itemBackground && item.itemBackground.image && `url${item.itemBackground.image}`, backgroundColor: item && item.itemBackground && item.itemBackground.color && `${item.itemBackground.color}` , backgroundPosition: 'center', backgroundRepeat: 'no-repeat'})}>
                                    <img src={firstImage} className="card-img-top" alt="image" style={cssConverter(item.imageCss)} />
                                    <div className="card-body">
                                        <h5 className="card-title cursor-pointer" style={cssConverter(item.titleCss)} onClick={() => {handleBlockItemUpdate(key)}} dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <span onClick={() => {handleBlockItemUpdate(key)}} className='cursor-pointer bg-transparent'><MDEditor.Markdown source={item.subtitle} linkTarget="_blank" className="card-text bg-transparent" style={cssConverter(item.subtitleCss)} /></span>
                                        <span onClick={() => {handleBlockItemUpdate(key)}} className='cursor-pointer bg-transparent'><MDEditor.Markdown source={item.description} linkTarget="_blank" className="card-text" style={cssConverter(item.descriptionCss)} /></span>
                                        <div className="mt-2">
                                            {item.button.map((value, key) => {
                                                return <button style={cssConverter(item.buttonCss)} className={`btn btn-sm me-1 mb-1 ${value.style && value.style !== '' ? value.style : 'btn-primary'}`} onClick={() => handleShowButtonModal(item.button,key)}>{value.title}</button>
                                            })}
                                        </div>
                                    </div>
                                    <div className='d-flex pos-absglr'>
                                        {/* <div className="col-4 col-md-4 text-end">
                                        <span className="badge bg-secondary p-1" style={{width: '55px', cursor: 'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}>Edit</span>
                                        </div> */}
                                        <div className="col-6 col-md-6 text-end">
                                            <span className="badge bg-success p-1 editBtnCss" onClick={() => {handleNewEditItem(key)}}>Edit</span>
                                        </div>
                                        <div className="col-6 col-md-6">
                                        <span className="badge bg-danger p-1 cursor-pointer editBtnCss" onClick={() => {handleDeleteContent(blockID, key)}}>Delete</span>
                                        </div>
                                    </div>
                                    {/* <span className="badge bg-secondary position-absolute" style={{top: '5px', right: '5px', cursor: 'pointer'}} onClick={() => {handleBlockItemUpdate(key)}}>Edit</span> */}
                                </div>
                            </div>
                        </SplideSlide>)
                    })}
                </Splide>
                {/* <div>
                    <div className="section full">

                        <div className="row p-2 pt-3 pb-3">
                            {tempItems && tempItems.map((item, key) => {
                                const firstImage = (item && item.image && item.image.length > 0) ? item.image[0].src : '' 
                                return(<div className="col-md-3 col-6">
                                    <div className="card" onClick={() => {handleBlockItemUpdate(key)}} style={{zIndex: '-5'}}>
                                        <img src={firstImage} className="card-img-top" alt="image" onClick={(e) => {handleDetailInfo(e, key)}} />
                                        <div className="card-body">
                                            <h5 className="card-title">{item.title}</h5>
                                            <p className="card-text">{item.subtitle}</p>
                                            <p className="card-text"><small>{item.description}</small></p>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>

                    </div>
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
      isVisible
    } = offcanvas
    const { 
        blocks: websiteDataBlocks 
    } = websiteData
    return {
      offcanvasVisible: isVisible,
      websiteDataBlocks
    }
}
  
const mapDispatchToProps = {
    showEditOffcanvas,
    showDeleteModal,
    showButtonModal,
    newButtonData,
    showUpdateDataOffcanvas, 
    setNewBlockType
}

export default connect(mapStateToProps, mapDispatchToProps)(CN_LIST_SLDR)
