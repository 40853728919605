import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideCategoryOffcanvas } from '../../../features/modal/offcanvasSlice'

const CategoryManage = ({
    isCategoryVisible,
    hideCategoryOffcanvas
}) => {
    return (
        <Offcanvas show={isCategoryVisible} placement={'bottom'} backdrop={true} scroll={true}>
            <div className="row p-1" style={{boxShadow: '0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%)'}}>
                <div className="col-6 col-md-6 d-flex align-items-center">
                    <strong className="text-muted fs-6">Add Category</strong>
                </div>
                <div className="col-6 col-md-6 d-flex align-items-center justify-content-end">
                    <button className="btn btn-sm btn-success">Add</button>
                    <span
                    className="text-muted fs-5"
                    style={{ cursor: 'pointer' }}
                    onClick={hideCategoryOffcanvas}
                    >
                        <ion-icon name="close"></ion-icon>
                    </span>
                </div>
            </div>
            <Offcanvas.Body>
                <div className="section full mb-2">
                    <div className="section full mt-1">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label" htmlFor="categoryName">Category Name</label>
                                <input type="text" className="form-control form-control-sm" id="categoryName" placeholder="Enter category name"
                                    autoComplete="off" />
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        isCategoryVisible
    } = offcanvas
    return {
        isCategoryVisible        
    }
}
  
const mapDispatchToProps = {
    hideCategoryOffcanvas
}

export default connect(mapStateToProps,mapDispatchToProps)(CategoryManage)