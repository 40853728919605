import React, { useState, useEffect } from 'react';
import axios from '../../AxiosConfig'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import jwt_decode from 'jwt-decode'

ChartJS.register(ArcElement, Tooltip, Legend);

function Device(props) {

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const website_ID = tokenData.SelectedDomain.Website_ID

    const today = new Date()
    const numberOfDaysToMinus = 30
    const fdate = today.setDate(today.getDate() - numberOfDaysToMinus); 
    const defaultFromDate = new Date(fdate).toISOString().split('T')[0] // yyyy-mm-dd

    const todayDate = new Date();
    const tdate = todayDate.setDate(todayDate.getDate()); 
    const defaultToDate  = new Date(tdate).toISOString().split('T')[0] // yyyy-mm-dd

    let [fromDate, setFromDate] = useState(defaultFromDate)
    let [toDate, setToDate] = useState(defaultToDate)
    const [deviceVisitors, setDeviceVisitors] = useState(null)
    const [deviceReportData, setDeviceReportData] = useState([])
    const [screenResolutionReportData, setScreenResolutionReportData] = useState([])
    const [deviceHitsReportData, setDeviceHitsReportData] = useState([])

    const getData = () => {
        let mobileBrand = []
        let count = []
        axios.get('/analytics/deviceReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{
            console.log(res)
            setDeviceReportData(res.data.data)
            for(const dataObj of res.data.data){
                mobileBrand.push(dataObj.DeviceBrand);
                count.push(parseInt(dataObj.sum));
            }

            setDeviceVisitors({
                labels: mobileBrand,
                datasets: [
                {
                    label: '# of Visitors',
                    data: count,
                    backgroundColor: [
                    'rgba(255, 99, 132,1)',
                    'rgba(54, 162, 235,1)',
                    'rgba(255, 206, 86,1)',
                    'rgba(75, 192, 192,1)',
                    'rgba(153, 102, 255,1)',
                    'rgba(255, 159, 64,1)',
                    ],
                    borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
                ],
            })
        })

        axios.get('/analytics/deviceHitsReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{
            console.log(res)
            setDeviceHitsReportData(res.data.data)
        })

        axios.get('/analytics/screenResolutionReport/'+ website_ID +'/'+ fromDate +'/' + toDate).then(res=>{
            console.log(res)
            setScreenResolutionReportData(res.data.data)
        })
    }

    const handleFromDateChange = ev => setFromDate(ev.target.value);
    const handleToDateChange = ev => setToDate(ev.target.value);

    useEffect(() => {
        getData()
    }, [fromDate, toDate])
    
    return (
        <div className="section mt-2 card-body">
            <div className="row">
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">From</label>
                            <input type="date" className="form-control" id="name5" autoComplete="off" defaultValue={fromDate} onChange={handleFromDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <label className="form-label" htmlFor="name5">To</label>
                            <input type="date" className="form-control" id="name6" autoComplete="off" defaultValue={toDate} onChange={handleToDateChange} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Top Devices</h5>
                                {deviceVisitors && deviceReportData.length > 0 && 
                                <Doughnut data={deviceVisitors} />
                                }
                                {deviceReportData.length === 0 && 
                                   <div className="text-danger text-center">No Data Found</div> 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Top Devices</h5>
                                <div className="row">
                                    {deviceReportData.map((user) => (
                                        <>
                                            <div className="col-6">{user.DeviceBrand}</div>
                                            <div className="col-6">{user.sum}</div>
                                        </>
                                    ))}
                                    {deviceReportData.length === 0 && 
                                        <div className="text-danger text-center">No Data Found</div> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Devices</h5>
                                <div className="row">
                                    <div className="col-6">
                                        Tablet/Phone
                                    </div>
                                    <div className="col-6">
                                        {deviceHitsReportData.mobileTablet}
                                    </div>
                                    <div className="col-6">
                                        PC
                                    </div>
                                    <div className="col-6">
                                        {deviceHitsReportData.computer}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-subtitle">Screen Resolutions</h5>
                                <div className="row">
                                    {screenResolutionReportData.map((user) => (
                                        <>
                                            <div className="col-6">{user.Resolution}</div>
                                            <div className="col-6">{user.sum}</div>
                                        </>
                                    ))}
                                    {screenResolutionReportData.length === 0 && 
                                        <div className="text-danger text-center">No Data Found</div> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Device