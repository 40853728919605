import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import SwitchImage from '../images/404-error.svg'
import { connect } from "react-redux"
import LoadingImage from '../images/loading-animate.svg'

const NotFound = ({
    websiteDataAppearnace = {}
}) => {
    const [loadingDom, setLoadingDom] = useState(false)

    let navigate = useNavigate()

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')

    useEffect(() => {
        if (!websiteDataAppearnace.hasOwnProperty('pages')) {
            setLoadingDom(true)
        } else {
            if (websiteDataAppearnace.pages[url] === undefined) {
                setLoadingDom(false)
            } else {
                setLoadingDom(true)
            }
        }
    }, [websiteDataAppearnace])

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/load?domain=&type=')
        }, 5000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
        {!loadingDom && 
            <div>
                <div className="error-page">
                    <img src={SwitchImage} alt="Error" className="imaged square w-100" />
                    <h1 className="title">Error 404</h1>
                </div>
            </div>
        }
        {loadingDom && 
            <div>
                <div className="error-page">
                    <img src={LoadingImage} alt="Error" className="imaged square w-100" />
                    <h1 className="title">Loading Page...</h1>
                </div>
            </div>
        }
        </>
    )
}

const mapStateToProps = ({ websiteData }) => {
    const {
        appearance: websiteDataAppearnace
      } = websiteData
    return {
        websiteDataAppearnace
    }
  }
  
  const mapDispatchToProps = {}

export default  connect(mapStateToProps, mapDispatchToProps)(NotFound)
