import React from 'react'
import Logo from '../images/icon/webweb-logo.png'

const OtpValidation = ({ OTP, setOTP, disableVerifyBtn, submitVerifyForm }) => {
  return (
    <div id="appCapsule" className="pt-0 pb-0">
      <div className="container-fluid buildForm validationbuildForm">
        <div className="row">
          <div className="col-md-5  mt-4">
            <div className="image-logo justify-content-start d-flex mb-4 ps-5">
              <img class="logo-img" src={Logo} />
            </div>
            <form action="app-pages.html">

              <div class="form-group boxed ps-5">
                <div class="input-wrapper">
                  {/* <label class="otp-text text-center w-100 mb-3" for="name5">Authenticate your phone number</label> */}
                  <h1 class="authenticate-phone pt-3">Authenticate your <br />phone number</h1>
                  <input type="text" class="form-control verify-input code-input" id="smscode" value={OTP} onChange={(e) => setOTP(e.target.value)} autoFocus placeholder="••••••" maxlength="6" />
                </div>
              </div>
              <div class="validate-btn mt-4 d-flex ps-5"> 
              {/* justify-content-center */}
                <button className="btn btn-lg btn-primary submit-btn-build-website" type="button" disabled={disableVerifyBtn} onClick={(e) => submitVerifyForm()}>
                  Authorise
                </button>
              </div>
            </form>
          </div>

          
          <div className="col-md-7 position-relative only-map">
            <div class="validate-otp">
              <div class=" mt-2 mb-5 validate-box">
              <div class="overlay"></div>
              </div>
            </div>

            <div className="shape shape-start shape-fluid-y svg-shim text-white">
              <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"></path></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtpValidation
