import React, { useState } from 'react'
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import ToolBar from '../Elements/Management/ToolBar'
import { setResponsiveIframe, showSwitchModal, showOffcanvas } from '../../features/modal/offcanvasSlice'

const DetailPage = ({
    items,
    properties,
    blockID,
    blockType,
    itemID,
    message,
    seq,
    setResponsiveIframe,
    showSwitchModal,
    showOffcanvas
}) => {
    console.log(items, itemID, properties, blockID, blockType, message, seq)

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    const [urlShow, setUrlShow] = useState(true)
    const [launchValue, setLaunchValue] = useState()

    let navigate = useNavigate()

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    
    let pageName = ''
    if (localStorage.getItem('previousPage') !== '' && localStorage.getItem('previousPage') !== null) {
        pageName = localStorage.getItem('previousPage')
        console.log(pageName)    
    } else {
        if (items.length > 0) {
            if (itemID !== -1 && itemID !== undefined) {
                pageName = items[itemID].title                
            } else {
                pageName = ''
            }
        } else {
            pageName = ''
        }
    }
    

    const handleLaunchSite = (e) => {
        e.preventDefault()
        console.log('Site Launched')
        setLaunchValue(true)
        axios.get('/website/publishWebsite/'+ selectedDomain).then(res=>{
            setResponsiveIframe({runPartyParticals: true})
            setLaunchValue(false)
        })
    }

    const handlesSearchDomain = (e) => {
        e.stopPropagation()
        navigate('/searchDomain')
    }

    const handlePreview = (e) => {
        e.stopPropagation()
        window.open('https://' + selectedDomain + '/' + url)
    }

    const handleSwitchModal = () => {
        console.log('SWITCH MODAL CLICKED')
        showSwitchModal({isSwitchModalVisible: true})
    }

    const handleBack = () => {
        window.history.go(-1)
    }
    
    const handleRefresh = () => {
        axios.get('/user/refresh?domain=' + selectedDomain + '&type=website').then(res => {
            console.log('Refresh Token')
            localStorage.setItem('token', res.data.data)
        })
    }

    const handleBlockDescriptionUpdate = (itemID) => {
        showOffcanvas({ isVisible:true, itemID:itemID, items:items, blockID: blockID, properties:properties,seq:seq })
    }

    return (
        <div id="appCapsule" className="container shadow">
            <div className="wide-block p-0">
                <div className="row w-100 m-0" style={{paddingLeft: '1%', paddingRight: '12px'}}>
                    <div className="col-2 col-md-1">
                        <div className="w-100 d-flex" style={{paddingTop: '10px'}}>
                        <div className="w-50">
                            <ion-icon name="arrow-back-outline" className="fs-5 text-secondary" onClick={handleBack} style={{cursor: 'pointer'}}></ion-icon>
                        </div>
                        <div className="w-50">
                            <ion-icon name="refresh-outline" className="fs-5 text-secondary" onClick={handleRefresh} style={{cursor: 'pointer'}}></ion-icon>
                        </div>
                        </div>
                    </div>
                    <div className="col-8 col-md-10 p-0">
                        <div
                        className="form-group boxed"
                        onClick={handleSwitchModal}
                        >
                        <div className="input-wrapper">
                            <div
                            className="form-control headerButton rounded-pill custom-disabled-color d-flex align-items-center"
                            style={{paddingLeft: '10px !important', fontSize: '14px', height: '28px'}}
                            >
                            <div style={{width: 'auto'}}>
                                <ion-icon
                                name="lock-closed"
                                data-bs-toggle="tooltip"
                                data-placement="bottom"
                                data-bs-html="true"
                                title="<ion-icon name='lock-closed-outline'></ion-icon> Your connection is secure"
                                style={{
                                    position: 'absolute',
                                    fontSize: '18px !important',
                                    zIndex: 1
                                }}
                                className="text-success header-address-bar"
                                ></ion-icon>
                                <div style={!urlShow ? {textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '60%', cursor: 'pointer'}: {cursor: 'pointer'}}>
                                    <span className="text-secondary" onClick={(e) => {handlePreview(e)}}>&nbsp;https://{selectedDomain}/{url}</span>
                                    <span className="text-primary" onClick={(e) => {handlesSearchDomain(e)}}>
                                    &nbsp;Connect Your Domain
                                    </span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-2 col-md-1">
                        <div className="w-100 d-flex justify-content-end" style={{paddingTop: '10px'}}>
                        {launchValue &&
                            <button className="btn btn-sm btn-primary" type="button" disabled style={{height: '20px', width: '40px', fontSize: '10px'}}>
                            <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                            Publishing...
                            </button>
                        }
                        {!launchValue &&
                            <button className="btn btn-sm btn-primary" style={{height: '20px', width: '40px', fontSize: '10px'}}  onClick={handleLaunchSite}>Publish</button>
                        }
                        </div>
                    </div>
                </div>
            </div>
            <ToolBar show={true} blockType={blockType} seq={seq} items={items} properties={properties} blockID={blockID} />
            <div className="section wide-block" style={{backgroundColor: 'properties.backgroundValue', boxShadow: '0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%)'}} onClick={() => {handleBlockDescriptionUpdate(itemID)}}>
                <div className="w-100 text-center">
                    <img src={items[itemID].image[0].src} alt={items[itemID].image[0].alt} className="imaged square w-50" />
                </div>
                <div className="section-title">
                    {items[itemID].title} 
                </div>
                <div className="pt-2 pb-1 ps-2 pe-2" style={{backgroundColor: 'properties.backgroundValue'}}>
                    <p style={{fontSize:'14px'}}>
                        {items[itemID].subtitle}<br />
                        {items[itemID].description}
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ offcanvas }) => {
    const {
        items,
        properties,
        blockID,
        blockType,
        itemID,
        message,
        seq
    } = offcanvas
    return {
        items,
        properties,
        blockID,
        blockType,
        itemID,
        message,
        seq
    }
  }
  
  const mapDispatchToProps = {
    setResponsiveIframe,
    showSwitchModal,
    showOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage)
