import React from 'react'
import { connect } from 'react-redux'
import { showOffcanvas, showDeleteModal, setNewBlockType, showUpdateDataOffcanvas } from '../../features/modal/offcanvasSlice'

const SM_MAPE_DFL1 = ({
    properties,
    items,
    blockType,
    blockID,
    showOffcanvas,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}) => {

    return (
        <div>
            <div className="section wide-block p-2 pt-4 position-relative">
                <div className="card">
                    <div className="card-body p-0">
                        {/* <h5 className="card-title">Card title</h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                            card's content.</p> */}
                        <iframe width="100%" height="300" allowFullScreen="" aria-hidden="false" tabindex="0" src={items[0].url}></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    showOffcanvas,
    showDeleteModal,
    setNewBlockType,
    showUpdateDataOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SM_MAPE_DFL1)
