import React from 'react'

function Story(props) {
    return (
        <div className="modal fade stories" id="StorywithButton" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="story-image">
                        <img src="assets/img/sample/photo/vertical4.jpg" alt="Story" />
                    </div>
                    <div className="modal-header">
                        <a href="#!" className="profile-detail">
                            <img src="assets/img/sample/avatar/avatar4.jpg" alt="avatar"
                                className="imaged w36 rounded me-1" />
                            <div>
                                <h4>Ashley Graham</h4>
                                <span className="text-muted">5 minutes ago</span>
                            </div>
                        </a>
                        <span data-bs-dismiss="modal" className="close-stories">
                            <ion-icon name="close"></ion-icon>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div>
                            <h1>Another Title Here</h1>
                            <p>This is simple text for the story</p>
                            <a href="#!" className="btn btn-primary">Do something</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Story
