import React from 'react'
import WebwebColorPicker from './WebwebColorPicker'

const FormProperties = ({

}) => {
    return (
        <div className="section full mt-2 mb-2 border-top">
            {null}
            {/* <form>
                <div className="row">
                    <div className="col-12 col-md-12 mt-2">
                        <label className="form-label mb-1" htmlFor="fontSize" style={{color: 'red', fontSize: '24px'}}>Font Preview</label>
                    </div>
                    <div className="col-7 col-md-7">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label mb-1" htmlFor="fontSize">Font Size</label>
                                <input type="range" className="form-control form-range border-0 p-0" min="0" max="5" id="fontSize"></input>
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-5">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label mb-1" htmlFor="fontColor">Font Color</label>
                                <WebwebColorPicker></WebwebColorPicker>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className="form-label mb-1 fw-bolder" htmlFor="border">Border</label>
                        <div className="row p-1" style={{border: '2px red solid'}}>
                            <div className="col-2 col-md-2">
                                <label className="form-label" htmlFor="border">Width</label>
                                <input type="number" className="form-control p-0" id="border" placeholder="0"
                                autoComplete="off" />
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div className="col-1 col-md-1 d-flex align-items-end pb-1">
                                px
                            </div>
                            <div className="col-5 col-md-5">
                                <label className="form-label mb-1" htmlFor="border">Color</label>
                                <WebwebColorPicker></WebwebColorPicker>
                            </div>
                            <div className="col-4 col-md-4">
                                <div className="form-group basic p-0">
                                    <div className="input-wrapper">
                                        <label className="form-label mb-1" htmlFor="type">Type</label>
                                        <select className="form-control form-select p-0" id="type">
                                            <option value="dotted">Dotted</option>
                                            <option value="dashed">Dashed</option>
                                            <option value="solid">Solid</option>
                                            <option value="double">Double</option>
                                            <option value="groove">Groove</option>
                                            <option value="ridge">Ridge</option>
                                            <option value="none">None</option>
                                        </select>
                                    </div>
                                </div>
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className="form-label mb-1" htmlFor="textAlignment">Text Alignment</label>
                        <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="textAlignment" id="textAlignment1" checked />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textAlignment1">Left</label>

                            <input type="radio" className="btn-check" name="textAlignment" id="textAlignment2" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textAlignment2">Center</label>

                            <input type="radio" className="btn-check" name="textAlignment" id="textAlignment3" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textAlignment3">Right</label>
                        </div>
                    </div>
                </div>

                <div className="form-group basic">
                    <div className="input-wrapper">
                        <label className="form-label mb-1" htmlFor="textTransform">Text Transform</label>
                        <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="textTransform" id="textTransform1" checked />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textTransform1">Uppercase</label>

                            <input type="radio" className="btn-check" name="textTransform" id="textTransform2" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textTransform2">Lowercase</label>

                            <input type="radio" className="btn-check" name="textTransform" id="textTransform3" />
                            <label className="btn btn-outline-primary btn-sm" htmlFor="textTransform3">Capitalize</label>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-7 col-md-7">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label mb-1" htmlFor="textShadow1">Text Shadow</label>
                                <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="textShadow" id="textShadow1" />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="textShadow1">On</label>

                                    <input type="radio" className="btn-check" name="textShadow" id="textShadow2" checked />
                                    <label className="btn btn-outline-primary btn-sm" htmlFor="textShadow2">Off</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-5">
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="form-label mb-1" htmlFor="shadowColor">Shadow Color</label>
                                <WebwebColorPicker></WebwebColorPicker>
                            </div>
                        </div>
                    </div>
                </div>

                    <div className="form-group basic mb-1">
                        <div className="input-wrapper">
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <label className="form-label" htmlFor="marginPadding">Margin / Padding</label>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                                    <div className="form-check form-switch webweb-custom-switch-sm">
                                        <label htmlFor="SwitchCheckDefault1" style={{paddingRight: '3px', fontSize: '11px'}}>px</label>
                                        <input className="form-check-input" type="checkbox" id="SwitchCheckDefault1" />
                                        <label className="form-check-label" htmlFor="SwitchCheckDefault1" style={{paddingLeft: '48px', fontSize: '11px'}}>%</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-none">
                        <div className="card-body border" style={{padding: '26px 43px 26px 43px'}}>
                            <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '0',width: '16%',left: '50%', transform: 'translateX(-50%)'}} />
                            <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '50%',width: '16%',right: '0px',transform: 'translateY(-50%)'}} />
                            <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '50%',width: '16%',left: '0px',transform: 'translateY(-50%)'}} />
                            <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',bottom: '0',width: '16%',left: '50%', transform: 'translateX(-50%)'}} />
                            <div className="card shadow-none">
                                <div className="card-body border" style={{height: '90px'}}>
                                    <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '0',width: '24%',left: '50%', transform: 'translateX(-50%)'}} />
                                    <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '50%',width: '24%',right: '0px',transform: 'translateY(-50%)'}} />
                                    <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',top: '50%',width: '24%',left: '0px',transform: 'translateY(-50%)'}} />
                                    <input type="number" className="form-control p-0" id="margin-top" placeholder="px" style={{position: 'absolute',bottom: '0',width: '24%',left: '50%', transform: 'translateX(-50%)'}} />
                                </div>
                            </div>
                        </div>
                    </div>

            </form> */}
        </div>
    )
}

export default FormProperties
