import React from 'react'

function SM_SITE_DFL1(props) {
    return (
        <div>
            <div className="section wide-block">
                <div className="card rounded-0" style={{backgroundColor: props.properties.backgroundValue}}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <img src="assets/img/WebsiteAutomation.svg" alt="avatar" className="image w-100 rounded-3" />
                            </div>
                            <div className="col-md-6 col-12">
                                <form>
                                    
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="businessname">Business Name</label>
                                            <input type="text" className="form-control" id="businessname" placeholder="Business Name" autoComplete="off" value="Business Name" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="individualname">Individual Name</label>
                                            <input type="text" className="form-control" id="individualname" placeholder="Individual Name" autoComplete="off" value="Individual Name" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="phone5">Whatsapp No.</label>
                                            <input type="tel" className="form-control" id="phone5" placeholder="Whatsapp No." value="Whatsapp No." />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="businessemail">Business Email</label>
                                            <input type="text" className="form-control" id="businessemail" placeholder="Business Email" autoComplete="off" value="username@example.com" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="Address">Address</label>
                                            <input type="text" className="form-control" id="Address" placeholder="Address" autoComplete="off" value="Address" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="category">Category</label>
                                            <input type="text" className="form-control" id="category" placeholder="Category" autoComplete="off" value="Category" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <label className="form-label" htmlFor="tags">Tags</label>
                                            <input type="text" className="form-control" id="tags" placeholder="Tags" autoComplete="off" value="Tags" readOnly />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SM_SITE_DFL1
