import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { connect } from "react-redux"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { hideAppearance, hidePageSetting } from '../../../features/modal/offcanvasSlice'
import { setWebsiteData, setInfoData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const Rightpanel = ({
    isAppearanceVisible,
    hideAppearance,
    hidePageSetting,
    showAppToast,
    setInfoData,
    fonts,
    templates,
    cropperConfig,
    appearance,
    setWebsiteData,
    isAppearanceVisibleShow
}) => {
    // console.log(fonts)
    const [favicon, setFavicon] = useState('')
    const [colors, setColors] = useState({})
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [fontList, setFontList] = useState([]);
    const [fontList2, setFontList2] = useState([]);
    const [newFontObj, setNewFontObj] = useState({})
    const [templateList, setTemplateList] = useState([])
    const [activeLoader, setActiveLoader] = useState(false)
    const [index, setIndex] = useState(null)
    const [activeTemplateName, setActiveTemplateName] = useState('')
    const [colorTabSwitch, setColorTabSwitch] = useState(false);

    const [colorSettings, setColorSettings] = useState(false);

    const [colorPallete, setColorPallete] = useState([]);

    let oldTemplateName = ''
    const collectData = () => {
        if (appearance !== undefined) {
            if (oldTemplateName === '') {
                oldTemplateName = appearance.properties.template
            }
        }
        setValue()
    }
    

    useEffect(() => {
        if (templates) {
            if (templates.length === 0 || templates === undefined) {
                axios.get('info/style/templates').then(res => {
                    if (res.data.message === 'Success') {
                        setTemplateList(res.data.data)
                    } else {
                        console.log("SOMETHING WENT WRONG")
                    }
                })
            } else {
                setTemplateList(templates)
            }
        }
    }, [templates])

    useEffect(() => {
        if (templateList.length > 0) {
            setInfoData({
                fonts: [],
                templates: templates,
                cropperConfig: []
            })
        }
    }, [templateList])

    const fetchWebsiteJSON = () => {
        const webDoc = localStorage.getItem('website')
        const res = (webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()

    useEffect(() => {
        // console.log(isAppearanceVisibleShow);
        if (isAppearanceVisibleShow) {
            setActiveTemplateName(websiteJSON.appearance.properties.template)
            // console.log(websiteJSON.appearance.properties.template);
            collectData();
        }
        if (colorPallete.length === 0) {
            axios.get('info/style/colors').then(res => {
                if (res.data.message === 'Success') {
                    const getColors = res.data.data;
                    let sendColors = [];
                    const currentColor = websiteJSON.appearance.properties.colors[websiteJSON.appearance.properties.mode];
                    console.log(currentColor);
                    const iscolorTheme = getColors.filter((color) => (
                            color.primary.toLowerCase() === currentColor.primary.toLowerCase() &&
                            color.secondary.toLowerCase() === currentColor.secondary.toLowerCase() &&
                            color.primaryActive.toLowerCase() === currentColor.primaryActive.toLowerCase() &&
                            color.secondaryActive.toLowerCase() === currentColor.secondaryActive.toLowerCase()
                        )
                    );
                    if(iscolorTheme.length === 0) {
                        sendColors.push(JSON.parse(JSON.stringify(currentColor)));
                    }
                    sendColors = [...sendColors, ...getColors];
                    console.log(sendColors);
                    setColorPallete(sendColors);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [isAppearanceVisibleShow]);

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false

    let selectedDomain = ""
    let selectedCategoryID = ''

    if (tokenData.userInfo.RoleID != 'Moderator') {
        selectedDomain = tokenData.SelectedDomain.Domain
    } else {
        selectedDomain = tokenData.SelectedDomain.CategoryName
        selectedCategoryID = tokenData.SelectedDomain.ID
    }

    const setValue = () => {

        if (tokenData.userInfo.RoleID != 'Moderator') {
            setFavicon(websiteJSON.appearance.properties.favicon)
            console.log(websiteJSON.appearance.properties.colors[websiteJSON.appearance.properties.mode]);
            setColors(websiteJSON.appearance.properties.colors[websiteJSON.appearance.properties.mode])
            // console.log(websiteJSON.appearance.properties.colors[websiteJSON.appearance.properties.mode])

            // document.getElementsByName("fontName")[0].value = websiteJSON.appearance.properties.font.fontName
            // document.getElementsByName("fontSize")[0].value = websiteJSON.appearance.properties.font.fontSize
            // document.getElementsByName("language")[0].value = websiteJSON.appearance.properties.language
        } else {
            axios.get('/website/category/' + selectedCategoryID).then(res => {
                let categoryConfig = {};
                categoryConfig = JSON.parse(res.data.data.Config)
                setColors(categoryConfig.colorConfig.light)
            })
        }
    }

    useEffect(() => {
        if (colors !== undefined && colors !== '' && colors !== {}) {
            {
                Object.keys(colors).map((key) => (
                    document.getElementsByName(key)[0].value = colors[key]
                ))
            }
        }
    }, [colors])

    useEffect(() => {
        if (websiteJSON.appearance) {
            let fontObj = {}
            let newFontName = ""
            let fontSize = ""
            if (tokenData.userInfo.RoleID != 'Moderator') {
                newFontName = websiteJSON.appearance.properties.font.fontName
                fontSize = { "fontSize": websiteJSON.appearance.properties.font.fontSize }
            } else {
                newFontName = "Nunito"
                fontSize = "M"
            }
            {
                fontList.map(value => {
                    value.FontPack !== undefined && value.FontPack.map(itme => {
                        if (itme.fontName.includes(newFontName)) {
                            fontObj = { ...itme, ...fontSize }
                        }
                    })
                })
            }
            // console.log(fontObj)
            setNewFontObj(fontObj)
        }
    }, [fontList]);

    // const handleFontStyle = () => {
    //     let fontObj = {}
    //     const newFontName = document.getElementById('fontName').value
    //     {fontList.map(value => {
    //         if (value.fontName.includes(newFontName)) {
    //             value.fontSize = document.getElementById('fontSize').value
    //             fontObj = value
    //         }
    //     })}
    //     setNewFontObj(fontObj)
    // }
    const handleWebsitePropertyUpdate = (fontFamily, themeColor) => {
        // e.preventDefault()
        // console.log(fontFamily)
        let colorsData = []
        {
            Object.keys(colors).map((key, value) => (
                colorsData.push({ [key]: document.getElementsByName(key)[0].value })
            ))
        }
        const colorsDataObj = Object.assign({}, ...colorsData)

        let mode = ''
        if (tokenData.userInfo.RoleID != 'Moderator') {
            mode = websiteJSON.appearance.properties.mode
        } else {
            mode = 'light'
        }
        // const font = {
        //     "fontName": document.getElementsByName("fontName")[0].value,
        //     "fontSize": document.getElementsByName("fontSize")[0].value
        // }
        let styleUpdate = {}
        let font = null
        if (fontFamily !== undefined) {
            fontFamily.fontSize = document.getElementsByName("fontSize")[0].value
            font = fontFamily
            styleUpdate = {
                "colors": { [mode]: colorsDataObj },
                "font": font,
                "language": ''
            }
        } else if (themeColor !== undefined) {
            newFontObj.fontSize = document.getElementsByName("fontSize")[0].value
            font = newFontObj
            styleUpdate = {
                "colors": { [mode]: themeColor },
                "font": font,
                "language": ''
            }
        } else {
            newFontObj.fontSize = document.getElementsByName("fontSize")[0].value
            font = newFontObj
            styleUpdate = {
                "colors": { [mode]: colorsDataObj },
                "font": font,
                "language": ''
            }
        }

        setDisableSubmitButton(true)

        if (tokenData.userInfo.RoleID != 'Moderator') {
            axios.put('appearance/' + selectedDomain, styleUpdate).then(res => {

                if (res.data.message === 'Success') {
                    setDisableSubmitButton(false)
                    setWebsiteData(res.data.data)
                    localStorage.setItem(
                        'website',
                        btoa(
                            unescape(encodeURIComponent(JSON.stringify(res.data.data)))
                        )
                    )
                    setNewFontObj(font)
                    hideAppearance()
                    hidePageSetting()
                    showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                } else {
                    setDisableSubmitButton(false)
                    hideAppearance()
                    hidePageSetting()
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                }
            })
        } else {
            axios.put('appearance/updateCategoryConfig/' + selectedCategoryID, styleUpdate).then(res => {
                // if (res.data.message === 'Success') {
                //     setDisableSubmitButton(false)
                //     setWebsiteData(res.data.data)
                //     localStorage.setItem(
                //         'website',
                //         btoa(
                //         unescape(encodeURIComponent(JSON.stringify(res.data.data)))
                //         )
                //     )
                //     hideAppearance()
                //     showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                // } else {
                //     setDisableSubmitButton(false)
                //     hideAppearance()
                //     showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                // }
            })
        }
    }

    const handleFontStyle = (fontStyleName) => {
        // console.log(fontStyleName)
        //  document.getElementById('fontName').value
        // console.log(fontList)
        // const one = fontList.map(value => {
        //     if (value.fontName === fontStyleName) {
        //         let fontObj = {}
        //         console.log(value.fontName, fontStyleName)
        //         value.fontSize = document.getElementById('fontSize').value
        //         fontObj = value
        //         console.log(fontObj)
        //         return fontObj
        //     }
        // })
        const two = fontList2.filter(value => value.fontName === fontStyleName)
        if (two.length !== 0) {
            two[0].fontSize = document.getElementById('fontSize').value
        }
        // console.log(one, two)
        // setNewFontObj(one[0])
        // console.log(one[0])
        if (two[0] !== undefined) {
            handleWebsitePropertyUpdate(two[0])
        }
    }

    useEffect(() => {
        if (fonts.length === 0) {
            axios.get('info/style/fonts').then(res => {
                if (res.data.message === 'Success') {
                    const getFont = res.data.data;
                    const fontPacks = [];
                    let tempArray = [];
                    for (let i = 0; i < getFont.length; i++) {
                        tempArray.push(getFont[i]);
                        if ((i + 1) % 4 === 0 || i === getFont.length - 1) {
                            fontPacks.push({ FontPack: tempArray });
                            tempArray = [];
                        }
                    }
                    // console.log(fontPacks);

                    setFontList(fontPacks);
                    setFontList2(getFont)
                    setInfoData({
                        fonts: fonts,
                        templates: templates,
                        cropperConfig: cropperConfig
                    })
                } else {
                }
            })
        } else {
            setFontList(fonts)
        }
    }, [])

    const handleTemplateChange = (k, templateName) => {
        setActiveLoader(true)
        setIndex(k)
        axios.put('appearance/' + selectedDomain, { "template": templateName }).then(res => {

            if (res.data.message === 'Success') {
                setActiveLoader(false)
                setWebsiteData(res.data.data)
                localStorage.setItem(
                    'website',
                    btoa(unescape(encodeURIComponent(JSON.stringify(res.data.data))))
                )
                showAppToast({ toastMessage: 'UPDATED SUCCESSFULLY', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                hideAppearance()
                hidePageSetting()
            } else {
                setActiveLoader(false)
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                hideAppearance()
                hidePageSetting()
            }
        })
    }

    const handleColorTabSwitch = () => {
        setColorTabSwitch(!colorTabSwitch)
        // console.log(colorTabSwitch)
    }

    const changeThemeColor = (item) => {
        const tempcolor = JSON.parse(JSON.stringify(item));
        // tempcolor.primary = item.primary;
        // tempcolor.secondary = item.secondary;
        // tempcolor.primaryActive = item.primaryActive;
        // tempcolor.secondaryActive = item.secondaryActive;
        handleWebsitePropertyUpdate(undefined, tempcolor);
    }
    return (
        <>
            <div className="section full mb-2 overflowX-hidden">
                {/* <div className="row ms-1 me-1">
                                    {templateList.map((item, key) => {
                                        return (<div className="col-6 col-sm-6 col-md-6 col-lg-6 mb-2" style={{cursor: 'pointer'}}>
                                            <div style={{height:'100px', width: 'auto',backgroundColor: (activeTemplateName === item.Name) ? '' : 'gray', backgroundImage: (activeTemplateName === item.Name) ? 'url(assets/img/gradiant.png)' : 'none', backgroundSize: 'cover', borderRadius: '8px'}} className={(activeTemplateName === item.Name) ? 'border-primary d-flex justify-content-center align-items-center text-light flex-column border' : 'd-flex justify-content-center align-items-center text-light flex-column border'} onClick={() => {handleTemplateChange(key, item.Name)}}>
                                                {(activeLoader && (key === index)) &&
                                                    <button className="btn border-0 bg-transparent text-light" type="button">
                                                        <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                        Changing...
                                                    </button>
                                                }
                                                {item.Name}
                                            </div>
                                        </div>)
                                    })}
                                </div> */}
                <div className="row w-100 mb-3 dots-bottom arrow-sm">
                    {/* {fontList.map(value => {
                                            return <div className="col-6 col-md-6 mb-2" onClick={(e) => {handleFontStyle(value.fontName)}}>
                                                <div className="card" style={{cursor: 'pointer', overflow: 'hidden', borderRadius: '5px'}}>
                                                    <div className="card-body d-flex align-items-center justify-content-center p-1">
                                                        <h3 className="mb-0">{value.fontName}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        })} */}

                    <Splide
                        options={{
                            perPage: 1,
                            rewind: true,
                            type: "loop",
                            gap: 0,
                            arrows: true,
                            pagination: true
                        }}
                    >
                        {fontList.map((item, key) => {
                            return (
                                <SplideSlide key={key}>
                                    <div className='container px-2'>
                                        <div className="row flex-row">
                                            {item.FontPack !== undefined && item.FontPack.map((items, i) => {
                                                return (
                                                    <div className="col-6 col-md-6 my-2 position-relative" key={i} onClick={(e) => { handleFontStyle(items.fontName) }}>
                                                        {newFontObj.fontName !== undefined && newFontObj.fontName === items.fontName && <>
                                                            <div className='position-absolute e-0 t-n1 z-index-10 bg-warning cursor-pointer d-flex align-items-center' style={{ height: '20px', width: '20px', borderRadius: '50%' }}>
                                                                <ion-icon name="checkmark-outline" className="fs-8 m-1" style={{ 'transform': 'scale(1.1)', 'marginLeft': '2px' }} aria-label="Delete"></ion-icon>
                                                            </div>
                                                        </>}
                                                        <div className="card" style={{ cursor: 'pointer', overflow: 'hidden', borderRadius: '5px' }}>
                                                            <div className="card-body d-flex align-items-center justify-content-center p-1">
                                                                <h3 className="mb-0">{items.fontName}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                </div>
                <div className="form-group basic d-none">
                    <div className="input-wrapper">
                        <label className="form-label mb-0" htmlFor="fontSize">
                            <div className="row">
                                <div className="col-12">
                                    Font Size
                                </div>
                            </div>
                        </label>
                        <select className="form-control form-control-sm form-select" name="fontSize" id="fontSize">
                            <option value="S" selected>Small</option>
                            <option value="M">Medium</option>
                            <option value="L">Large</option>
                        </select>
                    </div>
                </div>
                <div className={!colorSettings ? "row ps-3 pe-3 pt-2" : 'd-none'}>
                    <div className="form-group basic mb-1">
                        <div className="input-wrapper">
                            <span className='d-none'>{JSON.stringify(colors)}</span>
                            <label className="form-label mb-0" htmlFor="color_palette">Color Palette</label>
                        </div>
                    </div>
                    {
                        colorPallete.map((item, index) => {
                            return (
                                <div className="col-6 col-md-6 px-0 position-relative" key={index}>
                                    <div className="card m-1" style={{ cursor: 'pointer', overflow: 'hidden', borderRadius: '5px' }} onClick={() => changeThemeColor(item)}>
                                        <div className="card-body ps-1 pe-1 pb-0 pt-0">
                                            <div>
                                                <div className="row" style={{ height: '50px' }}>
                                                    <div className="col-3 col-md-3" style={{ 'backgroundColor': item.primary }} title={`Primary: ${item.primary}`}>

                                                    </div>
                                                    <div className="col-3 col-md-3" style={{ 'backgroundColor': item.secondary }} title={`Secondary: ${item.secondary}`}>

                                                    </div>
                                                    <div className="col-3 col-md-3" style={{ 'backgroundColor': item.primaryActive }} title={`Primary Active: ${item.primaryActive}`}>

                                                    </div>
                                                    <div className="col-3 col-md-3" style={{ 'backgroundColor': item.secondaryActive }} title={`Secondary Active: ${item.secondaryActive}`}>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className='d-none'>{JSON.stringify(item)}</span>
                                    {
                                        Object.keys(colors).length > 0 && colors.primary.toLowerCase() === item.primary.toLowerCase() && colors.secondary.toLowerCase() === item.secondary.toLowerCase() && colors.primaryActive.toLowerCase() === item.primaryActive.toLowerCase() && colors.secondaryActive.toLowerCase() === item.secondaryActive.toLowerCase() &&
                                        <div className="position-absolute bg-secondary d-flex justify-content-center align-items-center" style={{ top: '-5px', right: '0px', borderRadius: '100%', height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => setColorSettings(true)}>
                                            <ion-icon name="color-palette-outline" className="text-light" style={{ fontSize: '14px' }}></ion-icon>
                                        </div>
                                    }
                                </div>)
                        })
                    }

                </div>
                <div className={colorSettings ? "ps-2 pe-2 pt-2" : 'd-none'}>
                    <div className="form-group basic">
                        <div className="input-wrapper d-flex align-items-center">
                            <span className='badge bg-primary cursor-pointer me-1' onClick={() => setColorSettings(false)}>
                                <ion-icon name="arrow-back"></ion-icon>
                            </span>
                            <label className="form-label mb-0" htmlFor="address5">Colors</label>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Default</th>
                                    <th scope="col">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ backgroundColor: colors['primary'] }}>
                                    <td className="text-light">
                                        Primary
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['primary']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="primary" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['secondary'] }}>
                                    <td className="text-light">
                                        Secondary
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['secondary']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="secondary" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['primaryActive'] }}>
                                    <td className="text-light">
                                        Primary Active
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['primaryActive']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="primaryActive" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['secondaryActive'] }}>
                                    <td className="text-light">
                                        Secondary Active
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['secondaryActive']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="secondaryActive" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['headingText'] }}>
                                    <td className="text-light">
                                        Heading Text
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['headingText']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="headingText" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['infoText'] }}>
                                    <td className="text-light">
                                        Info Text
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['infoText']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="infoText" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['grey'] }}>
                                    <td className="text-light">
                                        Grey
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['grey']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="grey" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['black'] }}>
                                    <td className="text-light">
                                        Black
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['black']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="black" />
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: colors['white'] }}>
                                    <td className="text-light">
                                        White
                                    </td>
                                    <td>
                                        <p className="text-light m-0" style={{ textShadow: '0 0 3px #000000' }}>{colors['white']}</p>
                                    </td>
                                    <td>
                                        <input type="color" style={{ width: '100%', height: '23px' }} name="white" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="sidebar-buttons webweb-sidebar-buttons">
                        <button className="btn btn-block btn-success" type="button" onClick={() => handleWebsitePropertyUpdate()} style={{ borderRadius: "initial" }} disabled={disableSubmitButton}>
                            <ion-icon name="checkmark-outline"></ion-icon> Update
                        </button>
                    </div>
                </div>
            </div>
            {/* <Offcanvas show={isAppearanceVisible} placement={'end'} backdrop={true} scroll={true} onEntered={collectData} id="appCapsule">
                <Offcanvas.Body className="offcanvas-body ps-0 pe-0 p-0">
                    <div className="profileBox webweb-profileBox">
                        <div className="in">
                            <strong>Appearance</strong>
                        </div>
                        <a href="#!" className="close-sidebar-button" onClick={() => { hideAppearance() }}>
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div className="section full mb-2">
                        <div className="custom-extraHeader position-absolute p-0">
                        <ul className="nav nav-tabs lined" role="tablist">
                            <li className="nav-item">
                                <a className={(colorTabSwitch) ? 'nav-link' : 'nav-link active'} data-bs-toggle="tab" href="#style" role="tab" style={{ height: '46px' }} onClick={handleColorTabSwitch}>
                                    Style
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(!colorTabSwitch) ? 'nav-link' : 'nav-link active'} data-bs-toggle="tab" href="#colors" role="tab" style={{ height: '46px' }} onClick={handleColorTabSwitch}>
                                    Colors
                                </a>
                            </li>
                        </ul>
                    </div>
                        <div className="tab-content" style={{ marginTop: '50px' }}>
                            <form className="ps-2 pe-2" onSubmit={handleWebsitePropertyUpdate}>
                            <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="fontName">Font</label>
                                            <select className="form-control form-control-sm form-select" name="fontName" id="fontName" onChange={handleFontStyle}>
                                                {fontList.map(value => {
                                                    return <option value={value.fontName} selected>{value.fontName}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                            <div className={(colorTabSwitch) ? 'tab-pane fade' : 'tab-pane fade show active'} id="style" role="tabpanel" style={{ 'overflowX': 'hidden' }}>
                                        <div className="section full mt-2 mb-2 container d-flex flex-column align-items-center justify-content-center" style={{ marginTop: '50px' }}>
                                            
                                            <div className="form-group basic d-none">
                                                <div className="input-wrapper">
                                                    <label className="form-label mb-0" htmlFor="fontSize">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                Font Size
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <select className="form-control form-control-sm form-select" name="fontSize" id="fontSize">
                                                        <option value="S" selected>Small</option>
                                                        <option value="M">Medium</option>
                                                        <option value="L">Large</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="form-label mb-0" htmlFor="favicon">Favicon</label>
                                                </div>
                                            </div>
                                            <img src={favicon} alt="favicon" className="w48" />
                                        </div>
                                    </div>
                            <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="form-label mb-0" htmlFor="address5">Colors</label>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Default</th>
                                                    <th scope="col">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <tr style={{backgroundColor: colors['primary']}}>
                                                        <td className="text-light">
                                                            Primary
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['primary']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="primary" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['secondary']}}>
                                                        <td className="text-light">
                                                            Secondary
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['secondary']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="secondary" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['primaryActive']}}>
                                                        <td className="text-light">
                                                            Primary Active
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['primaryActive']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="primaryActive" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['secondaryActive']}}>
                                                        <td className="text-light">
                                                            Secondary Active
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['secondaryActive']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="secondaryActive" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['headingText']}}>
                                                        <td className="text-light">
                                                            Heading Text
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['headingText']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="headingText" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['infoText']}}>
                                                        <td className="text-light">
                                                            Info Text
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['infoText']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="infoText" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['grey']}}>
                                                        <td className="text-light">
                                                            Grey
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['grey']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="grey" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['black']}}>
                                                        <td className="text-light">
                                                            Black
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['black']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="black" />
                                                        </td>
                                                    </tr>
                                                    <tr style={{backgroundColor: colors['white']}}>
                                                        <td className="text-light">
                                                            White
                                                        </td>
                                                        <td>
                                                            <p className="text-light m-0" style={{textShadow: '0 0 3px #000000'}}>{colors['white']}</p>
                                                        </td>
                                                        <td>
                                                            <input type="color" style={{width:'100%', height:'23px'}} name="white" />
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            <div className="sidebar-buttons webweb-sidebar-buttons">
                                        <button className="btn btn-block btn-success" type="submit" style={{borderRadius: "initial"}} disabled={disableSubmitButton}>
                                            <ion-icon name="checkmark-outline"></ion-icon> Update
                                        </button>
                                    </div>
                                </form>
                            <div className='tab-pane fade show active' id="colors" role="tabpanel">

                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas> */}
        </>
    );
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        isAppearanceVisible
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        blocks: websiteDataBlocks,
        contents: websiteDataContents,
        fonts,
        templates,
        cropperConfig
    } = websiteData
    return {
        isAppearanceVisible,
        websiteDataAppearnace,
        websiteDataBlocks,
        websiteDataContents,
        fonts,
        templates,
        cropperConfig
    }
}

const mapDispatchToProps = {
    hideAppearance,
    setWebsiteData,
    showAppToast,
    setInfoData,
    hidePageSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(Rightpanel)