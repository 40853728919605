import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { connect } from "react-redux"
import jwt_decode from 'jwt-decode'
import { showTemplateOffcanvas, hideTemplateOffcanvas, showAddOffcanvas, addPageModal, showAppearance, hideSidebar, showPageSetting, showDeleteModal, showSidebarToggle, showSwitchModal, showClonePageModal, setADDClonePageProps } from '../../../features/modal/offcanvasSlice'
import { setActiveMenu } from '../../../features/utilities/utilitiesSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import 'react-nestable/dist/styles/index.css'
import { logout } from '../../../features/auth/authSlice'

const Sidebar = ({
    showTemplateOffcanvas,
    setWebsiteData,
    showAddOffcanvas,
    showAppToast,
    websiteDataAppearance = { properties: {} },
    websiteDataBlocks = {},
    websiteDataContents = {},
    addPageModal,
    showAppearance,
    hideSidebar,
    isSidebarVisible,
    logout,
    showPageSetting,
    showDeleteModal,
    sidebarToggle,
    showSidebarToggle,
    setActiveMenu,
    activeMenu,
    showSwitchModal,
    showClonePageModal,
    setADDClonePageProps
}) => {
    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain
    // console.log(selectedDomain)
    const userName = tokenData.userInfo.FirstName + ' ' + tokenData.userInfo.LastName
    const userRole = tokenData.userInfo.RoleID

    const search = useLocation().search
    const url = new URLSearchParams(search).get('url')
    // console.log(url)

    const collectData = () => {
        // console.log(websiteDataAppearance, websiteDataBlocks, websiteDataContents)
    }

    let navigate = useNavigate()
    const handleManageSite = () => {
        navigate("/build?url=" + websiteDataAppearance.properties.defaultPage)
    }

    const pageUrl = window.location.href

    const handleLogout = () => {
        logout()
        setTimeout(() => {
          navigate('/')
        }, 1000)
    }

    const handlePageSetting = (activeTabName) => {
        showPageSetting({isPageSettingVisible:true, defaultActiveTab:activeTabName})
    }

    const handleDeletePage = () => {
        showDeleteModal({isDeleteVisible:true, deleteMessage:'Are your sure? You want to delete this page?', triggerMessage:'page', deleteID: url})
    }

    const handleSidebarToggle = () => {
        showSidebarToggle({sidebarToggle: !sidebarToggle})
    }

    const handleActiveMenu = (name) => {
        setActiveMenu({activeMenu: name})
    }

    useEffect(() => {
        if(pageUrl.includes('email')) {
            setActiveMenu({activeMenu: 'digitalMarketing'})
        }else if (pageUrl.includes('overview') || pageUrl.includes('email')) {
            setActiveMenu({activeMenu: 'home'})
        } else if (pageUrl.includes('build')) {
            setActiveMenu({activeMenu: 'editSite'})
        } else {

        }
    }, [])

    const handleSwitchModal = () => {
        // console.log('SWITCH MODAL CLICKED')
        let tempBlockID = ''
        {Object.keys(websiteDataBlocks).map(value => {
            if (value.includes('SM_HEAD')) {
                tempBlockID = value
            }
        })}
        showSwitchModal({isSwitchModalVisible: true, switchModalMessage : 'changePage', blockID: tempBlockID})
    }

    const handleClonePageModal = () => {
        showClonePageModal({isClonePageModalVisible: true})
        setADDClonePageProps({'CloneOrAdd': 'Clone', 'Copies': 1})
    }
    return (
        <div role="dialog" onEntered={collectData} className={(sidebarToggle) ? 'offcanvas offcanvas-start  custom-show show-Sidebar-mobile' : 'offcanvas offcanvas-start custom-show'} tabindex="-1" id="sidebarPanel" style={{boxShadow: '0px 0px 10px gainsboro', transform: 'none !important', zIndex: 11, width: 'inherit'}}>
            <div className="offcanvas-body webweb-bg-secondary">
                <div className="profileBox sidebar-profilebox" style={{width: 'inherit', display: 'contents'}}>
                    <div className="">
                        {/* <strong>{userName}</strong>
                        <div className="text-muted" style={{overflow: "hidden",width: "100%"}}>
                            {userRole}
                        </div> */}
                        <a href="https://webweb.ai/" target="_blank" class="btn btn-primary btn-block rounded-0" style={{height: '46px'}}>Create Website</a>
                    </div>
                </div>
                <div className="section full">
                    <div className="pb-2">
                        <div className="tab-content">
                            {/* <ul className="listview image-listview webweb-bg-secondary">
                                <li className="custom-line">
                                    <Link to="/visitorReport?report=hits" className={(pageUrl.includes('visitorReport') ? 'item pt-0 pb-0 custom-active' : 'item pt-0 pb-0')}>
                                        <div className="icon-box">
                                            <ion-icon name="home-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Home
                                        </div>
                                    </Link>
                                </li>
                                <li className="custom-line">
                                    <a href="#!" className={(pageUrl.includes('build') ? 'item pt-0 pb-0 custom-active' : 'item pt-0 pb-0')} onClick={handleManageSite}>
                                        <div className="icon-box">
                                            <ion-icon name="earth-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Edit Site
                                        </div>
                                    </a>
                                </li>
                                <li className="custom-line">
                                    <Link to="/forms" className={(pageUrl.includes('forms') ? 'item pt-0 pb-0 custom-active' : 'item pt-0 pb-0')}>
                                        <div className="icon-box">
                                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Enquiries
                                        </div>
                                    </Link>
                                </li>
                                <li className="custom-line">
                                    <Link to="/support" className={(pageUrl.includes('support') ? 'item pt-0 pb-0 custom-active' : 'item pt-0 pb-0')}>
                                        <div className="icon-box">
                                            <ion-icon name="headset-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            Support
                                        </div>
                                    </Link>
                                </li>
                            </ul> */}
                            <ul className="listview image-listview webweb-bg-secondary">
                                <li className={(activeMenu === 'controlPanel') ? 'multi-level custom-line' : 'link-listview custom-line'}>
                                    {/* <Link to="/websites" className="item" onClick={() => {handleActiveMenu('controlPanel');handleSidebarToggle()}}>
                                        <div className="icon-box">
                                            <ion-icon name="speedometer-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <div>Control Panel</div>
                                        </div>
                                    </a> */}
                                    
                                    {
                                            tokenData.userInfo.RoleID === 'Admin' &&
                                            <span onClick={() => {handleActiveMenu('controlPanel');handleSidebarToggle()}}>
                                                <Link to="/websites" className="item">
                                                    <div className="icon-box">
                                                        <ion-icon name="speedometer-outline"></ion-icon>
                                                    </div>
                                                    <div className="in">
                                                        <div>Control Panel</div>
                                                    </div>
                                                </Link>
                                            </span>
                                    }
                                    {activeMenu === 'controlPanel' &&
                                        <ul className="listview link-listview webweb-bg-secondary" style={{height: 'auto'}}>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/websites" className={(pageUrl.includes('websites') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Websites</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/domains" className={(pageUrl.includes('domains') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Domains</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/dns" className={(pageUrl.includes('dns') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>DNS</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/EControl" className={(pageUrl.includes('EControl') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Emails</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/categories" className={(pageUrl.includes('categories') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Categories</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className='pt-0 pb-0'>Graphics</a>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/addTemplate" className={((pageUrl.includes('addTemplate') || pageUrl.includes('blockDetail')) ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Template</Link>
                                            </li>
                                        </ul>
                                    }
                                </li>
                                <li className={(activeMenu === 'home') ? 'multi-level custom-line' :'link-listview custom-line'}>
                                {/* multi-level  */}
                                    {/* <span onClick={() => {handleActiveMenu('home');handleSidebarToggle()}}>
                                        <Link to="/overview" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="person-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Admin</div>
                                            </div>
                                        </Link>
                                    </span>
                                    {activeMenu === 'home' &&
                                        <ul className="listview link-listview webweb-bg-secondary" style={{height: 'auto'}}>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/overview" className={(pageUrl.includes('overview') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Overview</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/visitorReport?report=hits" className={(pageUrl.includes('visitorReport') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Analytics</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/DSetting" className={(pageUrl.includes('DSetting') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Domain Setting</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/manageEmail" className={(pageUrl.includes('manageEmail') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Email Accounts</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/appinfo" className={(pageUrl.includes('appinfo') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Support</Link>
                                            </li>
                                        </ul>
                                    } */}
                                    <span onClick={() => {handleActiveMenu('home');handleSidebarToggle()}}>
                                        <Link to="/overview" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="home-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Home</div>
                                            </div>
                                        </Link>
                                    </span>
                                </li>
                                {/* <li className={(activeMenu === 'visitorReport') ? 'multi-level custom-line' :'link-listview custom-line'}>
                                    <span onClick={() => {handleActiveMenu('visitorReport');handleSidebarToggle()}}>
                                        <Link to="/visitorReport?report=hits" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="bar-chart-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Analytics</div>
                                            </div>
                                        </Link>
                                    </span>
                                </li> */}
                                <li className={(activeMenu === 'editSite') ? 'multi-level custom-line' : 'link-listview custom-line'}>
                                {/* multi-level  */}
                                    <a href="javascript:void(0)" className="item" onClick={() => {handleActiveMenu('editSite');handleManageSite();handleSidebarToggle()}}>
                                        <div className="icon-box">
                                            <ion-icon name="earth-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <div>Website</div>
                                        </div>
                                    </a>
                                    {activeMenu === 'editSite' &&
                                        <ul className="listview link-listview webweb-bg-secondary" style={{height: 'auto'}}>
                                            <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className={(pageUrl.includes('build') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')} onClick={handleManageSite}>Manage Website</a>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/visitorReport?report=hits" className={(pageUrl.includes('visitorReport') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Analytics</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/DSetting" className={(pageUrl.includes('DSetting') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Domain Setting</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/manageEmail" className={(pageUrl.includes('manageEmail') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Email Accounts</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/plugins" className={(pageUrl.includes('plugins') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Plugins</Link>
                                            </li>
                                            {/* <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" onClick={(e) => {e.preventDefault();showAppearance({isAppearanceVisible: true})}} className="pt-0 pb-0">Theme Setting</a>
                                            </li> */}
                                            {/* <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={() => {handlePageSetting('custom')}}>Page Style</a>
                                            </li> */}
                                            {/* <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={() => {handlePageSetting('seo')}}>SEO Data</a>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={handleSwitchModal}>Manage Pages <span className="badge badge-primary">6</span></a>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={handleClonePageModal}>Clone Pages</a>
                                            </li> */}
                                            {/* <li onClick={handleSidebarToggle}>
                                                <Link to="/mediaGallery" className={(pageUrl.includes('mediaGallery') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Media Gallery </Link>
                                            </li> */}
                                            {/* <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={() => {addPageModal({showAddPageModal: true})}}>Add Page</a>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <a href="javascript:void(0)" className="pt-0 pb-0" onClick={() => {handleDeletePage()}}>Delete Page</a>
                                            </li> */}
                                        </ul>
                                    }
                                </li>
                                {/* <li className="link-listview" onClick={handleSidebarToggle}>
                                    <Link to="/forms" className={(pageUrl.includes('forms') ? 'item pt-0 pb-0 custom-active' : 'item pt-0 pb-0')} onClick={() => {handleActiveMenu('')}}>
                                        <div className="icon-box">
                                            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <div>Enquiries</div>
                                        </div>
                                    </Link>
                                </li> */}
                                <li className={(activeMenu === 'connect') ? 'multi-level custom-line' : 'link-listview custom-line'}>
                                    <span onClick={() => {handleActiveMenu('connect');handleSidebarToggle()}}>
                                        <Link to="/forms" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="person-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Connect</div>
                                            </div>
                                        </Link>
                                    </span>
                                    {activeMenu === 'connect' &&
                                        <ul className="listview link-listview webweb-bg-secondary" style={{height: 'auto'}}>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/forms" className={(pageUrl.includes('forms') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Form Submissions</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/chatBot" className={(pageUrl.includes('chatBot') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>ChatBot</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/webmail" className={(pageUrl.includes('webmail') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Webmail</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/liveChat" className={(pageUrl.includes('liveChat') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Live Chat</Link>
                                            </li>
                                        </ul>
                                    }
                                </li>
                                <li className={(activeMenu === 'digitalMarketing') ? 'multi-level custom-line' : 'link-listview custom-line'}>
                                    <span onClick={() => {handleActiveMenu('digitalMarketing');handleSidebarToggle()}}>
                                        <Link to="/searchEngineOptimization" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="radio-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Digital Marketing</div>
                                            </div>
                                        </Link>
                                    </span>
                                    {activeMenu === 'digitalMarketing' &&
                                        <ul className="listview link-listview webweb-bg-secondary" style={{height: 'auto'}}>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/searchEngineOptimization" className={(pageUrl.includes('searchEngineOptimization') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Search Engine Optimization</Link>
                                            </li>
                                            {/* <li onClick={handleSidebarToggle}>
                                                <Link to="/socialMediaMarketing" className={(pageUrl.includes('socialMediaMarketing') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Social Media Marketing</Link>
                                            </li> */}
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/socialMedia" className={(pageUrl.includes('socialMedia') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Social Media Marketing</Link>
                                            </li>
                                            <li onClick={handleSidebarToggle}>
                                                <Link to="/emailMarketing" className={(pageUrl.includes('emailMarketing') ? 'pt-0 pb-0 custom-active' : 'pt-0 pb-0')}>Email Marketing</Link>
                                            </li>
                                        </ul>
                                    }
                                </li>
                                {/* <li className={(activeMenu === 'support') ? 'multi-level custom-line' :'link-listview custom-line'}>
                                    <span onClick={() => {handleActiveMenu('support');handleSidebarToggle()}}>
                                        <Link to="/appinfo" className="item">
                                            <div className="icon-box">
                                                <ion-icon name="headset-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>Support</div>
                                            </div>
                                        </Link>
                                    </span>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="sidebar-appBottomMenu p-0 webweb-bg-secondary border-light">
                <Link to="/appInfo" className="item border-end border-light">
                    <div className="col">
                        <ion-icon name="information-circle-outline"></ion-icon>
                        <strong>App Info</strong>
                    </div>
                </Link>
                <a href="#!" className="item" onClick={handleLogout}>
                    <div className="col">
                        <ion-icon name="log-out-outline"></ion-icon>
                        <strong>Logout</strong>
                    </div>
                </a>
            </div> */}
        </div>
    );
}

const mapStateToProps = ({ offcanvas, appToast, websiteData, utilities }) => {
    const {
      isVisible,
      showAddPageModal,
      isSidebarVisible,
      sidebarToggle
    } = offcanvas
    const {
        toastMessage,
        background,
        icon,
        position,
        timeout
      } = appToast
      const {
        appearance: websiteDataAppearance,
        blocks: websiteDataBlocks,
        contents: websiteDataContents
      } = websiteData
      const {
        activeMenu
      } = utilities
    return {
        offcanvasVisible: isVisible,
        toastMessage,
        background,
        icon,
        position,
        timeout,
        websiteDataAppearance,
        websiteDataBlocks,
        websiteDataContents,
        showAddPageModal,
        isSidebarVisible,
        sidebarToggle,
        activeMenu
    }
  }
  
  const mapDispatchToProps = {
    showTemplateOffcanvas,
    hideTemplateOffcanvas,
    setWebsiteData,
    showAddOffcanvas,
    showAppToast,
    addPageModal,
    showAppearance,
    hideSidebar,
    logout,
    showPageSetting,
    showDeleteModal,
    showSidebarToggle,
    setActiveMenu,
    showSwitchModal,
    showClonePageModal,
    setADDClonePageProps
  }

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)